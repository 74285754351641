import styles from './NotificationPopup.module.scss';
import { toAbsoluteUrl } from 'helpers';
import ReactMarkdown from 'react-markdown';
import NotificationDto from 'services/model/dto/notifications/notificationDto';

interface NotificationPopupProps {
  notification: NotificationDto;
  setNotificationPopup: (value: NotificationDto | null) => void;
}

const NotificationPopup = ({ notification, setNotificationPopup }: NotificationPopupProps) => {
  return (
    <div className={styles.notificationPopupWrap}>
      <div className={styles.notificationPopup}>
        <button className={styles.closePopup} onClick={() => setNotificationPopup(null)}>
          <img src={toAbsoluteUrl('/assets/icons/closeIcon.png')} alt='close' />
        </button>
        <div className={styles.notificationHeader}>
          <span className={styles.notificationTitle}>{notification.title}</span>
        </div>
        <div className={styles.notificationContent}>
          <ReactMarkdown>
            {notification?.detail ?? ''}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default NotificationPopup;
