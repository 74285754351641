export const navRoutes = [
  {
    name: 'dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    beta: false,
    new: false,
    modPerms: [],
    modAllowed: false,
  },
  {
    name: 'clipInsights',
    path: '/clip-insights',
    icon: 'clipinsights',
    beta: false,
    new: true,
    modPerms: [],
    modAllowed: false,
  },
  {
    name: 'liveConsole',
    path: '/live-console',
    icon: 'liveconsole',
    beta: false,
    new: false,
    modPerms: [],
    modAllowed: true,
  },
  {
    name: 'campaigns',
    path: '/campaigns',
    icon: 'campaigns',
    beta: false,
    new: false,
    modPerms: [],
    modAllowed: false,
  },
  // {
  //   name: 'notifications',
  //   path: '/notifications',
  //   icon: 'notifications',
  //   beta: false,
  //   new: true,
  // },
  {
    name: 'leaderboard',
    path: '/leaderboard',
    icon: 'leaderboard',
    beta: false,
    new: false,
    modPerms: [],
    modAllowed: false,
  },
  {
    name: 'payments',
    path: '/payments',
    icon: 'payments',
    beta: false,
    new: false,
    modPerms: [],
    modAllowed: false,
  },
  {
    name: 'helpCenter',
    path: '/help-center',
    icon: 'helpcenter',
    beta: false,
    new: false,
    modPerms: [],
    modAllowed: false,
  },
]
