import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from 'helpers'
//STYLES
import styles from './CampaignPopupMenu.module.scss'
import {Campaign} from 'components/CampaignPopup/CampaignPopup'

type CampaignPopupMenuProps = {
  isPopupTab: string
  setIsPopupTab: (value: string) => void
  campaign: Campaign | null
}

const CampaignPopupMenu = ({isPopupTab, setIsPopupTab, campaign}: CampaignPopupMenuProps) => {
  const {t} = useTranslation()
  return (
    <div className={styles.menu}>
      <div className={styles.brandIcon}>
        <img
          src={campaign?.client_logo}
          alt='brand'
          onError={(e) => {
            e.currentTarget.src = toAbsoluteUrl(
              '/assets/icons/Dashboard/Brands/defaultBrandAvatar.png'
            )
          }}
        />
      </div>
      <div
        className={classNames(styles.menuItem, {[styles.active]: isPopupTab === 'campaign'})}
        onClick={() => setIsPopupTab('campaign')}
      >
        <img
          src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC0ElEQVRoge2ZO2gUURSGY4IaJYoRIRFsEhuDjZWCWojpBDuVaGewUTFICHYqgjHYxLXQEBULg4iiIEgKEbJqEbAQfGChKEE02Ki4vqL4OP6HuYNnh5mdx30J7g8/uztz98z379ydufdOQ0Nd/7GIaBbcA2/j9755cgvQO+mvenzz5BagJ0SAXQltGuES/Bk+6poxUYBpg38qeH5tj2nD8OdEyIptqHb4FPwS/go/hPfBTTFtdwuwcgZ4Vskm/HL4FcXrBjw30r4s9u/JAM+fG20GKFNtXZNnAu8/qu1V3ccXfHcKfKjzpC6XeD0Mv4OPRGqdcAqvDjpB2TWcUuu7a/i1OeBDHaxRjy+Z3K2GrcOrA44XCMDqtw6XAX4V/LtgAP5er+8AVwvCh+KussUXfBf8SzMAawbeGFP/GFX/oUl9HjQV4IIB+FB8T1gdqf8pqa0J+E74h8EArLfwSnGMQYo/A/oDOhQ5Yxg+1DTcoQ2YAr8M/mYpAOs5vNRmgJJF+FCP4cU24JdQMLFwoXtwi+kAQ47gQ92kmLlEUfhWuOI4AKvPVIBDHuBZj0zAt1BwnfahGRMBBjzBs6Z14ZvhNx4DnNYNsNcjPN+Z23TgZ1OwROJDt+FO3V+/1zE0D89vwZu1wBV8E/zUETgPnUfhFdrgIsB2B+C8EHYAXmQMXAS46yDASePgIsAXw7A8/+U5dL/Y9sxmgBeGwHn8xKttHarufLGPZ1h21n5I/+47RcGvvTBSd55ow2fFzGgzJgAvso4VAJ+EtyaBYfs60fa1FfhIiJEc8Hcy1Lwi2o9ZDaAOyA/ijicAf4DPUvX60ECNWn2R72+wHkAcfAf8AH4P34f3wwvUvugqBT8L4K7CQ5E58Br4UqTNdWfwaaLgyjKZ1r+EnsCtvrmrxGcDvpgBfvyfg5cC3Hr4MgVPX0JVVLfa5JsvlyiYipof59RVlzn9AUs+Mmnaqi8yAAAAAElFTkSuQmCC'
          alt='menu'
        />
        <span className={styles.menuText}>{t('CampaignsPopup.campaign')}</span>
      </div>
      {campaign?.campaign_status !== 'past' && (
        <div
          className={classNames(styles.menuItem, {[styles.active]: isPopupTab === 'settings'})}
          onClick={() => setIsPopupTab('settings')}
        >
          <img
            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABTElEQVRoge2ZQU6DUBRFa1hCcQmmqe6A1sSVlN8liDGOTBjWHXRuN2Eamu6kjLqKxudFPgkq0J8APl76TnInDC73BvjvA6ORoig/ICIPMtDLwJRl8lwKLGm4GJcCMXfKBmItwIwW4EYLnOMATaAbKOki8S96L7Aq+XjW69RJ9JzeC/w5AY49QMdu8jMUsJ7X0IfYAta3uKU+RRYo+b9LL7ARWYAk30Ik+SEmYcvoW8lH5CBLKd9G3JHQrUTfaAFutAA3l1HAcKdsIHQpkA2gEFoNTAty+bSoSACX8h6KrObceZyhfFu8r3i4dpDPna8RBLyqCV+QcGf8hup/cKzPLHEZM+txCz1XeAz+B8eT9UhbeDRhXAq0GWRRzwVaDTKXrwmB9ZhCrxUevIOM8tWmjm0r8/8AIX2qflXcQmPufM4g7Ax6tAq48yjKJfMFLOQu0W6bkywAAAAASUVORK5CYII='
            alt='menu'
          />
          <span className={styles.menuText}>{t('CampaignsPopup.settings')}</span>
        </div>
      )}
    </div>
  )
}

export default CampaignPopupMenu
