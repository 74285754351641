import styles from './UserNavigation.module.scss'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'

//COMPONENTS
import DateFilter from '../DateFilter/DateFilter'

// Context
import {AppLevelContext} from 'context/AppLevelContext'

const UserNavigation = () => {
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()
  return (
    <div className={styles.userNavigation}>
      <div className={styles.userAvatar}>
        <img src={user?.link_to_logo} alt='avatar' />
      </div>
      <div className={styles.userMessage}>
        <span className={styles.messageTitle}>
          {t('DashboardPage.helloName', {
            NAME: user?.first_name ?? user?.title,
          })}{' '}
          👋
        </span>
        <span className={styles.messageDesc}>{t('DashboardPage.weHopeGreatDay')}</span>
      </div>
      <div className={styles.dateFilter}>
        <DateFilter />
      </div>
    </div>
  )
}

export default UserNavigation
