import styles from './StepInfo.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useContext, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'

// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
import SettingsContext from 'context/SettingsContext'
import EnumSetupPreview from 'services/model/enum/enumSetupPreview'
import {set} from 'lodash'

const StepInfo = (props: {
  activeTab: number
  checkOverlayStatus: EnumSetupPreview | null
  setCheckOverlayStatus: (arg0: EnumSetupPreview | null) => void
}) => {
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const {
    setupPreviewConfirmed,
    updateSetupPreviewConfirmed,
    checkSetupPreviewConfirmedDiff,
    checkSetupPreviewConfirmedSave,
  } = useContext(SettingsContext)

  const [newSetupPreviewConfirmed, setNewSetupPreviewConfirmed] = useState<EnumSetupPreview>(
    setupPreviewConfirmed ?? EnumSetupPreview.NotTested
  )

  useEffect(() => {
    if (setupPreviewConfirmed) {
      setNewSetupPreviewConfirmed(setupPreviewConfirmed)
      props.setCheckOverlayStatus(setupPreviewConfirmed)
    }
  }, [setupPreviewConfirmed])

  return (
    <div className={styles.stepInfo}>
      <div className={styles.stepInfo_text}>
        {(props.activeTab === 1 && (
          <>
            <p>
              <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepInfoIcon.png')} alt='info' />{' '}
              {t('SettingsPage.copyLinkInstruction')}
            </p>
            <button
              className={styles.copyLinkBtn}
              title={'' + t('SettingsPage.clickToCopyLink')}
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://api.livad.stream/streamers/${user?.streamer_id}/ad_display_screen`
                )
                toast.success('Link copied to clipboard')
              }}
            >
              <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/copyIcon.png')} alt='copy' />
              https://api.livad.stream/streamers/{user?.streamer_id}/ad_display_screen
            </button>
          </>
        )) ||
          (props.activeTab === 2 && (
            <p>
              <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepInfoIcon.png')} alt='info' />{' '}
              {t('SettingsPage.addNewSourceInstruction')}
            </p>
          )) ||
          (props.activeTab === 3 && (
            <p>
              <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepInfoIcon.png')} alt='info' />{' '}
              {t('SettingsPage.pasteLinkInstruction')}
              <br />
              <br />
              <img
                src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepWarnIcon.png')}
                alt='warn'
              />{' '}
              {t('SettingsPage.differentResolutionInstruction')}
            </p>
          )) ||
          (props.activeTab === 4 && (
            <p>
              <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepInfoIcon.png')} alt='info' />{' '}
              {t('SettingsPage.placementInstruction')}
            </p>
          )) ||
          (props.activeTab === 5 && (
            <p>
              <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepInfoIcon.png')} alt='info' />{' '}
              {t('SettingsPage.lockSourceInstruction')}
            </p>
          )) ||
          (props.activeTab === 6 && (
            <>
              <p>
                <img
                  src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepInfoIcon.png')}
                  alt='info'
                />{' '}
                {t('SettingsPage.demoAdInstruction')}
              </p>
              <div className={styles.checkOverlayBtns}>
                <button
                  disabled={isLoading}
                  className={classNames(styles.checkOverlayBtn, styles.cantSee, {
                    [styles.active]: newSetupPreviewConfirmed === EnumSetupPreview.Error,
                  })}
                  onClick={async () => {
                    setIsLoading(true)
                    setNewSetupPreviewConfirmed(EnumSetupPreview.Error)
                    await updateSetupPreviewConfirmed(EnumSetupPreview.Error)
                    setIsLoading(false)
                    props.setCheckOverlayStatus(EnumSetupPreview.Error)
                  }}
                >
                  {t('SettingsPage.cantSeeAd')}
                </button>
                <button
                  disabled={isLoading}
                  className={classNames(styles.checkOverlayBtn, styles.canSee, {
                    [styles.active]: newSetupPreviewConfirmed === EnumSetupPreview.Confirmed,
                  })}
                  onClick={async () => {
                    setIsLoading(true)
                    setNewSetupPreviewConfirmed(EnumSetupPreview.Confirmed)
                    await updateSetupPreviewConfirmed(EnumSetupPreview.Confirmed)
                    setIsLoading(false)
                    props.setCheckOverlayStatus(EnumSetupPreview.Confirmed)
                  }}
                >
                  {t('SettingsPage.canSeeAd')}
                </button>
              </div>
              {props.checkOverlayStatus === EnumSetupPreview.Error && (
                <div className={styles.overlayError}>
                  <img src={toAbsoluteUrl('/assets/icons/Sidebar/helpcenter.png')} alt='help' />
                  Join our Discord server for help:
                  <a href='https://discord.gg/livad' target='_blank'>
                    discord.gg/livad
                  </a>
                </div>
              )}
            </>
          ))}
      </div>
    </div>
  )
}

export default StepInfo
