import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import styles from '../DashboardTable/DashboardTable.module.scss'
import {useContext} from 'react'
import DashboardContext from 'context/DashboardContext'

const Brands = () => {
  const {dashboardData, tableView, setTableView, setSelectedBrandId, selectBrand} =
    useContext(DashboardContext)
  return (
    <>
      {tableView === 'brands' && (
        <>
          {/* Table Sub Header */}
          <tr className={classNames(styles.brandView, styles.header)}>
            <th>
              <img src={toAbsoluteUrl('/assets/icons/Dashboard/Brands/brandIcon.png')} alt='icon' />
              Brand
            </th>
            <th>
              <img src={toAbsoluteUrl('/assets/icons/Dashboard/Brands/viewIcon.png')} alt='icon' />
              Views
            </th>
            <th>
              <img src={toAbsoluteUrl('/assets/icons/Dashboard/Brands/clickIcon.png')} alt='icon' />
              Clicks
            </th>
            <th>
              <img src={toAbsoluteUrl('/assets/icons/Dashboard/Brands/ctrIcon.png')} alt='icon' />
              CTR
            </th>
            <th>
              <img
                src={toAbsoluteUrl('/assets/icons/Dashboard/Brands/revenueIcon.png')}
                alt='icon'
              />
              Revenue
            </th>
          </tr>

          {/* Actual Brand Mapping */}

          {dashboardData?.clients.map((client, index) => (
            <tr
              className={styles.brandView}
              onClick={() => {
                selectBrand(client.client_id)
              }}
              key={index}
            >
              <td>
                <img
                  src={
                    client.client_logo
                      ? client.client_logo
                      : toAbsoluteUrl('/assets/icons/Dashboard/Brands/defaultBrandAvatar.png')
                  }
                  alt='brand'
                  onError={(e) => {
                    e.currentTarget.src = toAbsoluteUrl(
                      '/assets/icons/Dashboard/Brands/defaultBrandAvatar.png'
                    )
                  }}
                />
                {client.client_name}
              </td>
              <td>{client.views.toLocaleString()}</td>
              <td>{client.clicks.toLocaleString()}</td>
              <td>{client.CTR}%</td>
              <td>
                {client.revenue.toLocaleString()} {client.currency_sign}
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  )
}

export default Brands
