import classNames from 'classnames'
import { toAbsoluteUrl } from 'helpers'
import styles from '../LiveNotifications.module.scss'
import { useTranslation, Trans } from 'react-i18next'

import LiveNotificationContext from 'context/LiveNotificationContext'

import GetDonationsForStreamerResponse from 'services/model/response/donation/getDonationsForStreamerResponse'
import EnumDonationConsent from 'services/model/enum/enumDonationConsent'
import EnumDonationAssetType from 'services/model/enum/enumDonationAssetType'
import { useContext, useEffect, useRef, useState } from 'react'
import EnumDonationStatus from 'services/model/enum/enumDonationStatus'
import formatNotificationTime from 'helpers/formatNotificationTime'

//COMPONENTS
import AssetPreview from './AssetPreview/AssetPreview'
import EnumDonationNameType from 'services/model/enum/enumDonationNameType'
import Loading from 'components/Shared/Loading/Loading'

interface SingleDonationProps {
  key: number
  donation: GetDonationsForStreamerResponse
}

const SingleDonation = ({ donation }: SingleDonationProps) => {
  const {
    updateDonationConsent,
    replayDonation
  } = useContext(LiveNotificationContext)
  const [previewAsset, setPreviewAsset] = useState(false)
  const [previewAssetSrc, setPreviewAssetSrc] = useState('')
  const [assetType, setAssetType] = useState('')
  const [consentLoading, setConsentLoading] = useState(false)
  const { t } = useTranslation()
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const videoElem = videoRef.current;

    if (!videoElem) return;

    const intervalId = setInterval(() => {
      if (!videoElem.muted) {
        videoElem.pause();
      }
    }, 100);  // Check every 100ms

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div className={classNames(styles.liveNotifications_item, styles.typeDonation)}>
      <div className={styles.donationInfo}>
        {
          donation.nameType === EnumDonationNameType.USERNAME && donation.fan.logo ? (
            <img src={donation.fan.logo} alt='avatar' />
          ) : (
            <img src={toAbsoluteUrl('/assets/icons/Leaderboard/defaultProfileImg.png')} alt='avatar' />
          )
        }
        <Trans
          i18nKey='LiveConsolePage.nameDonatedAmount'
          values={{
            name: donation.fan.displayName,
            amount: donation.currency.currencySymbol + donation.amount,
          }}
        >
          <span></span>
          <b></b>
        </Trans>
        {donation.takeover && (
          <>
            <div
              className={styles.takeoverItem}
              style={{
                backgroundImage: `url("${donation.takeover.logo}")`,
              }}
            >
              {donation.takeover.name}
            </div>
          </>
        )}
        {
          donation.consent === EnumDonationConsent.YES && donation.consentByMod ? (
            <Trans
              i18nKey='LiveConsolePage.acceptedBy'
              values={{
                name: donation.consentByMod.displayName,
              }}
            >
              <span></span>
            </Trans>
          ) : null
        }

        <div className={styles.rightSection}>
          {/* <div className={classNames(styles.donationStatus, styles.pending)}>PENDING</div> */}
          <div className={styles.date}>{formatNotificationTime(donation.createdAt)}</div>
          <div
            className={classNames(styles.donationStatus, {
              [styles.pending]: donation.status === EnumDonationStatus.PENDING,
              [styles.completed]: donation.status === EnumDonationStatus.COMPLETED,
              [styles.denied]:
                donation.status === EnumDonationStatus.REJECTED ||
                donation.status === EnumDonationStatus.FAILED ||
                donation.status === EnumDonationStatus.CANCELLED,
            })}
          >
            {donation.status === EnumDonationStatus.PENDING ||
              donation.status === EnumDonationStatus.PROCESSING ? (
              <img
                src={toAbsoluteUrl('/assets/icons/LiveConsole/loadingStatus.gif')}
                alt='loading'
              />
            ) : null}
            {t(`LiveConsolePage.${donation.status.toLocaleLowerCase()}`)}
          </div>
        </div>
      </div>
      {/* If you want to add an asset to the donation notification, add the following div to the parent div. */}
      {donation.asset ? (
        <>
          {donation.asset.type == EnumDonationAssetType.Image && (
            <div
              className={styles.donationAsset}
              onClick={() => {
                setAssetType('image')
                setPreviewAsset(true)
                setPreviewAssetSrc((donation.asset || { url: 'default_url' }).url)
              }}
            >
              <img
                src={donation.asset.url || toAbsoluteUrl('/assets/icons/LiveConsole/assetIcon.png')}
                alt='asset'
              />
            </div>
          )}
          {donation.asset.type == EnumDonationAssetType.Video && (
            <div
              className={styles.donationAsset}
              onClick={() => {
                setAssetType('video')
                setPreviewAsset(true)
                setPreviewAssetSrc((donation.asset || { url: 'default_url' }).url)

                // Set muted to true

              }}
            >
              <video ref={videoRef} src={donation.asset.url} autoPlay={true} muted loop>
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </>
      ) : null}

      <div className={styles.donationMessage}>{donation.message}</div>
      {donation.status === EnumDonationStatus.COMPLETED && (
        <div className={styles.donationAction}>
          <button onClick={() => {
            replayDonation(donation.id)
          }} className={styles.replayBtn}>
            <img src={toAbsoluteUrl('/assets/icons/LiveConsole/replayIcon.png')} alt='icon' />
            {t('LiveConsolePage.replay')}
          </button>
        </div>
      )}
      {donation.consent === EnumDonationConsent.UNKNOWN && (
        <div className={styles.donationAction}>
          {
            consentLoading ? (
              null
            ) : (
              <>

                <button
                  onClick={async () => {
                    setConsentLoading(true)
                    try {
                      await updateDonationConsent({
                        donationId: donation.id,
                        consent: EnumDonationConsent.NO,
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    setConsentLoading(false)
                  }}
                  className={styles.declineBtn}
                >
                  <img src={toAbsoluteUrl('/assets/icons/LiveConsole/declineIcon.png')} alt='icon' />
                  {t('LiveConsolePage.decline')}
                </button>
                <button
                  onClick={async () => {
                    setConsentLoading(true)
                    try {
                      await updateDonationConsent({
                        donationId: donation.id,
                        consent: EnumDonationConsent.YES,
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    setConsentLoading(false)
                  }}
                  className={styles.acceptBtn}
                >
                  <img src={toAbsoluteUrl('/assets/icons/LiveConsole/acceptIcon.png')} alt='icon' />
                  {t('LiveConsolePage.accept')}
                </button>
              </>
            )
          }
        </div>
      )}
      {/* {donation.consent === EnumDonationConsent.YES && (
        <div className={styles.donationAction}>
          <button className={styles.acceptBtn}>
            <img src={toAbsoluteUrl('/assets/icons/LiveConsole/acceptIcon.png')} alt='icon' />
            Accepted
          </button>
        </div>
      )}
      {donation.consent === EnumDonationConsent.NO && (
        <div className={styles.donationAction}>
          <button className={styles.declineBtn}>
            <img src={toAbsoluteUrl('/assets/icons/LiveConsole/declineIcon.png')} alt='icon' />
            Declined
          </button>
        </div>
      )} */}
      {previewAsset && (
        <AssetPreview
          setAssetType={setAssetType}
          assetType={assetType}
          previewAsset={previewAsset}
          setPreviewAsset={setPreviewAsset}
          previewAssetSrc={previewAssetSrc}
        />
      )}
    </div>
  )
}

export default SingleDonation
