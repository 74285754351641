import {useState, useContext, useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {loadingHandler} from 'helpers'
import axios from 'axios'
// STYLES
import styles from './GamePopup.module.scss'

// COMPONENTS
import GamePopupMenu from './components/GamePopupMenu/GamePopupMenu'
import GameDetail from './components/GameDetail/GameDetail'
import Loading from 'components/Shared/Loading/Loading'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
import {EnumGameType} from 'services/model/enum/enumGameType'

export interface Campaign {
  CPM: number
  asset: string
  campaign_brief: string
  campaign_id: number
  campaign_name: string
  campaign_status: string
  campaign_type: string
  client_id: number
  client_logo: string
  client_name: string
  end_date: string
  is_featured: number
  left_padding: string
  payable_date: string
  pretext: string
  question_text: string
  redirect_link: string
  start_date: string
  streamer_logo: string
  streamer_status: string
  top_padding: string
  width: string
}

type StreamerList = {
  count: number
  streamers:
    | [
        {
          link_to_logo: string
          title: string
        }
      ]
    | null
}

const GamePopup = () => {
  let {gameType} = useParams<{
    gameType: EnumGameType
  }>()
  const navigate = useNavigate()
  const location = useLocation()
  const [isPopupTab, setIsPopupTab] = useState('detail')

  const routePath = location.pathname.split('/')[1]

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <div className={styles.popupWrap}>
      <div className={styles.popup}>
        {gameType ? (
          <>
            <GamePopupMenu
              isPopupTab={isPopupTab}
              setIsPopupTab={setIsPopupTab}
              gameType={gameType}
            />
            <GameDetail gameType={gameType} />
          </>
        ) : null}
        <button className={styles.closePopup} onClick={() => navigate(`/${routePath}`)}>
          <img
            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjUlEQVRoge2ZW0/bQBCF4a3QQoEWCuWvACptfzGqUKDcCgRaCgF6o32KCERRLsS5UF7DGdhFTmSH2d2xzYOPNC+RfeY7G2e9uxkYSJUqVaonL8/z3qPOUZ/K5fKLqPrUarXRer2eQZ8L1DsRUxjOw6yN6qjKUiMR8+4+L+G97+vznwbO1XSuB/6u8Plho9EYF2K/g0d97e3jFAKGC7j5OsBUh6CGY67wNBDw+h7WhxiIxdgYN5b6mOoQBy7fBO6dgMfRY31QlzYBdhnG+nGaMPVvNpuvcG+O2WPTJsAYjTCzwXGr1XrN9VaPzSHTO0dhjQP4QnzjNEKdIMTkY550Da49ZcIf2Xy7XeozQwTVn0qlMhPm1W63p3DNjygfzdAQXvcc3a/OgkIo+J9MD/l3DRnCeI85en+r1epbH/wbfP4rMXitUqn0nGYEZoh/AJlV8L+Z8LsIPhIJvBY1QKMdbggqJvwWDVCk8FrFYnEYYOtMME5tR7lI7BdiTQD+S+zwPSE+28Lj3pVCoTCUCLwWAQBk1QI+kzi8lgqRMQiwnM/nnyXN/SDDt7VeIojtJ5ykXnDct7Q/hOimKFb4JxGCXjo0BQpMo3uRLR1igI8/hFpKZJmPyAF3U0Seka+D1MhvMeFzas9rMkNFF8JwJZrzb0ZUCO7OTj6EIfxx0B42sRBqveMEbxnCfW+g4Dck4LU8g9MOpxAm8KgTk6OPWELgxiXJke+Vwakc1ZJNgIL0yIeE4JzOXdgEWPTuT4dD4U1O4xxCEMOilXnAfwO6TiXgtdRvIuiE+gb1wck84Jj9DHvYaSH2BwWEuMG381HEnIxgfkVzOOf801b0ewJ4Fn1qYvBanU5nUNQwVapUqUi3aAeLpjOKEbgAAAAASUVORK5CYII='
            alt='close'
          />
        </button>
      </div>
    </div>
  )
}

export default GamePopup
