import {useTranslation} from 'react-i18next'
import styles from './Referrals.module.scss'
import {useContext, useEffect, useState} from 'react'
import {loadingHandler} from 'helpers'

//COMPONENTS
import Earnings from './components/Earnings/Earnings'
import ReferralsList from './components/ReferralsList/ReferralsList'
import Loading from 'components/Shared/Loading/Loading'
import LinkHeader from './components/LinkHeader/LinkHeader'
// Context
import ReferralsContext from 'context/ReferralsContext'

const Referrals = () => {
  const {getAllReferrals, referralsList} = useContext(ReferralsContext)
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    loadingHandler([getAllReferrals()], setPageLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {t} = useTranslation()
  document.title = t('Pages.recruitFriend') + ' • LIVAD'
  return (
    <div className={styles.referrals}>
      {pageLoading ? (
        <Loading />
      ) : (
        <>
          {referralsList?.referral_available && <LinkHeader />}
          <Earnings />
          <ReferralsList />
        </>
      )}
    </div>
  )
}

export default Referrals
