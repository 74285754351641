import styles from './Payability.module.scss'
import {useState, useEffect, useContext} from 'react'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'

// Context
import {AppLevelContext} from 'context/AppLevelContext'
import SingleCampaign from './SingleCampaign'

const MonthBox = ({data}: any) => {
  const {user} = useContext(AppLevelContext)
  const [isToggle, setIsToggle] = useState(false)
  const [monthName, setMonthName] = useState('')
  const [totalPayable, setTotalPayable] = useState(0)
  const [totalLocked, setTotalLocked] = useState(0)
  const {t} = useTranslation()

  const getMonth = () => {
    if (data) {
      for (let i = 0; i < 1; i++) {
        setMonthName(data[i].payable_date_formatted)
      }
    }
  }

  useEffect(() => {
    if (data) {
      let totalPayable = 0
      let totalLocked = 0

      data.map(({is_payable, revenue}: any) => {
        if (is_payable) {
          totalPayable += revenue
        } else {
          totalLocked += revenue
        }
      })

      setTotalPayable(totalPayable)
      setTotalLocked(totalLocked)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getMonth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className={styles.payabilityItem}>
      <div className={styles.payabilityItem_header} onClick={() => setIsToggle(!isToggle)}>
        <img
          src={toAbsoluteUrl('/assets/icons/Payments/toggle_icon.png')}
          alt='toggle'
          className={isToggle ? classNames(styles.toggleIcon, styles.active) : styles.toggleIcon}
        />
        <div className={styles.payabilityDate}>{monthName}</div>
        <div className={styles.totalRevenue} title={'' + t('PaymentsPage.totalRevenue')}>
          {t('PaymentsPage.totalAmount', {
            AMOUNT: (totalPayable + totalLocked).toLocaleString() + ' ' + user?.currency_sign,
          })}
        </div>
        <div className={styles.payableRevenue} title={'' + t('PaymentsPage.payableRevenue')}>
          <img src={toAbsoluteUrl('/assets/icons/Payments/payableStatus.png')} alt='payable' />
          {totalPayable.toLocaleString()} {user?.currency_sign}
        </div>
        <div className={styles.lockedRevenue} title={'' + t('PaymentsPage.lockedRevenue')}>
          <img src={toAbsoluteUrl('/assets/icons/Payments/lockedStatus.png')} alt='locked' />
          {totalLocked.toLocaleString()} {user?.currency_sign}
        </div>
      </div>
      <div
        className={styles.payabilityTable}
        style={isToggle ? {display: 'flex'} : {display: 'none'}}
      >
        <table>
          <thead>
            <tr>
              <th>{t('PaymentsPage.campaignName')}</th>
              <th>{t('PaymentsPage.payableDate')}</th>
              <th>{t('PaymentsPage.payableStatus')}</th>
              <th>{t('PaymentsPage.totalRevenue')}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item: any, index: number) => (
              <SingleCampaign item={item} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MonthBox
