import styles from './PollDemo.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {Campaign} from 'components/CampaignPopup/CampaignPopup'

type PollDemoProps = {
  campaign: Campaign | null
}

const PollDemo = ({campaign}: PollDemoProps) => {
  const convertVhToPercent = (vh: string) => {
    // check if the string is a valid vh value
    if (!vh.includes('vh')) return vh
    // remove the vh part of the string
    const value = vh.slice(0, -2)
    // convert to percentage
    return `${value}%`
  }

  return (
    <div className={styles.pollDemo}>
      <div className={styles.demoScreen}>
        <div className={styles.content}>
          {campaign?.answers &&
          campaign.answers.length &&
          campaign.answers[0].asset_type === 'video' ? (
            <video
              key={campaign?.asset}
              autoPlay
              loop
              muted
              disablePictureInPicture
              playsInline
              style={{
                width:
                  (campaign.answers && campaign.answers.length
                    ? campaign.answers[0].width
                    : 'auto') || 'auto',
                left:
                  (campaign.answers && campaign.answers.length
                    ? campaign.answers[0].left_padding
                    : undefined) || undefined,
                top:
                  (campaign.answers && campaign.answers.length && campaign.answers[0].top_padding
                    ? convertVhToPercent(campaign.answers[0].top_padding)
                    : undefined) || undefined,
                height:
                  campaign.answers &&
                  campaign.answers.length &&
                  campaign.answers[0].width === 'auto'
                    ? '100%'
                    : 'auto',
              }}
            >
              <source src={campaign?.answers && campaign.answers.length && campaign.answers[0].asset ? campaign.answers[0].asset : undefined} type='video/webm' />
              Your browser does not support the video.
            </video>
          ) : campaign?.answers &&
            campaign.answers.length &&
            campaign.answers[0].asset_type === 'image' ? (
            <img
              src={
                campaign?.answers && campaign.answers.length && campaign.answers[0].asset
                  ? campaign.answers[0].asset
                  : undefined
              }
              alt='campaign'
              style={{
                width:
                  (campaign.answers && campaign.answers.length
                    ? campaign.answers[0].width
                    : 'auto') || 'auto',
                left:
                  (campaign.answers && campaign.answers.length
                    ? campaign.answers[0].left_padding
                    : undefined) || undefined,
                top:
                  (campaign.answers && campaign.answers.length && campaign.answers[0].top_padding
                    ? convertVhToPercent(campaign.answers[0].top_padding)
                    : undefined) || undefined,
                height:
                  campaign.answers &&
                  campaign.answers.length &&
                  campaign.answers[0].width === 'auto'
                    ? '100%'
                    : 'auto',
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.previewChat}>
        <div className={styles.pollSession}>
          <div className={styles.title}>{campaign?.question_text ?? campaign?.pretext}</div>
          <div className={styles.pollOptions}>
            {campaign?.answers?.map((answer, index) => {
              return (
                <div key={index} className={styles.option}>
                  <div className={styles.optionText}>{answer?.text}</div>
                </div>
              )
            })}
          </div>
          <div className={styles.voteBtn}>Vote</div>
          <div className={styles.timerBar}></div>
        </div>
        <div className={styles.chatLoop}>
          <img
            className={styles.chatLoop}
            src={toAbsoluteUrl('/assets/icons/Campaigns/prod_chatloop.gif')}
            alt='chat'
          />
        </div>
      </div>
    </div>
  )
}

export default PollDemo
