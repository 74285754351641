import { createBaseFanApiService } from "./baseFanApiService"
import GetDonationsForStreamerRequest from "./model/request/donation/getDonationsForStreamerRequest"
import UpdateDonationConsentAsStreamerRequest from "./model/request/donation/updateDonationConsentRequest"
import GetDonationsForStreamerResponse from "./model/response/donation/getDonationsForStreamerResponse"

export const createDonationService = () => {
  const baseService = createBaseFanApiService('/streamerDashboard')

  const getDonations = async (req: GetDonationsForStreamerRequest): Promise<Array<GetDonationsForStreamerResponse>> => {
    const response = await baseService.post<Array<GetDonationsForStreamerResponse>>('/getDonations', req)
    return response.data
  }

  const updateDonationConsent = async (req: UpdateDonationConsentAsStreamerRequest): Promise<GetDonationsForStreamerResponse> => {
    const response = await baseService.post<GetDonationsForStreamerResponse>('/updateDonationConsent', req)
    return response.data
  }

  const replayDonation = async (donationId: string): Promise<void> => {
    const response = await baseService.post<void>('/replayDonation', { donationId })
  }

  return {
    getDonations,
    updateDonationConsent,
    replayDonation
  }

}
