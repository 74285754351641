import styles from '../LiveNotifications.module.scss'
import classNames from 'classnames'
import Countdown from 'react-countdown'
import {toAbsoluteUrl} from 'helpers'
import GameSessionDto from 'services/model/dto/gameSessionDto'
import {EnumGameSessionStatus} from 'services/model/enum/enumGameSessionStatus'
import {EnumGameType} from 'services/model/enum/enumGameType'
import {useEffect, useState} from 'react'
import formatNotificationTime from 'helpers/formatNotificationTime'
import countdownRenderer from 'helpers/countdownRenderer'
import {useTranslation, Trans} from 'react-i18next'

interface SingleGameSessionProps {
  key: number
  gameSession: GameSessionDto
}
const SingleGameSession = ({gameSession}: SingleGameSessionProps) => {
  const [endTime, setEndTime] = useState<Date | null>(null)
  const {t} = useTranslation()
  useEffect(() => {
    if (gameSession.endTime) {
      setEndTime(new Date(gameSession.endTime))
    } else if (gameSession.startTime) {
      setEndTime(new Date(new Date(gameSession.startTime).getTime() + 60000))
    }
  }, [gameSession.startTime, gameSession.endTime])
  return (
    <div className={classNames(styles.liveNotifications_item, styles.typeGame)}>
      <div className={styles.gameInfo}>
        <span className={styles.gameName}>
          <img
            src={
              gameSession.gameType == EnumGameType.Trivia
                ? toAbsoluteUrl('/assets/icons/Games/game_triviaGame.png')
                : gameSession.gameType == EnumGameType.Flag
                ? toAbsoluteUrl('/assets/icons/Games/game_findTheFlag.png')
                : gameSession.gameType == EnumGameType.Taboo
                ? toAbsoluteUrl('/assets/icons/Games/game_reverseTaboo.png')
                : ''
            }
            alt='game'
            className={styles.gameIcon}
          />
          <Trans
            i18nKey={'LiveConsolePage.gameSession'}
            values={{
              game:
                gameSession.gameType == EnumGameType.Trivia
                  ? 'Trivia'
                  : gameSession.gameType == EnumGameType.Flag
                  ? 'Find the Flag'
                  : gameSession.gameType == EnumGameType.Taboo
                  ? 'Reverse Taboo'
                  : '',
            }}
          >
            <b></b>
          </Trans>
        </span>
        <div className={styles.rightSection}>
          {/* <div className={classNames(styles.donationStatus, styles.pending)}>PENDING</div> */}
          <div className={styles.date}>{formatNotificationTime(gameSession.createdAt)}</div>
          <div
            className={classNames(styles.gameStatus, {
              [styles.ready]: gameSession.status == EnumGameSessionStatus.Ready,
              [styles.inProgress]: gameSession.status == EnumGameSessionStatus.InProgress,
              [styles.ended]: gameSession.status == EnumGameSessionStatus.Ended,
              [styles.cancelled]: gameSession.status == EnumGameSessionStatus.Cancelled,
            })}
          >
            {gameSession.status == EnumGameSessionStatus.InProgress ? (
              <img
                src={toAbsoluteUrl('/assets/icons/LiveConsole/loadingStatus.gif')}
                alt='loading'
              />
            ) : null}
            {t(`LiveConsolePage.${gameSession.status.toLocaleLowerCase()}`)}
          </div>
        </div>
      </div>
      <div className={styles.gameQuestion}>
        {gameSession.status == EnumGameSessionStatus.InProgress && endTime ? (
          <span className={styles.gameTimer}>
            <img src={toAbsoluteUrl('/assets/icons/LiveConsole/timerIcon.png')} alt='timer' />
            <Countdown renderer={countdownRenderer} date={endTime} />
          </span>
        ) : null}

        {[EnumGameType.Flag, EnumGameType.Trivia].includes(gameSession.gameType) ? (
          <>
            <b>{t('LiveConsolePage.question')}</b>"{gameSession.question}"
          </>
        ) : null}
        {[EnumGameType.Taboo].includes(gameSession.gameType) ? (
          <>
            <b>{t('LiveConsolePage.hints')}</b>
            {gameSession.hints?.map((hint) => `"${hint}"`).join(' - ')}
          </>
        ) : null}
      </div>
      {gameSession.status == EnumGameSessionStatus.Ended ? (
        <div className={styles.gameResult}>
          <div className={styles.gameWinner}>
            <img src={toAbsoluteUrl('/assets/icons/LiveConsole/personIcon.png')} alt='avatar' />
            <b>{t('LiveConsolePage.winner')}</b>
            {gameSession.winner ? gameSession.winner.username : t('LiveConsolePage.noWinner')}
          </div>

          <div className={styles.gameAnswer}>
            <img src={toAbsoluteUrl('/assets/icons/LiveConsole/answerIcon.png')} alt='answer' />
            <b>{t('LiveConsolePage.answer')}</b>
            {gameSession.answer}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default SingleGameSession
