// LAYOUTS
import MasterLayout from 'layouts/MasterLayout'
//ROUTES
import {useRoutes} from 'react-router-dom'
import routesPath from 'routes/routes'
import notSignedUpRoutes from 'routes/notSignedUpRoutes'
import {AppLevelContext} from 'context/AppLevelContext'
import { useContext, useEffect } from 'react'
import emailVerificationRoutes from 'routes/emailVerificationRoutes'
import kickVerificationRoutes from 'routes/kickVerificationRoutes'



const App = () => {
  const {
    user,
    kickUser
  } = useContext(AppLevelContext);
  const routing = useRoutes(routesPath)
  const notSignedUpRouting = useRoutes(notSignedUpRoutes)
  const emailVerificationRouting = useRoutes(emailVerificationRoutes)
  const kickVerificationRouting = useRoutes(kickVerificationRoutes)
  
  useEffect(() => {
    console.log({user, kickUser})
  },[user, kickUser])
  if (user && !user.signup_completed) {
    return (
      <MasterLayout>
        {notSignedUpRouting}
      </MasterLayout>
    );
  }

  if (kickUser && !kickUser.emailVerified) {
    return (
      <MasterLayout>
        {emailVerificationRouting}
      </MasterLayout>
    );
  }

  if (kickUser && (!kickUser.kickVerified || !kickUser.kickModVerified)) {
    return (
      <MasterLayout>
        {kickVerificationRouting}
      </MasterLayout>
    )
  }

  return (
    <MasterLayout>
      {routing}
      {/* <Route path="*" element={<Navigate to="/" />} /> */}
    </MasterLayout>
  );
}

export default App
