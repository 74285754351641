import styles from './EarningsHeader.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'

// CONTEXT
import PaymentContext from 'context/PaymentContext'
import {AppLevelContext} from 'context/AppLevelContext'

const EarningsHeader = () => {
  const {user} = useContext(AppLevelContext)
  const {paymentData} = useContext(PaymentContext)
  const {t} = useTranslation()
  return (
    <div className={styles.earningsHeader}>
      <div className={styles.earnings}>
        <div className={styles.earningsItem}>
          <div className={styles.earningsItemTitle}>
            <div className={styles.tooltip}>
              <img src={toAbsoluteUrl('/assets/icons/Payments/tooltip_icon.png')} alt='tooltip' />
              <span className={styles.tooltipText}>
                {t('PaymentsPage.totalAdEarningsExplanation')}
              </span>
            </div>
            {''}
            {t('PaymentsPage.totalAdEarnings')}
          </div>
          <div className={styles.earningsItemAmount}>
            {(
              paymentData && paymentData.payable_revenue + paymentData.locked_revenue
            )?.toLocaleString()}
            {user?.currency_sign}
          </div>
        </div>
        <div className={styles.earningsItem}>
          <div className={styles.earningsItemTitle}>
            <div className={styles.tooltip}>
              <img src={toAbsoluteUrl('/assets/icons/Payments/tooltip_icon.png')} alt='tooltip' />
              <span className={styles.tooltipText}>
                {t('PaymentsPage.referralEarningsExplanation')}
              </span>
            </div>
            {t('PaymentsPage.referralEarnings')}
          </div>
          <div className={styles.earningsItemAmount}>
            {paymentData?.referral_earnings.toLocaleString()}
            {user?.currency_sign}
          </div>
        </div>
        <div className={styles.earningsItem}>
          <div className={styles.earningsItemTitle}>
            <div className={styles.tooltip}>
              <img src={toAbsoluteUrl('/assets/icons/Payments/tooltip_icon.png')} alt='tooltip' />
              <span className={styles.tooltipText}>
                {t('PaymentsPage.donationEarningsExplanation')}
              </span>
            </div>
            {t('PaymentsPage.donationEarnings')}
          </div>
          <div className={styles.earningsItemAmount}>
            {paymentData?.donation_earnings.toLocaleString()}
            {user?.currency_sign}
          </div>
        </div>
        <div className={styles.earningsItem}>
          <div className={styles.earningsItemTitle}>
            <div className={styles.tooltip}>
              <img src={toAbsoluteUrl('/assets/icons/Payments/tooltip_icon.png')} alt='tooltip' />
              <span className={styles.tooltipText}>{t('PaymentsPage.paymentsExplanation')}</span>
            </div>
            {t('PaymentsPage.payments')}
          </div>
          <div className={styles.earningsItemAmount}>
            {paymentData?.total_payment.toLocaleString()}
            {user?.currency_sign}
          </div>
        </div>
      </div>
      <div className={styles.balance}>
        <div className={styles.balanceTitle}>
          <div className={styles.tooltip}>
            <img src={toAbsoluteUrl('/assets/icons/Payments/tooltip_icon.png')} alt='tooltip' />
            <span className={styles.tooltipText}>
              {t('PaymentsPage.payableBalanceExplanation')}
            </span>
          </div>
          {t('PaymentsPage.payableBalance')}
        </div>
        <div className={styles.balanceAmount}>
          {paymentData ? `${(paymentData?.balance).toLocaleString()}${user?.currency_sign}` : null}
        </div>
        <hr />
        <span className={styles.lockedRevenue}>
          <span className={styles.lockedRevenueTitle}>
            <div className={styles.tooltip}>
              <img src={toAbsoluteUrl('/assets/icons/Payments/tooltip_icon.png')} alt='tooltip' />
              <span className={styles.tooltipText}>
                {t('PaymentsPage.lockedRevenueExplanation')}
              </span>
            </div>
            {t('PaymentsPage.lockedRevenue')}:
          </span>
          <span className={styles.lockedRevenueAmount}>
            {paymentData?.locked_revenue.toLocaleString()}
            {user?.currency_sign}
          </span>
        </span>
      </div>
    </div>
  )
}

export default EarningsHeader
