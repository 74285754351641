import styles from './TabSwitcher.module.scss'

//COMPONENTS
import TabItem from './Components/TabItem/TabItem'
import {EnumPopupTabs} from 'components/ClipInsightsPopup/ClipInsightsPopup'

interface TabSwitcherProps {
  activeTab: string
  setActiveTab: (tab: EnumPopupTabs) => void
  tabs: {value: EnumPopupTabs}[]
}

const TabSwitcher = (props: TabSwitcherProps) => {
  return (
    <div className={styles.tabSwitcher}>
      {props.tabs.map((tab, index) => {
        return (
          <TabItem
            key={index}
            value={tab.value}
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
          />
        )
      })}
    </div>
  )
}

export default TabSwitcher
