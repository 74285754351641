import classNames from 'classnames'
import styles from './LabelSection.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {Label, Problem} from '../../Labels'
import {useState} from 'react'
import {t} from 'i18next'

interface LabelList {
  labels: Label[]
  setLabels: React.Dispatch<React.SetStateAction<Label[]>>
  problems: Problem[]
  setProblems: React.Dispatch<React.SetStateAction<Problem[]>>
  activeSection: 'labels' | 'problem'
}

const LabelSection = ({labels, setLabels, problems, setProblems, activeSection}: LabelList) => {
  return (
    <div className={styles.labelSection}>
      <div
        className={classNames(styles.sectionTitle, {
          [styles.problem]: activeSection === 'problem',
        })}
      >
        {activeSection === 'labels' ? 'Labels' : activeSection === 'problem' ? 'Problems' : null}
      </div>
      <div className={styles.labelList}>
        {activeSection === 'labels'
          ? labels.map((label, index) => (
              <div className={styles.label} key={label.id}>
                <span
                  onClick={() => {
                    setLabels(
                      labels.map((label, i) => {
                        if (index === i) {
                          return {
                            ...label,
                            selected: !label.selected,
                          }
                        } else {
                          return label
                        }
                      })
                    )
                  }}
                >
                  <div
                    className={classNames(styles.checkbox, {
                      [styles.labelChecked]: label.selected,
                    })}
                  >
                    <img src={toAbsoluteUrl('/assets/icons/MyClips/checkIcon.png')} alt='Checked' />
                  </div>
                  {t(`ClipInsightsPopup.${label.translationKey}`)}
                </span>
              </div>
            ))
          : activeSection === 'problem'
          ? problems.map((problem, index) => (
              <div className={styles.label} key={problem.id}>
                <span
                  onClick={() => {
                    setProblems(
                      problems.map((problem, i) => {
                        if (index === i) {
                          return {
                            ...problem,
                            selected: !problem.selected,
                          }
                        } else {
                          return problem
                        }
                      })
                    )
                  }}
                >
                  <div
                    className={classNames(styles.checkbox, {
                      [styles.problemChecked]: problem.selected,
                    })}
                  >
                    <img src={toAbsoluteUrl('/assets/icons/MyClips/checkIcon.png')} alt='Checked' />
                  </div>
                  {t(`ClipInsightsPopup.${problem.translationKey}`)}
                </span>
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

export default LabelSection
