import { toAbsoluteUrl } from "helpers"
import styles from '../Onboarding.module.scss'
import classNames from "classnames"



type SignupStepComponentProps = {
    name: string
    image: string
    completed: boolean
    redirectMessage: string
    description: string
}
const SignupStepComponent = ({name, image, completed, redirectMessage, description}: SignupStepComponentProps) => {


    return (
        <div className={classNames(styles.onboarding_step, {
            [styles.completed]: completed
        })}>        
        {
            completed ? (
              <>
              <div className={styles.onboarding_step_completed}>
                <img src={toAbsoluteUrl('/assets/icons/Settings/completed.png')} alt='completed' />
              </div>
              <span className={styles.onboarding_step_title}>{name}</span>
            </>
              

            ): (
              <>
              <div className={styles.onboarding_step_icon}>
                <img src={image} alt='step' />
              </div>
              <div className={styles.redirect_step}>{redirectMessage}</div>
              <span className={styles.onboarding_step_title}>{description}</span>
              </>
            )
        }
        </div>
    )

}

export default SignupStepComponent