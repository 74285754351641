import KickVerification from "pages/KickVerification/KickVerification"
import Login from "pages/Login/Login"
import { ReactElement } from "react-markdown/lib/react-markdown"
import { Navigate } from "react-router-dom"
import PrivateRoutes from "./PrivateRoutes"

interface Route {
    path: string
    element: ReactElement
    auth: boolean
    children?: Route[]
}


const routesPaths: Route[] = [
    {
        path: '/',
        element: <KickVerification />,
        auth: true
    },
    {
        path: '/login',
        element: <Login />,
        auth: false
    },
    {
        path: '*',
        element: <Navigate to='/' replace={true} />,
        auth: true,
    }
]

const authCheck = (routes: Route[]): Route[] => {
    return routes.map((route) => {
      if (route.auth) {
        route.element = <PrivateRoutes>{route.element}</PrivateRoutes>
      }
      return route
    })
}
  
export default authCheck(routesPaths);