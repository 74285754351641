import {useState, useEffect, useRef} from 'react'
import {motion} from 'framer-motion'

// STYLES
import styles from './DragAndDrop.module.scss'

// COMPONENTS
import Loading from 'components/Shared/Loading/Loading'

const getPercentageFromString = (sign, string) => {
  if (!string || !string.endsWith(sign)) {
    return
  }
  string = string.slice(0, -sign.length)
  return parseFloat(string)
}

const DragAndDrop = ({campaign, setCampaign, reset, setReset}) => {
  const constraintsRef = useRef(null)
  const itemRef = useRef(null)
  const [assetLoading, setAssetLoading] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)
  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)
  const [percentages, setPercentages] = useState(null)

  const convertVhToPercent = (vh) => {
    // check if the string is a valid vh value
    if (!vh.includes('vh')) return vh
    // remove the vh part of the string
    const value = vh.slice(0, -2)
    // convert to percentage
    return `${value}%`
  }

  const setAssetPlace = () => {
    let {top_padding, left_padding} = campaign
    top_padding = getPercentageFromString('%', convertVhToPercent(top_padding))
    left_padding = getPercentageFromString('%', left_padding)
    if (top_padding !== undefined) {
      setTop((top_padding / 100) * constraintsRef.current.getBoundingClientRect().height)
    }
    if (left_padding !== undefined) {
      setLeft((left_padding / 100) * constraintsRef.current.getBoundingClientRect().width)
    }
  }

  useEffect(() => {
    if (!campaign || !initialLoading) return
    setInitialLoading(false)
    setAssetPlace()
  }, [initialLoading])

  useEffect(() => {
    if (reset) {
      setAssetPlace()
      setReset(false)
    }
  }, [reset])

  useEffect(() => {
    if (!assetLoading) {
      setPercentages({
        left: left / constraintsRef.current.getBoundingClientRect().width,
        top: top / constraintsRef.current.getBoundingClientRect().height,
      })
    }
    // eslint-disable-next-line
  }, [left, top])

  useEffect(() => {
    if (!assetLoading && percentages) {
      setCampaign({
        ...campaign,
        top_padding: `${(percentages.top * 100).toFixed(2)}%`,
        left_padding: `${(percentages.left * 100).toFixed(2)}%`,
      })
    }
    // eslint-disable-next-line
  }, [percentages])

  const handleDragEnd = () => {
    if (itemRef.current && constraintsRef.current) {
      const itemRect = itemRef.current.getBoundingClientRect()
      const containerRect = constraintsRef.current.getBoundingClientRect()
      const left = itemRect.left - containerRect.left
      const top = itemRect.top - containerRect.top
      setLeft(left)
      setTop(top)
    }
  }

  return (
    <div className={styles.previewSection}>
      <motion.div ref={constraintsRef} className={styles.demoDragScreen}>
        {assetLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <Loading />
          </div>
        )}
        {!initialLoading && !reset && (
          <motion.div
            ref={itemRef}
            style={{
              display: 'flex',
              width: campaign?.width || 'auto',
              height: campaign?.width === 'auto' ? '100%' : 'auto',
            }}
            initial={{x: left, y: top}}
            drag
            dragMomentum={false}
            dragConstraints={constraintsRef}
            dragElastic={0}
            onDragEnd={handleDragEnd}
          >
            {campaign.asset_type === 'video' ? (
              <video
                onLoadedData={() => {
                  setAssetLoading(false)
                }}
                preload={'auto'}
                style={{width: '100%', height: 'auto', cursor: 'move'}}
                autoPlay
                muted
                playsInline
                loop={true}
                controls={false}
              >
                <source src={campaign.asset} type='video/webm' />
              </video>
            ) : campaign.asset_type === 'image' ? (
              <img
                draggable='false'
                onLoad={() => {
                  setAssetLoading(false)
                }}
                style={{width: '100%', height: 'auto', cursor: 'move'}}
                src={campaign.asset}
                alt='ad'
              />
            ) : null}
          </motion.div>
        )}
      </motion.div>
    </div>
  )
}

export default DragAndDrop
