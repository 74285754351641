import {loadingHandler, toAbsoluteUrl} from 'helpers'
import {useContext, useRef, useState} from 'react'
// STYLES
import styles from './DropdownMenu.module.scss'
// COMOPONENTS
import DropdownNav from '../DropdownNav/DropdownNav'
// Context
import {AppLevelContext} from 'context/AppLevelContext'
import Loading from 'components/Shared/Loading/Loading'
import AdminMenu from './components/AdminMenu'
import EnumRole from 'services/model/enum/enumRole'

type DropdownMenuProps = {
  isDropdownOpen: boolean
  setIsDropdownOpen: (value: boolean) => void
}

const DropdownMenu = ({isDropdownOpen, setIsDropdownOpen}: DropdownMenuProps) => {
  const {user, userSearchList, searchStreamers, updateStreamer, setUserSearchList} =
    useContext(AppLevelContext)
  return (
    <div className={styles.dropdownMenu}>
      <>
        {user?.role && [EnumRole.MANAGER, EnumRole.MOD, EnumRole.ADMIN].includes(user?.role) ? (
          <AdminMenu />
        ) : null}
        <DropdownNav />
      </>
    </div>
  )
}

export default DropdownMenu
