import {LiveConsoleAdDto} from 'services/model/dto/liveConsoleAdDto'
import SingleAdSession from './SingleAdSession'
import styles from '../LiveNotifications.module.scss'
import Loading from 'components/Shared/Loading/Loading'
import {useTranslation} from 'react-i18next'

interface AdFeedProps {
  ads: LiveConsoleAdDto[] | null
  pageLoading: boolean
}

const AdFeed = ({ads, pageLoading}: AdFeedProps) => {
  const {t} = useTranslation()
  return (
    <>
      {pageLoading || !ads ? (
        <Loading />
      ) : (
        <>
          {ads && ads.length > 0 ? (
            <>
              {ads.map((ad, index) => {
                return <SingleAdSession key={index} ad={ad} />
              })}
            </>
          ) : (
            <div className={styles.noData}>{t('LiveConsolePage.noData')}</div>
          )}
        </>
      )}
    </>
  )
}

export default AdFeed
