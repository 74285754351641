import styles from '../../GameDetail.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useContext} from 'react'

import {Campaign} from 'components/GamePopup/GamePopup'
import {EnumGameType} from 'services/model/enum/enumGameType'

import {AppLevelContext} from 'context/AppLevelContext'

type GameDemoProps = {
  gameType: EnumGameType
}

const GameDemo = ({gameType}: GameDemoProps) => {
  const {user} = useContext(AppLevelContext)
  return (
    <div className={styles.gameDemo}>
      <div className={styles.demoScreen}>
        <video
          autoPlay
          disablePictureInPicture
          playsInline
          loop
          muted
          style={{
            width: '100%',
            paddingLeft: '0%',
            paddingTop: '0vh',
            height: 'auto',
            transform: 'translateY(0%)',
          }}
        >
          <source
            src={
              gameType === EnumGameType.Trivia
                ? toAbsoluteUrl('/assets/icons/Games/triviaGame_demoVideo.mp4')
                : gameType === EnumGameType.Flag
                ? toAbsoluteUrl('/assets/icons/Games/findTheFlag_demoVideo.mp4')
                : gameType === EnumGameType.Taboo
                ? toAbsoluteUrl('/assets/icons/Games/reverseTaboo_demoVideo.mp4')
                : ''
            }
            type='video/mp4'
          />
          Your browser does not support the video.
        </video>
      </div>
      <div className={styles.demoChat}>
        {gameType === EnumGameType.Trivia && (
          <>
            <div className={styles.demoUser}>
              <img
                src={toAbsoluteUrl('/assets/icons/Campaigns/streamer-icon.png')}
                alt='streamer'
              />
              <span className={styles.username}> {user?.title}</span>: What is the name of the
              centre of the island?
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(20, 193, 102)'}}>
                viewer1
              </span>
              : Lala island
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(255, 51, 51)'}}>
                viewer2
              </span>
              : aloha haven
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(248, 78, 171)'}}>
                viewer3
              </span>
              : kai land
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(79, 79, 254)'}}>
                viewer4
              </span>
              : Eye Land
            </div>
            <div className={styles.demoUser}>
              <img
                src={toAbsoluteUrl('/assets/icons/Campaigns/streamer-icon.png')}
                alt='streamer'
              />
              <span className={styles.username}> {user?.title}</span>:{' '}
              <i
                style={{
                  background: 'rgb(250, 250, 250)',
                  color: 'rgb(18, 18, 18)',
                  padding: '0px 2px',
                }}
              >
                @viewer4
              </i>{' '}
              <i>has won the game!</i>
            </div>
          </>
        )}
        {gameType === EnumGameType.Flag && (
          <>
            <div className={styles.demoUser}>
              <img
                src={toAbsoluteUrl('/assets/icons/Campaigns/streamer-icon.png')}
                alt='streamer'
              />
              <span className={styles.username}> {user?.title}</span>: Which country's flag is this?
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(20, 193, 102)'}}>
                viewer1
              </span>
              : turks
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(255, 51, 51)'}}>
                viewer2
              </span>
              : turquie
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(248, 78, 171)'}}>
                viewer3
              </span>
              : turkish
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(79, 79, 254)'}}>
                fatihbaskaya0
              </span>
              : Turkey
            </div>
            <div className={styles.demoUser}>
              <img
                src={toAbsoluteUrl('/assets/icons/Campaigns/streamer-icon.png')}
                alt='streamer'
              />
              <span className={styles.username}> {user?.title}</span>:{' '}
              <i
                style={{
                  background: 'rgb(250, 250, 250)',
                  color: 'rgb(18, 18, 18)',
                  padding: '0px 2px',
                }}
              >
                @fatihbaskaya0
              </i>{' '}
              <i>has won the game!</i>
            </div>
          </>
        )}
        {gameType === EnumGameType.Taboo && (
          <>
            <div className={styles.demoUser}>
              <img
                src={toAbsoluteUrl('/assets/icons/Campaigns/streamer-icon.png')}
                alt='streamer'
              />
              <span className={styles.username}> {user?.title}</span>: (Reverse Taboo) Started!
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(20, 193, 102)'}}>
                viewer1
              </span>
              : Kebab
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(255, 51, 51)'}}>
                viewer2
              </span>
              : menemen
            </div>
            <div className={styles.demoUser}>
              <span className={styles.username} style={{color: 'rgb(79, 79, 254)'}}>
                agency666
              </span>
              : Shakshuka
            </div>
            <div className={styles.demoUser}>
              <img
                src={toAbsoluteUrl('/assets/icons/Campaigns/streamer-icon.png')}
                alt='streamer'
              />
              <span className={styles.username}> {user?.title}</span>:{' '}
              <i
                style={{
                  background: 'rgb(250, 250, 250)',
                  color: 'rgb(18, 18, 18)',
                  padding: '0px 2px',
                }}
              >
                @agency666
              </i>{' '}
              <i>has won the game!</i>
            </div>
          </>
        )}
        <img
          className={styles.chatLoop}
          src={toAbsoluteUrl('/assets/icons/Games/prod_chatloop.gif')}
          alt='chat'
        />
      </div>
    </div>
  )
}

export default GameDemo
