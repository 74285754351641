import {useContext} from 'react'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'
// STYLES
import styles from './ListView.module.scss'
// CONTEXT
import CampaignContext, {Campaign} from 'context/CampaignContext'

import SingleListItem from './components/SingleListItem/SingleListItem'
import ListViewHeader from './components/ListViewHeader/ListViewHeader'

type ListViewProps = {
  variant?: 'featured' | ''
}

const ListView = ({variant}: ListViewProps) => {
  const {
    campaignList,

    featuredCampaignList,
    activeFilter,
  } = useContext(CampaignContext)
  const {t} = useTranslation()
  const selectedFilter = activeFilter.slice(0, 1).toLowerCase() + activeFilter.slice(1)
  return (
    <div className={styles.list_table_wrap}>
      <table
        className={classNames(styles.list_view_wrap, {
          [styles.list_view_featured]: variant === 'featured',
        })}
      >
        {variant !== 'featured' && <ListViewHeader />}
        {variant === 'featured' && <ListViewHeader variant={variant} />}

        {variant !== 'featured' &&
          campaignList.length > 0 &&
          campaignList?.map((campaign: Campaign, index: number) => (
            <SingleListItem key={index} campaign={campaign} variant={variant} />
          ))}

        {campaignList.length === 0 && variant !== 'featured' && (
          <tbody className={styles.no_campaigns}>
            <tr className={styles.list_item}>
              <td colSpan={6}>
                <img
                  draggable='false'
                  src={toAbsoluteUrl('/assets/icons/Campaigns/no-campaigns.png')}
                  alt='no data'
                />
                <h1>
                  {t('CampaignsPage.noFilterCampaign', {
                    FILTER: t(`CampaignsPage.${selectedFilter}`),
                  })}
                </h1>
              </td>
            </tr>
          </tbody>
        )}

        {/* FEATURED */}
        {variant === 'featured' &&
          featuredCampaignList.length > 0 &&
          featuredCampaignList?.map((campaign: Campaign, index: number) => (
            <SingleListItem key={index} campaign={campaign} variant={variant} />
          ))}

        {featuredCampaignList.length === 0 && variant === 'featured' && (
          <tbody>
            <tr className={styles.no_campaigns}>
              <td>
                <img
                  draggable='false'
                  src={toAbsoluteUrl('/assets/icons/Campaigns/no-campaigns.png')}
                  alt='no data'
                />
                <h1>
                  {t('CampaignsPage.noFilterCampaign', {
                    FILTER: t(`CampaignsPage.${selectedFilter}`),
                  })}
                </h1>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}

export default ListView
