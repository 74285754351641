import styles from '../../HelpCenter.module.scss'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from 'helpers'

const Header = ({setSearchTerms, searchTerms}) => {
  const {t} = useTranslation()
  return (
    <div className={styles.helpCenter_header}>
      {/* SEARCH BOX */}
      <div className={styles.searchWrap}>
        <h1>{t('HelpCenterPage.hello')}</h1>
        <div className={styles.searchBox}>
          <input
            type='text'
            placeholder={t('HelpCenterPage.describeYourIssue')}
            onChange={(e) => setSearchTerms(e.target.value)}
          />
          <button>
            <img src={toAbsoluteUrl('/assets/icons/HelpCenter/search-icon.png')} alt='' />
          </button>
        </div>
        <p>{t('HelpCenterPage.chooseCategoryPrompt')}</p>
      </div>
    </div>
  )
}

export default Header
