import {useContext} from 'react'
import styles from '../../PayoutAccount.module.scss'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import PayoutAccountContext from 'context/PayoutAccountContext'
import {useTranslation} from 'react-i18next'

const TaxSelection = () => {
  const {t} = useTranslation()
  const {payoutAccountData, setPayoutAccountData} = useContext(PayoutAccountContext)
  if (!payoutAccountData) return null
  return (
    <div className={styles.line}>
      <div className={styles.label}>{t('SettingsPage.taxStatus')}</div>
      <div className={styles.inLine}>
        <div className={styles.taxStatus}>
          <div
            className={classNames(styles.taxType, {
              [styles.selected]: payoutAccountData.tax_exemption_type === 'available',
            })}
            onClick={() =>
              setPayoutAccountData({...payoutAccountData, tax_exemption_type: 'available'})
            }
          >
            <img src={toAbsoluteUrl('/assets/icons/Settings/yesTax.png')} alt='tax' />
            {t('SettingsPage.taxExempt')}
          </div>
          <div
            className={classNames(styles.taxType, {
              [styles.selected]: payoutAccountData.tax_exemption_type === 'not_available',
            })}
            onClick={() =>
              setPayoutAccountData({...payoutAccountData, tax_exemption_type: 'not_available'})
            }
          >
            <img src={toAbsoluteUrl('/assets/icons/Settings/noTax.png')} alt='tax' />
            {t('SettingsPage.notTaxExempt')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaxSelection
