import {toAbsoluteUrl} from 'helpers'
import styles from './KickVerification.module.scss'
import {useContext, useState} from 'react'
import {AppLevelContext} from 'context/AppLevelContext'
import {toast} from 'react-toastify'
import classNames from 'classnames'

const KickVerification = () => {
  const {kickUser, verifyKickAccount} = useContext(AppLevelContext)
  const [sidebarToggle, setSidebarToggle] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [verification, setVerification] = useState<{
    kickVerified: boolean
    kickModVerified: boolean
  } | null>(null)
  const handleVerification = async () => {
    setIsLoading(true)
    try {
      const res = await verifyKickAccount()
      if (res.kickVerified && res.kickModVerified) {
        setVerification(null)
        toast.success('Account verified succesfuly')
        window.location.reload()
      } else {
        setVerification(res)
        toast.warning('Please check the steps and try again')
      }
    } catch {
      toast.error('An error accuired while verifying you :/')
    }
  }
  if (!kickUser) {
    return null
  }
  return (
    <div className={styles.kickVerification}>
      <section className={styles.titleSection}>
        <h1 className={styles.title}>Connect Kick channel</h1>
        <p className={styles.subtitle}>
          Welcome to <b>LIVAD</b>! Before you can get started you must link your <b>Kick</b>{' '}
          channel.
        </p>
      </section>
      <section className={styles.stepSection}>
        <div className={styles.title}>
          <img src={toAbsoluteUrl('/assets/icons/Platforms/kick-icon.png')} alt='Kick' />
          Link Kick account
        </div>
        <div className={styles.stepList}>
          <div className={styles.step}>
            <b>1:</b> Go to{' '}
            <a href='https://kick.com/livad' target='_blank'>
              https://kick.com/livad
            </a>
          </div>
          <div className={styles.step}>
            <b>2:</b> Open the chat and type{' '}
            <span
              onClick={() => {
                navigator.clipboard.writeText(`!verify ${kickUser.kickVerificationCode}`)
                toast.success('Verification code copied to clipboard')
              }}
            >
              !verify {kickUser.kickVerificationCode}
            </span>
            {verification !== null ? (
              <div
                className={classNames(styles.stepStatus, {
                  [styles.verified]: verification?.kickVerified,
                  [styles.notVerified]: !verification?.kickVerified,
                })}
              >
                {verification?.kickVerified ? (
                  <svg
                    stroke='currentColor'
                    fill='currentColor'
                    strokeWidth={0}
                    viewBox='0 0 512 512'
                    height='1em'
                    width='1em'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z' />
                  </svg>
                ) : (
                  <svg
                    stroke='currentColor'
                    fill='currentColor'
                    strokeWidth={0}
                    viewBox='0 0 512 512'
                    height='1em'
                    width='1em'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z' />
                  </svg>
                )}
              </div>
            ) : null}
          </div>
          <div className={styles.step}>
            <b>3:</b> Add{' '}
            <span
              onClick={() => {
                navigator.clipboard.writeText('livad')
                toast.success('Username copied to clipboard')
              }}
            >
              livad
            </span>{' '}
            as a moderator{' '}
            <a href='https://kick.com/dashboard/community/moderators' target='_blank'>
              here
            </a>
            {verification !== null ? (
              <div
                className={classNames(styles.stepStatus, {
                  [styles.verified]: verification?.kickModVerified,
                  [styles.notVerified]: !verification?.kickModVerified,
                })}
              >
                {verification?.kickModVerified ? (
                  <svg
                    stroke='currentColor'
                    fill='currentColor'
                    strokeWidth={0}
                    viewBox='0 0 512 512'
                    height='1em'
                    width='1em'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z' />
                  </svg>
                ) : (
                  <svg
                    stroke='currentColor'
                    fill='currentColor'
                    strokeWidth={0}
                    viewBox='0 0 512 512'
                    height='1em'
                    width='1em'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z' />
                  </svg>
                )}
              </div>
            ) : null}
          </div>
        </div>
        <button onClick={handleVerification} className={styles.verifyBtn}>
          Verify
        </button>
      </section>
    </div>
  )
}

export default KickVerification
