import {toast} from 'react-toastify'
import {useContext, useState, useEffect} from 'react'
import {toAbsoluteUrl, compareArrays} from 'helpers'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
//STYLES
import styles from './Interests.module.scss'

// CONTEXT
import SettingsContext, {Interest} from 'context/SettingsContext'
import SingleInterest from './components/SingleInterest/SingleInterest'
import {useLocation, useNavigate} from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'

const Interests = () => {
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const {
    interests,
    updateInterests,
    checkInterestsDiff,
    checkInterestsSave,
    interestList,
    newInterests,
    setNewInterests,
  } = useContext(SettingsContext)
  const navigate = useNavigate()
  const location = useLocation()
  const routePath = location.pathname.split('/')[1]

  useEffect(() => {
    if (interests) {
      setNewInterests(interests)
    }
  }, [interests])

  const addInterest = (interest: number) => {
    if (!newInterests) return
    if (newInterests.length >= 5) return toast.warning('You can select up to 5 interests')
    const tmp = [...newInterests, interest]
    setNewInterests(tmp)
  }

  const removeInterest = (interest: number) => {
    if (!newInterests) return
    const tmp = newInterests.filter((item) => item !== interest)
    setNewInterests(tmp)
  }

  if (!newInterests) return null

  return (
    <div className={styles.interests}>
      <div className={styles.sectionTitle}>{t('SettingsPage.interests')}</div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.interests')}</div>
        <div className={styles.desc}>{t('SettingsPage.selectInterestPrompt')}</div>
      </div>
      <div className={styles.content}>
        {interestList.map((interest: Interest, index: number) => (
          <SingleInterest
            interest={interest}
            key={index}
            newData={newInterests}
            removeInterest={removeInterest}
            addInterest={addInterest}
          />
        ))}
      </div>
      {routePath === 'signup' ? (
        <div className={classNames(styles.line, styles.lineSave)}>
          <button
            onClick={async () => {
              navigate(`/${routePath}/${EnumSettingsTab.SponsorshipPreferences}`)
            }}
          >
            {t('Buttons.back')}
          </button>
          <button
            disabled={!checkInterestsSave(newInterests) || isLoading}
            onClick={async () => {
              setIsLoading(true)
              const success = await updateInterests(newInterests)
              setIsLoading(false)
              if (success) {
                navigate(`/${routePath}/${EnumSettingsTab.OverlaySetup}`)
              }
            }}
            className={classNames({
              [styles.disabled]: !checkInterestsSave(newInterests),
            })}
          >
            {t('Buttons.next')}
          </button>
        </div>
      ) : (
        <>
          {checkInterestsDiff(newInterests) && checkInterestsSave(newInterests) ? (
            <div className={classNames(styles.line, styles.lineSave)}>
              <button
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true)
                  await updateInterests(newInterests)
                  setIsLoading(false)
                }}
              >
                {t('Buttons.save')}
              </button>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default Interests
