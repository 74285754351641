import styles from './DateFilter.module.scss'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useState, useContext} from 'react'
import ClickAwayListener from 'react-click-away-listener'
import {useTranslation} from 'react-i18next'

// Models
import EnumDashboardDateRange from 'services/model/enum/enumDateRange'

// Context
import DashboardContext from 'context/DashboardContext'

const DateFilter = () => {
  const {setDateFilter, dateFilter} = useContext(DashboardContext)
  const [open, setOpen] = useState(false)
  const {t} = useTranslation()
  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false)
      }}
    >
      <div
        className={classNames(styles.dateFilter, {
          [styles.active]: open,
        })}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className={styles.dateFilter_selected}>
          <img src={toAbsoluteUrl('/assets/icons/Dashboard/dateIcon.png')} alt='date' />
          {dateFilter === EnumDashboardDateRange.Day
            ? t('DashboardPage.lastXHours', {X: '24'})
            : dateFilter === EnumDashboardDateRange.Week
            ? t('DashboardPage.lastXDays', {X: '7'})
            : dateFilter === EnumDashboardDateRange.Month
            ? t('DashboardPage.lastXDays', {X: '28'})
            : dateFilter === EnumDashboardDateRange.ThreeMonths
            ? t('DashboardPage.lastXMonths', {X: '3'})
            : dateFilter === EnumDashboardDateRange.SixMonths
            ? t('DashboardPage.lastXMonths', {X: '6'})
            : dateFilter === EnumDashboardDateRange.AllTime
            ? t('DashboardPage.allTime')
            : ''}
          <img src={toAbsoluteUrl('/assets/icons/Dashboard/dropDownIcon.png')} alt='arrow' />
        </div>
        {open && (
          <div className={styles.dateFilter_list}>
            <div
              // className={classNames(styles.dateFilter_item, styles.selected)}
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: dateFilter === EnumDashboardDateRange.Day,
              })}
              onClick={() => {
                setDateFilter(EnumDashboardDateRange.Day)
              }}
            >
              {t('DashboardPage.lastXHours', {X: '24'})}
            </div>
            <div
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: dateFilter === EnumDashboardDateRange.Week,
              })}
              onClick={() => {
                setDateFilter(EnumDashboardDateRange.Week)
              }}
            >
              {t('DashboardPage.lastXDays', {X: '7'})}
            </div>
            <div
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: dateFilter === EnumDashboardDateRange.Month,
              })}
              onClick={() => {
                setDateFilter(EnumDashboardDateRange.Month)
              }}
            >
              {t('DashboardPage.lastXDays', {X: '28'})}
            </div>
            <div
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: dateFilter === EnumDashboardDateRange.ThreeMonths,
              })}
              onClick={() => {
                setDateFilter(EnumDashboardDateRange.ThreeMonths)
              }}
            >
              {t('DashboardPage.lastXMonths', {X: '3'})}
            </div>
            <div
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: dateFilter === EnumDashboardDateRange.SixMonths,
              })}
              onClick={() => {
                setDateFilter(EnumDashboardDateRange.SixMonths)
              }}
            >
              {t('DashboardPage.lastXMonths', {X: '6'})}
            </div>
            <div
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: dateFilter === EnumDashboardDateRange.AllTime,
              })}
              onClick={() => {
                setDateFilter(EnumDashboardDateRange.AllTime)
              }}
            >
              {t('DashboardPage.allTime')}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default DateFilter
