import { createBaseStreamerApiService } from "./baseStreamerApiService";
import UpdateAffiliateCampaignRequest from "./model/request/campaigns/updateAffiliateCampaignRequest";
import UpdateCampaignStatusRequest from "./model/request/campaigns/updateCampaignStatusRequest";
import { AffiliateCampaignDetail } from "./model/response/campaigns/affiliateCampaignDetail";
import AffiliateCampaignResponseItem from "./model/response/campaigns/affiliateCampaignResponseItem";
import UpdateAffiliateCampaignResponse from "./model/response/campaigns/updateAffiliateCampaignRespone";



export const createCampaignService = () => {
    const baseService = createBaseStreamerApiService('/campaigns');


    const getCampaign = async (campaignId: number): Promise<{
        data: any,
        autoAcceptSettings: any
    }> => {
        const response = await baseService.get<{
            data: any,
            autoAcceptSettings: any
        }>(`/${campaignId}`);
        return response.data;
    }

    const getAcceptedStreamers = async (campaignId: number): Promise<{
        data: {
            streamers: [{
                title: string,
                link_to_logo: string | null
            }] | null,
            count: number
        }
    }> => {
        const response = await baseService.get<{
            data: {
                streamers: [{
                    title: string,
                    link_to_logo: string | null
                }] | null,
                count: number
            }
        }>(`/${campaignId}/streamers`);
        return response.data;
    }

    const getCampaigns = async (query: string = ''): Promise<any> => {
        const response = await baseService.get<any>(`?${query}`);
        return response.data;
    }

    const getFeaturedCampaigns = async (): Promise<{ data: any }> => {
        const response = await baseService.get<{ data: any }>('/featured');
        return response
    }


    const getAffiliateCampaigns = async (): Promise<AffiliateCampaignResponseItem[]> => {
        const response = await baseService.get<AffiliateCampaignResponseItem[]>('/getAffiliateCampaigns');
        return response.data;

    }

    const getAffiliateCampaignDetail = async (campaignId: number): Promise<AffiliateCampaignDetail> => {
        const response = await baseService.post<AffiliateCampaignDetail>('/getAffiliateCampaignDetail', {
            campaignId
        });
        return response.data;

    }

    const updateAffiliateCampaign = async (req: UpdateAffiliateCampaignRequest): Promise<UpdateAffiliateCampaignResponse> => {
        const response = await baseService.post<UpdateAffiliateCampaignResponse>('/updateAffiliateCampaign', req);
        return response.data;
    }

    const updateCampaignStatus = async (req: UpdateCampaignStatusRequest): Promise<void> => {
        await baseService.put<void>('/', req);
    }






    return {
        getCampaign,
        getCampaigns,
        getFeaturedCampaigns,
        getAcceptedStreamers,
        getAffiliateCampaigns,
        getAffiliateCampaignDetail,
        updateAffiliateCampaign,
        updateCampaignStatus
    }
}