import styles from './AffiliateStatBox.module.scss'

interface AffiliateStatBoxProps {
  title: string
  value: string | number | null
}

const AffiliateStatBox = (props: AffiliateStatBoxProps) => {
  return (
    <div className={styles.affiliateStatBox}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.value}>{props.value}</div>
    </div>
  )
}

export default AffiliateStatBox
