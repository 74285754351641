import styles from './UserReport.module.scss'

import {useContext} from 'react'
import {useTranslation} from 'react-i18next'

// Context

import DashboardContext from 'context/DashboardContext'
const UserReport = () => {
  const {dashboardData} = useContext(DashboardContext)
  const {t} = useTranslation()

  return (
    <div className={styles.userReport}>
      <div className={styles.userReport_item}>
        <div className={styles.userReport_itemTitle}>{t('DashboardPage.revenue')}</div>
        <div className={styles.userReport_itemValue}>
          {dashboardData?.totals.revenue?.toLocaleString()} {dashboardData?.totals.currency_sign}
        </div>
      </div>
      <span className={styles.breakLine}></span>
      <div className={styles.userReport_item}>
        <div className={styles.userReport_itemTitle}>{t('DashboardPage.views')}</div>
        <div className={styles.userReport_itemValue}>
          {dashboardData?.totals.views.toLocaleString()}
        </div>
      </div>
      <span className={styles.breakLine}></span>
      <div className={styles.userReport_item}>
        <div className={styles.userReport_itemTitle}>{t('DashboardPage.clicks')}</div>
        <div className={styles.userReport_itemValue}>
          {dashboardData?.totals.clicks.toLocaleString()}
        </div>
      </div>
      <span className={styles.breakLine}></span>
      <div className={styles.userReport_item}>
        <div className={styles.userReport_itemTitle}>{t('DashboardPage.ctr')}</div>
        <div className={styles.userReport_itemValue}>%{dashboardData?.totals.CTR}</div>
      </div>
      <span className={styles.breakLine}></span>
      <div className={styles.userReport_item}>
        <div className={styles.userReport_itemTitle}>{t('DashboardPage.ads')}</div>
        <div className={styles.userReport_itemValue}>{dashboardData?.totals.ad_count}</div>
      </div>
    </div>
  )
}

export default UserReport
