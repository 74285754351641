import PayoutAmountsDto from 'services/model/dto/payoutAmountsDto'
import styles from './Result.module.scss'
import {useTranslation} from 'react-i18next'
import { useContext } from 'react'
import {AppLevelContext} from 'context/AppLevelContext'
import classNames from 'classnames'

type ResultProps = {
  grossAmount: number
  amounts: PayoutAmountsDto
}
const Result = ({ grossAmount, amounts}: ResultProps) => {
  const round = (num: number) => Math.round(num * 100) / 100

  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()
  return (
    <div className={styles.payoutRequest_result}>
      <div className={styles.resultLine}>
        <div className={styles.resultLine_item}>{t('PayoutRequestPopup.grossPayout')}</div>
        <div className={styles.resultLine_item}>
          <span className={styles.upIcon}>+</span>{user?.currency_sign}{round(grossAmount).toLocaleString(undefined, {maximumFractionDigits: 2,})}
        </div>
      </div>
      <div className={styles.resultLine}>
        <div className={styles.resultLine_item}>{t('PayoutRequestPopup.vatValueAddedTax')}</div>
        <div className={styles.resultLine_item}>
          <span className={classNames({
            [styles.upIcon]: amounts.VAT >= 0,
            [styles.downIcon]: amounts.VAT < 0,
          })}>
              {amounts.VAT > 0 ? '+' : '-'}
            </span>{user?.currency_sign}{round(amounts.VAT).toLocaleString(undefined, {maximumFractionDigits: 2,})}
        </div>
      </div>
      <div className={styles.resultLine}>
        <div className={styles.resultLine_item}>{t('PayoutRequestPopup.vatWitholding')}</div>
        <div className={styles.resultLine_item}>
          <span className={classNames({
            [styles.upIcon]: amounts.VAT_withholding >= 0,
            [styles.downIcon]: amounts.VAT_withholding < 0,
          })}>
            {amounts.VAT_withholding > 0 ? '+' : '-'}
            </span>{user?.currency_sign}{round(amounts.VAT_withholding).toLocaleString(undefined, {maximumFractionDigits: 2,})}
        </div>
      </div>
      <div className={styles.resultLine}>
        <div className={styles.resultLine_item}>{t('PayoutRequestPopup.witholding')}</div>
        <div className={styles.resultLine_item}>
          <span className={classNames({
            [styles.upIcon]: amounts.withholding >= 0,
            [styles.downIcon]: amounts.withholding < 0,
          })}>{
            amounts.withholding > 0 ? '+' : '-'
          }</span>{user?.currency_sign}{round(amounts.withholding).toLocaleString(undefined, {maximumFractionDigits: 2,})}
        </div>
      </div>
      <div className={styles.resultLine}>
        <div className={styles.resultLine_item}>{t('PayoutRequestPopup.netPayout')}</div>
        <div className={styles.resultLine_item}>{user?.currency_sign}{Math.abs(amounts.netAmount).toLocaleString(undefined, {maximumFractionDigits: 2,})}</div>
      </div>
    </div>
  )
}

export default Result
