import {t} from 'i18next'
import {RatioCardListI} from '../../RatioCards'
import styles from './RatioCardItem.module.scss'
import classNames from 'classnames'

interface RatioCardItemProps {
  item: RatioCardListI
}

const RatioCardItem = ({item}: RatioCardItemProps) => {
  return (
    <div className={styles.ratioCardItem}>
      <div className={styles.titleSection}>
        {t(`ClipInsightsPage.${item.key}`)}
        {item.status !== 'no-change' && (
          <span
            className={classNames(styles.change, {
              [styles.up]: item.status === 'up',
              [styles.down]: item.status === 'down',
            })}
          >
            {item.status === 'up' ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  d='M13.7143 2.28571L2.28572 13.7143M13.7143 2.28571H3.42858M13.7143 2.28571V12.5714'
                  stroke='#14B87F'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  d='M13.7142 13.7143L2.28566 2.28572M13.7142 13.7143H3.42852M13.7142 13.7143V3.42858'
                  stroke='#EF4A3E'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            )}
            {item.status === 'up' ? '+' : undefined}
            {item.change}%
          </span>
        )}
      </div>
      <div className={styles.resultSection}>
        <div
          className={classNames(styles.currentValue, {
            [styles.up]:
              (item.key !== 'brandMention' && item.value !== undefined && item.value > 80) ||
              (item.key === 'brandMention' && item.value !== undefined && item.value > 10),
            [styles.down]:
              (item.key !== 'brandMention' && item.value !== undefined && item.value <= 80) ||
              (item.key === 'brandMention' && item.value !== undefined && item.value <= 10),
          })}
        >
          {item.value}%
        </div>
        <div className={styles.description}>
          {item.key !== 'brandMention' && item.value !== undefined && item.value > 80
            ? `${t(`ClipInsightsPage.${item.key}GoodDesc`)}`
            : item.key !== 'brandMention' && item.value !== undefined && item.value <= 80
            ? `${t(`ClipInsightsPage.${item.key}BadDesc`)}`
            : item.key === 'brandMention' && item.value !== undefined && item.value > 10
            ? `${t(`ClipInsightsPage.${item.key}GoodDesc`)}`
            : item.key === 'brandMention' && item.value !== undefined && item.value <= 10
            ? `${t(`ClipInsightsPage.${item.key}BadDesc`)}`
            : undefined}
        </div>
      </div>
    </div>
  )
}

export default RatioCardItem
