import styles from './AffiliateLink.module.scss'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {EnumAffiliateCampaignStatus} from 'services/model/enum/enumAffiliateCampaignStatus'
import {useEffect, useState} from 'react'

interface AffiliateLinkProps {
  status: EnumAffiliateCampaignStatus
  link: string | null
}

const AffiliateLink = (props: AffiliateLinkProps) => {
  const {t} = useTranslation()
  const [displayUrl, setDisplayUrl] = useState<string>('')

  useEffect(() => {
    const defaultLink = 'https://*****.*****'
    if (props.status === EnumAffiliateCampaignStatus.Active) {
      setDisplayUrl(props.link ?? defaultLink)
    } else {
      setDisplayUrl(defaultLink)
    }
  }, [props])

  return (
    <div className={styles.affiliateLink}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
      >
        <g clipPath='url(#clip0_246_111)'>
          <path
            d='M7.90072 6.09943C7.42291 5.62182 6.77497 5.35352 6.09939 5.35352C5.4238 5.35352 4.77586 5.62182 4.29805 6.09943L2.49614 7.90077C2.01831 8.37859 1.74988 9.02665 1.74988 9.70239C1.74988 10.3781 2.01831 11.0262 2.49614 11.504C2.97396 11.9818 3.62202 12.2503 4.29776 12.2503C4.9735 12.2503 5.62157 11.9818 6.09939 11.504L7.00005 10.6034'
            stroke='#5D7FFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6.09937 7.90084C6.57718 8.37845 7.22511 8.64676 7.9007 8.64676C8.57629 8.64676 9.22422 8.37845 9.70203 7.90084L11.5039 6.09951C11.9818 5.62169 12.2502 4.97362 12.2502 4.29788C12.2502 3.62214 11.9818 2.97408 11.5039 2.49626C11.0261 2.01844 10.3781 1.75 9.70232 1.75C9.02658 1.75 8.37852 2.01844 7.9007 2.49626L7.00003 3.39692'
            stroke='#5D7FFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_246_111'>
            <rect width='14' height='14' fill='white' />
          </clipPath>
        </defs>
      </svg>
      <div className={styles.affiliateLinkInput}>
        <span
          className={classNames(styles.inputText, {
            [styles.blured]: props.status !== EnumAffiliateCampaignStatus.Active,
          })}
        >
          {displayUrl}
        </span>
      </div>
      <button
        className={classNames(styles.copyBtn, {
          [styles.disabled]: props.status !== EnumAffiliateCampaignStatus.Active,
        })}
        onClick={() => {
          navigator.clipboard.writeText(displayUrl)
          toast.success('Copied to clipboard')
        }}
      >
        {t('CampaignsPopup.copy')}
      </button>
    </div>
  )
}

export default AffiliateLink
