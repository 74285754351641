import styles from './LiveNotifications.module.scss'
import classNames from 'classnames'
import {loadingHandler, toAbsoluteUrl} from 'helpers'
import {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

// Context
import LiveNotificationContext from 'context/LiveNotificationContext'

//COMPONENTS
import AdComing from '../AdComing/AdComing'
import SingleDonation from './components/SingleDonation'
import SingleGameSession from './components/SingleGameSession'
import Loading from 'components/Shared/Loading/Loading'
import SingleAdSession from './components/SingleAdSession'
import {EnumLiveFeedObjectType} from 'services/model/enum/enumLiveFeedObjectType'
import AllFeed from './components/AllFeed'
import AdFeed from './components/AdFeed'
import DonationsFeed from './components/DonationsFeed'
import GamesFeed from './components/GamesFeed'
import {AppLevelContext} from 'context/AppLevelContext'
import EnumRole from 'services/model/enum/enumRole'

const LiveNotifications = () => {
  const {
    donations,
    gameSessions,
    ads,
    liveFeed,
    selectedFeed,
    setSelectedFeed,
    getAllDonations,
    getAllGames,
    getAllAds,
  } = useContext(LiveNotificationContext)
  const {user} = useContext(AppLevelContext)

  const {t} = useTranslation()
  const [pageLoading, setPageLoading] = useState<boolean>(true)

  useEffect(() => {
    if (user?.role === EnumRole.MOD && !user?.self) {
      loadingHandler([getAllDonations()], setPageLoading)
      setSelectedFeed(EnumLiveFeedObjectType.Donation)
    } else {
      loadingHandler([getAllDonations(), getAllGames(), getAllAds()], setPageLoading)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className={styles.liveNotifications}>
      <span className={styles.liveNotifications_title}>
        <img
          src={toAbsoluteUrl('/assets/icons/LiveConsole/liveNotificationsIcon.png')}
          alt='notification'
        />
        {t('LiveConsolePage.liveFeed')}
      </span>
      {/* If Ad Coming Notification is active, it will be displayed here. */}
      {/* <AdComing /> */}
      <div className={styles.liveNotifications_categories}>
        {
          user?.role === EnumRole.MOD && !user?.self ? (
            <div
              className={classNames(styles.category, {
                [styles.active]: selectedFeed === EnumLiveFeedObjectType.Donation,
              })}
              onClick={() => {
                setSelectedFeed(EnumLiveFeedObjectType.Donation)
              }}
            >
              {t('LiveConsolePage.donations')}
            </div>
          ): 
          <>
            <div
              className={classNames(styles.category, {
                [styles.active]: selectedFeed === 'all',
              })}
              onClick={() => {
                setSelectedFeed('all')
              }}
            >
              {t('LiveConsolePage.all')}
            </div>
            <div
              className={classNames(styles.category, {
                [styles.active]: selectedFeed === EnumLiveFeedObjectType.Ad,
              })}
              onClick={() => {
                setSelectedFeed(EnumLiveFeedObjectType.Ad)
              }}
            >
              {t('LiveConsolePage.ads')}
            </div>
            <div
              className={classNames(styles.category, {
                [styles.active]: selectedFeed === EnumLiveFeedObjectType.Donation,
              })}
              onClick={() => {
                setSelectedFeed(EnumLiveFeedObjectType.Donation)
              }}
            >
              {t('LiveConsolePage.donations')}
            </div>
            <div
              className={classNames(styles.category, {
                [styles.active]: selectedFeed === EnumLiveFeedObjectType.GameSession,
              })}
              onClick={() => {
                setSelectedFeed(EnumLiveFeedObjectType.GameSession)
              }}
            >
              {t('LiveConsolePage.games')}
            </div>
        </>
        }
      </div>
      <div  className={styles.liveNotifications_list}>
        {selectedFeed == 'all' ? (
          <AllFeed liveFeed={liveFeed} pageLoading={pageLoading} />
        ) : selectedFeed === EnumLiveFeedObjectType.Ad ? (
          <AdFeed ads={ads} pageLoading={pageLoading} />
        ) : selectedFeed === EnumLiveFeedObjectType.Donation ? (
          <DonationsFeed donations={donations} pageLoading={pageLoading} />
        ) : (
          <GamesFeed gameSessions={gameSessions} pageLoading={pageLoading} />
        )}
      </div>
    </div>
  )
}

export default LiveNotifications
