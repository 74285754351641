import {toAbsoluteUrl} from 'helpers'
import styles from './Chat.module.scss'
import {useContext, useEffect, useState} from 'react'
import {AdChatMessageDto} from 'services/model/dto/ad/adChatMessageDto'
import {AppLevelContext} from 'context/AppLevelContext'
import Loading from 'components/Shared/Loading/Loading'

const colorPalette = [
  '#FF0000',
  '#0000FF',
  '#008000',
  '#B22222',
  '#FF7F50',
  '#9ACD32',
  '#FF4500',
  '#2E8B57',
  '#DAA520',
  '#5D7FFF',
  '#D2691E',
  '#5F9EA0',
  '#1E90FF',
  '#FF69B4',
  '#8A2BE2',
  '#00FF7F',
  '#7FFF00',
  '#FFD700',
  '#FF6347',
  '#40E0D0',
  '#EE82EE',
  '#F5DEB3',
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#F3FF33',
  '#FF33F6',
  '#33FFF0',
]

interface ChatProps {
  chatList: AdChatMessageDto[] | null
}

const Chat = ({chatList}: ChatProps) => {
  const {user} = useContext(AppLevelContext)
  const [userColors, setUserColors] = useState<{[key: string]: string}>({})

  useEffect(() => {
    if (!chatList) return

    const getUserColor = (username: string, userColors: {[key: string]: string}) => {
      let randomColor = userColors[username]
      if (!randomColor) {
        const newColor = colorPalette[Math.floor(Math.random() * colorPalette.length)]
        randomColor = newColor
      }
      return randomColor
    }
    const newList: {[key: string]: string} | null = {
      ...userColors,
    }
    chatList.forEach((chat) => {
      if (!newList[chat.sender]) {
        newList[chat.sender] = getUserColor(chat.sender, newList)
      }
    })
    setUserColors(newList)
  }, [chatList])

  if (!chatList) {
    return <Loading width='15' height='15' />
  }

  return (
    <div className={styles.chat}>
      {chatList.map((chat) => (
        <div key={chat.id} className={styles.chatItem}>
          <div className={styles.message}>
            <div className={styles.username} style={{color: userColors[chat.sender]}}>
              {chat.sender.toLocaleLowerCase() === user?.title.toLocaleLowerCase() && (
                <img src={toAbsoluteUrl('/assets/icons/Campaigns/streamer-icon.png')} alt='Badge' />
              )}
              {chat.sender}
              <span>:</span>
            </div>
            {chat.message}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Chat
