import {useContext} from 'react'
import styles from '../../PayoutAccount.module.scss'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import PayoutAccountContext from 'context/PayoutAccountContext'
import {useTranslation} from 'react-i18next'
const CompanySelection = () => {
  const {t} = useTranslation()
  const {payoutAccountData, setPayoutAccountData} = useContext(PayoutAccountContext)
  if (!payoutAccountData) return null
  return (
    <div className={styles.line}>
      <div className={styles.label}>{t('SettingsPage.taxStatus')}</div>
      <div className={styles.inLine}>
        <div className={styles.taxStatus}>
          <div
            className={classNames(styles.taxType, {
              [styles.selected]: payoutAccountData.billing_account_type === 'personal',
            })}
            onClick={() =>
              setPayoutAccountData({...payoutAccountData, billing_account_type: 'personal'})
            }
          >
            <img src={toAbsoluteUrl('/assets/icons/Settings/personalCompany.png')} alt='tax' />
            {t('SettingsPage.personalCompany')}
          </div>
          <div
            className={classNames(styles.taxType, {
              [styles.selected]: payoutAccountData.billing_account_type === 'business',
            })}
            onClick={() =>
              setPayoutAccountData({...payoutAccountData, billing_account_type: 'business'})
            }
          >
            <img src={toAbsoluteUrl('/assets/icons/Settings/largeCompany.png')} alt='tax' />
            {t('SettingsPage.ltdOrIncCompany')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanySelection
