import {useContext, useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
import CategorySelection from './components/CategorySelection/CategorySelection'
//STYLES
import styles from './HelpCenter.module.scss'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'

import {HelpCenterData} from './data/HelpCenterData'
import Header from './components/Header/Header'
import SingleQuestion from './components/SingleQuestion/SingleQuestion'

const HelpCenter = () => {
  const {t} = useTranslation()
  document.title = t('Pages.helpCenter') + ' • LIVAD'
  const location = useLocation()
  const {user} = useContext(AppLevelContext)
  const [questions, setQuestions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('campaigns')
  const [searchTerms, setSearchTerms] = useState('')

  const handleCategoryChange = () => {
    let newQuestions = []
    newQuestions = HelpCenterData.filter((question) => {
      return question.category === selectedCategory
    })
    setQuestions(newQuestions)
  }
  // HANDLE SEARCH
  const handleSearch = (selectedLanguage) => {
    let newQuestions = []
    newQuestions = HelpCenterData.filter((question) => {
      if (
        question.question[selectedLanguage].toLowerCase().includes(searchTerms.toLowerCase()) ||
        question.answer[selectedLanguage].toLowerCase().includes(searchTerms.toLowerCase())
      ) {
        return question
      }
    })
    setQuestions(newQuestions)
  }
  // Change effect on category change or new questions
  useEffect(() => {
    let currentTab = location.pathname.split('/')[2]
    if (currentTab === 'campaigns') {
      setSelectedCategory(currentTab)
    } else if (currentTab === 'troubleshooting') {
      setSelectedCategory(currentTab)
    } else if (currentTab === 'payouts') {
      setSelectedCategory(currentTab)
    } else if (currentTab === 'setup') {
      setSelectedCategory(currentTab)
    } else if (currentTab === 'rules') {
      setSelectedCategory(currentTab)
    } else {
      setSelectedCategory('campaigns')
    }

    if (location.pathname.split('/')[3]) {
      let newQuestions = questions
      newQuestions.map((question) => {
        if (question.id === location.pathname.split('/')[3]) {
          question.is_opened = true
        }
      })
      setQuestions(newQuestions)
    }
  }, [location, questions])
  // Check url for question id and scroll to it if exists
  useEffect(() => {
    if (location.pathname.split('/')[3]) {
      setTimeout(() => {
        let question = document.getElementById(location.pathname.split('/')[3])
        if (question) {
          question.scrollIntoView({behavior: 'smooth'})
        }
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //
  useEffect(() => {
    if (user.language_preference !== null) {
      if (searchTerms !== '') {
        handleSearch(user.language_preference)
      } else {
        handleCategoryChange()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms])

  useEffect(() => {
    if (searchTerms !== null || searchTerms !== '' || searchTerms !== undefined) {
      handleCategoryChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  return (
    <div className={styles.helpCenter_container}>
      {/* HEADER */}
      <Header setSearchTerms={setSearchTerms} searchTerms={searchTerms} />

      {/* CATEGORY SELECTION */}
      <CategorySelection
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
      />

      {/* QUESTIONS CONTAINER */}
      <div className={styles.helpCenter_articles}>
        {/* SINGLE QUESTION */}
        {questions.map((question, index) => (
          <SingleQuestion
            key={index}
            position={index}
            question={question}
            setQuestions={setQuestions}
            questions={questions}
          />
        ))}
      </div>
    </div>
  )
}

export default HelpCenter
