import styles from './SetOverlay.module.scss'
import {toAbsoluteUrl} from 'helpers'

const SetOverlay = (props: {softwareTab: string; setSoftwareTab: (arg0: string) => void}) => {
  return (
    <div className={styles.setOverlay}>
      {(props.softwareTab === 'obs' && (
        <>
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/OBS/setOverlay.gif')} alt='' />
        </>
      )) ||
        (props.softwareTab === 'streamlabs' && (
          <>
            <img
              src={toAbsoluteUrl('/assets/icons/OverlaySetup/Streamlabs/setOverlay.gif')}
              alt=''
            />
          </>
        ))}
    </div>
  )
}

export default SetOverlay
