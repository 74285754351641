import styles from './Toggle.module.scss'
import classNames from 'classnames'
import {useContext, useState, useEffect} from 'react'
import LiveConsoleContext from 'context/LiveConsoleContext'
import {useTranslation} from 'react-i18next'

const Toggle = () => {
  const {adSettings, setAdSettings, saveAdSettings} = useContext(LiveConsoleContext)
  const {t} = useTranslation()
  return (
    <div className={styles.toggleWrap} onClick={() => {}}>
      <div
        onClick={() => {
          saveAdSettings({ad_posting_preference: 'automated'})
          setAdSettings({...adSettings, ad_posting_preference: 'automated'})
        }}
        className={classNames(
          styles.toggleItem,
          adSettings?.ad_posting_preference === 'automated' && styles.active
        )}
      >
        {t('LiveConsolePage.automatic')}
      </div>
      <div
        onClick={() => {
          saveAdSettings({ad_posting_preference: 'manual'})
          setAdSettings({...adSettings, ad_posting_preference: 'manual'})
        }}
        className={classNames(
          styles.toggleItem,
          adSettings?.ad_posting_preference === 'manual' && styles.active
        )}
      >
        {t('LiveConsolePage.manual')}
      </div>
    </div>
  )
}

export default Toggle
