export const quickList = [
  {
    icon: 'settings',
    path: '/settings',
    isEnabled: true,
  },
  {
    icon: 'notification',
    path: '/notifications',
    isEnabled: true,
    permission: 'notifications'
  },
]
