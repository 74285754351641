import React from 'react'
import styles from './FilterItem.module.scss'
import classNames from 'classnames'
import EnumClipInsightsFilter from 'services/model/enum/enumClipInsightsFilter'
import {t} from 'i18next'
import {toAbsoluteUrl} from 'helpers'

interface FilterItemProps {
  filter: EnumClipInsightsFilter
  activeFilter: EnumClipInsightsFilter
  setActiveFilter: React.Dispatch<React.SetStateAction<EnumClipInsightsFilter>>
}

const FilterItem = ({filter, activeFilter, setActiveFilter}: FilterItemProps) => {
  return (
    <div
      className={classNames(styles.filterItem, {
        [styles.active]: filter === activeFilter,
      })}
      onClick={() => setActiveFilter(filter)}
    >
      {filter !== EnumClipInsightsFilter.All && (
        <img
          src={toAbsoluteUrl(`/assets/icons/ClipInsights/${filter}_emoji.png`)}
          alt='filter-emoji'
        />
      )}
      {t(`ClipInsightsPage.${filter}`)}
    </div>
  )
}

export default FilterItem
