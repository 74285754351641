import styles from './BillingInformation.module.scss'
import {useTranslation} from 'react-i18next'

const BillingInformation = () => {
  const {t} = useTranslation()
  return (
    <div className={styles.billingInformation}>
      <div className={styles.billingInformation_title}>
        {t('PayoutRequestPopup.billingInformation')}
      </div>
      <div className={styles.billingInformation_content}>
        <div className={styles.billingInformation_contentItem}>
          <span>{t('PayoutRequestPopup.companyName')}</span>LIVAD Medya Teknoloji Anonim Şirketi
        </div>
        <div className={styles.billingInformation_contentItem}>
          <span>{t('PayoutRequestPopup.address')}</span>Şehit Muhtar Mah. Mis Sok. No:24 İç Kapı
          No:13 Beyoğlu / İstanbul
        </div>
        <div className={styles.billingInformation_contentItem}>
          <span>{t('PayoutRequestPopup.taxAdministration')}</span>Beyoğlu
        </div>
        <div className={styles.billingInformation_contentItem}>
          <span>{t('PayoutRequestPopup.taxNo')}</span>6081348234
        </div>
      </div>
    </div>
  )
}

export default BillingInformation
