import styles from './Range.module.scss'
import {useContext, useState} from 'react'
import LiveConsoleContext from 'context/LiveConsoleContext'
import {useTranslation} from 'react-i18next'
interface RangeProps {
  type: string
}

const Range = ({type}: RangeProps) => {
  const {adSettings, setAdSettings, saveAdSettings} = useContext(LiveConsoleContext)
  const [inputValue, setInputValue] = useState(
    type === 'delay' ? adSettings?.automated_start_delay : adSettings?.ad_frequency
  )
  const {t} = useTranslation()

  const handleMouseUp = () => {
    if (type === 'delay') {
      setAdSettings({...adSettings, automated_start_delay: inputValue})
      setTimeout(() => {
        saveAdSettings({automated_start_delay: inputValue})
      }, 500) // Delay in milliseconds before calling saveAdSettings function
    } else {
      setAdSettings({...adSettings, ad_frequency: inputValue})
      setTimeout(() => {
        saveAdSettings({ad_frequency: inputValue})
      }, 500) // Delay in milliseconds before calling saveAdSettings function
    }
  }

  const handleInputChange = (e: any) => {
    setInputValue(Number(e.target.value))
  }

  return (
    <div className={styles.rangeWrap}>
      <div className={styles.rangeSlider}>
        <span className={styles.currentRange}>
          {t('LiveConsolePage.xMin', {
            X: inputValue,
          })}
        </span>
        <input
          className={styles.inputRange}
          type='range'
          min='10'
          max='60'
          value={inputValue}
          onChange={handleInputChange}
          onMouseUp={handleMouseUp}
        />
      </div>
      <div className={styles.rangeMinMax}>
        <span>
          {t('LiveConsolePage.xMinutes', {
            X: '10',
          })}
        </span>
        <span>
          {t('LiveConsolePage.xHour', {
            X: '1',
          })}
        </span>
      </div>
    </div>
  )
}

export default Range
