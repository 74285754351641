import styles from '../../PayoutAccount.module.scss'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'

import PayoutAccountContext from 'context/PayoutAccountContext'
const ForeignInputs = () => {
  const {t} = useTranslation()
  const {payoutAccountData, setPayoutAccountData} = useContext(PayoutAccountContext)
  if (!payoutAccountData) return null
  return (
    <>
      {/* Full Name */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.fullName')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.fullName')}
          value={payoutAccountData.individual_full_name}
          onChange={(e) => {
            setPayoutAccountData({
              ...payoutAccountData,
              individual_full_name: e.target.value.replace(/[^\p{L}\s]/gu, ''),
            })
          }}
        />
      </div>
      {/* National Id */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.nationalId')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.nationalId')}
          defaultValue={payoutAccountData.individual_id_number}
          onChange={(e) => {
            setPayoutAccountData({...payoutAccountData, individual_id_number: e.target.value})
          }}
        />
      </div>
      {/* Adress */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.address')}</div>
        <textarea
          placeholder={'' + t('SettingsPage.address')}
          defaultValue={payoutAccountData.individual_address}
          onChange={(e) => {
            setPayoutAccountData({...payoutAccountData, individual_address: e.target.value})
          }}
        ></textarea>
      </div>
      {/* Payment Method - Only Wise */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.payoutMethod')}</div>
        <select disabled>
          <option value='Wise' selected>
            Wise
          </option>
        </select>
      </div>
      {/* Wise Account Mail*/}
      <div className={styles.line}>
        <div className={styles.label}>Wise Mail</div>
        <input
          type='email'
          placeholder='Wise Mail'
          defaultValue={payoutAccountData.wise_email}
          onChange={(e) => {
            setPayoutAccountData({...payoutAccountData, wise_email: e.target.value})
          }}
        />
      </div>
    </>
  )
}

export default ForeignInputs
