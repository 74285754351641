import { toast } from "react-toastify"
import { createBaseAdsApiService } from "./baseAdsApiService"
import { createBaseStreamerApiService } from "./baseStreamerApiService"
import GameSessionDto from "./model/dto/gameSessionDto"
import { LiveConsoleAdDto } from "./model/dto/liveConsoleAdDto"
import { ManualAdRequest } from "./model/request/liveConsole/manualAdRequest"
import { AdAvailabilitiesResponse } from "./model/response/liveConsole/adAvailabilitiesResponse"
import { t } from "i18next"

export const createLiveConsoleService = () => {
  const baseService = createBaseStreamerApiService('/live_console')
  const baseAdsService = createBaseAdsApiService('')

  const getAds = async (): Promise<Array<LiveConsoleAdDto>> => {
    const response = await baseService.get<{
        ads: Array<LiveConsoleAdDto>
    }>('/ads')
    return response.data.ads
  }

  const getAdAvailabilities = async (): Promise<AdAvailabilitiesResponse> => {
    const response = await baseAdsService.get<AdAvailabilitiesResponse>('/adAvailabilities');
    return response.data
  }
  
  const postManualAd = async (body: ManualAdRequest): Promise<void> => {
    toast.promise(baseAdsService.post('/ads/streamers', body), {
      pending: t('Sending the ad...'),
      success: t('Successfully sent the ad') ?? '',
      error: t('Error while sending the ad'),
    })
  }

  const postRandomAd = async (): Promise<void> => {
    toast.promise(baseAdsService.post('/ads/streamers/random'), {
      pending: t('Sending the ad...'),
      success: t('Successfully sent the ad') ?? '',
      error: t('Error while sending the ad'),
    })
  }

  return {
    getAds,
    getAdAvailabilities,
    postManualAd,
    postRandomAd
  }
  
}
