function formatNotificationTime(date: Date) {
  const inputDate = new Date(date) // Convert the input date to a Date object
  const today = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)

  const isToday = inputDate.toDateString() === today.toDateString()
  const isYesterday = inputDate.toDateString() === yesterday.toDateString()

  const time = inputDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  if (isToday) {
    return `${time}`
  } else if (isYesterday) {
    return `Yesterday ${time}`
  } else {
    return `${inputDate.getDate()} ${inputDate.toLocaleString('default', {
      month: 'short',
      year: 'numeric',
    })} ${time}`
  }
}

export default formatNotificationTime
