import { createBaseStreamerApiService } from "./baseStreamerApiService"

export interface LeaderboardItemData {
    flag: string
    order: number
    platform: string
    points: number
    streamer_id: number
    streamer_logo: string
    streamer_title: string
}
export interface LeaderboardItem {
    contest: {
        id: number
        name: string
        scoreDescription?: string
        brandColor1?: string
        logo?: string
        clientName?: string
        isPrized?: boolean
        prizes?: Array<{
            name: string
            image?: string
        }>
        redirectUrl?: string
    }
    data: LeaderboardItemData[]
    self?: {
        position: number
        points: number
        ctr?: number
    }
    totalPoints: number
}

export const createLeaderboardService = () => {
    const baseService = createBaseStreamerApiService('/leaderboard');
    const getLeaderboardData = async (): Promise<LeaderboardItem[]> => {
        const response = await baseService.get<LeaderboardItem[]>('');
        return response.data;
    }

    return {
        getLeaderboardData
    }

}