import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'
import {useContext} from 'react'
import styles from './LinkHeader.module.scss'
import {toast} from 'react-toastify'

// Context
import {AppLevelContext} from 'context/AppLevelContext'

const LinkHeader = () => {
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()
  return (
    <div className={styles.linkHeader}>
      <div className={styles.linkHeader_title}>{t('RecruitedFriendPage.howToReferFriend')}</div>
      <div className={styles.linkHeader_list}>
        <div className={styles.linkHeader_list_item}>
          <div className={styles.itemNumber}>1</div>
          <div className={styles.itemText}>
            <button
              className={styles.copyLink}
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://streamer.livad.stream?referrer=${user?.streamer_id}`
                )
                toast.success('Referral link copied to clipboard')
              }}
            >
              {t('RecruitedFriendPage.copyYourLink')}
            </button>
          </div>
        </div>
        <div className={styles.linkHeader_list_item}>
          <div className={styles.itemNumber}>2</div>
          <div className={styles.itemText}>{t('RecruitedFriendPage.shareWithStreamerFriends')}</div>
        </div>
        <div className={styles.linkHeader_list_item}>
          <div className={styles.itemNumber}>3</div>
          <div className={styles.itemText}>
            Earn 10.00$ for every 10.000 views they get until they reach 50.000 views
          </div>
        </div>
        <div className={styles.linkHeader_list_item}>
          <div className={styles.itemNumber}>4</div>
          <div className={styles.itemText}>{t('RecruitedFriendPage.trackProgressAndEarnings')}</div>
        </div>
        <div className={styles.linkHeader_list_item}>
          <div className={styles.itemNumber}>5</div>
          <div className={styles.itemText}>
            <a href={'https://discord.gg/livad'} target={'_blank'} title={'Click for join'}>
              {t('RecruitedFriendPage.needHelpJoinDiscord')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkHeader
