//STYLES
import styles from './Games.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useNavigate} from 'react-router-dom'
import {createGamesService} from 'services/gamesService'
import {EnumGameType} from 'services/model/enum/enumGameType'
import {useTranslation} from 'react-i18next'

const games = [
  {
    name: 'Trivia',
    type: EnumGameType.Trivia,
    description: 'streamInteractiveTrivia',
    categories: ['interactive', 'guess'],
    image: toAbsoluteUrl('/assets/icons/Games/game_triviaGame.png'),
    icon: toAbsoluteUrl('/assets/icons/Games/game_triviaGame_icon.png'),
    rate: '4.5',
  },
  {
    name: 'Find the Flag',
    type: EnumGameType.Flag,
    description: 'learnFlags',
    categories: ['interactive', 'guess'],
    image: toAbsoluteUrl('/assets/icons/Games/game_findTheFlag.png'),
    icon: toAbsoluteUrl('/assets/icons/Games/game_findTheFlag_icon.png'),
    rate: '4.5',
  },
  {
    name: 'Reverse Taboo',
    type: EnumGameType.Taboo,
    description: 'guessWordHints',
    categories: ['interactive', 'card game'],
    image: toAbsoluteUrl('/assets/icons/Games/game_reverseTaboo.png'),
    icon: toAbsoluteUrl('/assets/icons/Games/game_reverseTaboo_icon.png'),
    rate: '4.5',
  },
]

const Games = () => {
  const gamesService = createGamesService()
  const navigate = useNavigate()
  const {t} = useTranslation()

  return (
    <div className={styles.games_container}>
      <div className={styles.games_header}>
        <span className={styles.headerTitle}>
          <img src={toAbsoluteUrl('/assets/icons/LiveConsole/gamesIcon.png')} alt='games' />
          {t('LiveConsolePage.games')}
        </span>
      </div>
      <div className={styles.games_list}>
        {games.map((game, index) => {
          return (
            <div className={styles.games_listItem} key={index}>
              <img src={game.image} className={styles.gameHeaderImg} alt='game' />
              <div className={styles.gameInfo}>
                <span className={styles.gameName}>{game.name}</span>
              </div>
              <div className={styles.gameDesc}>
                <span>{t(`LiveConsolePage.${game.description}`)}</span>
                <div className={styles.gameCategory}>
                  <img
                    src={toAbsoluteUrl('/assets/icons/Games/gameCategory_icon.png')}
                    alt='category'
                  />
                  <span>{t('LiveConsolePage.interactive')}</span>
                  <span>{t('LiveConsolePage.guess')}</span>
                </div>
              </div>
              <div className={styles.gameFooter}>
                <button
                  onClick={() => {
                    navigate(`/live-console/game-popup/${game.type}`)
                  }}
                  className={styles.gameDetailBtn}
                >
                  {t('LiveConsolePage.detail')}
                </button>
                <button
                  onClick={() => {
                    if (game.type == EnumGameType.Taboo) {
                      gamesService.postTabooGame()
                    } else {
                      gamesService.postWordGame(game.type)
                    }
                  }}
                  className={styles.gamePlayBtn}
                >
                  {t('LiveConsolePage.play')}
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Games
