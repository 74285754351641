import styles from './Moderators.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useContext, useEffect, useState} from 'react'
import classNames from 'classnames'
import SettingsContext from 'context/SettingsContext'
import {AppLevelContext} from 'context/AppLevelContext'
import Loading from 'components/Shared/Loading/Loading'
import EnumModPermType from 'services/model/enum/enumModPermType'

const Moderators = () => {
  const AVAILABLE_PERMS = [EnumModPermType.ManageDonations]
  const [platform, setPlatform] = useState('twitch')
  const {user} = useContext(AppLevelContext)
  const {
    mods,
    addMod,
    fetchModPerms,
    checkModPermsSave,
    updateModPerms,
    removeMod,
    selectedModPerms,
    setSelectedModPerms,
    selectedModUsername,
    permsLoading,
    fetchMods
  } = useContext(SettingsContext)
  const [search, setSearch] = useState('');
  
  useEffect(() => {
    if (mods === null) {
      fetchMods();
    }
  }, [])

  if (user?.platform !== 'twitch') {
    return (
      <div className={styles.moderators}>
        Only available for twitch
      </div>
    )

  }

  if (mods === null) {
    return <Loading />
  }
  return (
    <div className={styles.moderators}>
      <div className={styles.line}>
        <div className={styles.label}>Add Moderator</div>
        <div className={styles.desc}>
          Add moderators to your LIVAD account. Moderators will be able to manage LIVAD's tools on
          your behalf.
        </div>
        <div className={styles.inLine}>
          <span>
            <img
              src={toAbsoluteUrl('/assets/icons/Platforms/twitch-icon.png')}
              alt='platform'
            />
          </span>
          <span >Twitch</span>
          {/* <select
            name='platform'
            id='platform'
            onChange={(e) => {
              setPlatform(e.target.value)
            }}
          >
            <option value='twitch'>Twitch</option>
            <option value='youtube'>YouTube</option>
            <option value='trovo'>Trovo</option>
          </select> */}
          <input onChange={(e) => {
            setSearch(e.target.value)
          }} value={search} type='text' placeholder='Username' />
          <button onClick={() => {
            if (search == '') {
              return;
            }
            addMod(search)
          }} className={styles.addBtn}>Add</button>
        </div>
      </div>
      {
        mods?.length ? (
        <div className={styles.line}>
          <div className={styles.label}>Moderators</div>
          <div className={styles.desc}>
            Manage your moderators here by removing or editing their permissions.
          </div>
          <div className={styles.moderatorList}>
          {
                mods.map((mod, index) => {
                  return (
                    <div key={index} className={styles.moderatorItem}>
                      <div className={styles.moderatorAvatar}>
                            <img src={mod.logo} alt='avatar' />
                            <span className={styles.moderatorUsername}>
                              <img src={toAbsoluteUrl('/assets/icons/Platforms/twitch-icon.png')} alt='platform' />
                              {mod.username}
                            </span>
                      </div>
                      <div className={styles.moderatorActions}>
                        <button onClick={() => {
                          removeMod(mod.username);
                        }} className={styles.removeBtn}>
                          <img src={toAbsoluteUrl('/assets/icons/Settings/removeIcon.png')} alt='remove' />
                          Remove
                        </button>
                        <button onClick={() => {
                          fetchModPerms(mod.username)
                        }} className={classNames(styles.editPerm, {
                          [styles.selected]: selectedModUsername === mod.username
                        })}>
                          <img src={toAbsoluteUrl('/assets/icons/Settings/editIcon.png')} alt='edit' />
                          Edit
                        </button>
                      </div>
                    </div>
                  )
                })
            }
          </div>
        </div>
        ): null
      }

      {
        selectedModPerms && !permsLoading ? (
          <div className={styles.line}>
            <div className={styles.editingView}>
              <div className={styles.editingView_title}>
                <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepInfoIcon.png')} alt='info' />
                You are editing{' '}
                <span>
                  <img src={toAbsoluteUrl('/assets/icons/Platforms/trovo-icon.png')} alt='platform' />
                  {selectedModPerms.twitchUsername}
                </span>
                's permissions:
              </div>
              <ul className={styles.permList}>
                <li>
                  <div onClick={() => {
                    if (AVAILABLE_PERMS.filter((perm) => (selectedModPerms?.permData.find(x=>x.perm===perm&&x.hasPerm))).length) {
                      setSelectedModPerms({
                        ...selectedModPerms,
                        permData: selectedModPerms.permData.map((perm) => {
                          return {
                            ...perm,
                            hasPerm: false
                          }
                        })
                      })
                    } else {
                      setSelectedModPerms({
                        ...selectedModPerms,
                        permData: AVAILABLE_PERMS.map((perm) => {
                          return {
                            perm,
                            hasPerm: true
                          }
                        })
                      })
                    }
                  }} className={classNames(styles.checkbox, {
                    [styles.checked]: AVAILABLE_PERMS.filter((perm) => (selectedModPerms?.permData.find(x=>x.perm===perm&&x.hasPerm))).length
                  })}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>Super Moderator, super moderator have all permissions of moderator</span>
                </li>
                <li>
                  <div onClick={() => {
                    setSelectedModPerms({
                      ...selectedModPerms,
                      permData: selectedModPerms.permData.map((perm) => {
                        if (perm.perm === EnumModPermType.ManageDonations) {
                          return {
                            ...perm,
                            hasPerm: !perm.hasPerm
                          }
                        }
                        return perm;
                      })
                    })
                  }} className={classNames(styles.checkbox, {
                    [styles.checked]: selectedModPerms.permData.filter((perm) => perm.perm === EnumModPermType.ManageDonations && perm.hasPerm).length
                  })}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>View and Manage coming donations from donator at Live Feed</span>
                </li>
                {/* <li>
                  <div className={styles.checkbox}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>
                    Manage sponsorships, moderator can accept or deny any sponsorship for your account
                  </span>
                </li>
                <li>
                  <div className={classNames(styles.checkbox, styles.checked)}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>
                    Manage game sessions, moderator can start any game session for your stream
                  </span>
                </li>
                <li>
                  <div className={styles.checkbox}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>
                    Manage overlays, moderator can send manually or randomly any overlay to your stream
                  </span>
                </li>
                <li>
                  <div className={classNames(styles.checkbox, styles.checked)}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>
                    Manage overlay service, moderator can turn on or off overlay service for your stream
                  </span>
                </li>
                <li>
                  <div className={classNames(styles.checkbox, styles.checked)}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>
                    Manage placement mode, moderator can select placement as automatic or manual for
                    your stream
                  </span>
                </li>
                <li>
                  <div className={styles.checkbox}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>
                    Manage overlay frequency, moderator can set overlay frequency for your stream
                  </span>
                </li>
                <li>
                  <div className={styles.checkbox}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>Manage start delay, moderator can set start delay for your stream</span>
                </li>
                <li>
                  <div className={styles.checkbox}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>View and Manage coming sponsorship overlays at Live Feed</span>
                </li> 
                <li>
                  <div className={styles.checkbox}>
                    <img src={toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')} alt='checked' />
                  </div>
                  <span>View and Manage game sessions at Live Feed</span>
                </li> */}
              </ul>
              {
                checkModPermsSave() ? (
                  <button onClick={() => {
                    updateModPerms()
                  }} className={styles.saveBtn}>Save Permissions</button>
                ): null
              }
            </div>
          </div>
        ): permsLoading ? (
          <Loading />
        ): null
      }
    </div>
  )
}

export default Moderators
