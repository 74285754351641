import {useState} from 'react'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'

//STYLES
import styles from './KickAuthForm.module.scss'
import EmailSignup from './components/EmailSignup'
import ForgotPassword from './components/ForgotPassword'
import EmailLogin from './components/EmailLogin'

interface KickAuthFormProps {
  setIsTab: (tab: string) => void
}

const KickAuthForm = ({setIsTab}: KickAuthFormProps) => {
  const [view, setView] = useState('signIn')
  return (
    <div className={styles.kickAuthForm}>
      {view === 'signIn' ? (
        <EmailLogin view={view} setView={setView} setTab={setIsTab} />
      ) : view === 'signUp' ? (
        <EmailSignup view={view} setView={setView} />
      ) : view === 'forgotPw' ? (
        <ForgotPassword view={view} setView={setView} />
      ) : null}
    </div>
  )
}

export default KickAuthForm
