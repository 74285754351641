import {createBaseStreamerApiService} from './baseStreamerApiService'
import {GetManagedStreamersResponse} from './model/response/admin/getManagedStreamerResponse'

export const createAdminService = () => {
  const baseService = createBaseStreamerApiService('/admin')

  const searchStreamers = async (query: string): Promise<GetManagedStreamersResponse> => {
    const response = await baseService.get<GetManagedStreamersResponse>(
      `/streamers/search?search=${query}`
    )
    return response.data
  }

  const updateAdminStreamer = async (streamerId: string) => {
    const response = await baseService.put<void>('/streamers', {
      streamer_id: streamerId,
    })
    return response
  }

  return {
    searchStreamers,
    updateAdminStreamer,
  }
}
