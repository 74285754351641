import styles from './GuideItem.module.scss'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
const GuideItem = ({
  guideImage,
  guideTitle,
  guideDescription,
}: {
  guideImage: string
  guideTitle: string
  guideDescription: string
}) => {
  return (
    <div
      className={classNames(styles.guideItem, {
        [styles.lastGuideItem]: guideTitle === 'Son olarak..',
      })}
    >
      <div className={styles.guideContent}>
        <div className={styles.title}>{guideTitle}</div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{__html: guideDescription}}
        ></div>
      </div>
      {guideTitle !== 'Son olarak..' && <img src={toAbsoluteUrl(guideImage)} alt='Guide' />}
    </div>
  )
}

export default GuideItem
