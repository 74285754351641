import {toAbsoluteUrl} from 'helpers'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
// STYLES
import styles from './TermsPrivacy.module.scss'

const TermsPrivacy = () => {
  const {t} = useTranslation()
  document.title = t('Pages.termsPrivacyPolicy') + ' • LIVAD'
  const [active, setActive] = useState('terms')
  return (
    <div className={styles.termsPrivacy}>
      <div className={styles.header}>
        <img src={toAbsoluteUrl('/assets/icons/logo.png')} alt='logo' />
        <div className={styles.termsPrivacy_menu}>
          <div
            onClick={() => setActive('terms')}
            className={classNames(styles.termsPrivacy_menuItem, {
              [styles.active]: active === 'terms',
            })}
          >
            {t('TermsPrivacyPolicyPage.termsAndConditions')}
          </div>
          <div
            onClick={() => setActive('privacy')}
            className={classNames(styles.termsPrivacy_menuItem, {
              [styles.active]: active === 'privacy',
            })}
          >
            {t('TermsPrivacyPolicyPage.privacyPolicy')}
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {active === 'terms' ? (
          <iframe
            title='Terms and Conditions'
            src='https://docs.google.com/gview?url=https://livad.blob.core.windows.net/livad-ads/Images/Streamer/TermsAndConditions.pdf&embedded=true'
          ></iframe>
        ) : (
          <iframe
            title='Privacy Policy'
            src='https://docs.google.com/gview?url=https://livad.blob.core.windows.net/livad-ads/Images/Streamer/PrivacyPolicy.pdf&embedded=true&dark=true'
          ></iframe>
        )}
      </div>
    </div>
  )
}

export default TermsPrivacy
