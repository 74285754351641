import styles from './Opportunities.module.scss'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import DashboardContext from 'context/DashboardContext'
const Opportunities = () => {
  const navigate = useNavigate()
  const {opportunities} = useContext(DashboardContext)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const {t} = useTranslation()

  const formatDate = (date: string) => {
    const dateObj = new Date(date)
    const month = dateObj.toLocaleString('default', {month: 'short'})
    const day = dateObj.getDate()

    return `${month} ${day}`
  }

  return (
    <>
      {opportunities.map((item: any, index: number) => (
        <div
          className={styles.opportunities}
          key={index}
          style={{
            display: activeIndex === index ? 'flex' : 'none',
          }}
        >
          <div className={styles.opportunitiesGlow}></div>
          {/* <div className={styles.opportunitiesBrand}>
            <img src={item.url_to_logo} alt='brand' />
          </div> */}
          <div className={styles.opportunitiesTitle}>
            <img src={toAbsoluteUrl('/assets/icons/Dashboard/opportunitiesIcon.png')} alt='icon' />
            {t('DashboardPage.opportunities')}
          </div>

          <div className={styles.opportunitiesContent}>
            {opportunities.length > 1 ? (
              <button
                className={styles.backBtn}
                onClick={() => {
                  if (activeIndex > 0) {
                    setActiveIndex(activeIndex - 1)
                  } else {
                    setActiveIndex(opportunities.length - 1)
                  }
                }}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/Dashboard/opportunitiesArrow.png')}
                  alt='back'
                />
              </button>
            ) : null}
            <div className={styles.opportunitiesContent_info}>
              <div className={styles.opportunitiesContent_infoTitle}>
                <img src={item.url_to_logo} alt='brand' />
                {item.client_name}
              </div>
              <div className={styles.opportunitiesContent_infoDesc}>{item.campaign_name}</div>
              <div className={styles.opportunitiesContent_infoDate}>
                <span>
                  <img
                    src={toAbsoluteUrl('/assets/icons/Dashboard/opportunitiesDate.png')}
                    alt='icon'
                  />
                  <b>{t('DashboardPage.startDate')}</b>
                  {formatDate(item.start_date)}
                </span>
                <span>
                  <img
                    src={toAbsoluteUrl('/assets/icons/Dashboard/opportunitiesDate.png')}
                    alt='icon'
                  />
                  <b>{t('DashboardPage.endDate')}</b>
                  {formatDate(item.end_date)}
                </span>
              </div>
            </div>
            {opportunities.length > 1 ? (
              <button
                className={styles.nextBtn}
                onClick={() => {
                  if (activeIndex < opportunities.length - 1) {
                    setActiveIndex(activeIndex + 1)
                  } else {
                    setActiveIndex(0)
                  }
                }}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/Dashboard/opportunitiesArrow.png')}
                  alt='next'
                />
              </button>
            ) : null}
          </div>

          <button
            onClick={() => navigate(`/dashboard/campaign-popup/${item.campaign_id}`)}
            className={styles.viewBtn}
          >
            {t('DashboardPage.viewCampaign')}
          </button>
        </div>
      ))}
    </>
  )
}

export default Opportunities
