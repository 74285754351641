import {useContext} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
import styles from './LanguagesDropdown.module.scss'
import {toAbsoluteUrl} from 'helpers'
import { createStreamersService } from 'services/streamersService'
import EnumLanguagePreference from 'services/model/enum/enumLanguagePreference'

const LanguagesDropdown = () => {
  const streamerService = createStreamersService()
  const changeLanguage = async (language: string) => {
    // await axios
    //   .put(
    //     `${process.env.REACT_APP_STREAMER_API_URL}/streamers`,
    //     {
    //       language_preference: language,
    //     },
    //     config
    //   )
    //   .then((res) => {
    //     window.location.reload()
    //   })
    //   .catch((err) => {
    //     toast.error("Couldn't change language")
    //     console.log(err)
    //   })
    try {
      await streamerService.updateStreamersAccount({
        language_preference: language as EnumLanguagePreference
      })
      window.location.reload()
    } catch (error) {
      toast.error("Couldn't change language")
      console.log(error)
    }
  }

  return (
    <div className={styles.dropdownMenu}>
      <div className={styles.dropdownItem} onClick={() => changeLanguage('en')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/eng.jpeg')} alt='flag' />
        <span className={styles.dropdownItemText}>English</span>
      </div>
      <div className={styles.dropdownItem} onClick={() => changeLanguage('tr')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/tr.png')} alt='flag' />
        <span className={styles.dropdownItemText}>Türkçe</span>
      </div>
      <div className={styles.dropdownItem} onClick={() => changeLanguage('es')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/es.png')} alt='flag' />
        <span className={styles.dropdownItemText}>Español</span>
      </div>

      <div className={styles.dropdownItem} onClick={() => changeLanguage('ar')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/ar.png')} alt='flag' />
        <span className={styles.dropdownItemText}>عربي</span>
      </div>

      <div className={styles.dropdownItem} onClick={() => changeLanguage('cs')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/cs.png')} alt='flag' />
        <span className={styles.dropdownItemText}>čeština</span>
      </div>

      <div className={styles.dropdownItem} onClick={() => changeLanguage('de')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/de.png')} alt='flag' />
        <span className={styles.dropdownItemText}>Deutsch</span>
      </div>

      <div className={styles.dropdownItem} onClick={() => changeLanguage('fr')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/fr.png')} alt='flag' />
        <span className={styles.dropdownItemText}>Français</span>
      </div>

      <div className={styles.dropdownItem} onClick={() => changeLanguage('nl')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/nl.png')} alt='flag' />
        <span className={styles.dropdownItemText}>Nederlands</span>
      </div>

      <div className={styles.dropdownItem} onClick={() => changeLanguage('pt')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/pt.png')} alt='flag' />
        <span className={styles.dropdownItemText}>Português</span>
      </div>

      <div className={styles.dropdownItem} onClick={() => changeLanguage('ru')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/ru.png')} alt='flag' />
        <span className={styles.dropdownItemText}>Русский</span>
      </div>

      <div className={styles.dropdownItem} onClick={() => changeLanguage('sk')}>
        <img src={toAbsoluteUrl('/assets/icons/Flags/sk.png')} alt='flag' />
        <span className={styles.dropdownItemText}>Slovenský</span>
      </div>
    </div>
  )
}

export default LanguagesDropdown
