import {useState, useContext, useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {loadingHandler} from 'helpers'
import {toAbsoluteUrl} from 'helpers'
import axios from 'axios'
// STYLES
import styles from './CampaignPopup.module.scss'

// COMPONENTS
import CampaignPopupMenu from './components/CampaignPopupMenu/CampaignPopupMenu'
import Settings from './components/Settings/Settings'
import CampaignDetail from './components/CampaignDetail/CampaignDetail'
import Loading from 'components/Shared/Loading/Loading'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
import {GetAutoAcceptSettingsResponse} from 'services/model/response/streamers/getAutoAcceptSettingsResponse'
import EnumCategoryTargetingType from 'services/model/enum/enumCategoryTargetingType'
import { createCampaignService } from 'services/campaignService'

export interface Campaign {
  CPM: number
  CPM_with_bonus: number
  CPM_bonus_percentage: number
  asset: string
  campaign_brief: string
  campaign_id: number
  campaign_name: string
  campaign_status: string
  campaign_type: string
  client_id: number
  client_logo: string
  client_name: string
  end_date: string
  is_featured: number
  left_padding: string
  payable_date: string
  pretext: string
  question_text: string
  redirect_link: string
  start_date: string
  streamer_logo: string
  streamer_status: string
  top_padding: string
  width: string
  asset_type: string
  category_targeting_on: boolean
  category_targeting_type: EnumCategoryTargetingType
  language_targeting_on: boolean
  country_targeting_on: boolean
  categories: {
    id: number
    name: string
    logo: string | null
  }[]
  excludedCategories: {
    id: number
    name: string
    logo: string
  }[]
  countries: {
    id: string
    name: string
    logo: string
  }[]
  languages: {
    id: string
    name: string
    logo: string
  }[]
  answers?: {
    id: number
    text: string
    is_correct: boolean
    campaign_id: number
    interactive_asset: string | null
    interactive_redirect_link: string | null
    interactive_trigger_id: number | null
    asset: string | null
    asset_type: string | null
    duration: 15000 | null
    top_padding: string | null
    left_padding: string | null
    width: string | null
  }[]
}

type StreamerList = {
  count: number
  streamers: [
        {
          link_to_logo: string | null
          title: string
        }
  ] | null
}

const CampaignPopup = () => {
  const campaignService = createCampaignService()
  let {campaignId} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [isPopupTab, setIsPopupTab] = useState('campaign')
  const [pageLoading, setPageLoading] = useState(true)
  const [campaign, setCampaign] = useState<Campaign | null>(null)
  const [autoAcceptSettings, setAutoAcceptSettings] =
    useState<GetAutoAcceptSettingsResponse | null>(null)
  const [streamerList, setStreamerList] = useState<StreamerList>({
    streamers: null,
    count: 0,
  })

  const routePath = location.pathname.split('/')[1]

  if (campaignId === undefined) {
    campaignId = location.pathname.split('/')[3]
  }


  // GET CAMPAIGN DATA AND POPULATE POPUP
  const getCampaign = async () => {
    if (campaignId === undefined) return
    try {
      const response = await campaignService.getCampaign(parseInt(campaignId))
      setAutoAcceptSettings(response.autoAcceptSettings)
      setCampaign(response.data)
      setPageLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  // GET STREAMERS THAT ACCEPT CAMPAIGN
  const getAcceptedStreamers = async () => {
    if (campaignId === undefined) return
    try {
      const response = await campaignService.getAcceptedStreamers(parseInt(campaignId))
      setStreamerList({
        streamers: response.data.streamers,
        count: response.data.count,
      })
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    loadingHandler([getCampaign(), getAcceptedStreamers()], setPageLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <div className={styles.popupWrap}>
      <div className={styles.popup}>
        {!pageLoading && (
          <CampaignPopupMenu
            isPopupTab={isPopupTab}
            setIsPopupTab={setIsPopupTab}
            campaign={campaign}
          />
        )}

        {isPopupTab === 'campaign' && !pageLoading ? (
          <CampaignDetail
            campaign={campaign}
            streamerList={streamerList}
            autoAcceptSettings={autoAcceptSettings}
          />
        ) : isPopupTab === 'settings' && !pageLoading ? (
          <Settings campaign={campaign} />
        ) : (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        )}

        <button
          className={styles.closePopup}
          onClick={() => {
            navigate(`/${routePath}`)
          }}
        >
          <img src={toAbsoluteUrl('/assets/icons/closeIcon.png')} alt='close' />
        </button>
      </div>
    </div>
  )
}

export default CampaignPopup
