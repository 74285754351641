import {useNavigate, useLocation} from 'react-router-dom'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import styles from '../../Sidebar.module.scss'
import {useTranslation} from 'react-i18next'
import { useContext } from 'react'
import {AppLevelContext} from 'context/AppLevelContext'
type SingleNavigationProps = {
  route: {
    name: string
    path: string
    icon: string
    beta: boolean
    new: boolean
    modPerms?: string[], // Add this prop
    modAllowed?: boolean, // Add this prop
  }
}

const SingleNavigation = ({route}: SingleNavigationProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {user} = useContext(AppLevelContext) // Get the user context

  let isActive = location.pathname === route.path
  if (route.path === '/dashboard' && location.pathname === '/') {
    isActive = true
  }

  // Check if the user has permission to see this route
  let hasPermission = true;
  if (user && user.role === 'mod' && !user.self) {
    if (route.modAllowed) {
      hasPermission = route.modPerms ? route.modPerms.every(perm => user.mod_perms.map(x=> x as string).includes(perm)) : true;
    } else {
      hasPermission = false;
    }
  }

  return hasPermission ? (
    <div
      className={classNames(styles.menuItem, {[styles.active]: isActive})}
      onClick={() => {
        navigate(route.path)
      }}
    >
      <img src={toAbsoluteUrl(`/assets/icons/Sidebar/${route.icon}.png`)} alt='' />
      {t(`Pages.${route.name}`)}
      {/* BETA */}
      {route.beta && <span className={styles.beta}>beta</span>}
      {/* NEW */}
      {route.new && <span className={styles.new}>new</span>}
    </div>
  ) : null;
}

export default SingleNavigation
