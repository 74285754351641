import styles from './Steps.module.scss'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
const Steps = (props: {activeTab: number; setActiveTab: (activeTab: number) => void}) => {
  const {t} = useTranslation()
  const {activeTab, setActiveTab} = props
  return (
    <div className={styles.steps}>
      <div
        className={classNames(
          styles.stepItem,
          {[styles.completed]: activeTab > 1},
          {
            [styles.current]: activeTab === 1,
          }
        )}
        onClick={() => setActiveTab(1)}
      >
        <div
          className={classNames(
            styles.stepNumber,
            {[styles.completed]: activeTab > 1},
            {
              [styles.current]: activeTab === 1,
            }
          )}
        >
          1
        </div>
        {t('SettingsPage.copyLink')}
      </div>
      <hr className={classNames(styles.stepLine, {[styles.completed]: activeTab > 1})} />
      <div
        className={classNames(
          styles.stepItem,
          {[styles.completed]: activeTab > 2},
          {
            [styles.current]: activeTab === 2,
          }
        )}
        onClick={() => setActiveTab(2)}
      >
        <div
          className={classNames(
            styles.stepNumber,
            {[styles.completed]: activeTab > 2},
            {
              [styles.current]: activeTab === 2,
            }
          )}
        >
          2
        </div>
        {t('SettingsPage.browserSource')}
      </div>
      <hr className={classNames(styles.stepLine, {[styles.completed]: activeTab > 2})} />
      <div
        className={classNames(
          styles.stepItem,
          {[styles.completed]: activeTab > 3},
          {
            [styles.current]: activeTab === 3,
          }
        )}
        onClick={() => setActiveTab(3)}
      >
        <div
          className={classNames(
            styles.stepNumber,
            {[styles.completed]: activeTab > 3},
            {
              [styles.current]: activeTab === 3,
            }
          )}
        >
          3
        </div>
        {t('SettingsPage.setOverlay')}
      </div>
      <hr className={classNames(styles.stepLine, {[styles.completed]: activeTab > 3})} />
      <div
        className={classNames(
          styles.stepItem,
          {[styles.completed]: activeTab > 4},
          {
            [styles.current]: activeTab === 4,
          }
        )}
        onClick={() => setActiveTab(4)}
      >
        <div
          className={classNames(
            styles.stepNumber,
            {[styles.completed]: activeTab > 4},
            {
              [styles.current]: activeTab === 4,
            }
          )}
        >
          4
        </div>
        {t('SettingsPage.placement')}
      </div>
      <hr className={classNames(styles.stepLine, {[styles.completed]: activeTab > 4})} />
      <div
        className={classNames(
          styles.stepItem,
          {[styles.completed]: activeTab > 5},
          {
            [styles.current]: activeTab === 5,
          }
        )}
        onClick={() => setActiveTab(5)}
      >
        <div
          className={classNames(
            styles.stepNumber,
            {[styles.completed]: activeTab > 5},
            {
              [styles.current]: activeTab === 5,
            }
          )}
        >
          5
        </div>
        {t('SettingsPage.lockOverlay')}
      </div>
      <hr className={classNames(styles.stepLine, {[styles.completed]: activeTab > 5})} />
      <div
        className={classNames(
          styles.stepItem,
          {[styles.completed]: activeTab > 6},
          {
            [styles.current]: activeTab === 6,
          }
        )}
        onClick={() => setActiveTab(6)}
      >
        <div
          className={classNames(
            styles.stepNumber,
            {[styles.completed]: activeTab > 6},
            {
              [styles.current]: activeTab === 6,
            }
          )}
        >
          6
        </div>
        {t('SettingsPage.checkOverlay')}
      </div>
    </div>
  )
}

export default Steps
