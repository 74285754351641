import {useContext, useState} from 'react'
import {toAbsoluteUrl} from 'helpers'
import ClickAwayListener from 'react-click-away-listener'

//STYLES
import styles from './SearchBrand.module.scss'
import SelectInput from 'components/Shared/SelectInput/SelectInput'
import { createStreamersService } from 'services/streamersService'
import SettingsContext from 'context/SettingsContext'

const SearchBrand = () => {
  const {
    autoAcceptSettings,
    setAutoAcceptSettings,
  } = useContext(SettingsContext)
  const streamerService = createStreamersService();
  if (!autoAcceptSettings) {
    return null
  }
  return (
    <div className={styles.searchBrand}>
      {/* <div className={styles.selectWrapper}>
        <div className={styles.selectInput} onClick={() => setSearchBrand(!searchBrand)}>
          <span className={styles.inputPlaceholder}>Select... </span>
          <img
            src={toAbsoluteUrl('/assets/icons/Settings/selectBox_arrowIcon.png')}
            alt='Arrow-Icon'
          />
        </div>
        {searchBrand && (
          <ClickAwayListener onClickAway={() => setSearchBrand(false)}>
            <div className={styles.selectResult}>
              <div className={styles.selectSearch}>
                <input type='text' placeholder='Search...' />
                <button className={styles.searchBtn}>
                  <svg
                    strokeWidth={0}
                    viewBox='0 0 512 512'
                    height='1em'
                    width='1em'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M456.69 421.39 362.6 327.3a173.81 173.81 0 0 0 34.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 0 0 327.3 362.6l94.09 94.09a25 25 0 0 0 35.3-35.3zM97.92 222.72a124.8 124.8 0 1 1 124.8 124.8 124.95 124.95 0 0 1-124.8-124.8z' />
                  </svg>
                </button>
              </div>
              <div className={styles.searchResultList}>
                <div className={styles.resultItem}>
                  <span className={styles.resultText}>
                    <img
                      src='https://livad.blob.core.windows.net/livad-ads/clientLogos/client-32/1685447476037.jpg'
                      alt='Brand'
                    />
                    LIVAD Technologies
                  </span>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div> */}
      <SelectInput
          showResult={false}
          fetchResults={streamerService.searchAutoAcceptClients}
          // defaultValue={{name: 'Select...'}}
          initialSearch={true}
          select={(result) => {
            const clients = autoAcceptSettings.autoAcceptClients;
            const found = clients.find((client) => client.id === result.id);
            if (!found) {
              setAutoAcceptSettings({
                ...autoAcceptSettings,
                autoAcceptClients: [...clients, result],
              });
            }
          }}
          defaultAvatar={toAbsoluteUrl('/assets/icons/defaultBusinessAvatar.png')}
      />
      <div className={styles.selectedList}>
        {/* <div className={styles.listItem}>
          <img
            src='https://livad.blob.core.windows.net/livad-ads/clientLogos/client-18/1685447459189.jpg'
            alt='Brand'
          />
          Yemeksepeti
          <button className={styles.removeBtn}>
            <svg
              strokeWidth={0}
              viewBox='0 0 24 24'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path fill='none' d='M0 0h24v24H0V0z' />
              <path d='M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zM9.41 15.95 12 13.36l2.59 2.59L16 14.54l-2.59-2.59L16 9.36l-1.41-1.41L12 10.54 9.41 7.95 8 9.36l2.59 2.59L8 14.54z' />
            </svg>
          </button>
        </div> */}
        {
          autoAcceptSettings.autoAcceptClients.map((client) => (
            <div className={styles.listItem} key={client.id}>
              <img
                src={client.logo ?? toAbsoluteUrl('/assets/icons/defaultBusinessAvatar.png')}
                alt='Brand'
                onError={(e) => {
                  e.currentTarget.src = toAbsoluteUrl('/assets/icons/defaultBusinessAvatar.png');
                }}
              />
              {client.name}
              <button
                className={styles.removeBtn}
                onClick={() => {
                  const clients = autoAcceptSettings.autoAcceptClients;
                  const filtered = clients.filter((c) => c.id !== client.id);
                  setAutoAcceptSettings({
                    ...autoAcceptSettings,
                    autoAcceptClients: filtered,
                  });
                }}
              >
                <svg
                  strokeWidth={0}
                  viewBox='0 0 24 24'
                  height='1em'
                  width='1em'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path fill='none' d='M0 0h24v24H0V0z' />
                  <path d='M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zM9.41 15.95 12 13.36l2.59 2.59L16 14.54l-2.59-2.59L16 9.36l-1.41-1.41L12 10.54 9.41 7.95 8 9.36l2.59 2.59L8 14.54z' />
                </svg>
              </button>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default SearchBrand
