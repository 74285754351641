import styles from './Campaigns.module.scss'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {Campaign} from 'context/CampaignContext'
import {calculateRemainingTime} from 'helpers'
import {AppLevelContext} from 'context/AppLevelContext'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import LiveConsoleContext from 'context/LiveConsoleContext'
import {useTranslation} from 'react-i18next'

interface SingleCampaignProps {
  campaign: Campaign
}

const SingleCampaign = ({campaign}: SingleCampaignProps) => {
  const navigate = useNavigate()
  const {user} = useContext(AppLevelContext)
  const {adAvailabilityData} = useContext(LiveConsoleContext)
  const {t} = useTranslation()

  const remainingTime = calculateRemainingTime(campaign.end_date)
  return (
    <div className={styles.campaigns_listItem}>
      <div className={styles.listItem_header}>
        <span className={styles.remainingTime}>
          <img
            src='https://livad-test-bucket.s3.eu-central-1.amazonaws.com/img/prd-streamer/campaigns/remaining-time-icon.png'
            alt=''
          />
          {t('LiveConsolePage.xDaysLeft', {
            X: remainingTime.days,
          })}
        </span>
        <img
          className={styles.listItem_avatar}
          src={campaign.url_to_logo}
          alt=''
          onError={(e) => {
            e.currentTarget.src = toAbsoluteUrl(
              '/assets/icons/Dashboard/Brands/defaultBrandAvatar.png'
            )
          }}
        />
      </div>
      <div className={styles.listItem_content}>
        <span className={styles.clientName} title={campaign.client_name}>
          {campaign.client_name}
        </span>
        <span className={styles.campaignName} title={campaign.campaign_name}>
          {campaign.campaign_name}
        </span>
        <div className={styles.campaignSub}>
          <span className={styles.campaignType}>
            <img
              src='https://livad-test-bucket.s3.eu-central-1.amazonaws.com/img/prd-streamer/campaigns/video-icon.png'
              alt=''
            />
            {campaign.campaign_type.slice(0, 1).toUpperCase() + campaign.campaign_type.slice(1)}
          </span>
          <span className={styles.campaignRpm}>
            <b>RPM:</b>
            {campaign.CPM} {user?.currency_sign}
          </span>
        </div>
        {/* TODO: If manual ad posting is available, show this button */}
        <button
          onClick={() => {
            if (
              !adAvailabilityData?.streamerAvailable ||
              !adAvailabilityData.availableCampaignIds.includes(campaign.campaign_id)
            )
              return
            navigate(`/live-console/ad-popup/${campaign.campaign_id}`)
          }}
          className={classNames(styles.campaignSendAdBtn, {
            [styles.disabled]:
              !adAvailabilityData?.streamerAvailable ||
              !adAvailabilityData.availableCampaignIds.includes(campaign.campaign_id),
          })}
        >
          <img src={toAbsoluteUrl('/assets/icons/LiveConsole/sendAdIcon.png')} alt='send' />
          {t('LiveConsolePage.sendAd')}
        </button>
        <button
          className={styles.campaignInspectBtn}
          onClick={() => navigate(`/live-console/campaign-popup/${campaign.campaign_id}`)}
        >
          <img
            src='https://livad-test-bucket.s3.eu-central-1.amazonaws.com/img/prd-streamer/campaigns/inspect-icon.png'
            alt=''
          />
          {t('LiveConsolePage.inspect')}
        </button>
      </div>
    </div>
  )
}

export default SingleCampaign
