import {createBaseStreamerApiService} from './baseStreamerApiService'
import {AcceptAdRequest} from './model/request/adInterval/acceptAdRequest'
import {DenyAdRequest} from './model/request/adInterval/denyAdRequest'
import {GetAdIntervalRequest} from './model/request/adInterval/getAdIntervalRequest'
import {SnoozeAdRequest} from './model/request/adInterval/snoozeAdRequest'
import {
  GetAdIntervalResponse,
  GetAdIntervalResponseItem,
} from './model/response/adInterval/getAdIntervalResponse'
import {UpdateResponse} from './model/response/updateResponse'

export const createAdIntervalService = () => {
  const baseService = createBaseStreamerApiService('/ad_interval/v2')

  const getAdInterval = async (req: GetAdIntervalRequest): Promise<GetAdIntervalResponse> => {
    const response = await baseService.post<GetAdIntervalResponse>(`/get`, req)
    return response.data
  }

  const acceptAd = async (req: AcceptAdRequest): Promise<UpdateResponse> => {
    const response = await baseService.post<UpdateResponse>(`/accept`, req)
    return response.data
  }

  const denyAd = async (req: DenyAdRequest): Promise<UpdateResponse> => {
    const response = await baseService.post<UpdateResponse>(`/deny`, req)
    return response.data
  }

  const snoozeAd = async (req: SnoozeAdRequest): Promise<GetAdIntervalResponseItem> => {
    const response = await baseService.post<GetAdIntervalResponseItem>(`/snooze`, req)
    return response.data
  }

  return {
    getAdInterval,
    acceptAd,
    denyAd,
    snoozeAd,
  }
}
