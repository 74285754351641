import styles from './LeaderboardNav.module.scss'
import classNames from 'classnames'
import { useContext } from 'react'

// CONTEXT
import LeaderboardContext from 'context/LeaderboardContext'
import { toAbsoluteUrl } from 'helpers'

const LeaderboardNav = () => {
  const {leaderboardData, selectedIndex, setSelectedIndex} = useContext(LeaderboardContext)
  return (
    <>
    {
      (leaderboardData) ? (
        <div className={styles.campaignLeaderboard}>
          <>
            {
              leaderboardData.map((item, index) => {
                  return (
                    <div 
                      onClick={() => setSelectedIndex(index)}
                      className={classNames(
                        styles.campaignLeaderboard_item, 
                        index == selectedIndex ? styles.active: undefined
                      )}
                    >
                      <img
                        src={item?.contest?.logo ?? toAbsoluteUrl('/assets/icons/logo-vector.png')}
                        alt='brand'
                      />
                      <div className={styles.campaignLeaderboard_itemDetails}>
                        <span>{item?.contest?.clientName ?? 'LIVAD Technologies'}</span>
                        <span>{item?.contest?.name}</span>
                      </div>
                    </div>
                  )
              })
            }
          </>
        </div>
        ):null
    }
    </>
  )
}

export default LeaderboardNav
