import LiveNotificationContext from 'context/LiveNotificationContext'
import styles from './AdComing.module.scss'
import {gsap} from 'gsap'
import {toAbsoluteUrl} from 'helpers'
import {useContext, useEffect, useRef, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {createAdIntervalService} from 'services/adIntervalService'
import classNames from 'classnames'
import {GetAdIntervalResponseItem} from 'services/model/response/adInterval/getAdIntervalResponse'

interface AdComingProps {
  adInterval: GetAdIntervalResponseItem | null
}

const AdComing = ({adInterval}: AdComingProps) => {
  const {t} = useTranslation()
  const {debounceUpdateAdInterval, adIntervalInitialized, setAdIntervalInitialized} =
    useContext(LiveNotificationContext)
  const [remainingTime, setRemainingTime] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const adIntervalService = createAdIntervalService()
  const [isPosting, setIsPosting] = useState(false)
  const [actionDisabled, setActionDisabled] = useState(true)
  const adIntervalRef = useRef<GetAdIntervalResponseItem | null>(null)

  const adComingSound = new Audio(toAbsoluteUrl('/assets/sounds/Ad_Coming.mp3'))
  const adPostedSound = new Audio(toAbsoluteUrl('/assets/sounds/Ad_Posting.mp3'))

  useEffect(() => {
    if (adInterval) {
      if (adInterval.id !== adIntervalRef.current?.id && adInterval.snoozeCount === 0) {
        setRemainingTime(null)
        setAdIntervalInitialized(false)
        setIsPosting(false)
        setIsLoading(false)
        setActionDisabled(true)
      } else if (adInterval.id !== adIntervalRef.current?.id && adInterval.snoozeCount > 0) {
        setRemainingTime(null)
        setIsPosting(false)
        setIsLoading(false)
        setActionDisabled(true)
      }
      adIntervalRef.current = adInterval
    }
  }, [adInterval])

  useEffect(() => {
    if (!isPosting) return
    const interval = setTimeout(() => {
      const lvd = gsap.timeline({
        defaults: {duration: 0.75, ease: 'power1.out'},
      })
      lvd.fromTo(
        '.' + styles.adComing,
        {
          opacity: 1,
          scale: 1,
          animation: 'unset',
        },
        {
          opacity: 0,
          scale: 0,
          animation: 'unset',
          onComplete: () => {
            setRemainingTime(null)
            setAdIntervalInitialized(false)
            setIsPosting(false)
            debounceUpdateAdInterval(null)
          },
        }
      )
    }, 1000 * 60 * 2)
    return () => clearTimeout(interval)
  }, [isPosting])

  useEffect(() => {
    if (!adIntervalInitialized) return
    const lvd = gsap.timeline({
      defaults: {duration: 0.75, ease: 'power1.out'},
    })
    lvd.fromTo(
      '.' + styles.adComing,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        onComplete: () => {
          lvd.to('.' + styles.adComing, {
            scale: 1.004,
            duration: 0.6,
            yoyo: true,
            repeat: -1,
            ease: 'power1.inOut',
          })
        },
      }
    )
    adComingSound.volume = 0.5
    adComingSound.play()
  }, [adIntervalInitialized])

  useEffect(() => {
    if (!adInterval) return
    if (adInterval.status === 'posted') {
      setIsPosting(false)
      setRemainingTime(null)
      setActionDisabled(true)
      adPostedSound.volume = 0.5
      adPostedSound.play()
    }
  }, [adInterval?.status])

  useEffect(() => {
    if (!adInterval || adInterval.status !== 'valid') return

    const twoDigitFormatter = new Intl.NumberFormat(undefined, {minimumIntegerDigits: 2})

    const interval = setInterval(() => {
      const now = new Date().getTime()
      const predicted = new Date(adInterval.predictedTimestamp).getTime()
      const distance = predicted - now
      if (distance <= 60000) {
        setActionDisabled(true)
      } else {
        if (actionDisabled) {
          setActionDisabled(false)
        }
      }
      if (distance <= 0) {
        setRemainingTime('00:00')
        clearInterval(interval)
        if (adInterval.status === 'valid') {
          setIsPosting(true)
        }
        if (adIntervalInitialized === false) {
          setAdIntervalInitialized(true)
        }
        return
      }
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      hours > 0
        ? setRemainingTime(
            `${hours}:${twoDigitFormatter.format(minutes)}:${twoDigitFormatter.format(seconds)}`
          )
        : setRemainingTime(
            `${twoDigitFormatter.format(minutes)}:${twoDigitFormatter.format(seconds)}`
          )
      if (adIntervalInitialized === false) {
        setAdIntervalInitialized(true)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [adInterval, actionDisabled, adIntervalInitialized, isPosting])

  const acceptAd = async () => {
    if (!adInterval || isLoading) return
    setIsLoading(true)
    try {
      await toast.promise(adIntervalService.acceptAd({id: adInterval.id}), {
        pending: 'Accepting...',
        success: 'Accepted',
      })
      setIsLoading(false)
      debounceUpdateAdInterval({
        ...adInterval,
        isAccepted: true,
      })
    } catch {
      toast.error('Failed to accept')
      setIsLoading(false)
    }
  }

  const denyAd = async () => {
    if (!adInterval || isLoading) return
    setIsLoading(true)
    try {
      await toast.promise(adIntervalService.denyAd({id: adInterval.id}), {
        pending: 'Declining...',
        success: 'Declined',
      })
      const lvd = gsap.timeline({
        defaults: {duration: 0.3, ease: 'power1.out'},
      })
      lvd.fromTo(
        '.' + styles.adComing,
        {
          scale: 1,
          opacity: 1,
        },
        {
          scale: 0,
          opacity: 0,
          onComplete: () => {
            debounceUpdateAdInterval(null)
            setIsLoading(false)
          },
        }
      )
    } catch {
      toast.error('Failed to decline')
      setIsLoading(false)
    }
  }

  const snoozeAd = async () => {
    if (!adInterval || isLoading) return
    setIsLoading(true)
    try {
      const newAdInterval = await toast.promise(adIntervalService.snoozeAd({id: adInterval.id}), {
        pending: 'Snoozing...',
        success: 'Snoozed',
      })
      debounceUpdateAdInterval(newAdInterval)
      setIsLoading(false)
    } catch (e: any) {
      console.log(e)
      if (e.response?.data?.message) {
        toast.error(e.response.data?.message)
      } else {
        toast.error('Failed to snooze')
      }
      setIsLoading(false)
    }
  }

  if (!adInterval || (adInterval.status === 'valid' && !adIntervalInitialized)) return null

  return (
    <div
      className={classNames(styles.adComing, {
        [styles.adPosting]: isPosting,
        [styles.adPosted]: adInterval.status === 'posted',
      })}
    >
      <div className={styles.adComing_campaign}>
        <span className={styles.campaignTitle}>
          <img
            src={
              adInterval.client.logo ||
              toAbsoluteUrl('/assets/icons/Dashboard/Brands/defaultBrandAvatar.png')
            }
            alt='Brand'
            onError={(e) => {
              e.currentTarget.src = toAbsoluteUrl(
                '/assets/icons/Dashboard/Brands/defaultBrandAvatar.png'
              )
            }}
          />
          {adInterval.campaign.name}
        </span>
        <div className={styles.adComing_notification}>
          {!isPosting && adInterval.status === 'valid' && (
            <span className={styles.remaining}>
              {remainingTime && (
                <span className={styles.remainingTime}>
                  <svg
                    stroke='currentColor'
                    fill='currentColor'
                    strokeWidth={0}
                    viewBox='0 0 24 24'
                    height='1em'
                    width='1em'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61 1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42A8.962 8.962 0 0 0 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9a8.994 8.994 0 0 0 7.03-14.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z' />
                  </svg>
                  {remainingTime}
                </span>
              )}
              <Trans
                i18nKey={'LiveConsolePage.upcomingAdDescription'}
                values={{
                  clientname: adInterval.client.name,
                }}
              >
                <b></b>
              </Trans>
            </span>
          )}
          {isPosting && (
            <span className={styles.posting}>
              <span className={styles.remainingTime}>
                <img src={toAbsoluteUrl('/assets/icons/loading.gif')} alt='Loading' />
                {t('LiveConsolePage.posting')}
              </span>
              <Trans
                i18nKey={'LiveConsolePage.postingAdDescription'}
                values={{
                  clientname: adInterval.client.name,
                }}
              >
                <b></b>
              </Trans>
            </span>
          )}
          {adInterval.status === 'posted' && (
            <span className={styles.posted}>
              <span className={styles.remainingTime}>
                <svg
                  stroke='currentColor'
                  fill='none'
                  strokeWidth={0}
                  viewBox='0 0 24 24'
                  height='1em'
                  width='1em'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z'
                    fill='currentColor'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z'
                    fill='currentColor'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
                    fill='currentColor'
                  />
                </svg>
                {t('LiveConsolePage.posted')}
              </span>
              <Trans
                i18nKey={'LiveConsolePage.postedAdDescription'}
                values={{
                  clientname: adInterval.client.name,
                }}
              >
                <b></b>
              </Trans>
            </span>
          )}
        </div>
      </div>
      {adInterval.isAccepted == false && !actionDisabled && (
        <div className={styles.adComing_action}>
          <button disabled={isLoading} className={styles.adComing_decline_btn} onClick={denyAd}>
            <img src={toAbsoluteUrl('/assets/icons/LiveConsole/declineIcon.png')} alt='icon' />
            {t('Buttons.decline')}
          </button>
          {adInterval.snoozeCount < 3 && (
            <button disabled={isLoading} className={styles.adComing_snooze_btn} onClick={snoozeAd}>
              <img src={toAbsoluteUrl('/assets/icons/LiveConsole/snoozeIcon.png')} alt='icon' />
              {t('Buttons.snooze')}{' '}
              <span className={styles.snoozeCount}>{`${adInterval.snoozeCount}/3`}</span>
            </button>
          )}
          <button disabled={isLoading} className={styles.adComing_accept_btn} onClick={acceptAd}>
            <img src={toAbsoluteUrl('/assets/icons/LiveConsole/acceptIcon.png')} alt='icon' />
            {t('Buttons.accept')}
          </button>
        </div>
      )}
      {adInterval.campaign.brief !== null && adInterval.campaign.brief.trim() !== '' && (
        <div className={styles.streamerScript}>
          <div className={styles.scriptTitle}>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth={0}
              viewBox='0 0 24 24'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M9 2C13.0675 2 16.426 5.03562 16.9337 8.96494L19.1842 12.5037C19.3324 12.7367 19.3025 13.0847 18.9593 13.2317L17 14.071V17C17 18.1046 16.1046 19 15 19H13.001L13 22H4L4.00025 18.3061C4.00033 17.1252 3.56351 16.0087 2.7555 15.0011 1.65707 13.6313 1 11.8924 1 10 1 5.58172 4.58172 2 9 2ZM21.1535 18.1024 19.4893 16.9929C20.4436 15.5642 21 13.8471 21 12.0001 21 10.153 20.4436 8.4359 19.4893 7.00722L21.1535 5.89771C22.32 7.64386 23 9.74254 23 12.0001 23 14.2576 22.32 16.3562 21.1535 18.1024Z' />
            </svg>
            {t('CampaignsPopup.campaignBrief')}
          </div>
          <p className={styles.streamerScriptText}>
            {adInterval.campaign.brief || t('CampaignsPopup.noBrief')}
          </p>
          {adInterval.campaign.availableForBonus && (
            <div className={styles.scriptDescription}>
              <img
                src={toAbsoluteUrl('/assets/icons/ClipInsights/earnBonusIcon.png')}
                alt='Bonus'
              />
              {t('LiveConsolePage.readAndGetBonusRpm')}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AdComing
