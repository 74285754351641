import {createContext, useState} from 'react'
// Context
import { createPayoutService } from 'services/payoutService'
import { GetPayoutsResponse } from 'services/model/response/payouts/getPayoutsResponse'
import { GetBalanceThresholdResponse } from 'services/model/response/payouts/getBalanceThresholdResponse'

interface PaymentContextInterface {
  getPaymentData: () => Promise<void>
  paymentData: GetPayoutsResponse | null
  getPaymentTreshold: () => void
  paymentTreshold: GetBalanceThresholdResponse | null
  getPayabilityData: () => void
  payabilityData: any
}

export const PaymentContext = createContext<PaymentContextInterface>({
  getPaymentData: async () => {},
  paymentData: null,
  getPaymentTreshold: () => {},
  paymentTreshold: null,
  getPayabilityData: () => {},
  payabilityData: null,
})

interface PaymentContextProviderProps {
  children: React.ReactNode
}

export const PaymentContextProvider = ({children}: PaymentContextProviderProps) => {
  const payoutService = createPayoutService()
  const [paymentData, setPaymentData] = useState<GetPayoutsResponse | null>(null)
  const [paymentTreshold, setPaymentTreshold] = useState<GetBalanceThresholdResponse | null>(null)
  const [payabilityData, setPayabilityData] = useState<any>(null)
  const getPaymentData = async () => {
    try {
      const response = await payoutService.getPayouts()
      setPaymentData(response)
    } catch (error) {
      console.log(error)
    }
  }

  const getPaymentTreshold = async () => {
    try {
      const response = await payoutService.getBalanceThreshold()
      setPaymentTreshold(response)
    } catch (error) {
      console.log(error)
    }
  }

  const getPayabilityData = async () => {
    try {
      const response = await payoutService.getPayabilityData()
      const data = JSON.parse(JSON.stringify(response))
      const dataArray = Object.values(data.data)
      setPayabilityData(dataArray)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <PaymentContext.Provider
      value={{
        getPaymentData,
        paymentData,
        getPaymentTreshold,
        paymentTreshold,
        getPayabilityData,
        payabilityData,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

export default PaymentContext
