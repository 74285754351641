export const formatCurrency = (value: number) => {
  if (!value && value !== 0) return
  let parts = value.toString().split('.')
  let integerPart = parts[0]
  let decimalPart = parts.length > 1 ? parts[1] : ''

  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  let result = formattedIntegerPart
  if (decimalPart) {
    result += ',' + decimalPart
  }

  return result
}

export const formatNumber = (value: number) => {
  if (!value && value !== 0) return
  let parts = value.toString().split('.')
  let integerPart = parts[0]
  let decimalPart = parts.length > 1 ? parts[1] : ''

  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  let result = formattedIntegerPart
  if (decimalPart) {
    result += ',' + decimalPart
  }

  return result
}

export const formatTime = (seconds: number) => {
  if (!seconds && seconds !== 0) return
  const hrs = Math.floor(seconds / 3600)
    .toString()
    .padStart(1, '0')
  const mins = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const secs = (seconds % 60).toString().padStart(2, '0')
  return `${hrs}:${mins}:${secs}`
}

export const formatLink = (link: string) => {
  if (!link) return
  if (link.startsWith('https://')) {
    return link
  }
  if (link.startsWith('http://')) {
    return link
  }
  return `https://${link}`
}
