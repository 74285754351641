const countdownRenderer = (data: { hours: number, minutes: number, seconds: number, completed: boolean }) => {
  
  if (data.completed) {
    // Render a completed state
    return '0 minute'
  } else {
    // Render a countdown
    return (
      <span>
        {/* {data.minutes} */}
        {data.minutes > 0 ? ' minutes' : ''} {data.seconds}{' '}
        {data.seconds > 0 ? ' seconds' : ' second'}
      </span>
    )
  }
}
export default countdownRenderer