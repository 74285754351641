import Loading from 'components/Shared/Loading/Loading'
import styles from '../LiveNotifications.module.scss'
import GetDonationsForStreamerResponse from 'services/model/response/donation/getDonationsForStreamerResponse'
import SingleDonation from './SingleDonation'
import LiveFeedObjectDto from 'services/model/dto/liveFeedObjectDto'
import SingleGameSession from './SingleGameSession'
import SingleAdSession from './SingleAdSession'
import {EnumLiveFeedObjectType} from 'services/model/enum/enumLiveFeedObjectType'
import {useTranslation} from 'react-i18next'

interface AllFeedProps {
  liveFeed: LiveFeedObjectDto[] | null
  pageLoading: boolean
}

const AllFeed = ({liveFeed, pageLoading}: AllFeedProps) => {
  const {t} = useTranslation()
  return (
    <>
      {pageLoading || !liveFeed ? (
        <Loading />
      ) : (
        <>
          {liveFeed && liveFeed.length > 0 ? (
            <>
              {liveFeed.map((liveFeedObject, index) => {
                return liveFeedObject.type === EnumLiveFeedObjectType.Donation ? (
                  <SingleDonation key={index} donation={liveFeedObject.donation} />
                ) : liveFeedObject.type === EnumLiveFeedObjectType.GameSession ? (
                  <SingleGameSession key={index} gameSession={liveFeedObject.gameSession} />
                ) : (
                  <SingleAdSession key={index} ad={liveFeedObject.ad} />
                )
              })}
            </>
          ) : (
            <div className={styles.noData}>
              {/* No donations yet, share your link to get donations from your viewers 💸 */}
              {t('LiveConsolePage.noData')}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default AllFeed
