import styles from '../LiveNotifications.module.scss'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import formatNotificationTime from 'helpers/formatNotificationTime'
import {LiveConsoleAdDto} from 'services/model/dto/liveConsoleAdDto'
import {GetDashboardAdsResponseAd} from 'services/model/response/dashboard/getDashboardAdsResponse'
import {useTranslation, Trans} from 'react-i18next'

interface SingleAdSessionProps {
  key: number
  ad: LiveConsoleAdDto
}
const SingleAdSession = ({ad}: SingleAdSessionProps) => {
  const {t} = useTranslation()
  return (
    <div className={classNames(styles.liveNotifications_item, styles.typeAd)}>
      <div className={styles.adInfo}>
        <span className={styles.brandName}>
          <img src={ad.clientLogo} alt='brand' className={styles.brandIcon} />
          <Trans i18nKey='LiveConsolePage.brandAdSession' values={{brand: ad.clientName}}>
            <b></b>
          </Trans>
        </span>
        <div className={styles.rightSection}>
          {/* <div className={classNames(styles.donationStatus, styles.pending)}>PENDING</div> */}
          <div className={styles.date}>{formatNotificationTime(ad.timestamp)}</div>
          <div className={classNames(styles.adStatus, styles.ended)}>
            {t('LiveConsolePage.sent')}
          </div>
        </div>
      </div>
      <div className={styles.adCampaign}>
        <b>{t('LiveConsolePage.campaign')}</b>
        {ad.campaignName}
      </div>
      <div className={styles.adResult}>
        <div className={styles.adResult_item}>
          <img src={toAbsoluteUrl('/assets/icons/LiveConsole/viewIcon.png')} alt='views' />
          <b>{t('LiveConsolePage.views')}</b>
          {ad.views}
        </div>
        /
        <div className={styles.adResult_item}>
          <img src={toAbsoluteUrl('/assets/icons/LiveConsole/clickIcon.png')} alt='clicks' />
          <b>{t('LiveConsolePage.clicks')}</b>
          {ad.clicks}
        </div>
        /
        <div className={styles.adResult_item}>
          <img src={toAbsoluteUrl('/assets/icons/LiveConsole/ctrIcon.png')} alt='ctr' />
          <b>{t('LiveConsolePage.ctr')}</b>
          {ad.CTR}%
        </div>
        /
        <div className={styles.adResult_item}>
          <img src={toAbsoluteUrl('/assets/icons/LiveConsole/revenueIcon.png')} alt='ctr' />
          <b>{t('LiveConsolePage.revenue')}</b>
          {ad.revenue.toLocaleString()} {ad.currencySign}
        </div>
      </div>
    </div>
  )
}

export default SingleAdSession
