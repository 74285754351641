import {useContext, useState, useEffect} from 'react'
import {toAbsoluteUrl, compareObjects} from 'helpers'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
// STYLES
import styles from './StreamingPreferences.module.scss'
// CONTEXT
import SettingsContext from 'context/SettingsContext'
import {useLocation, useNavigate} from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'
import EnumBroadcastingSoftware from 'services/model/enum/enumBroadcastingSoftware'

const StreamingPreferences = () => {
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const {
    streamingPreferences,
    streamingLanguages,
    checkStreamingPreferencesSave,
    checkStreamingPreferencesDiff,
    updateStreamingPreferences,
    newStreamingPreferences,
    setNewStreamingPreferences,
  } = useContext(SettingsContext)
  const navigate = useNavigate()
  const location = useLocation()
  const routePath = location.pathname.split('/')[1]

  useEffect(() => {
    if (streamingPreferences) {
      setNewStreamingPreferences({
        broadcasting_language_id: streamingPreferences.broadcasting_language_id,
        broadcasting_software: streamingPreferences.broadcasting_software,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamingPreferences])

  if (!newStreamingPreferences) return null

  return (
    <div className={styles.streamingPreferences}>
      <div className={styles.sectionTitle}>{t('SettingsPage.streamingPreferences')}</div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.broadcastingSoftware')}</div>
        <div className={styles.inLine}>
          <span>
            <img
              src={
                newStreamingPreferences.broadcasting_software === EnumBroadcastingSoftware.OBS
                  ? toAbsoluteUrl('/assets/icons/Settings/obs-icon.png')
                  : newStreamingPreferences.broadcasting_software ===
                    EnumBroadcastingSoftware.STREAMLABS
                  ? toAbsoluteUrl('/assets/icons/Settings/streamlabs-icon.png')
                  : newStreamingPreferences.broadcasting_software ===
                    EnumBroadcastingSoftware.TWITCH_STUDIO
                  ? toAbsoluteUrl('/assets/icons/Platforms/twitch-icon.png')
                  : toAbsoluteUrl('/assets/icons/Settings/other-icon.png')
              }
              alt=''
            />
          </span>
          <select
            value={newStreamingPreferences.broadcasting_software ?? ''}
            onChange={(e) => {
              setNewStreamingPreferences({
                ...newStreamingPreferences,
                broadcasting_software: e.target.value,
              })
            }}
          >
            <option value='' hidden>
              {t('SettingsPage.selectBroadcastingSoftware')}
            </option>
            {Object.entries(EnumBroadcastingSoftware).map(([key, value], index) => (
              <option key={index} value={value}>
                {value
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.broadcastingLanguage')}</div>
        <div className={styles.inLine}>
          <span>
            <img
              alt=''
              src={
                newStreamingPreferences.broadcasting_language_id
                  ? streamingLanguages.find(
                      (language) =>
                        language['id'] === newStreamingPreferences.broadcasting_language_id
                    )?.['flag_url']
                  : toAbsoluteUrl('/assets/icons/Settings/other-icon.png')
              }
            />
          </span>
          <select
            onChange={(e) => {
              setNewStreamingPreferences({
                ...newStreamingPreferences,
                broadcasting_language_id: e.target.value,
              })
            }}
            value={newStreamingPreferences.broadcasting_language_id ?? ''}
          >
            <option value='' hidden>
              {t('SettingsPage.selectBroadcastingLanguage')}
            </option>
            {streamingLanguages.map((language, index) => (
              <option key={index} value={language['id']}>
                {language['name']}
              </option>
            ))}
          </select>
        </div>
      </div>
      {routePath === 'signup' ? (
        <div className={classNames(styles.line, styles.lineSave)}>
          <button
            onClick={async () => {
              navigate(`/${routePath}/${EnumSettingsTab.SocialAccounts}`)
            }}
          >
            {t('Buttons.back')}
          </button>
          <button
            disabled={!checkStreamingPreferencesSave(newStreamingPreferences) || isLoading}
            onClick={async () => {
              setIsLoading(true)
              const success = await updateStreamingPreferences(newStreamingPreferences)
              setIsLoading(false)
              if (success) {
                navigate(`/${routePath}/${EnumSettingsTab.SponsorshipPreferences}`)
              }
            }}
            className={classNames({
              [styles.disabled]: !checkStreamingPreferencesSave(newStreamingPreferences),
            })}
          >
            {t('Buttons.next')}
          </button>
        </div>
      ) : (
        <>
          {checkStreamingPreferencesDiff(newStreamingPreferences) &&
          checkStreamingPreferencesSave(newStreamingPreferences) ? (
            <div className={classNames(styles.line, styles.lineSave)}>
              <button
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true)
                  await updateStreamingPreferences(newStreamingPreferences)
                  setIsLoading(false)
                }}
              >
                {t('Buttons.save')}
              </button>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default StreamingPreferences
