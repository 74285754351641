import styles from '../../PayoutAccount.module.scss'
import {useContext} from 'react'
import PayoutAccountContext from 'context/PayoutAccountContext'
import CitiesSelect from '../CitiesSelect/CitiesSelect'
import DistrictSelect from '../DistrictSelect/DistrictSelect'
import {useTranslation} from 'react-i18next'

const NonTaxExemptInputs = () => {
  const {t} = useTranslation()
  const {payoutAccountData, setPayoutAccountData} = useContext(PayoutAccountContext)
  if (!payoutAccountData) return null
  return (
    <>
      {/* Full name */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.fullName')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.fullName')}
          value={payoutAccountData.individual_full_name}
          onChange={(e) =>
            setPayoutAccountData({
              ...payoutAccountData,
              individual_full_name: e.target.value.replace(/[^\p{L}\s]/gu, ''),
            })
          }
        />
      </div>

      {/* City */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.city')}</div>
        <CitiesSelect />
      </div>

      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.district')}</div>
        <DistrictSelect />
      </div>

      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.nationalId')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.nationalId')}
          defaultValue={payoutAccountData?.individual_id_number}
          onChange={(e) => {
            setPayoutAccountData({
              ...payoutAccountData,
              individual_id_number: e.target.value,
            })
          }}
        />
      </div>

      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.payoutMethod')}</div>
        <select value={payoutAccountData.payment_method} disabled>
          <option value='wire'>Wire</option>
        </select>
      </div>

      <div className={styles.line}>
        <div className={styles.label}>IBAN</div>
        <input
          type='text'
          placeholder='IBAN'
          defaultValue={payoutAccountData.IBAN}
          onChange={(e) => {
            setPayoutAccountData({
              ...payoutAccountData,
              IBAN: e.target.value,
            })
          }}
        />
      </div>
    </>
  )
}

export default NonTaxExemptInputs
