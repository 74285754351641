import {GetClipInsightsResponseItem} from 'services/model/response/clipInsights/getClipInsightsResponse'
import styles from './AdTimeline.module.scss'

//COMPONENTS
import AdTimelineItem from './Components/AdTimelineItem/AdTimelineItem'
import {t} from 'i18next'
interface AdTimeLineProps {
  data: GetClipInsightsResponseItem[] | null
  setData: React.Dispatch<React.SetStateAction<GetClipInsightsResponseItem[] | null>>
}
const AdTimeline = ({data, setData}: AdTimeLineProps) => {
  return (
    <div className={styles.adTimeline}>
      {data && data.length > 0 ? (
        data.map((adTimelineItem, index) => (
          <AdTimelineItem
            key={index}
            item={adTimelineItem}
            setItem={(newItem) => {
              setData((prevData) => {
                if (prevData !== null) prevData[index] = newItem
                return prevData !== null ? [...prevData] : null
              })
            }}
          />
        ))
      ) : (
        <div className={styles.noData}>
          <svg
            stroke='currentColor'
            fill='currentColor'
            strokeWidth={0}
            viewBox='0 0 24 24'
            height='1em'
            width='1em'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path fill='none' d='M0 0h24v24H0V0z' />
            <path d='m9.56 8-2-2-4.15-4.14L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.55-.18L19.73 21l1.41-1.41-8.86-8.86L9.56 8zM5 16V8h1.73l8 8H5zm10-8v2.61l6 6V6.5l-4 4V7c0-.55-.45-1-1-1h-5.61l2 2H15z' />
          </svg>
          {t('ClipInsightsPage.clipNoData')}
        </div>
      )}
    </div>
  )
}

export default AdTimeline
