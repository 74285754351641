import {createContext, useState, useContext, useEffect} from 'react'
import axios from 'axios'
import {createCampaignService} from 'services/campaignService'

export type Campaign = {
  CPM: number
  CPM_with_bonus: number
  CPM_bonus_percentage: number
  asset: string
  campaign_brief: string
  campaign_id: number
  campaign_name: string
  campaign_status: string
  campaign_type: string
  client_name: string
  currency: string
  end_date: string
  pretext: string
  redirect_link: string
  start_date: string
  streamer_status: string
  url_to_logo: string
}

export interface CampaignContextInterface {
  campaignList: Campaign[]
  featuredCampaignList: Campaign[]
  getFeaturedCampaigns: () => void
  activeFilter: string
  setActiveFilter: (filter: string) => void
  getAllCampaigns: () => Promise<void>
}

export const CampaignContext = createContext<CampaignContextInterface>({
  campaignList: [],
  featuredCampaignList: [],
  getFeaturedCampaigns: () => {},
  activeFilter: 'available',
  setActiveFilter: (filter: string) => {},
  getAllCampaigns: async () => {},
})

type CampaignContextType = {
  children: React.ReactNode
}

export const CampaignContextProvider = ({children}: CampaignContextType) => {
  const campaignService = createCampaignService()
  const [activeFilter, setActiveFilter] = useState('available')
  const [campaignList, setCampaignList] = useState<Campaign[]>([])
  const [featuredCampaignList, setFeaturedCampaignList] = useState([])

  //TODO Use url object and refactor
  const getAllCampaigns = async () => {
    let QUERY
    if (activeFilter === 'available') {
      QUERY = 'campaign_status=active,pending,paused&streamer_status=requested'
    } else if (activeFilter === 'active') {
      QUERY = 'campaign_status=active,pending,paused&streamer_status=accepted'
    } else if (activeFilter === 'past') {
      QUERY = 'campaign_status=past&streamer_status=requested,accepted,denied'
    } else if (activeFilter === 'denied') {
      QUERY = 'campaign_status=active,pending,paused&streamer_status=denied'
    }
    try {
      const response = await campaignService.getCampaigns(QUERY)
      setCampaignList(response)
    } catch (error) {
      console.log(error)
    }
  }

  const getFeaturedCampaigns = async () => {
    try {
      const response = await campaignService.getFeaturedCampaigns()
      setFeaturedCampaignList(response?.data?.data ?? [])
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CampaignContext.Provider
      value={{
        activeFilter,
        setActiveFilter,
        campaignList,
        featuredCampaignList,
        getAllCampaigns,
        getFeaturedCampaigns,
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

export default CampaignContext
