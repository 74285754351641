import { createBaseStreamerApiService } from "./baseStreamerApiService"





export const createAdSettingsService = () => {
    const baseService = createBaseStreamerApiService('/ad_settings')


    const updateAdSettings = async (adSettings: any) => {
        await baseService.put('', adSettings);
    }

    const getAdSettings = async (): Promise<any> => {
        const response = await baseService.get<any>('');
        return response.data.data;
    }

    return {
        updateAdSettings,
        getAdSettings
    }

}