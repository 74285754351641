export const navList = [
  {
    title: 'payoutAccount',
    icon: 'payout',
    path: '/settings/payout-account',
    signupRequired: true,
  },
  {
    title: 'helpCenter',
    icon: 'help',
    path: '/help-center',
    signupRequired: true,
  },
  {
    title: 'recruitFriend',
    icon: 'friends',
    path: '/referrals',
    signupRequired: true,
  },
  {
    title: 'termsPrivacyPolicy',
    icon: 'termsAndPrivacy',
    path: '/terms-and-privacy-policy',
    signupRequired: true,
  },
  {
    title: 'settings',
    icon: 'settings',
    path: '/settings',
    signupRequired: true,
  },
  {
    title: 'logout',
    icon: 'logout',
    path: null,
    signupRequired: false,
  },
]
