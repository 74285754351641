import AffiliateCampaignResponseItem from 'services/model/response/campaigns/affiliateCampaignResponseItem'
import styles from './Affiliate.module.scss'
import AffiliateListItem from './Components/AffiliateListItem/AffiliateListItem'
import {useEffect, useState} from 'react'
import {createCampaignService} from 'services/campaignService'
import Loading from 'components/Shared/Loading/Loading'
import {toAbsoluteUrl} from 'helpers'
import {t} from 'i18next'
import {toast} from 'react-toastify'
import {useAffiliateCampaignsContext} from 'context/AffiliateCampaignsContext'

const dummyData = [
  {
    id: 1787,
    status: 'active',
    brand: 'HelloFresh',
    brandLogo: 'https://www.hellofresh.com/favicons/hellofresh.ico',
    clicks: 1249,
    conversions: 16,
    revenue: '128.52',
    link: 'https://www.hellofresh.ie/pages/resolution?utm_source=affiliate&utm_medium=affiliate&utm_campaign=affiliate',
  },
  {
    id: 2,
    status: 'available',
    brand: 'Factor US',
    brandLogo: 'https://www.factor75.com/assets/factor/favicon/favicon-32x32.png',
    clicks: null,
    conversions: null,
    revenue: null,
    link: 'https://factor75.com/?utm_source=affiliate&utm_medium=affiliate&utm_campaign=affiliate',
  },
  {
    id: 3,
    status: 'pending',
    brand: 'Yemeksepeti',
    brandLogo:
      'https://livad.blob.core.windows.net/livad-ads/clientLogos/client-18/1685447459189.jpg',
    clicks: null,
    conversions: null,
    revenue: null,
    link: 'https://yemeksepeti.com/?utm_source=affiliate&utm_medium=affiliate&utm_campaign=affiliate',
  },
  {
    id: 4,
    status: 'denied',
    brand: 'SteelSeries',
    brandLogo:
      'https://media.steelseriescdn.com/filer_public/c2/ec/c2ec1dd9-fb85-41f0-8517-7dd79aca81a3/ss_logo_icon_001.png',
    clicks: null,
    conversions: null,
    revenue: null,
    link: 'https://steelseries.com/?utm_source=affiliate&utm_medium=affiliate&utm_campaign=affiliate',
  },
  {
    id: 5,
    status: 'past',
    brand: 'Ford',
    brandLogo: 'https://images.themagger.net/wp-content/uploads/2020/01/Ford-Logo-yeni-250x250.jpg',
    clicks: null,
    conversions: null,
    revenue: null,
    link: 'https://ford.com/?utm_source=affiliate&utm_medium=affiliate&utm_campaign=affiliate',
  },
]

const Affiliate = () => {
  const {campaigns, applyToCampaign} = useAffiliateCampaignsContext()

  return (
    <div className={styles.affiliate}>
      <div className={styles.sectionHeader}>
        <span className={styles.title}>{t('CampaignsPage.myAffiliateLinks')}</span>
      </div>
      <div className={styles.sectionAffiliateList}>
        {!campaigns ? (
          <Loading />
        ) : campaigns.length === 0 ? (
          <div className={styles.no_campaigns}>
            <img
              draggable='false'
              src={toAbsoluteUrl('/assets/icons/Campaigns/no-campaigns.png')}
              alt='no data'
            />
            <h1>{t('CampaignsPage.noFilterAffiliate')}</h1>
          </div>
        ) : (
          <>
            {campaigns.map((data, index) => {
              return (
                <AffiliateListItem key={index} item={data} apply={() => applyToCampaign(data.id)} />
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default Affiliate
