import {toAbsoluteUrl} from 'helpers'

//STYLES
import styles from './BonusEarning.module.scss'
import {t} from 'i18next'

const BonusEarning = () => {
  return (
    <div className={styles.bonusEarning}>
      <div className={styles.bonusEarningInfo}>
        <div className={styles.sectionTitle}>
          <img
            src={toAbsoluteUrl('/assets/icons/ClipInsights/earnBonusIcon.png')}
            alt='Bonus Earning Icon'
            draggable={false}
            onContextMenu={(e) => {
              e.preventDefault()
            }}
          />
          {t('ClipInsightsPage.howToBonus')}
        </div>
        <div className={styles.articleList}>
          <div className={styles.listItem}>
            <div className={styles.itemNumber}>1)</div>
            <div className={styles.itemText}>{t('ClipInsightsPage.readScript')}</div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.itemNumber}>2)</div>
            <div className={styles.itemText}>{t('ClipInsightsPage.avoidRiskyLang')}</div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.itemNumber}>3)</div>
            <div className={styles.itemText}>{t('ClipInsightsPage.overlayWorking')}</div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.itemNumber}>4)</div>
            <div className={styles.itemText}>{t('ClipInsightsPage.earnBonus')} 💸</div>
          </div>
        </div>
      </div>
      <div className={styles.bonusHeaderImage}>
        <img
          src={toAbsoluteUrl('/assets/icons/ClipInsights/bonusHeaderImage.png')}
          alt='Header Image'
          draggable={false}
          onContextMenu={(e) => {
            e.preventDefault()
          }}
        />
      </div>
    </div>
  )
}

export default BonusEarning
