import styles from '../../Select/Select.module.scss'
import {useState, useContext, useEffect} from 'react'
import {toAbsoluteUrl} from 'helpers'
import ClickAwayListener from 'react-click-away-listener'

// Context
import SettingsContext from 'context/SettingsContext'
import Loading from 'components/Shared/Loading/Loading'

const CitySelect = ({newProfileDetails, setNewProfileDetails}: any) => {
  const {
    cities,
    filteredCities,
    handleSearchCity,
    cityIsLoading,
    setCityIsLoading,
    profileDetails,
  } = useContext(SettingsContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCity, setSelectedCity] = useState<any>(null)
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    if (profileDetails?.city_id) {
      const selectedCity = cities.find((city) => city.value === profileDetails?.city_id)
      if (selectedCity) {
        setSelectedCity(selectedCity)
        setInitialLoading(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Reset selected city when country changes
  useEffect(() => {
    if (initialLoading) return
    setSelectedCity(null)
    setSearchTerm('')
    setNewProfileDetails({...newProfileDetails, city_id: null})
  }, [newProfileDetails?.country_id])

  const handleSelectCity = (city: any) => {
    setSelectedCity(city)
    setNewProfileDetails({...newProfileDetails, city_id: city.value})
    setSearchTerm('')
    setIsOpen(false)
  }

  useEffect(() => {
    setCityIsLoading(true)
    const debounceTimer = setTimeout(() => {
      handleSearchCity(newProfileDetails?.country_id, searchTerm)
    }, 1000)
    return () => clearTimeout(debounceTimer)
  }, [searchTerm])

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false)
        setSearchTerm('')
      }}
    >
      <div className={styles.selectBox}>
        <div className={styles.selectBox_selected} onClick={() => setIsOpen(!isOpen)}>
          {selectedCity ? selectedCity.label : 'Select City'}
          <img
            className={styles.selectBox_arrow}
            src={toAbsoluteUrl('/assets/icons/Settings/arrowIcon.png')}
            alt='arrow'
          />
        </div>
        {isOpen && (
          <div className={styles.selectItem_list}>
            <div className={styles.listItem_search}>
              <input
                type='text'
                placeholder='Search'
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </div>
            <div className={styles.listResults}>
              {!cityIsLoading &&
                filteredCities?.map((city: any, idx: any) => (
                  <span
                    className={styles.listItem}
                    key={idx}
                    onClick={() => handleSelectCity(city)}
                  >
                    {city.label}
                  </span>
                ))}
              {cityIsLoading && <Loading />}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default CitySelect
