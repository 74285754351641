import {useContext, useState} from 'react'
import {toAbsoluteUrl} from 'helpers'
import ClickAwayListener from 'react-click-away-listener'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
import EnumRole from 'services/model/enum/enumRole'
import EnumPlatform from 'services/model/enum/EnumPlatform'
// COMPONENTS
import DropdownMenu from './components/DropdownMenu/DropdownMenu'
// STYLE
import styles from '../../Header.module.scss'

type ProfileMenuProps = {
  isDropdownOpen: boolean
  setIsDropdownOpen: (value: boolean) => void
}

const ProfileMenu = ({isDropdownOpen, setIsDropdownOpen}: ProfileMenuProps) => {
  const {user, kickUser} = useContext(AppLevelContext)

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <div>
        <div className={styles.profileMenu} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <img
            className={styles.profilePicture}
            src={user?.link_to_logo || toAbsoluteUrl('/assets/icons/defaultAvatar.png')}
            alt='profile'
            onError={(e) => {
              e.currentTarget.src = toAbsoluteUrl('/assets/icons/defaultAvatar.png')
            }}
          />
          {!kickUser && (
            <div className={styles.profileInfo}>
              {user?.first_name && user?.last_name ? (
                <span className={styles.name}>
                  {!user?.self && (
                    <img
                      src={toAbsoluteUrl(`/assets/icons/Platforms/${user.platform}-icon.png`)}
                      alt={user.platform}
                      className={styles.platformIcon}
                    />
                  )}
                  {!user?.self ? user?.title : user?.first_name + ' ' + user?.last_name}
                </span>
              ) : (
                <span className={styles.name}>{user?.title}</span>
              )}
              <span className={styles.username}>
                {!user?.self ? (
                  <div className={styles.otherView}>
                    <img
                      src={
                        user?.role === EnumRole.ADMIN
                          ? toAbsoluteUrl('/assets/icons/Badges/badge_admin.png')
                          : user?.role === EnumRole.MOD
                          ? toAbsoluteUrl('/assets/icons/Badges/badge_moderator.png')
                          : user?.role === EnumRole.MANAGER
                          ? toAbsoluteUrl('/assets/icons/Badges/badge_manager.png')
                          : ''
                      }
                      alt='badge'
                      className={styles.badge}
                    />
                    <span style={{textTransform: 'capitalize'}}>{user?.role}</span> View
                  </div>
                ) : (
                  <>
                    <img
                      src={toAbsoluteUrl(`/assets/icons/Platforms/${user.platform}-icon.png`)}
                      alt={user.platform}
                      className={styles.platformIcon}
                    />
                    {user?.title}
                  </>
                )}
              </span>
            </div>
          )}
          {kickUser && (
            <div className={styles.profileInfo}>
              <span className={styles.username}>
                {user && !user?.self ? (
                  <div className={styles.otherView}>
                    <img
                      src={
                        user?.role === EnumRole.ADMIN
                          ? toAbsoluteUrl('/assets/icons/Badges/badge_admin.png')
                          : user?.role === EnumRole.MOD
                          ? toAbsoluteUrl('/assets/icons/Badges/badge_moderator.png')
                          : user?.role === EnumRole.MANAGER
                          ? toAbsoluteUrl('/assets/icons/Badges/badge_manager.png')
                          : ''
                      }
                      alt='badge'
                      className={styles.badge}
                    />
                    <span style={{textTransform: 'capitalize'}}>{user?.role}</span> View
                  </div>
                ) : (
                  kickUser.email
                )}
              </span>
            </div>
          )}

          <img src={toAbsoluteUrl('/assets/icons/Header/downArrow.png')} alt='arrow' />
        </div>
        {isDropdownOpen && (
          <DropdownMenu isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />
        )}
      </div>
    </ClickAwayListener>
  )
}

export default ProfileMenu
