import {useState} from 'react'

//STYLES
import styles from './Filter.module.scss'

//COMPONENTS
import FilterItem from './Components/FilterItem/FilterItem'
import EnumClipInsightsFilter from 'services/model/enum/enumClipInsightsFilter'

interface FilterProps {
  activeFilter: EnumClipInsightsFilter
  setActiveFilter: React.Dispatch<React.SetStateAction<EnumClipInsightsFilter>>
}

const Filter = ({activeFilter, setActiveFilter}: FilterProps) => {
  return (
    <div className={styles.filter}>
      {Object.entries(EnumClipInsightsFilter).map(([key, value]) => (
        <FilterItem
          key={value}
          filter={value}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
      ))}
    </div>
  )
}

export default Filter
