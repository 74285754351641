import styles from './Threshold.module.scss'
import classNames from 'classnames'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

// CONTEXT
import PaymentContext from 'context/PaymentContext'
import {AppLevelContext} from 'context/AppLevelContext'

const Threshold = () => {
  const navigate = useNavigate()
  const {user} = useContext(AppLevelContext)
  const {paymentTreshold, paymentData} = useContext(PaymentContext)
  const {t} = useTranslation()

  const calculatePercentage = () => {
    let percentage = 0

    if (paymentData && paymentTreshold) {
      percentage = (paymentData.balance / paymentTreshold.threshold_amount) * 100
    }

    if (percentage > 100) {
      percentage = 100
    }

    if (percentage < 0) {
      percentage = 0
    }

    return percentage
  }

  return (
    <div className={styles.threshold}>
      <div className={styles.thresholdContent}>
        <div className={styles.thresholdTitle}>
          {t('PaymentsPage.paymentThresholdInfo', {
            AMOUNT: paymentTreshold?.threshold_amount?.toLocaleString() + ' ' + user?.currency_sign,
          })}
        </div>
        <div className={styles.thresholdBar}>
          <div
            className={styles.thresholdBarFill}
            style={{width: `${calculatePercentage()}%`}}
          ></div>
        </div>
        <div className={styles.thresholdDescription}>
          <div className={styles.thresholdDescriptionText}>
            {t('PaymentsPage.paymentThresholdReached', {
              PERCENTAGE: calculatePercentage().toFixed(1) + '%',
            })}
          </div>
          <div className={styles.thresholdDescriptionText}>
            {t('PaymentsPage.paymentThresholdAmount', {
              AMOUNT:
                ' ' +
                paymentTreshold?.threshold_amount?.toLocaleString() +
                ' ' +
                user?.currency_sign,
            })}
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          if (calculatePercentage() >= 100) navigate('/payments/payout-request')
        }}
        className={classNames(styles.payoutButton, {
          [styles.disabled]: calculatePercentage() < 100,
        })}
      >
        {t('PaymentsPage.requestPayout')}
      </button>
    </div>
  )
}

export default Threshold
