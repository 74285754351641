import {createContext, useState, useContext, useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import GetDonationPreferencesResponse from 'services/model/response/streamers/getDonationPreferencesResponse'
import {createStreamersService} from 'services/streamersService'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'
import {
  InterestsDto,
  ProfileDetailsDto,
  SocialMediaDto,
  StreamingPreferencesDto,
} from 'services/model/dto/streamerAccountModels'
import EnumSetupPreview from 'services/model/enum/enumSetupPreview'
import SignupStepsDto from 'services/model/dto/signupStepsDto'
import {StreamerModDto} from 'services/model/dto/mods/streamerModDto'
import {PermsForModDto} from 'services/model/dto/mods/permsForModDto'
import EnumTipType from 'services/model/enum/enumTipType'
import {GetAutoAcceptSettingsResponse} from 'services/model/response/streamers/getAutoAcceptSettingsResponse'
import {UpdateAutoAcceptSettingsRequest} from 'services/model/request/streamers/updateAutoAcceptSettingsRequest'
import EnumStreamerAutoAcceptPreference from 'services/model/enum/enumStreamerAutoAcceptPreference'
import {createIndexService} from 'services/indexService'
import {AppLevelContext} from './AppLevelContext'

export type Interest = {
  id: number
  name: string
  image: string
}

export type PhoneCode = {
  value: number
  label: string
}

export type Country = {
  value: string
  label: string
  flag?: string
}

export type Language = {
  business_available: number
  currency_id: unknown
  flag_url: string
  id: string
  min_CPC_bid: unknown
  min_CPV_bid: unknown
  name: string
  native_name: string
}

export interface SettingsContextInterface {
  getStreamersAccount: () => Promise<void>
  signupSteps: SignupStepsDto | null

  activeTab: EnumSettingsTab
  setActiveTab: (tab: EnumSettingsTab) => void

  // Profile Details
  profileDetails: ProfileDetailsDto | null
  newProfileDetails: ProfileDetailsDto | null
  setNewProfileDetails: (data: ProfileDetailsDto | null) => void
  updateProfileDetails: (data: ProfileDetailsDto) => Promise<boolean>
  checkProfileDetailsSave: (data: ProfileDetailsDto) => boolean
  checkProfileDetailsDiff: (data: ProfileDetailsDto) => boolean

  // Social Media
  socialMedia: SocialMediaDto | null
  newSocialMedia: SocialMediaDto | null
  setNewSocialMedia: (data: SocialMediaDto | null) => void
  updateSocialMedia: (data: SocialMediaDto) => Promise<boolean>
  checkSocialMediaSave: (data: SocialMediaDto) => boolean
  checkSocialMediaDiff: (data: SocialMediaDto) => boolean

  // Streaming Preferences
  streamingPreferences: StreamingPreferencesDto | null
  newStreamingPreferences: StreamingPreferencesDto | null
  setNewStreamingPreferences: (data: StreamingPreferencesDto | null) => void
  updateStreamingPreferences: (data: StreamingPreferencesDto) => Promise<boolean>
  checkStreamingPreferencesSave: (data: StreamingPreferencesDto) => boolean
  checkStreamingPreferencesDiff: (data: StreamingPreferencesDto) => boolean

  streamingLanguages: Language[]
  getStreamerLanguages: () => Promise<void>

  //Country-city
  countries: Country[]
  filteredCountries: Country[]
  setFilteredCountries: (data: Country[]) => void
  countryIsLoading: boolean
  setCountryIsLoading: (data: boolean) => void
  cities: any[]
  filteredCities: any[]
  setFilteredCities: (data: any[]) => void
  cityIsLoading: boolean
  setCityIsLoading: (data: boolean) => void
  phoneCodes: PhoneCode[]
  getAllCountries: () => Promise<void>
  handleSearchCountry: (search?: string) => Promise<void>
  getAllCities: (countryId: string) => Promise<void>
  handleSearchCity: (id: string, search?: string) => Promise<void>

  // Interests
  interests: number[] | null
  newInterests: number[] | null
  setNewInterests: (data: number[] | null) => void
  updateInterests: (data: number[]) => Promise<boolean>
  checkInterestsSave: (data: number[]) => boolean
  checkInterestsDiff: (data: number[]) => boolean
  interestList: Interest[]
  getAllInterests: () => Promise<void>

  // Setup Preview
  setupPreviewConfirmed: EnumSetupPreview | null
  checkSetupPreviewConfirmedDiff: (data: EnumSetupPreview) => boolean
  updateSetupPreviewConfirmed: (data: EnumSetupPreview) => Promise<boolean>
  checkSetupPreviewConfirmedSave: (data: EnumSetupPreview) => boolean

  // SIGNUP
  completeSignup: () => Promise<void>

  //Donations
  donationPreferences: GetDonationPreferencesResponse | null
  initialDonationPreferences: GetDonationPreferencesResponse | null
  fetchDonationPreferences: () => Promise<void>
  setDonationPreferences: (data: GetDonationPreferencesResponse | null) => void
  donationCanBeSaved: () => boolean
  getDonationPreferencesDiff: () => boolean
  updateDonationPreferences: () => Promise<boolean>

  // MODS
  mods: StreamerModDto[] | null
  fetchMods: () => Promise<void>
  addMod: (twitchUsername: string) => Promise<void>
  fetchModPerms: (twitchUsername: string) => Promise<void>
  checkModPermsSave: () => boolean
  updateModPerms: () => Promise<void>
  removeMod: (twitchUsername: string) => Promise<void>
  selectedModPerms: {
    permData: PermsForModDto
    twitchUsername: string
  } | null
  setSelectedModPerms: (
    data: {
      permData: PermsForModDto
      twitchUsername: string
    } | null
  ) => void
  selectedModUsername: string | null
  permsLoading: boolean
  autoAcceptSettings: GetAutoAcceptSettingsResponse | null
  fetchAutoAcceptSettings: () => Promise<void>
  updateAutoAcceptSettings: () => Promise<boolean>
  setAutoAcceptSettings: (data: GetAutoAcceptSettingsResponse) => void
  getAutoAcceptSettingsDiff: () => boolean
  checkAutoAcceptSettingsSave: () => boolean
}

export const SettingsContext = createContext<SettingsContextInterface>({
  getStreamersAccount: async () => {},
  signupSteps: null,

  activeTab: EnumSettingsTab.ProfileDetails,
  setActiveTab: (tab: EnumSettingsTab) => {},

  profileDetails: null,
  newProfileDetails: null,
  setNewProfileDetails: (data: ProfileDetailsDto | null) => {},
  updateProfileDetails: async (data: ProfileDetailsDto) => false,
  checkProfileDetailsSave: (data: ProfileDetailsDto) => false,
  checkProfileDetailsDiff: (data: ProfileDetailsDto) => false,

  // Social Media
  socialMedia: null,
  newSocialMedia: null,
  setNewSocialMedia: (data: SocialMediaDto | null) => {},
  updateSocialMedia: async (data: SocialMediaDto) => false,
  checkSocialMediaSave: (data: SocialMediaDto) => false,
  checkSocialMediaDiff: (data: SocialMediaDto) => false,

  // Streaming Preferences
  streamingPreferences: null,
  newStreamingPreferences: null,
  setNewStreamingPreferences: (data: StreamingPreferencesDto | null) => {},
  updateStreamingPreferences: async (data: StreamingPreferencesDto) => false,
  checkStreamingPreferencesSave: (data: StreamingPreferencesDto) => false,
  checkStreamingPreferencesDiff: (data: StreamingPreferencesDto) => false,

  // Sponsorship Preferences
  // sponsorshipPreferences: null,
  // updateSponsorshipPreferences: async (data: SponsorshipPreferencesDto) => false,
  // checkSponsorshipPreferencesSave: (data: SponsorshipPreferencesDto) => false,
  // checkSponsorshipPreferencesDiff: (data: SponsorshipPreferencesDto) => false,

  streamingLanguages: [],
  getStreamerLanguages: async () => {},

  //Country - city
  countries: [],
  filteredCountries: [],
  setFilteredCountries: (data: Country[]) => {},
  countryIsLoading: false,
  setCountryIsLoading: (data: boolean) => {},
  cities: [],
  filteredCities: [],
  setFilteredCities: (data: any[]) => {},
  cityIsLoading: false,
  setCityIsLoading: (data: boolean) => {},
  phoneCodes: [],
  getAllCountries: async () => {},
  handleSearchCountry: async (search?: string) => {},
  getAllCities: async (countryId: string) => {},
  handleSearchCity: async (id: string, search?: string) => {},

  // Interests
  interests: null,
  newInterests: null,
  setNewInterests: (data: number[] | null) => {},
  updateInterests: async (data: number[]) => false,
  checkInterestsSave: (data: number[]) => false,
  checkInterestsDiff: (data: number[]) => false,
  interestList: [],
  getAllInterests: async () => {},

  // Setup Preview
  setupPreviewConfirmed: EnumSetupPreview.NotTested,
  checkSetupPreviewConfirmedDiff: (data: EnumSetupPreview) => false,
  updateSetupPreviewConfirmed: async (data: EnumSetupPreview) => false,
  checkSetupPreviewConfirmedSave: (data: EnumSetupPreview) => false,

  // SIGNUP
  completeSignup: async () => {},

  //Donations
  donationPreferences: null,
  initialDonationPreferences: null,
  fetchDonationPreferences: async () => {},
  setDonationPreferences: (data: GetDonationPreferencesResponse | null) => {},
  donationCanBeSaved: () => false,
  getDonationPreferencesDiff: () => false,
  updateDonationPreferences: async () => {
    return false
  },

  // MODS
  mods: null,
  fetchMods: async () => {},
  addMod: async (twitchUsername: string) => {},
  fetchModPerms: async (twitchUsername: string) => {},
  checkModPermsSave: () => false,
  updateModPerms: async () => {},
  removeMod: async (twitchUsername: string) => {},
  selectedModPerms: null,
  setSelectedModPerms: (
    data: {
      permData: PermsForModDto
      twitchUsername: string
    } | null
  ) => {},
  selectedModUsername: null,
  permsLoading: false,
  autoAcceptSettings: null,
  fetchAutoAcceptSettings: async () => {},
  updateAutoAcceptSettings: async () => false,
  setAutoAcceptSettings: (data: GetAutoAcceptSettingsResponse) => {},
  getAutoAcceptSettingsDiff: () => false,
  checkAutoAcceptSettingsSave: () => false,
})

type SettingsContextType = {
  children: React.ReactNode
}

export const SettingsContextProvider = ({children}: SettingsContextType) => {
  const {user, getUser} = useContext(AppLevelContext)
  const indexService = createIndexService()
  const streamerService = createStreamersService()
  const [activeTab, setActiveTab] = useState<EnumSettingsTab>(EnumSettingsTab.ProfileDetails)

  //PROFILE INFORMATION
  const [profileDetails, setProfileDetails] = useState<ProfileDetailsDto | null>(null)
  const [newProfileDetails, setNewProfileDetails] = useState<ProfileDetailsDto | null>(null)
  const [socialMedia, setSocialMedia] = useState<SocialMediaDto | null>(null)
  const [newSocialMedia, setNewSocialMedia] = useState<SocialMediaDto | null>(null)
  const [streamingPreferences, setStreamingPreferences] = useState<StreamingPreferencesDto | null>(
    null
  )
  const [newStreamingPreferences, setNewStreamingPreferences] =
    useState<StreamingPreferencesDto | null>(null)
  const [interests, setInterests] = useState<number[] | null>(null)
  const [newInterests, setNewInterests] = useState<number[] | null>(null)
  const [setupPreviewConfirmed, setSetupPreviewConfirmed] = useState<EnumSetupPreview | null>(null)

  // INTEREST STATES
  const [interestList, setInterestList] = useState<InterestsDto>([])
  // COUNTRIES & CITIES & PHONE CODES
  const [countries, setCountries] = useState<Country[]>([])
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([])
  const [countryIsLoading, setCountryIsLoading] = useState(false)
  const [cities, setCities] = useState([])
  const [cityIsLoading, setCityIsLoading] = useState(false)
  const [filteredCities, setFilteredCities] = useState<any>([])
  const [phoneCodes, setPhoneCodes] = useState([])
  // STREAMING PREFERENCES
  const [streamingLanguages, setStreamingLanguages] = useState([])
  // SIGNUP STEPS
  const [signupSteps, setSignupSteps] = useState<SignupStepsDto | null>(null)
  // DONATIONS
  const [initialDonationPreferences, setInitialDonationPreferences] =
    useState<GetDonationPreferencesResponse | null>(null)
  const [donationPreferences, setDonationPreferences] =
    useState<GetDonationPreferencesResponse | null>(null)
  // MODS
  const [mods, setMods] = useState<StreamerModDto[] | null>(null)
  const [initialSelectedModPerms, setInitialSelectedModPerms] = useState<{
    permData: PermsForModDto
    twitchUsername: string
  } | null>(null)
  const [selectedModPerms, setSelectedModPerms] = useState<{
    permData: PermsForModDto
    twitchUsername: string
  } | null>(null)
  const [selectedModUsername, setSelectedModUsername] = useState<string | null>(null)
  const [permsLoading, setPermsLoading] = useState<boolean>(false)

  const [initialAutoAcceptSettings, setInitialAutoAcceptSettings] =
    useState<GetAutoAcceptSettingsResponse | null>(null)
  const [autoAcceptSettings, setAutoAcceptSettings] =
    useState<GetAutoAcceptSettingsResponse | null>(null)

  // GET ALL PROFILE INFO
  const getStreamersAccount = async () => {
    try {
      const data = await streamerService.getStreamersAccount()
      setProfileDetails({
        first_name: data.first_name,
        last_name: data.last_name,
        contact_email: data.contact_email,
        phone: data.phone,
        phone_code: data.phone_code,
        gender: data.gender,
        gender_detail: data.gender_detail,
        birth_date: data.birth_date,
        country_id: data.country_id,
        country_name: data.country_name,
        city_id: data.city_id,
        city_name: data.city_name,
      })
      setSocialMedia({
        discord_username: data.discord_username,
        instagram_username: data.instagram_username,
        twitter_username: data.twitter_username,
        tiktok_username: data.tiktok_username,
      })
      setStreamingPreferences({
        broadcasting_software: data.broadcasting_software,
        broadcasting_language_id: data.broadcasting_language_id,
      })
      setInterests(data.interests ?? [])
      setSetupPreviewConfirmed(data.setup_preview_confirmed ?? null)
    } catch (error) {
      console.log(error)
    }
  }
  const checkProfileDetailsDiff = (data: ProfileDetailsDto): boolean => {
    let success = false
    if (data.first_name !== profileDetails?.first_name) {
      success = true
    }
    if (data.last_name !== profileDetails?.last_name) {
      success = true
    }
    if (data.contact_email !== profileDetails?.contact_email) {
      success = true
    }
    if (data.phone !== profileDetails?.phone) {
      success = true
    }
    if (data.phone_code !== profileDetails?.phone_code) {
      success = true
    }
    if (data.gender !== profileDetails?.gender) {
      success = true
    }
    if (data.gender_detail !== profileDetails?.gender_detail) {
      success = true
    }
    if (data.birth_date !== profileDetails?.birth_date) {
      success = true
    }
    if (data.country_id !== profileDetails?.country_id) {
      success = true
    }
    if (data.country_name !== profileDetails?.country_name) {
      success = true
    }
    if (data.city_id !== profileDetails?.city_id) {
      success = true
    }
    if (data.city_name !== profileDetails?.city_name) {
      success = true
    }
    return success
  }
  const checkProfileDetailsSave = (data: ProfileDetailsDto): boolean => {
    let success = true
    if (!data.first_name) {
      success = false
    }
    if (!data.last_name) {
      success = false
    }
    if (!data.contact_email) {
      success = false
    }
    if (!data.phone) {
      success = false
    }
    if (!data.phone_code) {
      success = false
    }
    if (!data.gender) {
      success = false
    }
    if (!data.birth_date) {
      success = false
    }
    if (!data.country_id) {
      success = false
    }
    if (!data.city_id) {
      success = false
    }
    return success
  }
  const updateProfileDetails = async (data: ProfileDetailsDto): Promise<boolean> => {
    if (!checkProfileDetailsSave(data)) {
      toast.error('Please fill out all required fields')
      return false
    }
    try {
      const countryDiff = data.country_id !== profileDetails?.country_id
      await toast.promise(streamerService.updateStreamersAccount(data), {
        pending: 'Saving Profile Details...',
        success: 'Profile details updated successfully',
        error: 'Error while saving Profile Details',
      })
      setProfileDetails(data)
      if (countryDiff && !user?.signup_completed) {
        await getUser()
      }
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const checkSocialMediaDiff = (data: SocialMediaDto): boolean => {
    let success = false
    if (data.discord_username !== socialMedia?.discord_username) {
      success = true
    }
    if (data.instagram_username !== socialMedia?.instagram_username) {
      success = true
    }
    if (data.twitter_username !== socialMedia?.twitter_username) {
      success = true
    }
    if (data.tiktok_username !== socialMedia?.tiktok_username) {
      success = true
    }
    return success
  }
  const checkSocialMediaSave = (data: SocialMediaDto): boolean => {
    let success = true
    if (!data.discord_username) {
      success = false
    }
    return success
  }
  const updateSocialMedia = async (data: SocialMediaDto): Promise<boolean> => {
    if (!checkSocialMediaSave(data)) {
      toast.error('Please fill out all required fields')
      return false
    }
    try {
      await toast.promise(streamerService.updateStreamersAccount(data), {
        pending: 'Saving Social Media..',
        success: 'Social media updated successfully',
        error: 'Error while saving Social Media',
      })
      setSocialMedia(data)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }
  const checkStreamingPreferencesDiff = (data: StreamingPreferencesDto): boolean => {
    let success = false
    if (data.broadcasting_software !== streamingPreferences?.broadcasting_software) {
      success = true
    }
    if (data.broadcasting_language_id !== streamingPreferences?.broadcasting_language_id) {
      success = true
    }
    return success
  }
  const checkStreamingPreferencesSave = (data: StreamingPreferencesDto): boolean => {
    let success = true
    if (!data.broadcasting_software) {
      success = false
    }
    if (!data.broadcasting_language_id) {
      success = false
    }
    return success
  }

  const updateStreamingPreferences = async (data: StreamingPreferencesDto): Promise<boolean> => {
    if (!checkStreamingPreferencesSave(data)) {
      toast.error('Please fill out all required fields')
      return false
    }
    try {
      await toast.promise(streamerService.updateStreamersAccount(data), {
        pending: 'Saving Streaming Preferences...',
        success: 'Streaming preferences updated successfully',
        error: 'Error while saving Streaming Preferences',
      })
      setStreamingPreferences(data)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }
  const checkInterestsDiff = (data: number[]): boolean => {
    let success = false
    // Check if the items of data and interests are the same
    if (data.length !== interests?.length) {
      success = true
    } else {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== interests[i]) {
          success = true
        }
      }
    }
    return success
  }
  const checkInterestsSave = (data: number[]): boolean => {
    let success = true
    if (data.length < 3) {
      success = false
    }
    if (data.length > 5) {
      success = false
    }
    return success
  }
  const updateInterests = async (data: number[]): Promise<boolean> => {
    if (!checkInterestsSave(data)) {
      toast.error('Please choose at least 3 interests')
      return false
    }
    try {
      await toast.promise(streamerService.updateInterests(data), {
        pending: 'Saving Interests...',
        success: 'Interests updated successfully',
        error: 'Error while saving Interests',
      })
      setInterests(data)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const checkSetupPreviewConfirmedDiff = (data: EnumSetupPreview): boolean => {
    let success = false
    if (data !== setupPreviewConfirmed) {
      success = true
    }
    return success
  }

  const checkSetupPreviewConfirmedSave = (data: EnumSetupPreview): boolean => {
    let success = true
    if (!data) {
      success = false
    }
    return success
  }

  const updateSetupPreviewConfirmed = async (data: EnumSetupPreview): Promise<boolean> => {
    try {
      await toast.promise(streamerService.updateStreamersAccount({setup_preview_confirmed: data}), {
        pending: 'Saving...',
        success: 'Saved successfully',
        error: 'Error while saving Setup Preview Confirmed',
      })

      setSetupPreviewConfirmed(data)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const completeSignup = async () => {
    if (!signupSteps) return
    const enabled = Object.values(signupSteps).filter((x) => !x).length === 0
    if (Object.values(signupSteps).filter((x) => !x).length === 1 && !signupSteps?.overlaySetup) {
      toast.error(
        'You need to confirm that you can see the overlay on your streaming software, if you have any issues please contact support'
      )
    }
    if (!enabled) return
    await toast.promise(streamerService.completeSignup(), {
      pending: 'Completing signup...',
      success: 'Signup completed successfully',
      error: 'Error while completing signup',
    })
    await getUser()
  }

  // GET ALL INTERESTS
  const getAllInterests = async () => {
    try {
      const response = await streamerService.getAllInterests()
      setInterestList(response)
    } catch (error) {
      console.log(error)
    }
  }

  const getAllCountries = async () => {
    try {
      const response = await indexService.getCountries()
      setCountries(response.countries_array)
      setFilteredCountries(response.countries_array)
      setPhoneCodes(response.phone_code_array)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearchCountry = async (search?: string) => {
    try {
      setFilteredCountries([])
      setCountryIsLoading(true)
      const response = await indexService.getCountries(search)
      setFilteredCountries(response.countries_array)
      setCountryIsLoading(false)
    } catch (error) {
      setCountryIsLoading(false)
      console.log(error)
    }
  }

  // GET ALL CITIES
  const getAllCities = async (id: string) => {
    try {
      const response = await indexService.getCities(id)
      setCities(response.cities_array)
      setFilteredCities(response.cities_array)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearchCity = async (id: string, search?: string) => {
    try {
      setFilteredCities([])
      setCityIsLoading(true)
      const response = await indexService.getCities(id, search)
      setFilteredCities(response.cities_array)
      setCityIsLoading(false)
    } catch (error) {
      setCityIsLoading(false)
      console.log(error)
    }
  }

  // GET POSIBLE STREAMER LANGUAGES
  const getStreamerLanguages = async () => {
    try {
      const response = await indexService.getLanguages()
      setStreamingLanguages(response.languages)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchDonationPreferences = async () => {
    try {
      const tmpDonationPreferences = await streamerService.getDonationPreferences()
      if (tmpDonationPreferences.tipEnabled && tmpDonationPreferences?.minAmount == null) {
        tmpDonationPreferences.minAmount = tmpDonationPreferences.currencyMinAmount
      }
      if (!tmpDonationPreferences.primaryColor) {
        tmpDonationPreferences.primaryColor = '#5d7fff'
      }
      if (!tmpDonationPreferences.textColor) {
        tmpDonationPreferences.textColor = '#fafafa'
      }
      if (!tmpDonationPreferences.backgroundColor) {
        tmpDonationPreferences.backgroundColor = '#121212'
      }
      if(!tmpDonationPreferences.tipEnabled && !user?.signup_completed) {
        setDonationPreferences({
          ...tmpDonationPreferences,
          typePrices: tmpDonationPreferences.typePrices.map((price) => ({...price})),
          takeoverPrices: tmpDonationPreferences.takeoverPrices.map((price) => ({...price})),
          tipEnabled: true,
        })
      } else {
        setDonationPreferences({
          ...tmpDonationPreferences,
          typePrices: tmpDonationPreferences.typePrices.map((price) => ({...price})),
          takeoverPrices: tmpDonationPreferences.takeoverPrices.map((price) => ({...price})),
        })
      }
      setInitialDonationPreferences({
        ...tmpDonationPreferences,
        typePrices: tmpDonationPreferences.typePrices.map((price) => ({...price})),
        takeoverPrices: tmpDonationPreferences.takeoverPrices.map((price) => ({...price})),
      })
    } catch (error) {
      console.log(error)
    }
  }
  const donationCanBeSaved = (): boolean => {
    if (donationPreferences === null || initialDonationPreferences === null) {
      return false
    }
    if (!initialDonationPreferences || !donationPreferences) {
      return false
    }
    if (donationPreferences.tipEnabled && donationPreferences.minAmount == null) {
      return false
    }
    if (
      donationPreferences.minAmount != null &&
      donationPreferences.minAmount < donationPreferences.currencyMinAmount
    ) {
      return false
    }
    return true
  }
  const getDonationPreferencesDiff = (): boolean => {
    if (!donationPreferences || !initialDonationPreferences) {
      return false
    }
    return JSON.stringify(donationPreferences) !== JSON.stringify(initialDonationPreferences)
  }
  const updateDonationPreferences = async () => {
    // Compare initial and current donation preferences
    if (!donationCanBeSaved()) {
      return false
    }
    if (!donationPreferences) {
      return false
    }
    try {
      if (donationPreferences.tipEnabled && donationPreferences.minAmount == null) {
        toast.error('Please set a minimum donation amount')
        return false
      }
      let typePrices: Array<{
        type: EnumTipType
        minAmount: number
      }> = []
      if (donationPreferences.typePrices) {
        donationPreferences.typePrices.forEach((typePrice) => {
          if (typePrice.minAmount) {
            typePrices.push({
              type: typePrice.type,
              minAmount: typePrice.minAmount,
            })
          }
        })
      }
      let takeoverPrices: Array<{
        takeoverId: number
        minAmount: number
      }> = []
      if (donationPreferences.takeoverPrices) {
        donationPreferences.takeoverPrices.forEach((takeoverPrice) => {
          if (takeoverPrice.minAmount) {
            takeoverPrices.push({
              takeoverId: takeoverPrice.takeover.id,
              minAmount: takeoverPrice.minAmount,
            })
          }
        })
      }
      const tmpDonationPreferences = await toast.promise(
        streamerService.updateDonationPreferences({
          tipEnabled: donationPreferences.tipEnabled,
          anonymousEnabled: donationPreferences.anonymousEnabled,
          minAmount: donationPreferences.minAmount ?? donationPreferences.currencyMinAmount,
          position: donationPreferences.position,
          primaryColor: donationPreferences.primaryColor,
          textColor: donationPreferences.textColor,
          backgroundColor: donationPreferences.backgroundColor,
          typePrices,
          takeoverPrices,
        }),
        {
          pending: 'Updating donation preferences...',
          success: 'Donation preferences updated successfully',
          error: 'Error while updating donation preferences',
        }
      )
      setDonationPreferences({
        ...tmpDonationPreferences,
        typePrices: tmpDonationPreferences.typePrices.map((price) => ({...price})),
        takeoverPrices: tmpDonationPreferences.takeoverPrices.map((price) => ({...price})),
      })
      setInitialDonationPreferences({
        ...tmpDonationPreferences,
        typePrices: tmpDonationPreferences.typePrices.map((price) => ({...price})),
        takeoverPrices: tmpDonationPreferences.takeoverPrices.map((price) => ({...price})),
      })
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const fetchMods = async () => {
    try {
      const tmpMods = await streamerService.getModsForStreamer()
      setMods(tmpMods)
      setSelectedModPerms(null)
      setInitialSelectedModPerms(null)
      setSelectedModUsername(null)
    } catch (error) {
      console.log(error)
    }
  }

  const addMod = async (twitchUsername: string) => {
    try {
      const tmpMod = await toast.promise(streamerService.addModToStreamer({twitchUsername}), {
        pending: 'Adding mod...',
        success: 'Mod added successfully',
        error: 'Error while adding mod',
      })
      if (mods) {
        setMods([...mods, tmpMod])
      } else {
        setMods([tmpMod])
      }
      fetchModPerms(twitchUsername)
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    }
  }

  const fetchModPerms = async (twitchUsername: string) => {
    try {
      setSelectedModPerms(null)
      setInitialSelectedModPerms(null)
      if (selectedModUsername === twitchUsername) {
        setSelectedModUsername(null)
        return
      }
      setSelectedModUsername(twitchUsername)
      setPermsLoading(true)
      const tmpModPerms = await streamerService.getPermsForMod(twitchUsername)
      setPermsLoading(false)
      setInitialSelectedModPerms({
        permData: tmpModPerms,
        twitchUsername,
      })
      setSelectedModPerms({
        permData: tmpModPerms,
        twitchUsername,
      })
    } catch (error) {
      setSelectedModUsername(null)
      setPermsLoading(false)
      setSelectedModPerms(null)
      setInitialSelectedModPerms(null)
      toast.error('Something went wrong')
      console.log(error)
    }
  }

  const checkModPermsSave = (): boolean => {
    if (!selectedModPerms || !initialSelectedModPerms) {
      return false
    }
    return JSON.stringify(selectedModPerms) !== JSON.stringify(initialSelectedModPerms)
  }

  const updateModPerms = async () => {
    if (!checkModPermsSave()) {
      return
    }
    if (!selectedModPerms) {
      return
    }
    try {
      const tmpModPerms = await toast.promise(
        streamerService.updatePermsForMod({
          twitchUsername: selectedModPerms.twitchUsername,
          perms: selectedModPerms.permData.filter((item) => item.hasPerm).map((item) => item.perm),
        }),
        {
          pending: 'Updating mod permissions...',
          success: 'Mod permissions updated successfully',
          error: 'Error while updating mod permissions',
        }
      )
      setInitialSelectedModPerms({
        permData: tmpModPerms,
        twitchUsername: selectedModPerms.twitchUsername,
      })
      setSelectedModPerms({
        permData: tmpModPerms,
        twitchUsername: selectedModPerms.twitchUsername,
      })
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    }
  }

  const removeMod = async (twitchUsername: string) => {
    try {
      await toast.promise(streamerService.removeModFromStreamer({twitchUsername}), {
        pending: 'Removing mod...',
        success: 'Mod removed successfully',
        error: 'Error while removing mod',
      })
      if (mods) {
        setMods(mods.filter((mod) => mod.username !== twitchUsername))
      }
      setSelectedModPerms(null)
      setInitialSelectedModPerms(null)
      setSelectedModUsername(null)
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    }
  }

  const fetchAutoAcceptSettings = async () => {
    try {
      const response = await streamerService.getAutoAcceptSettings()
      if (response.autoAcceptPreference === null) {
        setInitialAutoAcceptSettings(response)
        setAutoAcceptSettings({
          ...response,
          autoAcceptPreference: EnumStreamerAutoAcceptPreference.ALL,
        })
      } else {
        setAutoAcceptSettings(response)
        setInitialAutoAcceptSettings(response)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAutoAcceptSettingsDiff = (): boolean => {
    if (!autoAcceptSettings || !initialAutoAcceptSettings) {
      return false
    }
    return JSON.stringify(autoAcceptSettings) !== JSON.stringify(initialAutoAcceptSettings)
  }

  const updateAutoAcceptSettings = async (): Promise<boolean> => {
    if (!autoAcceptSettings) {
      return false
    }
    if (!checkAutoAcceptSettingsSave()) {
      toast.error('Please fill out all required fields')
      return false
    }
    if (autoAcceptSettings.autoAcceptPreference === null) {
      toast.error('Please choose an auto accept preference')
      return false
    }
    try {
      const response = await toast.promise(
        streamerService.updateAutoAcceptSettings({
          autoAcceptPreference: autoAcceptSettings.autoAcceptPreference,
          autoAcceptMinimum: autoAcceptSettings.autoAcceptMinimum,
          autoAcceptClients: autoAcceptSettings.autoAcceptClients,
        }),
        {
          pending: 'Saving Auto Accept Settings...',
          success: 'Auto Accept Settings updated successfully',
          error: 'Error while saving Auto Accept Settings',
        }
      )
      setAutoAcceptSettings(response)
      setInitialAutoAcceptSettings(response)
      return true
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
      return false
    }
  }

  const checkAutoAcceptSettingsSave = (): boolean => {
    if (!autoAcceptSettings || !initialAutoAcceptSettings) {
      return false
    }
    if (
      autoAcceptSettings.autoAcceptPreference === EnumStreamerAutoAcceptPreference.BRAND &&
      autoAcceptSettings.autoAcceptClients.length === 0
    ) {
      return false
    }
    return true
  }

  useEffect(() => {
    console.log({
      profileDetails,
      socialMedia,
      streamingPreferences,
      interests,
      setupPreviewConfirmed,
    })
    if (
      profileDetails &&
      socialMedia &&
      streamingPreferences &&
      interests &&
      setupPreviewConfirmed &&
      autoAcceptSettings
    ) {
      setSignupSteps({
        profileDetails: profileDetails ? checkProfileDetailsSave(profileDetails) : false,
        socialMedia: socialMedia ? checkSocialMediaSave(socialMedia) : false,
        streamingPreferences: streamingPreferences
          ? checkStreamingPreferencesSave(streamingPreferences)
          : false,
        interests: interests ? checkInterestsSave(interests) : false,
        overlaySetup: setupPreviewConfirmed === EnumSetupPreview.Confirmed,
        sponsorshipPreferences: !!initialAutoAcceptSettings?.autoAcceptPreference,
      })
    }
  }, [
    profileDetails,
    socialMedia,
    streamingPreferences,
    interests,
    setupPreviewConfirmed,
    autoAcceptSettings,
  ])

  return (
    <SettingsContext.Provider
      value={{
        getStreamersAccount,
        signupSteps,

        activeTab,
        setActiveTab,

        profileDetails,
        newProfileDetails,
        setNewProfileDetails,
        updateProfileDetails,
        checkProfileDetailsSave,
        checkProfileDetailsDiff,

        socialMedia,
        newSocialMedia,
        setNewSocialMedia,
        updateSocialMedia,
        checkSocialMediaSave,
        checkSocialMediaDiff,

        streamingPreferences,
        newStreamingPreferences,
        setNewStreamingPreferences,
        updateStreamingPreferences,
        checkStreamingPreferencesSave,
        checkStreamingPreferencesDiff,

        streamingLanguages,
        getStreamerLanguages,

        // Country-city
        countries,
        filteredCountries,
        setFilteredCountries,
        countryIsLoading,
        setCountryIsLoading,
        cities,
        filteredCities,
        setFilteredCities,
        cityIsLoading,
        setCityIsLoading,
        phoneCodes,
        getAllCountries,
        handleSearchCountry,
        getAllCities,
        handleSearchCity,

        // Interests
        interests,
        newInterests,
        setNewInterests,
        updateInterests,
        checkInterestsSave,
        checkInterestsDiff,
        interestList,
        getAllInterests,

        // Setup Preview Confirmed
        setupPreviewConfirmed,
        updateSetupPreviewConfirmed,
        checkSetupPreviewConfirmedDiff,
        checkSetupPreviewConfirmedSave,

        // SIGNUP
        completeSignup,

        //Donations
        donationPreferences,
        initialDonationPreferences,
        fetchDonationPreferences,
        setDonationPreferences,
        donationCanBeSaved,
        getDonationPreferencesDiff,
        updateDonationPreferences,

        // MODS
        mods,
        fetchMods,
        addMod,
        fetchModPerms,
        checkModPermsSave,
        updateModPerms,
        removeMod,
        selectedModPerms,
        setSelectedModPerms,
        selectedModUsername,
        permsLoading,

        // Auto Accept Settings
        autoAcceptSettings,
        fetchAutoAcceptSettings,
        updateAutoAcceptSettings,
        setAutoAcceptSettings,
        getAutoAcceptSettingsDiff,
        checkAutoAcceptSettingsSave,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsContext
