import {useContext, useState, useEffect} from 'react'
import classNames from 'classnames'
import {toAbsoluteUrl, compareArrays} from 'helpers'
import {useTranslation} from 'react-i18next'
// STYLES
import styles from '../../Interests.module.scss'
// COMPONENTS
import {Interest} from 'context/SettingsContext'
// CONTEXT
import SettingsContext from 'context/SettingsContext'

type SingleInterestProps = {
  interest: Interest
  newData: any
  removeInterest: (interest: number) => void
  addInterest: (interest: number) => void
}

const SingleInterest = ({interest, newData, addInterest, removeInterest}: SingleInterestProps) => {
  const {interests} = useContext(SettingsContext)
  const [selected, setSelected] = useState(false)
  const {t} = useTranslation()

  let iconUrl = interest.image
  let fileName = iconUrl.substring(iconUrl.lastIndexOf('/') + 1)
  let nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'))

  const checkSelected = () => {
    // check if newData array contains the interest id
    if (newData.includes(interest['id'])) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }

  useEffect(() => {
    checkSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData])

  return (
    <div
      className={classNames(styles.item, {
        [styles.selected]: selected,
      })}
      onClick={() => {
        if (selected) {
          removeInterest(interest['id'])
        } else {
          addInterest(interest['id'])
        }
      }}
    >
      {selected && (
        <img
          className={styles.selected_icon}
          src={toAbsoluteUrl('/assets/icons/Settings/selected.png')}
          alt='selected'
        />
      )}
      <img
        src={toAbsoluteUrl('/assets/icons/Interest/' + nameWithoutExtension + '.png')}
        alt='interest'
      />
      <div className={styles.title}>{t(`SettingsPage.${interest.name.toLocaleLowerCase()}`)}</div>
    </div>
  )
}

export default SingleInterest
