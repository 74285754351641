import styles from './CopyLink.module.scss'
import {toAbsoluteUrl} from 'helpers'

const CopyLink = (props: {softwareTab: string; setSoftwareTab: (arg0: string) => void}) => {
  return (
    <div className={styles.copyLink}>
      {(props.softwareTab === 'obs' && (
        <>
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/OBS/copyLink.gif')} alt='' />
        </>
      )) ||
        (props.softwareTab === 'streamlabs' && (
          <>
            <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/Streamlabs/copyLink.gif')} alt='' />
          </>
        ))}
    </div>
  )
}

export default CopyLink
