import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
// LOCALE FILES
// import translationEN from './locale/en/translation.json'
// import translationTR from './locale/tr/translation.json'
// import translationES from './locale/es/translation.json'
import tranlationArSA from './locale/ar-SA.json'
import tranlationCsCZ from './locale/cs-CZ.json'
import tranlationDeDE from './locale/de-DE.json'
import tranlationEnUS from './locale/en-US.json'
import tranlationEsES from './locale/es-ES.json'
import tranlationFrFR from './locale/fr-FR.json'
import tranlationNlNL from './locale/nl-NL.json'
import tranlationPtBR from './locale/pt-BR.json'
import tranlationPtPT from './locale/pt-PT.json'
import tranlationRuRU from './locale/ru-RU.json'
import tranlationSkSK from './locale/sk-SK.json'
import tranlationTrTR from './locale/tr-TR.json'


const availableLanguages = ['ar', 'cs', 'de', 'en', 'es', 'fr', 'nl', 'pt', 'ru', 'sk', 'tr']


const resources = {
  'ar': {
    translation: tranlationArSA,
  },
  'cs': {
    translation: tranlationCsCZ,
  },
  'de': {
    translation: tranlationDeDE,
  },
  'en': {
    translation: tranlationEnUS,
  },
  'es': {
    translation: tranlationEsES,
  },
  'fr': {
    translation: tranlationFrFR,
  },
  'nl': {
    translation: tranlationNlNL,
  },
  'pt': {
    translation: tranlationPtBR,
  },
  'ru': {
    translation: tranlationRuRU,
  },
  'sk': {
    translation: tranlationSkSK,
  },
  'tr': {
    translation: tranlationTrTR,
  },

}


i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
