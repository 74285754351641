import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
//STYLES
import styles from './SelectInput.module.scss'
import {useEffect, useState} from 'react'
import ClickAwayListener from 'react-click-away-listener'
import SearchResultDto from 'services/model/dto/searchResultDto'
import {toast} from 'react-toastify'
import Loading from '../Loading/Loading'
import SearchRequest from 'services/model/request/searchRequest'
import {useTranslation} from 'react-i18next'

interface Props<T> {
  fetchResults: (req: SearchRequest) => Promise<SearchResultDto<T>[]>
  // setSelectedResults: (values: SearchResultDto<T>[]) => void;
  select: (value: SearchResultDto<T>) => void
  showResult?: boolean
  defaultValue?: SearchResultDto<T> | null
  initialSearch?: boolean
  defaultAvatar?: string
}

const SelectInput = <T,>({
  fetchResults,
  select,
  showResult = false,
  defaultValue = null,
  initialSearch = false,
  defaultAvatar
}: Props<T>): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<SearchResultDto<T>[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState<SearchResultDto<T> | null>(null)

  const {t} = useTranslation()

  const handleSearch = async () => {
    setResults(null)
    setIsLoading(true)
    try {
      const req: SearchRequest = {
        query,
      }
      const response = await fetchResults(req)
      setResults(response)
    } catch (error) {
      toast.error('Error while searching')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (initialSearch) {
      handleSearch()
    }
  }, [])

  return (
    <div className={styles.selectWrapper}>
      <div
        onClick={() => {
          setOpen(!open)
        }}
        className={styles.selectInput}
      >
        <span className={styles.inputPlaceholder}>
          {selectedValue && showResult
            ? selectedValue.name
            : defaultValue
            ? defaultValue.name
            : t('SettingsPage.selectInputPlaceholder') ?? undefined}
        </span>
        <img src={toAbsoluteUrl('/assets/icons/arrowIcon.png')} alt='Arrow-Icon' />
      </div>
      {!!open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={styles.selectResult}>
            <div className={styles.selectSearch}>
              <input
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch()
                  }
                }}
                type='text'
                placeholder={t('SettingsPage.searchInputPlaceholder') ?? undefined}
              />
              <button onClick={handleSearch} className={styles.searchBtn}>
                <svg
                  strokeWidth='0'
                  viewBox='0 0 512 512'
                  height='1em'
                  width='1em'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M456.69 421.39 362.6 327.3a173.81 173.81 0 0 0 34.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 0 0 327.3 362.6l94.09 94.09a25 25 0 0 0 35.3-35.3zM97.92 222.72a124.8 124.8 0 1 1 124.8 124.8 124.95 124.95 0 0 1-124.8-124.8z'></path>
                </svg>
              </button>
            </div>
            {!!(isLoading || results !== null) && (
              <>
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className={styles.searchResultList}>
                    {results?.map((result, index) => (
                      <div
                        key={index}
                        className={styles.resultItem}
                        onClick={() => {
                          select(result)
                          setSelectedValue(result)
                          setOpen(false)
                          // setResults(null);
                          setQuery('')
                        }}
                      >
                        {/* <div className={classNames(styles.resultCheckbox, {
                              [styles.checked]: result.selected
                            })}>
                              <img
                                src={toAbsoluteUrl(
                                  "/assets/icons/NewCampaign/selectedIcon.png"
                                )}
                                alt="Checked-Icon"
                              />
                            </div>  */}
                        <span className={styles.resultText}>
                          {result.logo && <img src={result.logo  ?? defaultAvatar} alt='Icon' 
                            onError={(e) => {
                              e.currentTarget.src = defaultAvatar ?? ''
                            }}
                          />}
                          {!!result.platform && (
                            <img
                              src={toAbsoluteUrl(
                                `/assets/icons/Platforms/${result.platform}-icon.png`
                              )}
                              alt='Platform'
                            />
                          )}
                          {result.name}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}

export default SelectInput
