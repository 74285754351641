// src/routes/routes.tsx
import React, {ReactElement} from 'react'
import Login from 'pages/Login/Login'
import PrivateRoutes from './PrivateRoutes'
import {Navigate} from 'react-router-dom'
//PAGES
import EmailVerification from 'pages/EmailVerification/EmailVerification'

interface Route {
  path: string
  element: ReactElement
  auth: boolean
  children?: Route[] // Add this line
}

const routesPaths: Route[] = [
  {
    path: '/',
    element: <EmailVerification />,
    auth: true,
  },
  {
    path: '/login',
    element: <Login />,
    auth: false,
  },
  {
    path: '*',
    element: <Navigate to='/' replace={true} />,
    auth: true,
  },
]

const authCheck = (routes: Route[]): Route[] => {
  return routes.map((route) => {
    if (route.auth) {
      route.element = <PrivateRoutes>{route.element}</PrivateRoutes>
    }
    return route
  })
}

export default authCheck(routesPaths);