import {useContext} from 'react'
import styles from './EmailVerification.module.scss'
import {toAbsoluteUrl} from 'helpers'
import { AppLevelContext } from 'context/AppLevelContext';
import Loading from 'components/Shared/Loading/Loading';
import { createIndexService } from 'services/indexService';
import { toast } from 'react-toastify';

const EmailVerification = () => {
  const indexService = createIndexService()
  const {
    kickUser,
  } = useContext(AppLevelContext);

  const sendEmailVerification = async () => {
    try {
      await toast.promise(indexService.sendEmailVerification(), {
        pending: "Sending email...",
        success: "Email sent successfully",
      });
    } catch(error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred');
      }
    }
  }

  if (!kickUser) {
    return <Loading />;
  }

  return (
    <div className={styles.emailVerification}>
      <div className={styles.headerPattern}></div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={toAbsoluteUrl('/assets/icons/logo.png')} alt='Logo' />
        </div>
        <img
          className={styles.titleIcon}
          src={toAbsoluteUrl('/assets/icons/Auth/emailIcon.png')}
          alt='Email'
        />
        <div className={styles.contentText}>
          <div className={styles.line}>
            <span className={styles.titleText}>Please verify your email</span>
          </div>
          <div className={styles.line}>
            <span className={styles.text}>
              We just sent an email to <span>{kickUser.email}</span>
            </span>
            <div className={styles.text}>Click the link in the email to verify your account</div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <button
            onClick={() => {
              sendEmailVerification()
            }}
            className={styles.resendBtn}
          >
            Resend email
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmailVerification
