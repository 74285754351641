//tslint:disable
//ts-ignore
//ts-nocheck

import {useState, useEffect, useContext} from 'react'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import {formatDate, toAbsoluteUrl} from 'helpers'
//STYLES
import styles from './GameDetail.module.scss'
// COMPONENTS
import GameDemo from './components/GameDemo/GameDemo'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
// TYPES
import {Campaign} from 'components/GamePopup/GamePopup'
import {EnumGameType} from 'services/model/enum/enumGameType'
import {createGamesService} from 'services/gamesService'
import {useLocation, useNavigate} from 'react-router-dom'

type GameDetailProps = {
  gameType: EnumGameType
}

const GameDetail = ({gameType}: GameDetailProps) => {
  const {user} = useContext(AppLevelContext)
  const gamesService = createGamesService()
  const navigate = useNavigate()
  const location = useLocation()

  const routePath = location.pathname.split('/')[1]

  return (
    <div className={styles.campaignPopupTab}>
      <div className={styles.campaignCustomer}>
        <div className={styles.brandName}>
          {gameType === EnumGameType.Trivia
            ? 'Trivia'
            : gameType === EnumGameType.Flag
            ? 'Find the Flag'
            : gameType === EnumGameType.Taboo
            ? 'Reverse Taboo'
            : ''}
        </div>
      </div>

      <GameDemo gameType={gameType} />

      <div className={styles.gameDetail_footer}>
        <div className={styles.campaignBrief}>
          <img
            className={styles.livadLogoMark}
            src={toAbsoluteUrl('/assets/icons/logoMark.png')}
            alt='logo'
          />
          <p className={styles.campaignBrief_text}>
            {gameType === EnumGameType.Trivia
              ? 'Looking to make your stream more interactive and fun? Challenge your viewers in this trivia game and step up your quiz game by answering fun trivia questions!'
              : gameType === EnumGameType.Flag
              ? 'Learn about different flags from countries around the world while making your streams more interactive and fun.'
              : gameType === EnumGameType.Taboo
              ? 'Can you guess the word based on the hints? Hurry up, you only have 60 seconds. 😉'
              : ''}
          </p>
        </div>
        <div className={styles.campaignAgreement}>
          <button
            onClick={async () => {
              try {
                if (gameType == EnumGameType.Taboo) {
                  await gamesService.postTabooGame()
                  navigate(`/${routePath}`)
                } else {
                  await gamesService.postWordGame(gameType)
                  navigate(`/${routePath}`)
                }
              } catch (error) {}
            }}
            className={styles.gamePlayBtn}
          >
            Play
          </button>
        </div>
      </div>
    </div>
  )
}

export default GameDetail
