import styles from './Payability.module.scss'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import {useState, useContext} from 'react'
import PaymentContext from 'context/PaymentContext'
import MonthBox from './MonthBox'
import {useTranslation} from 'react-i18next'

const Payability = () => {
  const {payabilityData} = useContext(PaymentContext)
  const [isToggle, setIsToggle] = useState(false)
  const {t} = useTranslation()
  return (
    <div className={styles.payability}>
      <div className={styles.payabilityTitle}>{t('PaymentsPage.payability')}</div>
      <div className={styles.payabilityDesc}>
        <span className={styles.icon}>
          <img src={toAbsoluteUrl('/assets/icons/Payments/helpTooltip_icon.png')} alt='info' />
        </span>
        {t('PaymentsPage.lockedRevenueExplanation')}
      </div>
      <div className={styles.payabiltyTable}>
        {payabilityData.map((item: any, index: number) => (
          <MonthBox data={item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default Payability
