//tslint:disable
//ts-ignore
//ts-nocheck

import {useState, useEffect, useContext} from 'react'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import {formatDate, toAbsoluteUrl} from 'helpers'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {useTranslation, Trans} from 'react-i18next'
//STYLES
import styles from './CampaignDetail.module.scss'
// COMPONENTS
import CampaignDemo from './components/CampaignDemo/CampaignDemo'
import PollDemo from './components/PollDemo/PollDemo'
import {Campaign} from 'components/CampaignPopup/CampaignPopup'
import TargetingInfo from './components/TargetingInfo/TargetingInfo'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
import CampaignContext from 'context/CampaignContext'
// TYPES
import {GetAutoAcceptSettingsResponse} from 'services/model/response/streamers/getAutoAcceptSettingsResponse'
import EnumStreamerAutoAcceptPreference from 'services/model/enum/enumStreamerAutoAcceptPreference'
import {createCampaignService} from 'services/campaignService'
import EnumCampaignHasStreamerStatus from 'services/model/enum/enumCampaignHasStreamerStatus'
import {Tooltip} from '@mui/material'

export type CampaignDetailProps = {
  campaign: Campaign | null
  streamerList: {
    streamers:
      | [
          {
            link_to_logo: string | null
            title: string
          }
        ]
      | null
    count: number
  }
  autoAcceptSettings: GetAutoAcceptSettingsResponse | null
}

const CampaignDetail = ({campaign, streamerList, autoAcceptSettings}: CampaignDetailProps) => {
  const campaignService = createCampaignService()
  const {user} = useContext(AppLevelContext)
  const {setActiveFilter, activeFilter} = useContext(CampaignContext)
  const [userAgreement, setUserAgreement] = useState(false)
  const [autoAccept, setAutoAccept] = useState(false)
  const [showAutoAccept, setShowAutoAccept] = useState(false)
  const navigate = useNavigate()
  const {t} = useTranslation()

  useEffect(() => {
    if (campaign?.streamer_status === 'accepted') {
      setUserAgreement(true)
    }
  }, [campaign])

  useEffect(() => {
    if (!campaign || !autoAcceptSettings) {
      return
    }
    if (autoAcceptSettings.autoAcceptPreference === EnumStreamerAutoAcceptPreference.ALL) {
      setShowAutoAccept(false)
    }
    if (autoAcceptSettings.autoAcceptPreference === EnumStreamerAutoAcceptPreference.MANUAL) {
      setShowAutoAccept(true)
    }
    if (autoAcceptSettings.autoAcceptPreference === EnumStreamerAutoAcceptPreference.BRAND) {
      if (autoAcceptSettings.autoAcceptClients.map((x) => x.id).includes(campaign.client_id)) {
        setShowAutoAccept(false)
      } else {
        setShowAutoAccept(true)
      }
    }
  }, [campaign, autoAcceptSettings])

  const fullUrl = window.location
  const originUrl = fullUrl.pathname.split('/')[1]

  // ACCEPT AND SEND IT TO /CAMPAIGNS/ID
  const acceptCampaign = async () => {
    if (!campaign) {
      return
    }
    try {
      await toast.promise(
        campaignService.updateCampaignStatus({
          campaignId: campaign.campaign_id,
          status: EnumCampaignHasStreamerStatus.Accepted,
          autoAcceptClient: autoAccept,
        }),
        {
          pending: 'Accepting campaign...',
          success: 'Campaign accepted!',
          error: 'Error accepting campaign',
        }
      )
      if (originUrl !== 'campaigns') {
        navigate(`/${originUrl}`)
      } else {
        navigate(`/campaigns`)
      }
      setActiveFilter('active')
    } catch (error) {
      console.log(error)
    }
  }

  // DECLINE AND SEND IT TO /CAMPAIGNS/ID
  const declineCampaign = async () => {
    if (!campaign) {
      return
    }
    try {
      await campaignService.updateCampaignStatus({
        campaignId: campaign.campaign_id,
        status: EnumCampaignHasStreamerStatus.Denied,
        autoAcceptClient: null,
      })
      setActiveFilter('denied')
      if (originUrl !== 'campaigns') {
        navigate(`/${originUrl}`)
      } else {
        navigate(`/campaigns`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!campaign || !autoAcceptSettings) {
    return null
  }
  return (
    <div className={styles.campaignPopupTab}>
      <div className={styles.campaignCustomer}>
        <div className={styles.brandName}>{campaign?.client_name}</div>
        <div className={styles.campaignName}>{campaign?.campaign_name}</div>
      </div>
      <div className={styles.campaignDetail_header}>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.startEndDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/start-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.startDate')}
          </div>
          <div className={styles.item_value}>
            {formatDate('MMM DD, YYYY', campaign?.start_date)}
          </div>
        </div>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.startEndDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/start-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.endDate')}
          </div>
          <div className={styles.item_value}>{formatDate('MMM DD, YYYY', campaign?.end_date)}</div>
        </div>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.payableDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/payable-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.payableDate')}
          </div>
          <div className={styles.item_value}>
            {formatDate('MMM DD, YYYY', campaign?.payable_date)}
          </div>
        </div>
        {!campaign?.is_featured && (
          <>
            <div className={styles.detailHeader_item}>
              <div className={classNames(styles.item_title, styles.payableDate)}>
                <svg
                  stroke='currentColor'
                  fill='currentColor'
                  strokeWidth={0}
                  viewBox='0 0 512 512'
                  height='1em'
                  width='1em'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M326.7 403.7c-22.1 8-45.9 12.3-70.7 12.3s-48.7-4.4-70.7-12.3c-.3-.1-.5-.2-.8-.3c-30-11-56.8-28.7-78.6-51.4C70 314.6 48 263.9 48 208C48 93.1 141.1 0 256 0S464 93.1 464 208c0 55.9-22 106.6-57.9 144c-1 1-2 2.1-3 3.1c-21.4 21.4-47.4 38.1-76.3 48.6zM256 84c-11 0-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V312c0 11 9 20 20 20s20-9 20-20V298.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V104c0-11-9-20-20-20zM48 352H64c19.5 25.9 44 47.7 72.2 64H64v32H256 448V416H375.8c28.2-16.3 52.8-38.1 72.2-64h16c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V400c0-26.5 21.5-48 48-48z' />
                </svg>
                {t('CampaignsPopup.rpm')}
                <Tooltip title={t('CampaignsPopup.rpmExplanation')} placement='top'>
                  <svg
                    stroke='currentColor'
                    fill='currentColor'
                    strokeWidth={0}
                    viewBox='0 0 512 512'
                    height='1em'
                    width='1em'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z' />
                  </svg>
                </Tooltip>
              </div>
              <div className={styles.item_value}>
                {campaign?.CPM}
                {user?.currency_sign}
              </div>
            </div>
            {campaign.CPM_bonus_percentage > 0 && (
              <div className={styles.detailHeader_item}>
                <div className={classNames(styles.item_title, styles.payableDate)}>
                  <img
                    src={toAbsoluteUrl('/assets/icons/ClipInsights/earnBonusIcon.png')}
                    alt='icon'
                    style={{height: '22px'}}
                  />
                  {t('CampaignsPopup.rpmWithBonus')}
                  <Tooltip title={t('CampaignsPopup.rpmWithBonusExplanation')} placement='top'>
                    <svg
                      stroke='currentColor'
                      fill='currentColor'
                      strokeWidth={0}
                      viewBox='0 0 512 512'
                      height='1em'
                      width='1em'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z' />
                    </svg>
                  </Tooltip>
                </div>
                <div className={styles.item_value}>
                  {campaign?.CPM_with_bonus}
                  {user?.currency_sign}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <TargetingInfo campaign={campaign} />

      <div className={styles.participations}>
        {streamerList.streamers?.map((streamer, index) => (
          <img
            draggable={false}
            onContextMenu={(e) => e.preventDefault()}
            src={
              streamer.link_to_logo ??
              toAbsoluteUrl(`/assets/icons/Leaderboard/defaultProfileImg.png`)
            }
            alt={streamer.title}
            title={streamer.title}
            key={index}
            onError={(e) => {
              e.currentTarget.src = toAbsoluteUrl('/assets/icons/Leaderboard/defaultProfileImg.png')
            }}
          />
        ))}
        <span className={styles.participationsDetail}>
          <Trans
            i18nKey={'CampaignsPopup.streamersInCampaign'}
            values={{
              count: '+' + streamerList.count,
            }}
          >
            <span className={styles.participationsCount}></span>
          </Trans>
        </span>
      </div>

      {campaign?.campaign_type === 'poll' || campaign.campaign_type === 'interactive' ? (
        <PollDemo campaign={campaign} />
      ) : (
        <CampaignDemo campaign={campaign} />
      )}

      <div className={styles.campaignDetail_footer}>
        <div className={styles.campaignBrief}>
          <img
            className={styles.livadLogoMark}
            src={toAbsoluteUrl('/assets/icons/logoMark.png')}
            alt='logo'
          />
          <div className={styles.campaignBrief_title}>{t('CampaignsPopup.campaignBrief')}</div>
          {campaign?.campaign_brief ? (
            <p
              className={styles.campaignBrief_text}
              dangerouslySetInnerHTML={{__html: campaign?.campaign_brief}}
            ></p>
          ) : (
            <p>{campaign.pretext}</p>
          )}
        </div>
        {campaign?.campaign_status !== 'past' && (
          <div className={styles.campaignAgreement}>
            {!showAutoAccept ? null : (
              <div className={styles.campaignAgreement_title}>
                <div
                  className={classNames(styles.checkbox, {
                    [styles.checked]: autoAccept,
                  })}
                  onClick={() => {
                    setAutoAccept(!autoAccept)
                  }}
                >
                  <img
                    src={toAbsoluteUrl('/assets/icons/Campaigns/check-icon.png')}
                    alt='checked'
                  />
                </div>
                <span className={styles.agreementText}>
                  {t('CampaignsPopup.autoAcceptCampaign', {
                    brand: campaign?.client_name,
                  })}
                </span>
              </div>
            )}
            <div className={styles.campaignAgreement_title}>
              <div
                className={classNames(styles.checkbox, {
                  [styles.checked]: userAgreement,
                })}
                onClick={() => {
                  setUserAgreement(!userAgreement)
                }}
              >
                <img src={toAbsoluteUrl('/assets/icons/Campaigns/check-icon.png')} alt='checked' />
              </div>
              <span className={styles.agreementText}>
                {t('CampaignsPopup.readAndUnderstoodCampaignBrief')}
              </span>
            </div>

            <div className={styles.campaignButtons}>
              <div
                className={classNames(styles.denyButton, {
                  [styles.fullView]: campaign?.streamer_status === 'accepted',
                })}
                onClick={() => {
                  declineCampaign()
                }}
              >
                <div className={styles.buttonInner}>
                  <img src={toAbsoluteUrl('/assets/icons/Campaigns/close-icon.png')} alt='deny' />
                  {t('CampaignsPopup.deny')}
                </div>
              </div>
              {campaign?.streamer_status !== 'accepted' && (
                <div
                  className={classNames(styles.acceptButton, {[styles.available]: userAgreement})}
                  onClick={() => {
                    if (userAgreement) {
                      acceptCampaign()
                    } else {
                      toast.error('Please read and accept the campaign brief', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                      })
                    }
                  }}
                >
                  <div className={styles.buttonInner}>
                    <img
                      src={toAbsoluteUrl('/assets/icons/Campaigns/check-icon.png')}
                      alt='accept'
                    />
                    {t('CampaignsPopup.accept')}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CampaignDetail
