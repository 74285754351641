import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'
import qs from 'qs'
import { Id, toast } from 'react-toastify'
import { EnumRequestType } from './model/enum/enumRequestType'
import { getAuth } from 'firebase/auth'

export const createBaseStreamerApiService = (path: string) => {
  const baseUrl = process.env.REACT_APP_STREAMER_API_URL + path

  const postWithToast = async<T>(
    url: string,
    messages: {
      success: string
      loading: string
    },
    body: Object = {},
    requestType: EnumRequestType = EnumRequestType.Json
  ): Promise<AxiosResponse<T>> => {
    const toastId = toast.loading(messages.loading);
    try {
      let headers = await generateHeader(requestType)
      if (requestType === EnumRequestType.FormUrlEncoder) {
        body = qs.stringify(body, { allowDots: true })
      }
      let response = await axios.post<T, AxiosResponse<T>>(baseUrl + url, body ?? {}, {
        headers: headers,
        // withCredentials: true,
      })
      toast.update(toastId, {
        render: messages.success,
        type: 'success',
        isLoading: false,
        autoClose: 3000
      })
      return response
    } catch (error) {
      return toastErrorHandler<T>(error, toastId);
    }
  }
  const post = async <T>(
    url: string,
    body: Object = {},
    requestType: EnumRequestType = EnumRequestType.Json,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> => {
    try {
      let headers = await generateHeader(requestType)
      if (requestType === EnumRequestType.FormUrlEncoder) {
        body = qs.stringify(body, { allowDots: true })
      }
      let response = await axios.post<T, AxiosResponse<T>>(baseUrl + url, body ?? {}, {
        headers: headers,
        // withCredentials: true,
        ...config
      })
      return response
    } catch (err: any) {
      throw err;
      // return errorHandler<T>(err)
    }
  }
  const put = async <T>(
    url: string,
    body: Object = {},
    requestType: EnumRequestType = EnumRequestType.Json
  ): Promise<AxiosResponse<T>> => {
    try {
      let headers = await generateHeader(requestType)
      if (requestType === EnumRequestType.FormUrlEncoder) {
        body = qs.stringify(body, { allowDots: true })
      }
      let response = await axios.put<T, AxiosResponse<T>>(baseUrl + url, body ?? {}, {
        headers: headers,
        // withCredentials: true,
      })
      return response
    } catch (err: any) {
      throw err;
    }
  }
  const putWithToast = async<T>(
    url: string,
    messages: {
      success: string
      loading: string
    },
    body: Object = {},
    requestType: EnumRequestType = EnumRequestType.Json
  ): Promise<AxiosResponse<T>> => {
    const toastId = toast.loading(messages.loading);
    try {
      let headers = await generateHeader(requestType)
      if (requestType === EnumRequestType.FormUrlEncoder) {
        body = qs.stringify(body, { allowDots: true })
      }
      let response = await axios.put<T, AxiosResponse<T>>(baseUrl + url, body ?? {}, {
        headers: headers,
        // withCredentials: true,
      })
      toast.update(toastId, {
        render: messages.success,
        type: 'success',
        isLoading: false,
        autoClose: 3000
      })
      return response
    } catch (error) {
      return toastErrorHandler<T>(error, toastId);
    }
  }

  const get = async <T>(
    url: string,
    body?: Object,
    requestType: EnumRequestType = EnumRequestType.Json
  ): Promise<AxiosResponse<T>> => {
    try {
      let headers = await generateHeader(requestType)
      let response = await axios.get<T, AxiosResponse<T>>(baseUrl + url, {
        headers: headers,
        params: body ?? {},
        // withCredentials: true,
        paramsSerializer: {
          serialize: (params) => {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
              skipNulls: true,
              allowDots: true,
            })
          },
        }
      })
      return response
    } catch (err: any) {
      throw err;
      // return errorHandler<T>(err)
    }
  }

  const redirect = async (url: string): Promise<void> => {
    window.location.href = url;
  }

  const toastErrorHandler = <T>(error: any, toastId: Id): AxiosResponse<T> => {
    if (error.response) {
      if (error.response.status === 401) {

      } else if (error.response?.status === 400 && error.response?.message) {
        toast.update(toastId, {
          render: error.response.message,
          type: "error",
          isLoading: false,
          autoClose: 3000
        })
      } else {
        toast.update(toastId, {
          render: 'Something went wrong',
          type: "error",
          isLoading: false,
          autoClose: 3000
        })
      }
    } else {
      //server ayakta degil ise
      if (!window.location.pathname.includes('/Login')) {
        //todo: Berkant
        logout()
      }
    }
    let response: AxiosResponse<any> = {
      data: undefined,
      status: error.response?.status ?? 500,
      statusText: error.response?.statusText ?? error.response?.message ?? 'Something went wrong',
      headers: error.response?.headers ?? {},
      config: error.config ?? {},
    }
    return response
  }

  const errorHandler = <T>(error: any): AxiosResponse<T> => {
    if (error.response) {
      if (error.response.status === 401) {
        //todo: Berkant
        if (!window.location.pathname.includes('/Login')) {
          logout()
        }
      } else if (error.response.status === 400) {
        toast.error(error.response.message)
      } else {
        toast.error('Something went wrong')
      }
    } else {
      //server ayakta degil ise
      if (!window.location.pathname.includes('/Login')) {
        //todo: Berkant
        logout()
      }
    }
    let response: AxiosResponse<any> = {
      data: undefined,
      status: error.response?.status ?? 500,
      statusText: error.response?.statusText ?? error.response?.message ?? 'Something went wrong',
      headers: error.response?.headers ?? {},
      config: error.config ?? {},
    }
    return response
  }

  const generateHeader = async (requestType: EnumRequestType): Promise<{ [key: string]: string }> => {
    let headers: { [key: string]: string } = {}
    if (requestType === EnumRequestType.Json) {
      headers['Content-Type'] = 'application/json'
      headers.Authorization = await getToken()
    } else if (requestType === EnumRequestType.FormUrlEncoder) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded'
      headers.Authorization = await getToken()
    } else if (requestType === EnumRequestType.NoAuthJson) {
      headers['Content-Type'] = 'application/json'
    } else if (requestType == EnumRequestType.FormData) {
      headers['Content-Type'] = 'multipart/form-data'
      headers.Authorization = await getToken()
    } else {
      throw new Error('requestType is not valid')
    }
    return headers
  }

  const getToken = async (): Promise<string> => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // User is not logged in, throw an error
      throw new Error('User is not logged in.');
    }
    const idTokenResult = await user.getIdTokenResult();
    const freshToken = 'Bearer ' + idTokenResult.token;
    return freshToken;
  }

  const logout = () => {
    // CHANGE THIS TO NAVIGATE IF POSSIBLE
    window.location.href = '/Login'
  }
  return { get, post, put, postWithToast, putWithToast, redirect }
}
