import {useContext, useEffect} from 'react'
import {toAbsoluteUrl} from 'helpers'
import styles from './PayoutAccount.module.scss'
import {useTranslation} from 'react-i18next'

// Context
import PayoutAccountContext from 'context/PayoutAccountContext'

//COMPONENTS
import CountrySelect from './components/CountrySelect/CountrySelect'
import ForeignInputs from './components/ForeignInputs/ForeignInputs'
import InvoiceSelection from './components/InvoiceSelection/InvoiceSelection'
import CompanySelection from './components/CompanySelection/CompanySelection'
import TaxSelection from './components/TaxSelection/TaxSelection'
import PersonalCompanyInputs from './components/PersonalCompanyInputs/PersonalCompanyInputs'
import LtdCompanyInput from './components/LtdCompanyInputs/LtdCompanyInput'
import TaxExemptInputs from './components/TaxExemptInputs/TaxExemptInputs'
import NonTaxExemptInputs from './components/NonTaxExemptInputs/NonTaxExemptInputs'

const PayoutAccount = () => {
  const {t} = useTranslation()
  const {payoutAccountData, sendPayoutAccountData, checkPayoutAccountData, checkPayoutAccountDiff} =
    useContext(PayoutAccountContext)
  return (
    <div className={styles.payoutAccount}>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.billingCountry')}</div>
        <div className={styles.desc}>{t('SettingsPage.selectCountryPrompt')}</div>
        <CountrySelect />
      </div>

      {payoutAccountData?.billing_country_id === 'TR' && (
        <>
          {/* INVOICE STATUS */}
          <InvoiceSelection />
          {/* TAX STATUS */}
          {payoutAccountData?.billing_preference === 'available' ? (
            <CompanySelection />
          ) : payoutAccountData?.billing_preference === 'not_available' ? (
            <TaxSelection />
          ) : null}
        </>
      )}

      {/* ForeignInput */}
      {payoutAccountData?.billing_country_id !== 'TR' &&
        payoutAccountData?.billing_country_id !== '' && <ForeignInputs />}

      {/* INPUT RENDER LOGIC Refactor this when have time*/}

      {payoutAccountData?.billing_country_id === 'TR' &&
        payoutAccountData?.billing_preference === 'available' &&
        payoutAccountData?.billing_account_type === 'personal' && <PersonalCompanyInputs />}

      {payoutAccountData?.billing_country_id === 'TR' &&
        payoutAccountData?.billing_preference === 'available' &&
        payoutAccountData?.billing_account_type === 'business' && <LtdCompanyInput />}

      {payoutAccountData?.billing_country_id === 'TR' &&
        payoutAccountData?.billing_preference === 'not_available' &&
        payoutAccountData?.tax_exemption_type === 'available' && <TaxExemptInputs />}

      {payoutAccountData?.billing_country_id === 'TR' &&
        payoutAccountData?.billing_preference === 'not_available' &&
        payoutAccountData?.tax_exemption_type === 'not_available' && <NonTaxExemptInputs />}

      {payoutAccountData &&
      checkPayoutAccountData(payoutAccountData)?.isValid &&
      checkPayoutAccountDiff() ? (
        <div className={styles.line}>
          <button
            className={styles.sendBtn}
            onClick={() => sendPayoutAccountData(payoutAccountData)}
          >
            {t('Buttons.save')}
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default PayoutAccount
