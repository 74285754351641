import styles from './PayoutInformation.module.scss'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import {useContext, useEffect} from 'react'
import PayoutAccountContext from 'context/PayoutAccountContext'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

const PayoutInformation = () => {
  const {getPayoutAccountData, payoutAccountData} = useContext(PayoutAccountContext)
  const navigate = useNavigate()
  const {t} = useTranslation()

  if (payoutAccountData === null) {
    return null
  }

  return (
    <div className={styles.payoutInformation}>
      <div className={styles.payoutInformation_title}>
        {t('PayoutRequestPopup.payoutInformation')}
      </div>
      <div className={styles.payoutInformation_content}>
        <button
          className={styles.payoutInformation_editBtn}
          onClick={() => {
            navigate('/settings/payout-account')
          }}
        >
          <img src={toAbsoluteUrl('/assets/icons/PayoutRequest/editIcon.png')} alt='edit' />
          {t('Buttons.edit')}
        </button>
        <div className={classNames(styles.payoutInformation_contentItem, styles.personName)}>
          <img src={toAbsoluteUrl('/assets/icons/PayoutRequest/personIcon.png')} alt='person' />
          {payoutAccountData.individual_full_name || payoutAccountData.business_full_name}
        </div>
        <div className={styles.payoutInformation_contentItem}>
          <span>{t('PayoutRequestPopup.country')}</span>
          {/* <img
            src='https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
            alt='country'
            className={styles.countryFlag}
          /> */}
          {payoutAccountData.billing_country_name}
        </div>
        <div className={styles.payoutInformation_contentItem}>
          <span>{t('PayoutRequestPopup.city')}</span>
          {payoutAccountData.city_name}
        </div>
        <div className={styles.payoutInformation_contentItem}>
          <span>{t('PayoutRequestPopup.district')}</span>
          {payoutAccountData.district_name}
        </div>
        <div className={styles.payoutInformation_contentItem}>
          <span>{t('PayoutRequestPopup.nationalID')}</span>
          {payoutAccountData.individual_id_number}
        </div>
        <div className={styles.payoutInformation_contentItem}>
          <span>{t('PayoutRequestPopup.payoutMethod')}</span>
          {payoutAccountData.payment_method === 'wise' && (
            <>
              <img
                src={toAbsoluteUrl('/assets/icons/PayoutRequest/wiseIcon.png')}
                alt='method'
                className={styles.methodIcon}
              />
              Wise
            </>
          )}
          {payoutAccountData.payment_method === 'wire' && (
            <>
              <img
                src={toAbsoluteUrl('/assets/icons/PayoutRequest/wireIcon.png')}
                alt='method'
                className={styles.methodIcon}
              />
              IBAN
            </>
          )}
        </div>

        <div className={styles.payoutInformation_contentItem}>
          {payoutAccountData.payment_method === 'wise' && (
            <>
              <span>{t('PayoutRequestPopup.country')}</span>
              {payoutAccountData.wise_email}
            </>
          )}
          {payoutAccountData.payment_method === 'wire' && (
            <>
              <span>IBAN:</span>
              {payoutAccountData.IBAN}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PayoutInformation
