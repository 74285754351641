import styles from './DateFilter.module.scss'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useState, useContext} from 'react'
import ClickAwayListener from 'react-click-away-listener'
import {useTranslation} from 'react-i18next'
import EnumClipInsightsDateRange from 'services/model/enum/enumClipInsightsDateRange'

// Models

interface DateFilterProps {
  value: EnumClipInsightsDateRange
  setValue: (value: EnumClipInsightsDateRange) => void
}

const DateFilter = ({value, setValue}: DateFilterProps) => {
  const [open, setOpen] = useState(false)
  const {t} = useTranslation()
  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false)
      }}
    >
      <div
        className={classNames(styles.dateFilter, {
          [styles.active]: open,
        })}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className={styles.dateFilter_selected}>
          <svg
            stroke='currentColor'
            fill='currentColor'
            strokeWidth={0}
            viewBox='0 0 24 24'
            height='1em'
            width='1em'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z' />
          </svg>
          {value === EnumClipInsightsDateRange.Day
            ? t('DashboardPage.lastXHours', {X: '24'})
            : value === EnumClipInsightsDateRange.Week
            ? t('DashboardPage.lastXDays', {X: '7'})
            : value === EnumClipInsightsDateRange.Month
            ? t('DashboardPage.lastXDays', {X: '28'})
            : ''}
          <img src={toAbsoluteUrl('/assets/icons/Dashboard/dropDownIcon.png')} alt='arrow' />
        </div>
        {open && (
          <div className={styles.dateFilter_list}>
            <div
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: value === EnumClipInsightsDateRange.Day,
              })}
              onClick={() => {
                setValue(EnumClipInsightsDateRange.Day)
              }}
            >
              {t('DashboardPage.lastXHours', {X: '24'})}
            </div>
            <div
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: value === EnumClipInsightsDateRange.Week,
              })}
              onClick={() => {
                setValue(EnumClipInsightsDateRange.Week)
              }}
            >
              {t('DashboardPage.lastXDays', {X: '7'})}
            </div>
            <div
              className={classNames(styles.dateFilter_item, {
                [styles.selected]: value === EnumClipInsightsDateRange.Month,
              })}
              onClick={() => {
                setValue(EnumClipInsightsDateRange.Month)
              }}
            >
              {t('DashboardPage.lastXDays', {X: '28'})}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default DateFilter
