import {useContext, useEffect} from 'react'
import DashboardContext from 'context/DashboardContext'
import classNames from 'classnames'
import styles from '../DashboardTable/DashboardTable.module.scss'

const Campaigns = () => {
  const {
    tableView,
    setTableView,
    selectedBrandId,
    setSelectedCampaignId,
    campaigns,
    selectCampaign,
    dashboardLoading,
  } = useContext(DashboardContext)

  return (
    <>
      {tableView === 'campaigns' && (
        <>
          {/* Table Sub Header */}
          <tr className={classNames(styles.campaignView, styles.header)}>
            <th>Campaign</th>
            <th>Views</th>
            <th>Clicks</th>
            <th>CTR</th>
            <th>RPM</th>
            <th>Revenue</th>
          </tr>

          {/* Actual Campaign Mapping */}

          {campaigns?.campaigns.map((campaign, index) => (
            <tr
              className={styles.campaignView}
              onClick={() => {
                selectCampaign(campaign.campaign_id)
              }}
            >
              <td>{campaign.campaign_name}</td>
              <td>{campaign.views.toLocaleString()}</td>
              <td>{campaign.clicks.toLocaleString()}</td>
              <td>{campaign.CTR}%</td>
              <td>
                {campaign.RPM ? campaign.RPM.toLocaleString() : '0'} {campaign.currency_sign}
              </td>
              <td>
                {campaign.revenue.toLocaleString()} {campaign.currency_sign}
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  )
}

export default Campaigns
