import styles from './Payments.module.scss'
import {useTranslation} from 'react-i18next'
import {loadingHandler} from 'helpers'
import {useLocation} from 'react-router-dom'
import classNames from 'classnames'
import {useState, useEffect, useContext} from 'react'
// Context
import PaymentContext from 'context/PaymentContext'

//COMPONENTS
import Payouts from './components/Payouts/Payouts'
import EarningsHeader from './components/EarningsHeader/EarningsHeader'
import Threshold from './components/Threshold/Threshold'
import Payability from './components/Payability/Payability'
import Loading from 'components/Shared/Loading/Loading'
import PayoutRequest from './components/PayoutRequest/PayoutRequest'

const Payments = () => {
  const location = useLocation()
  const {getPaymentData, getPaymentTreshold, getPayabilityData} = useContext(PaymentContext)
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    loadingHandler([getPaymentData(), getPaymentTreshold(), getPayabilityData()], setPageLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {t} = useTranslation()
  document.title = t('Pages.payments') + ' • LIVAD'
  return (
    <div className={styles.paymentsContainer}>
      {pageLoading ? (
        <Loading />
      ) : (
        <>
          <EarningsHeader />
          <Threshold />
          <Payouts />
          <Payability />
          {location.pathname.includes('payments/payout-request') && <PayoutRequest />}
        </>
      )}
    </div>
  )
}

export default Payments
