import {toAbsoluteUrl} from 'helpers'
import styles from '../KickAuthForm.module.scss'
import classNames from 'classnames'
import {useContext, useState} from 'react'
import {AppLevelContext} from 'context/AppLevelContext'
import {toast} from 'react-toastify'

interface Props {
  view: string
  setView: (view: string) => void
  setTab: (tab: string) => void
}

const EmailLogin = ({view, setView, setTab}: Props) => {
  const {emailSignIn} = useContext(AppLevelContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const checkLoginEnabled = () => {
    if (email && password) {
      return true
    } else {
      return false
    }
  }
  const handleLogin = async (e: any) => {
    e.preventDefault()
    if (isLoading) return
    if (!checkLoginEnabled()) {
      toast.error('Please enter email and password')
      return
    }
    setIsLoading(true)
    const data = await emailSignIn(email, password)
    setIsLoading(false)
    if (data.success) {
      setEmail('')
      setPassword('')
    } else {
      toast.error(data.error)
    }
  }
  return (
    <div className={styles.loginView}>
      <div className={styles.authFormWrap}>
        <div className={styles.inputLine}>
          <button
            className={styles.backBtn}
            onClick={() => {
              setTab('sign')
            }}
          >
            <img src={toAbsoluteUrl('/assets/icons/Auth/backIcon.png')} alt='Back' />
            Back to Platform Selection
          </button>
        </div>
        <div className={styles.platformInfo}>
          <img src={toAbsoluteUrl('/assets/icons/Auth/kick-icon.png')} alt='KICK' />
          You are signing in with KICK
        </div>
        <div className={styles.inputLine}>
          <input
            type='text'
            placeholder='Email'
            id='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className={styles.inputIcon}>
            <img
              src={toAbsoluteUrl('/assets/icons/Auth/email-icon.png')}
              alt='Email'
              draggable='false'
              onContextMenu={(e) => e.preventDefault()}
            />
          </span>
        </div>
        <div className={styles.inputLine}>
          <input
            type='password'
            placeholder='Password'
            id='password'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLogin(e)
              }
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className={styles.inputIcon}>
            <img
              src={toAbsoluteUrl('/assets/icons/Auth/password-icon.png')}
              alt='Password'
              draggable='false'
              onContextMenu={(e) => e.preventDefault()}
            />
          </span>
        </div>
        <div className={styles.authActionLine}>
          <span
            className={styles.btnForget}
            onClick={() => {
              setView('forgotPw')
            }}
          >
            Forgot password?
          </span>
          <button
            onClick={(e) => {
              handleLogin(e)
            }}
            className={classNames(styles.btnLogin, {
              [styles.disabled]: isLoading,
            })}
          >
            {!isLoading ? (
              <span className={styles.btnText}>Sign in</span>
            ) : (
              <span className={styles.isLoading}>
                <img src={toAbsoluteUrl('/assets/icons/loading.gif')} alt='Loading' />
              </span>
            )}
          </button>
        </div>
        <div className={styles.authActionLine}>
          <span className={styles.btnSignup}>
            Don't have an account?{' '}
            <span
              style={{color: '#5d7fff', cursor: 'pointer'}}
              onClick={() => {
                setView('signUp')
              }}
            >
              Sign up
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
export default EmailLogin
