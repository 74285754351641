import { createBaseStreamerApiService } from "./baseStreamerApiService"
import { GetDashboardAdsRequest } from "./model/request/dashboard/getDashboardAdsRequest"
import { GetDashboardCampaignsRequest } from "./model/request/dashboard/getDashboardCampaignsRequest"
import { GetDashboardRequest } from "./model/request/dashboard/getDashboardRequest"
import { GetDashboardAdsResponse } from "./model/response/dashboard/getDashboardAdsResponse"
import { GetDashboardCampaignsResponse } from "./model/response/dashboard/getDashboardCampaignsResponse"
import { GetDashboardResponse } from "./model/response/dashboard/getDashboardResponse"

export const createDashboardService = () => {
  const baseService = createBaseStreamerApiService('/dashboard')

  const getDashboardData = async (req: GetDashboardRequest): Promise<GetDashboardResponse> => {
    const response = await baseService.post<GetDashboardResponse>('/get', req)
    return response.data
  }

  const getDashboardCampaigns = async (req: GetDashboardCampaignsRequest): Promise<GetDashboardCampaignsResponse> => {
    const response = await baseService.post<GetDashboardCampaignsResponse>('/getCampaigns', req)
    return response.data
  }

  const getDashboardAds = async (req: GetDashboardAdsRequest): Promise<GetDashboardAdsResponse> => {
    const response = await baseService.post<GetDashboardAdsResponse>('/getAds', req)
    return response.data
  }

  return {
    getDashboardData,
    getDashboardCampaigns,
    getDashboardAds
  }
  
}
