import styles from './Sidebar.module.scss'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import ClickAwayListener from 'react-click-away-listener'
import {useState} from 'react'
// COMPONENTS
import Navigation from './Navigation/Navigation'

type SidebarProps = {
  sidebarToggle: boolean
  setSidebarToggle: (value: boolean) => void
}

const Sidebar = ({sidebarToggle, setSidebarToggle}: SidebarProps) => {
  return (
    <div
      className={classNames(styles.sidebar, {
        [styles.active]: sidebarToggle,
      })}
    >
      <img className={styles.logo} src={toAbsoluteUrl('/assets/icons/logo.png')} alt='' />
      <div className={styles.close} onClick={() => setSidebarToggle(false)}>
        <img src={toAbsoluteUrl('/assets/icons/closeIcon.png')} alt='' />
      </div>
      {/* NAVIGATION */}
      <Navigation />
      
    </div>
  )
}

export default Sidebar
