import styles from './AffiliateListItem.module.scss'
import AffiliateLink from './Components/AffiliateLink/AffiliateLink'
import AffiliateStatBox from './Components/AffiliateStatBox/AffiliateStatBox'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import {useNavigate} from 'react-router-dom'
import AffiliateCampaignResponseItem from 'services/model/response/campaigns/affiliateCampaignResponseItem'
import {EnumAffiliateCampaignStatus} from 'services/model/enum/enumAffiliateCampaignStatus'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'

interface Props {
  key: number
  item: AffiliateCampaignResponseItem
  apply: () => Promise<any>
}

const AffiliateListItem = ({key, item, apply}: Props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  return (
    <div key={key} className={styles.affiliateListItem}>
      <div className={styles.brandSection}>
        <div className={styles.brandLogo}>
          <img
            src={item.logo ?? toAbsoluteUrl('/assets/icons/defaultBusinessAvatar.png')}
            alt='Brand Logo'
          />
        </div>
        <div className={styles.brandName}>{item.clientName}</div>
      </div>
      <div className={styles.detailSection}>
        {item.status === EnumAffiliateCampaignStatus.Active && (
          <div className={styles.statBoxs}>
            <AffiliateStatBox title={t('CampaignsPage.clicks')} value={item.clicks} />
            <AffiliateStatBox title={t('CampaignsPage.conversions')} value={item.conversions} />
            <AffiliateStatBox title={t('CampaignsPage.revenue')} value={0} />
          </div>
        )}
        {item.status !== EnumAffiliateCampaignStatus.Past &&
        item.status !== EnumAffiliateCampaignStatus.Denied ? (
          <AffiliateLink status={item.status} link={item.url} />
        ) : null}
        <div className={styles.buttons}>
          <button
            className={classNames(styles.button, styles.inspect)}
            onClick={() => navigate(`/campaigns/affiliate-popup/${item.id}`)}
          >
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/inspect-icon.png')} alt='Icon' />
            {t('CampaignsPage.inspect')}
          </button>
          <button
            className={classNames(styles.button, {
              [styles.active]: item.status === EnumAffiliateCampaignStatus.Active,
              [styles.apply]: item.status === EnumAffiliateCampaignStatus.Available,
              [styles.denied]: item.status === EnumAffiliateCampaignStatus.Denied,
              [styles.pending]: item.status === EnumAffiliateCampaignStatus.Pending,
              [styles.past]: item.status === EnumAffiliateCampaignStatus.Past,
            })}
          >
            {item.status === EnumAffiliateCampaignStatus.Available ? (
              <div onClick={apply}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.45496 9.4844L5.76896 8.92265C4.00421 8.33465 3.12146 8.04065 3.12146 7.4999C3.12146 6.9599 4.00421 6.66514 5.76896 6.07714L12.1537 3.94864C13.3957 3.53464 14.0167 3.32765 14.3445 3.6554C14.6722 3.98315 14.4652 4.60415 14.052 5.8454L11.9227 12.2309C11.3347 13.9956 11.0407 14.8784 10.5 14.8784C9.95996 14.8784 9.66521 13.9956 9.07721 12.2309L8.51471 10.5456L11.7802 7.28015C11.9168 7.13869 11.9924 6.94924 11.9907 6.75259C11.989 6.55595 11.9101 6.36784 11.7711 6.22878C11.632 6.08973 11.4439 6.01085 11.2473 6.00914C11.0506 6.00743 10.8612 6.08303 10.7197 6.21965L7.45496 9.4844Z'
                    fill='#FAFAFA'
                  />
                </svg>
                {t('CampaignsPage.apply')}
              </div>
            ) : (
              t(`CampaignsPage.${item.status}`)
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AffiliateListItem
