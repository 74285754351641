import styles from './Payouts.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useContext} from 'react'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'

// Context
import {AppLevelContext} from 'context/AppLevelContext'
import PaymentContext from 'context/PaymentContext'
import {useNavigate} from 'react-router-dom'
import { PayoutDto } from 'services/model/dto/payouts/payoutDto'

const Payouts = () => {
  const {user} = useContext(AppLevelContext)
  const {paymentData} = useContext(PaymentContext)
  const navigate = useNavigate()

  const {t} = useTranslation()

  const formatDate = (date: string) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const d = new Date(date)
    const day = '' + d.getDate()
    const year = d.getFullYear()
    const month = monthNames[d.getMonth()]
    return `${t(`MonthName.${month.toLocaleLowerCase()}`)} ${day}, ${year}`
  }

  return (
    <div className={styles.payouts}>
      <div className={styles.payoutsTitle}>{t('PaymentsPage.payouts')}</div>
      <div className={styles.payoutsWarning}>
        <span className={styles.icon}>
          <img src={toAbsoluteUrl('/assets/icons/Payments/warning_icon.png')} alt='warning' />
        </span>
        {t('PaymentsPage.paymentRequestTiming')}
      </div>
      <div className={styles.payoutsTable}>
        <table>
          <thead>
            <tr>
              <th>{t('PaymentsPage.date')}</th>
              <th>{t('PaymentsPage.payoutStatus')}</th>
              <th>{t('PaymentsPage.grossPayout')}</th>
              <th>{t('PaymentsPage.vat')}</th>
              <th>{t('PaymentsPage.vatWithholding')}</th>
              <th>{t('PaymentsPage.withholding')}</th>
              <th>{t('PaymentsPage.netPayout')}</th>
            </tr>
          </thead>
          <tbody>
            {paymentData?.payouts_data?.map((payout: PayoutDto, index: number) => (
              <tr
                onClick={() => {
                  if (payout.status === 'waiting_for_invoice') {
                    navigate(`/payments/payout-request/${payout.id}`)
                  }
                }}
                key={index}
              >
                <td>{formatDate(payout.requested_timestamp)}</td>
                <td
                  className={classNames({
                    [styles.completed]: payout.status === 'completed',
                    [styles.denied]: payout.status === 'denied',
                    [styles.pending]: payout.status === 'requested',
                    [styles.invoice]: payout.status === 'waiting_for_invoice',
                  })}
                >
                  {t(`PaymentsPage.${payout.status}`)}
                </td>
                <td>
                  + {payout.gross_payment_amount} {user?.currency_sign}
                </td>
                <td>
                  + {payout.VAT} {user?.currency_sign}
                </td>
                <td>
                  {payout.VAT_withholding} {user?.currency_sign}
                </td>
                <td>
                  {payout.withholding} {user?.currency_sign}
                </td>
                <td className={styles.netPayout}>
                  {payout.net_payment_amount} {user?.currency_sign}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Payouts
