//Compare 2 object and return true if they are equal
export const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

// Compare 2 arrays and return true if they are equal
export const compareArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

const checkEmpty = (object) => {
  return !/\S/.test(object) || object === null || object === undefined
}

// Get Page Difference
export const getPageDiff = (initialPageData, pageData) => {
  var pageDiff = {}
  for (const [key, value] of Object.entries(initialPageData)) {
    if (pageData[key] !== value && (!checkEmpty(pageData[key]) || pageData[key] === null)) {
      pageDiff[key] = pageData[key]
    }
  }
  return pageDiff
}
