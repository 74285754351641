import classNames from 'classnames'
import styles from './ClipStatus.module.scss'
import {GetClipInsightsDetailResponse} from 'services/model/response/clipInsights/getClipInsightsDetailResponse'
import {useEffect, useState} from 'react'
import {t} from 'i18next'

export interface ClipStatusI {
  key: 'overlayDisplay' | 'brandMention' | 'brandSafety'
  status?: string
  reason?: string
}

const ClipStatusList: ClipStatusI[] = [
  {
    key: 'overlayDisplay',
  },
  {
    key: 'brandMention',
  },
  {
    key: 'brandSafety',
  },
]

interface ClipStatusProps {
  clipDetail: GetClipInsightsDetailResponse | null
}

const ClipStatus = ({clipDetail}: ClipStatusProps) => {
  const [clipStatusList, setClipStatusList] = useState<ClipStatusI[]>([])
  useEffect(() => {
    if (clipDetail) {
      const newClipStatusList = ClipStatusList.map((clipStatus) => {
        if (clipStatus.key === 'overlayDisplay') {
          return {
            ...clipStatus,
            status:
              clipDetail.clipStatus === 'no-data'
                ? 'noData'
                : clipDetail.clipStatus === 'in-progress'
                ? 'inProgress'
                : clipDetail.overlayDisplayed
                ? 'detected'
                : clipDetail.overlayDisplayed === null
                ? 'inProgress'
                : 'notDetected',
          }
        } else if (clipStatus.key === 'brandMention') {
          return {
            ...clipStatus,
            status:
              clipDetail.clipStatus === 'no-data'
                ? 'noData'
                : clipDetail.clipStatus === 'in-progress'
                ? 'inProgress'
                : clipDetail.isBrandMentioned
                ? 'detected'
                : clipDetail.isBrandMentioned === null
                ? 'inProgress'
                : 'notDetected',
          }
        } else if (clipStatus.key === 'brandSafety') {
          return {
            ...clipStatus,
            status:
              clipDetail.clipStatus === 'no-data'
                ? 'noData'
                : clipDetail.clipStatus === 'in-progress'
                ? 'inProgress'
                : clipDetail.brandSafetyTag
                ? clipDetail.brandSafetyTag
                : clipDetail.brandSafetyTag === null
                ? 'inProgress'
                : undefined,
            reason: clipDetail.brandSafetyCategory ?? undefined,
          }
        } else {
          return clipStatus
        }
      })
      setClipStatusList(newClipStatusList)
    }
  }, [clipDetail])

  return (
    <div className={styles.clipStatus}>
      {clipStatusList.map((clipStatus) => {
        if (
          clipStatus.status === null ||
          clipStatus.status === undefined ||
          clipStatus.status === 'noData' ||
          clipStatus.status === 'inProgress'
        )
          return null
        return (
          <div
            className={classNames(styles.status, {
              [styles.green]: clipStatus.status === 'detected' || clipStatus.status === 'no_risk',
              [styles.red]:
                clipStatus.status === 'notDetected' || clipStatus.status === 'high_risk',
              [styles.yellow]: clipStatus.status === 'low_risk',
              [styles.orange]: clipStatus.status === 'medium_risk',
            })}
            key={clipStatus.key}
          >
            <span className={styles.statusTitle}>
              {clipStatus.key === 'overlayDisplay' ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  fill='none'
                >
                  <g clip-path='url(#clip0_23_34)'>
                    <path
                      d='M0.874892 0.875H13.1249C13.5008 0.875 13.8054 1.1797 13.8054 1.55556V12.4444C13.8054 12.8203 13.5008 13.125 13.1249 13.125H0.874892C0.499034 13.125 0.194336 12.8203 0.194336 12.4444V1.55556C0.194336 1.1797 0.499034 0.875 0.874892 0.875ZM4.95823 2.23611V11.7639H12.4443V2.23611H4.95823Z'
                      fill='#F4F4F4'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_23_34'>
                      <rect width='14' height='14' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              ) : clipStatus.key === 'brandMention' ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  fill='none'
                >
                  <path
                    d='M5.25016 1.16663C7.62287 1.16663 9.582 2.9374 9.87815 5.22951L11.1909 7.29379C11.2774 7.4297 11.26 7.6327 11.0598 7.71845L9.91683 8.20804V9.91663C9.91683 10.561 9.39451 11.0833 8.75016 11.0833H7.58408L7.5835 12.8333H2.3335L2.33364 10.6785C2.33369 9.98966 2.07888 9.33837 1.60754 8.7506C0.966787 7.95155 0.583496 6.93719 0.583496 5.83329C0.583496 3.25596 2.67283 1.16663 5.25016 1.16663ZM12.3397 10.5597L11.3689 9.91249C11.9256 9.07908 12.2502 8.07744 12.2502 7.00002C12.2502 5.92254 11.9256 4.9209 11.3689 4.0875L12.3397 3.44029C13.0202 4.45888 13.4168 5.68311 13.4168 7.00002C13.4168 8.31689 13.0202 9.54108 12.3397 10.5597Z'
                    fill='#F4F4F4'
                  />
                </svg>
              ) : clipStatus.key === 'brandSafety' ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='14'
                  viewBox='0 0 12 14'
                  fill='none'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M5.53931 0.565441C5.83635 0.454098 6.16365 0.454098 6.46069 0.565441L11.0544 2.2881C11.3047 2.3819 11.5203 2.54985 11.6725 2.76949C11.8247 2.98914 11.9063 3.25002 11.9062 3.51725V7.03672C11.9063 8.1336 11.6009 9.20881 11.0242 10.1419C10.4476 11.0749 9.62247 11.829 8.64141 12.3195L6.44034 13.4201C6.30363 13.4884 6.15286 13.524 6 13.524C5.84714 13.524 5.69637 13.4884 5.55966 13.4201L3.35859 12.3195C2.37753 11.829 1.55244 11.0749 0.975795 10.1419C0.39915 9.20881 0.0937264 8.1336 0.09375 7.03672V3.51725C0.0937167 3.25002 0.175259 2.98914 0.327475 2.76949C0.479692 2.54985 0.69533 2.3819 0.945562 2.2881L5.53931 0.565441ZM6 1.7946L1.40625 3.51725V7.03672C1.4064 7.88976 1.64407 8.72591 2.09263 9.45149C2.54119 10.1771 3.18293 10.7634 3.94594 11.1448L6 12.1732V1.7946Z'
                    fill='#F4F4F4'
                  />
                </svg>
              ) : null}
              {t(`ClipInsightsPage.${clipStatus.key}`)}:
              <span className={styles.value}>{t(`ClipInsightsPopup.${clipStatus.status}`)}</span>
            </span>
            <span className={styles.description}>
              <p>
                {clipStatus.key === 'overlayDisplay' && clipStatus.status === 'notDetected'
                  ? t(`ClipInsightsPopup.${clipStatus.key}_notDetected`)
                  : clipStatus.key === 'overlayDisplay'
                  ? t(`ClipInsightsPopup.${clipStatus.key}_detected`)
                  : null}
                {clipStatus.key === 'brandMention' && clipStatus.status === 'notDetected'
                  ? t(`ClipInsightsPopup.${clipStatus.key}_notDetected`)
                  : clipStatus.key === 'brandMention'
                  ? t(`ClipInsightsPopup.${clipStatus.key}_detected`)
                  : null}
                {clipStatus.key === 'brandSafety' && clipStatus.status === 'no_risk'
                  ? t(`ClipInsightsPopup.${clipStatus.key}_detected`)
                  : clipStatus.status === 'low_risk' ||
                    clipStatus.status === 'medium_risk' ||
                    clipStatus.status === 'high_risk'
                  ? t(`ClipInsightsPopup.${clipStatus.key}_notDetected`)
                  : null}
              </p>
              {clipStatus.status !== 'no_risk' && clipStatus.reason && (
                <p>
                  <span className={styles.reasonTitle}>{t(`ClipInsightsPopup.reason`)}: </span>
                  {clipStatus.reason}
                </p>
              )}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default ClipStatus
