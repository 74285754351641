import {Navigate} from 'react-router-dom'

// PAGES
import Campaigns from 'pages/Campaigns/Campaigns'
import Dashboard from 'pages/Dashboard/Dashboard'
import HelpCenter from 'pages/HelpCenter/HelpCenter'
import LeaderBoard from 'pages/LeaderBoard/LeaderBoard'
import LiveConsole from 'pages/LiveConsole/LiveConsole'
import Login from 'pages/Login/Login'
import Payments from 'pages/Payments/Payments'
import TermsPrivacy from 'pages/TermsPrivacy/TermsPrivacy'
import Referrals from 'pages/Referrals/Referrals'
import Settings from 'pages/Settings/Settings'
import Notifications from 'pages/Notifications/Notifications'
import FansGuide from 'pages/FansGuide/FansGuide'
import KickVerification from 'pages/KickVerification/KickVerification'
import ClipInsights from 'pages/ClipInsights/ClipInsights'

// POPUP
import CampaignPopup from 'components/CampaignPopup/CampaignPopup'
import AffiliatePopup from 'components/AffiliatePopup/AffiliatePopup'
import GamePopup from 'components/GamePopup/GamePopup'
import AdPopup from 'components/AdPopup/AdPopup'
import ClipInsightsPopup from 'components/ClipInsightsPopup/ClipInsightsPopup'
// ROUTES
import PrivateRoutes from './PrivateRoutes'
// CONTEXT
import {LeaderboardContextProvider} from 'context/LeaderboardContext'
import {CampaignContextProvider} from 'context/CampaignContext'
import {SettingsContextProvider} from 'context/SettingsContext'
import {LiveNotificationProvider} from 'context/LiveNotificationContext'
import {LiveConsoleProvider} from 'context/LiveConsoleContext'
import {PayoutAccountContextProvider} from 'context/PayoutAccountContext'
import {ReferralsContextProvider} from 'context/ReferralsContext'
import {PaymentContextProvider} from 'context/PaymentContext'
import {DashboardContextProvider} from 'context/DashboardContext'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'
import RestrictedRoute from 'components/Shared/auth/RestrictedRoute'
import {AffiliateCampaignsContextProvider} from 'context/AffiliateCampaignsContext'
import {ReactElement} from 'react'

interface Route {
  path: string
  element: ReactElement
  auth: boolean
  modAllowed: boolean
  modPerms: string[]
}

export const routesPaths: Route[] = [
  {
    path: '/kick-verification',
    element: <KickVerification />,
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/dashboard/*',
    element: (
      <DashboardContextProvider>
        <Dashboard />
      </DashboardContextProvider>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/',
    element: <Navigate to='/dashboard' />,
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/clip-insights',
    element: <ClipInsights />,
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/clip-insights/clip-popup/:adId',
    element: (
      <>
        <ClipInsights />
        <ClipInsightsPopup />
      </>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/live-console',
    element: (
      <LiveConsoleProvider>
        <LiveNotificationProvider>
          <CampaignContextProvider>
            <LiveConsole />
          </CampaignContextProvider>
        </LiveNotificationProvider>
      </LiveConsoleProvider>
    ),
    auth: true,
    modAllowed: true,
    modPerms: [],
  },
  {
    path: '/campaigns',
    element: (
      <CampaignContextProvider>
        <AffiliateCampaignsContextProvider>
          <Campaigns />
        </AffiliateCampaignsContextProvider>
      </CampaignContextProvider>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/leaderboard/',
    element: (
      <>
        <LeaderboardContextProvider>
          <LeaderBoard />
        </LeaderboardContextProvider>
      </>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/payments/*',
    element: (
      <PaymentContextProvider>
        <PayoutAccountContextProvider>
          <Payments />
        </PayoutAccountContextProvider>
      </PaymentContextProvider>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/help-center/*',
    element: <HelpCenter />,
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/notifications/',
    element: (
      <RestrictedRoute isPage={true} permission={'notifications'}>
        <Notifications />
      </RestrictedRoute>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/terms-and-privacy-policy',
    element: <TermsPrivacy />,
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/fans-guide',
    element: <FansGuide />,
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/referrals',
    element: (
      <ReferralsContextProvider>
        <Referrals />
      </ReferralsContextProvider>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/settings/:settingsTab',
    element: (
      <SettingsContextProvider>
        <PayoutAccountContextProvider>
          <Settings />
        </PayoutAccountContextProvider>
      </SettingsContextProvider>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/settings',
    element: <Navigate to={`/settings/${EnumSettingsTab.ProfileDetails}`} />,
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/login',
    element: <Login />,
    auth: false,
    modAllowed: true,
    modPerms: [],
  },
  // POPUP ROUTES
  {
    path: '/campaigns/campaign-popup/:campaignId',
    element: (
      <>
        <CampaignContextProvider>
          <AffiliateCampaignsContextProvider>
            <Campaigns />
            <CampaignPopup />
          </AffiliateCampaignsContextProvider>
        </CampaignContextProvider>
      </>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/campaigns/affiliate-popup/:campaignId',
    element: (
      <>
        <CampaignContextProvider>
          <AffiliateCampaignsContextProvider>
            <Campaigns />
            <AffiliatePopup />
          </AffiliateCampaignsContextProvider>
        </CampaignContextProvider>
      </>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/live-console/campaign-popup/:campaignId',
    element: (
      <>
        <LiveConsoleProvider>
          <LiveNotificationProvider>
            <CampaignContextProvider>
              <LiveConsole />
              <CampaignPopup />
            </CampaignContextProvider>
          </LiveNotificationProvider>
        </LiveConsoleProvider>
      </>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/live-console/game-popup/:gameType',
    element: (
      <>
        <LiveConsoleProvider>
          <LiveNotificationProvider>
            <CampaignContextProvider>
              <LiveConsole />
              <GamePopup />
            </CampaignContextProvider>
          </LiveNotificationProvider>
        </LiveConsoleProvider>
      </>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/live-console/ad-popup/:campaignId',
    element: (
      <>
        <LiveConsoleProvider>
          <LiveNotificationProvider>
            <CampaignContextProvider>
              <LiveConsole />
              <AdPopup />
            </CampaignContextProvider>
          </LiveNotificationProvider>
        </LiveConsoleProvider>
      </>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
  {
    path: '/notifications/campaign-popup/:campaignId',
    element: (
      <>
        <RestrictedRoute permission={'notifications'}>
          <CampaignContextProvider>
            <Notifications />
            <CampaignPopup />
          </CampaignContextProvider>
        </RestrictedRoute>
      </>
    ),
    auth: true,
    modAllowed: false,
    modPerms: [],
  },

  {
    path: '*',
    element: <Navigate to='/' replace={true} />,
    auth: true,
    modAllowed: false,
    modPerms: [],
  },
]

const authCheck = (routes: Route[]) =>
  routes.map((route) => {
    if (route?.auth) {
      route.element = (
        <PrivateRoutes modPerms={route.modPerms} modAllowed={route.modAllowed}>
          {route.element}
        </PrivateRoutes>
      )
    }
    return route
  })

export default authCheck(routesPaths)
