import {
  onIdTokenChanged,
  signInWithCustomToken,
  signOut,
  getAuth,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from 'firebase/auth'
import {createContext, useEffect, useState} from 'react'
import {auth} from '../firebase/firebaseConfig'
import {toast} from 'react-toastify'
import {LoginResponse} from 'services/model/response/loginResponse'
import {createStreamersService} from 'services/streamersService'
import i18next from 'i18next'
import {createAdminService} from 'services/adminService'
import {GetManagedStreamersResponse} from 'services/model/response/admin/getManagedStreamerResponse'
import {ManagedStreamer} from 'services/model/dto/managedStreamer'
import NotificationDto from 'services/model/dto/notifications/notificationDto'
import {signInWithEmailAndPassword} from 'firebase/auth'
import {createIndexService} from 'services/indexService'
import {KickLoginResponse} from 'services/model/response/kickLoginResponse'
import useInterval from 'hooks/useInterval'
import {useNavigate} from 'react-router-dom'

interface AppLevelContextInterface {
  user: LoginResponse | null
  handleLogout: () => void
  permissions: string[]
  userSearchList: GetManagedStreamersResponse | null
  searchStreamers: (search: string) => void
  updateStreamer: (streamerId: string) => void
  setUserSearchList: (value: GetManagedStreamersResponse | null) => void
  mainStreamer: ManagedStreamer | null
  notifications: NotificationDto[] | null
  unreadNotifications: number | null
  getNotifications: () => void
  readNotifications: (notificationIds: number[]) => Promise<void>
  userLoading: boolean
  initialLoading: boolean
  tokenLoading: boolean
  emailSignIn: (
    email: string,
    password: string
  ) => Promise<{
    success: boolean
    error: string | null
  }>
  resetPassword: (email: string) => Promise<{
    success: boolean
    error: string | null
  }>
  getUser: () => Promise<void>
  emailSignup: (
    email: string,
    password: string
  ) => Promise<{
    success: boolean
    error: string | null
  }>
  kickUser: KickLoginResponse | null
  kickUserLoading: boolean
  isPasswordSignIn: boolean | null
  verifyKickAccount: () => Promise<{kickVerified: boolean; kickModVerified: boolean}>
}

export const AppLevelContext = createContext<AppLevelContextInterface>({
  user: null,
  handleLogout: () => {},
  permissions: [],
  userSearchList: null,
  searchStreamers: () => {},
  updateStreamer: () => {},
  setUserSearchList: () => {},
  mainStreamer: null,
  notifications: null,
  unreadNotifications: null,
  getNotifications: () => {},
  readNotifications: async () => {},
  userLoading: true,
  initialLoading: true,
  tokenLoading: true,
  emailSignIn: async () => {
    return {
      success: false,
      error: 'Not implemented',
    }
  },
  resetPassword: async () => {
    return {
      success: false,
      error: 'Not implemented',
    }
  },
  getUser: async () => {},
  emailSignup: async () => {
    return {
      success: false,
      error: 'Not implemented',
    }
  },
  kickUser: null,
  kickUserLoading: true,
  isPasswordSignIn: null,
  verifyKickAccount: async () => {
    return {kickVerified: false, kickModVerified: false}
  },
})

interface AppLevelContextProviderProps {
  children: React.ReactNode
}

export const AppLevelContextProvider = ({children}: AppLevelContextProviderProps) => {
  const streamerService = createStreamersService()
  const adminService = createAdminService()
  const indexService = createIndexService()
  const [token, setToken] = useState<string | null>(null)
  const [user, setUser] = useState<LoginResponse | null>(null)
  const [permissions, setPermissions] = useState<string[]>([])
  const [userSearchList, setUserSearchList] = useState<GetManagedStreamersResponse | null>(null)
  const [mainStreamer, setMainStreamer] = useState<ManagedStreamer | null>(null)
  const [notifications, setNotifications] = useState<NotificationDto[] | null>(null)
  const [unreadNotifications, setUnreadNotifications] = useState<number | null>(null)
  const [userLoading, setUserLoading] = useState<boolean>(true)
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [tokenLoading, setTokenLoading] = useState<boolean>(true)
  const [isPasswordSignIn, setIsPasswordSignIn] = useState<boolean | null>(null)
  const [kickUser, setKickUser] = useState<KickLoginResponse | null>(null)
  const [kickUserLoading, setKickUserLoading] = useState<boolean>(true)
  const [userNotFound, setUserNotFound] = useState<boolean>(false)
  const navigate = useNavigate()

  const getKickUser = async () => {
    setKickUserLoading(true)
    try {
      const response = await streamerService.kickLogin()
      setKickUser(response)
      console.log('kickLogin', response)
    } catch (error) {
      console.log(error)
      handleLogout()
    } finally {
      setKickUserLoading(false)
    }
  }

  const getUser = async () => {
    setUserLoading(true)
    try {
      const user = await streamerService.login()
      setUser(user)
      setKickUser(null)
      setUserNotFound(false)
      setKickUserLoading(false)
      i18next.changeLanguage(user.language_preference)
    } catch (error: any) {
      console.log(error)
      console.log(error?.status)
      console.log(error?.response?.status)
      console.log({
        status: error?.response?.status,
        isPasswordSignIn,
      })
      if (error?.response?.status === 404 && isPasswordSignIn) {
        // await getKickUser()
        setUserNotFound(true)
      } else {
        handleLogout()
      }
    } finally {
      setUserLoading(false)
    }
  }

  const handleLogout = async () => {
    try {
      await signOut(auth)
      setToken(null)
      setUser(null)
      setKickUser(null)
      navigate('/login')
    } catch (error) {
      console.log(error)
    }
  }

  const searchStreamers = async (search: string) => {
    const response = await adminService.searchStreamers(search)
    setUserSearchList(response)
    if (response.self) {
      setMainStreamer(response.self)
    }
  }

  const updateStreamer = async (streamerId: string) => {
    try {
      const response = await adminService.updateAdminStreamer(streamerId)
      console.log('success', response)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const getNotifications = async () => {
    try {
      const response = await streamerService.getNotifications()
      setNotifications(response)
    } catch (error) {
      console.log(error)
    }
  }

  const readNotifications = async (ids: Array<number>) => {
    // Get ids from indexes
    try {
      if (!notifications) {
        return
      }
      await streamerService.readNotifications(ids)
      // Select the notifications that were read and mark them as isRead:true
      const newNotifications = notifications.map((notification, index) => {
        if (ids.includes(notification.id)) {
          return {
            ...notification,
            isRead: true,
          }
        }
        return notification
      })
      setNotifications(newNotifications)
    } catch (error) {
      console.log(error)
    }
  }

  const emailSignup = async (email: string, password: string) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password)
      await indexService.sendEmailVerification()
      return {
        success: true,
        error: null,
      }
    } catch (error: any) {
      console.log('error', error.message)
      if (error.message === 'Firebase: Error (auth/invalid-credential).') {
        return {
          success: false,
          error: 'Invalid email or password',
        }
      } else if (error.message === 'Firebase: Error (auth/invalid-email).') {
        return {
          success: false,
          error: 'Invalid email',
        }
      } else {
        return {
          success: false,
          error: 'Something went wrong',
        }
      }
    }
  }

  const emailSignIn = async (
    email: string,
    password: string
  ): Promise<{
    success: boolean
    error: string | null
  }> => {
    try {
      const data = await signInWithEmailAndPassword(auth, email, password)
      return {
        success: true,
        error: null,
      }
    } catch (error: any) {
      console.log('error', error.message)
      if (error.message === 'Firebase: Error (auth/invalid-credential).') {
        return {
          success: false,
          error: 'Invalid email or password',
        }
      } else if (error.message === 'Firebase: Error (auth/invalid-email).') {
        return {
          success: false,
          error: 'Invalid email',
        }
      } else {
        return {
          success: false,
          error: 'Something went wrong',
        }
      }
    }
  }

  // Function to reset password
  const resetPassword = async (
    email: string
  ): Promise<{
    success: boolean
    error: string | null
  }> => {
    try {
      await sendPasswordResetEmail(auth, email)
      // Handle success - you can set some state here if you want to show a message to the user
      return {
        success: true,
        error: null,
      }
    } catch (error: any) {
      // Handle errors - for example, showing error messages
      if (error.message === 'Firebase: Error (auth/invalid-email).') {
        return {
          success: false,
          error: 'Invalid email',
        }
      } else {
        return {
          success: false,
          error: 'Something went wrong',
        }
      }
    }
  }

  const verifyKickAccount = async () => {
    try {
      const response = await toast.promise(streamerService.verifyKickAccount(), {
        pending: 'Verifying account...',
        error: 'Error verifying account',
      })
      return {
        kickVerified: response.kickVerified,
        kickModVerified: response.kickModVerified,
      }
    } catch (error) {
      console.log(error)
      return {
        kickVerified: false,
        kickModVerified: false,
      }
    }
  }

  useEffect(() => {
    setInitialLoading(true)
    const urlParams = new URLSearchParams(window.location.search)
    const customToken = urlParams.get('customToken')
    const error = urlParams.get('error')
    console.log({
      customToken,
      error,
      urlParams,
    })
    if (customToken) {
      signInWithCustomToken(auth, customToken)
        .then((userCredential) => {
          setInitialLoading(false)
        })
        .catch((error) => {
          console.error('Error signing in with custom token:', error)
        })
    } else {
      setInitialLoading(false)
    }
    if (error) {
      // console.log({error})
      toast.error(error)
      handleLogout()
    }
  }, [])

  useEffect(() => {
    if (initialLoading) return
    const unsubscribeAuth = onIdTokenChanged(auth, async (user) => {
      console.log('user', JSON.stringify(user?.providerId), 'kickUser', kickUser)
      if (!kickUser) {
        setTokenLoading(false)
        if (user) {
          const idTokenResult = await user.getIdTokenResult(true)
          const authToken = idTokenResult.token
          setToken(authToken)
          setIsPasswordSignIn(user.providerData[0]?.providerId === 'password')
        } else {
          setToken(null)
          setUserLoading(false)
        }
      }
    })
    return () => unsubscribeAuth()
  }, [initialLoading])

  useEffect(() => {
    console.log({token, isPasswordSignIn})
    if (token && isPasswordSignIn !== null) {
      if (userNotFound && isPasswordSignIn) {
        getKickUser()
      } else {
        getUser()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isPasswordSignIn, userNotFound])

  useInterval(async () => {
    if (!kickUser) return
    if (!kickUser.emailVerified) {
      const authData = getAuth()
      if (authData.currentUser) {
        await authData.currentUser.reload()
        console.log(new Date(), 'emailVerified', authData.currentUser?.emailVerified)
        if (authData.currentUser?.emailVerified) {
          const idTokenResult = await authData.currentUser.getIdTokenResult(true)
          const authToken = idTokenResult.token
          setToken(authToken)
          await getKickUser()
        }
      }
    }
  }, 3000)

  // useEffect(() => {
  //   if (notifications) {
  //     // Get the count of notifications items that has isRead: false
  //     const unreadNotifications = notifications.filter((notification) => !notification.isRead)
  //     setUnreadNotifications(unreadNotifications.length)
  //   } else {
  //     setUnreadNotifications(null)
  //   }
  // }, [notifications])

  return (
    <AppLevelContext.Provider
      value={{
        user,
        handleLogout,
        permissions,
        userSearchList,
        searchStreamers,
        updateStreamer,
        setUserSearchList,
        mainStreamer,
        notifications,
        unreadNotifications,
        getNotifications,
        readNotifications,
        userLoading,
        initialLoading,
        tokenLoading,
        emailSignIn,
        resetPassword,
        getUser,
        emailSignup,
        kickUser,
        kickUserLoading,
        isPasswordSignIn,
        verifyKickAccount,
      }}
    >
      {children}
    </AppLevelContext.Provider>
  )
}
