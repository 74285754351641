import {useState, createContext, useEffect, useContext, useCallback} from 'react'
import io, {Socket} from 'socket.io-client'
import styles from 'pages/LiveConsole/Components/AdComing/AdComing.module.scss'
import {gsap} from 'gsap'

import GetDonationsForStreamerResponse from 'services/model/response/donation/getDonationsForStreamerResponse'
import {createDonationService} from 'services/donationService'

import UpdateDonationConsentAsStreamerRequest from 'services/model/request/donation/updateDonationConsentRequest'
import useInterval from 'hooks/useInterval'
import {AppLevelContext} from './AppLevelContext'
import _ from 'lodash' // import lodash for the debounce function
import {createGamesService} from 'services/gamesService'
import GameSessionDto from 'services/model/dto/gameSessionDto'
import LiveFeedObjectDto from 'services/model/dto/liveFeedObjectDto'
import {EnumLiveFeedObjectType} from 'services/model/enum/enumLiveFeedObjectType'
import {createLiveConsoleService} from 'services/liveConsoleService'
import {LiveConsoleAdDto} from 'services/model/dto/liveConsoleAdDto'
import EnumRole from 'services/model/enum/enumRole'
import {toast} from 'react-toastify'
import {GetAdIntervalResponseItem} from 'services/model/response/adInterval/getAdIntervalResponse'
import {createAdIntervalService} from 'services/adIntervalService'

export interface LiveNotificationContextInterface {
  donations: GetDonationsForStreamerResponse[] | null
  gameSessions: GameSessionDto[] | null
  ads: LiveConsoleAdDto[] | null
  liveFeed: LiveFeedObjectDto[] | null
  selectedFeed: EnumLiveFeedObjectType | 'all'
  adInterval: GetAdIntervalResponseItem | null
  setAdInterval: (interval: GetAdIntervalResponseItem) => void
  adIntervalInitialized: boolean
  setAdIntervalInitialized: React.Dispatch<React.SetStateAction<boolean>>
  getAdInterval: () => Promise<void>
  debounceUpdateAdInterval: (data: GetAdIntervalResponseItem | null) => Promise<void>
  postedAdInterval: GetAdIntervalResponseItem | null
  setSelectedFeed: (feed: EnumLiveFeedObjectType | 'all') => void
  getAllDonations: () => void
  getNextDonations: () => void
  getAllGames: () => void
  getAllAds: () => void
  updateDonationConsent: (req: UpdateDonationConsentAsStreamerRequest) => Promise<void>
  replayDonation: (donationId: string) => void
}

export const LiveNotificationContext = createContext<LiveNotificationContextInterface>({
  donations: null,
  gameSessions: null,
  ads: null,
  liveFeed: null,
  selectedFeed: 'all',
  adInterval: null,
  setAdInterval: () => {},
  adIntervalInitialized: false,
  setAdIntervalInitialized: () => {},
  getAdInterval: async () => {},
  debounceUpdateAdInterval: async () => {},
  postedAdInterval: null,
  setSelectedFeed: () => {},
  getAllDonations: () => {},
  getNextDonations: () => {},
  getAllGames: () => {},
  getAllAds: () => {},
  updateDonationConsent: async (req: UpdateDonationConsentAsStreamerRequest) => {},
  replayDonation: (donationId: string) => {},
})

interface LiveNotificationContextType {
  children: React.ReactNode
}
export const LiveNotificationProvider = ({children}: LiveNotificationContextType) => {
  const {user} = useContext(AppLevelContext)
  const donationService = createDonationService()
  const gamesService = createGamesService()
  const liveConsoleService = createLiveConsoleService()
  const adIntervalService = createAdIntervalService()
  const [donations, setDonations] = useState<GetDonationsForStreamerResponse[] | null>(null)
  const [gameSessions, setGameSessions] = useState<GameSessionDto[] | null>(null)
  const [ads, setAds] = useState<LiveConsoleAdDto[] | null>(null)
  const [liveFeed, setLiveFeed] = useState<LiveFeedObjectDto[] | null>(null)
  const [selectedFeed, setSelectedFeed] = useState<EnumLiveFeedObjectType | 'all'>('all')
  const [fanSocket, setFanSocket] = useState<Socket | null>(null)
  const [adSocket, setAdSocket] = useState<Socket | null>(null)
  const [adInterval, setAdInterval] = useState<GetAdIntervalResponseItem | null>(null)
  const [postedAdInterval, setPostedAdInterval] = useState<GetAdIntervalResponseItem | null>(null)
  const [adIntervalInitialized, setAdIntervalInitialized] = useState(false)

  const updateDonationConsent = async (req: UpdateDonationConsentAsStreamerRequest) => {
    try {
      const data = await toast.promise(donationService.updateDonationConsent(req), {
        pending: 'Updating donation consent...',
        success: 'Donation consent updated!',
      })
      await debounceUpdateDonations([data])
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      }
    }
  }

  const replayDonation = async (donationId: string) => {
    try {
      await toast.promise(donationService.replayDonation(donationId), {
        pending: 'Replaying donation...',
        success: 'Donation replayed!',
      })
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Failed to replay donation')
      }
    }
  }

  const getAllDonations = async () => {
    try {
      const tempDonations = await donationService.getDonations({})
      // Return a new promise that resolves after debounceUpdateDonations is called
      await debounceUpdateDonations(tempDonations)
      return
    } catch (error) {
      console.log(error)
    }
  }

  const getAllGames = async () => {
    try {
      const tempGameSessions = await gamesService.getGameSessions()
      // Return a new promise that resolves after debounceUpdateDonations is called
      await debounceUpdateGamesSessions(tempGameSessions)
      return
    } catch (error) {
      console.log(error)
    }
  }

  const getAllAds = async () => {
    try {
      const tempAds = await liveConsoleService.getAds()
      // Return a new promise that resolves after debounceUpdateDonations is called
      await debounceUpdateAds(tempAds)
      return
    } catch (error) {
      console.log(error)
    }
  }

  const getNextDonations = async () => {
    // Find the donation with the oldest created date
    if (donations === null) {
      return
    }
    const oldestDonation = donations.reduce((oldest, donation) => {
      if (new Date(donation.createdAt).getTime() < new Date(oldest.createdAt).getTime()) {
        return donation
      }
      return oldest
    }, donations[0])
    const tempDonations = await donationService.getDonations({
      createdBefore: oldestDonation.createdAt,
    })
    await debounceUpdateDonations(tempDonations)
  }

  const getAdInterval = async () => {
    const result = await adIntervalService.getAdInterval({})
    debounceUpdateAdInterval(result.valid)
    debounceUpdatePostedAdInterval(result.posted)
  }

  const debounceUpdatePostedAdInterval = useCallback((data: GetAdIntervalResponseItem | null) => {
    return new Promise<void>((resolve) => {
      setPostedAdInterval((prevState) => {
        return data
      })
      resolve()
    })
  }, [])

  const debounceUpdateAdInterval = useCallback((data: GetAdIntervalResponseItem | null) => {
    return new Promise<void>((resolve) => {
      setAdInterval((prevState) => {
        return data
      })
      resolve()
    })
  }, [])

  useEffect(() => {
    if (!postedAdInterval) return
    const ms = postedAdInterval.adTimestamp
      ? new Date(postedAdInterval.adTimestamp).getTime() + 1000 * 60 * 2 - new Date().getTime()
      : 0
    const timeout = setTimeout(
      () => {
        const lvd = gsap.timeline({
          defaults: {duration: 0.75, ease: 'power1.out'},
        })
        lvd.fromTo(
          '.' + styles.adComing,
          {
            opacity: 1,
            scale: 1,
            animation: 'unset',
          },
          {
            opacity: 0,
            scale: 0,
            animation: 'unset',
            onComplete: () => {
              debounceUpdatePostedAdInterval(null)
              clearTimeout(timeout)
            },
          }
        )
      },
      ms < 0 ? 0 : ms
    )
    return () => clearTimeout(timeout)
  }, [postedAdInterval])

  const debounceUpdateDonations = useCallback(
    (data: GetDonationsForStreamerResponse[]) => {
      return new Promise<void>((resolve) => {
        _.debounce(() => {
          setDonations((prevState) => {
            // If prevState is null, initialize it as an empty array
            const currentHistory = prevState === null ? [] : prevState

            // Insert or update the new donation events, depending on whether they already exist
            // The end result should be sorted by createdAt desc
            const updatedHistory = [...currentHistory]

            data.forEach((newData) => {
              const index = updatedHistory.findIndex((d) => d.id === newData.id)
              if (index === -1) {
                updatedHistory.push(newData)
              } else {
                updatedHistory[index] = newData
              }
            })

            updatedHistory.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            })

            return updatedHistory
          })
          // Resolve the promise after updating the donations state
          resolve()
        }, 300)()
      })
    },
    [] // Empty dependency array ensures that the debounce function is only created once
  )

  const debounceUpdateGamesSessions = useCallback(
    (data: GameSessionDto[]) => {
      return new Promise<void>((resolve) => {
        _.debounce(() => {
          setGameSessions((prevState) => {
            // If prevState is null, initialize it as an empty array
            const currentHistory = prevState === null ? [] : prevState

            // Insert or update the new donation events, depending on whether they already exist
            // The end result should be sorted by createdAt desc
            const updatedSessions = [...currentHistory]

            data.forEach((newData) => {
              const index = updatedSessions.findIndex((d) => d.uuid === newData.uuid)
              if (index === -1) {
                updatedSessions.push(newData)
              } else {
                updatedSessions[index] = newData
              }
            })

            updatedSessions.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            })

            return updatedSessions
          })
          // Resolve the promise after updating the donations state
          resolve()
        }, 300)()
      })
    },
    [] // Empty dependency array ensures that the debounce function is only created once
  )

  const debounceUpdateAds = useCallback(
    (data: LiveConsoleAdDto[]) => {
      return new Promise<void>((resolve) => {
        _.debounce(() => {
          setAds((prevState) => {
            // If prevState is null, initialize it as an empty array
            const currentHistory = prevState === null ? [] : prevState

            // Insert or update the new donation events, depending on whether they already exist
            // The end result should be sorted by createdAt desc
            const updatedAds = [...currentHistory]

            data.forEach((newData) => {
              const index = updatedAds.findIndex((d) => d.adId === newData.adId)
              if (index === -1) {
                updatedAds.push(newData)
              } else {
                updatedAds[index] = newData
              }
            })

            updatedAds.sort((a, b) => {
              return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            })

            return updatedAds
          })
          // Resolve the promise after updating the donations state
          resolve()
        }, 300)()
      })
    },
    [] // Empty dependency array ensures that the debounce function is only created once
  )

  const debounceUpdateLiveFeed = useCallback(
    _.debounce(() => {
      const donationFeed: LiveFeedObjectDto[] = donations
        ? donations.map((d) => ({donation: d, type: EnumLiveFeedObjectType.Donation}))
        : []

      const gameSessionFeed: LiveFeedObjectDto[] = gameSessions
        ? gameSessions.map((g) => ({gameSession: g, type: EnumLiveFeedObjectType.GameSession}))
        : []

      const adFeed: LiveFeedObjectDto[] = ads
        ? ads.map((a) => ({ad: a, type: EnumLiveFeedObjectType.Ad}))
        : []

      const combinedFeed = [...donationFeed, ...gameSessionFeed, ...adFeed].sort((a, b) => {
        const aCreatedAt =
          a.type === EnumLiveFeedObjectType.Donation
            ? a.donation.createdAt
            : a.type === EnumLiveFeedObjectType.GameSession
            ? a.gameSession.createdAt
            : a.ad.timestamp
        const bCreatedAt =
          b.type === EnumLiveFeedObjectType.Donation
            ? b.donation.createdAt
            : b.type === EnumLiveFeedObjectType.GameSession
            ? b.gameSession.createdAt
            : b.ad.timestamp

        return new Date(bCreatedAt).getTime() - new Date(aCreatedAt).getTime()
      })

      setLiveFeed(combinedFeed)
    }, 300),
    [donations, gameSessions, ads]
  )

  useEffect(() => {
    const tmpFanSocket = io(process.env.REACT_APP_FANS_API_URL || '')
    setFanSocket(tmpFanSocket)
    if (user?.streamer_id) {
      tmpFanSocket.on(
        `donation-streamer-${user.streamer_id}`,
        (data: GetDonationsForStreamerResponse) => {
          // Handle the incoming donation event data here
          console.log('Donation event received:', data)
          // Find the donation for the data
          const donationIndex = donations?.findIndex((d) => d.id === data.id)
          // If the donation exists

          debounceUpdateDonations([data])
        }
      )
    }
    if (user?.role === EnumRole.MOD && !user?.self) {
      return () => {
        tmpFanSocket.disconnect()
      }
    } else {
      const tmpAdScoket = io(process.env.REACT_APP_ADS_API_URL || '')
      setAdSocket(tmpAdScoket)
      if (user?.streamer_id) {
        tmpAdScoket.on(`ad-streamer-${user.streamer_id}`, (data: LiveConsoleAdDto) => {
          // Handle the incoming donation event data here
          console.log('Ad event received:', data)
          debounceUpdateAds([data])
        })
        tmpAdScoket.on(`gameSession-streamer-${user.streamer_id}`, (data: GameSessionDto) => {
          // Handle the incoming donation event data here
          console.log('Ad event received:', data)
          debounceUpdateGamesSessions([data])
        })
        tmpAdScoket.on(
          `adInterval-streamer-${user.streamer_id}`,
          (data: GetAdIntervalResponseItem) => {
            // Handle the incoming donation event data here
            console.log('Ad interval event received:', data)
            if (data.status === 'posted') {
              debounceUpdatePostedAdInterval(data)
            } else if (data.status === 'valid') {
              setAdIntervalInitialized(false)
              debounceUpdateAdInterval(data)
            }
          }
        )
      }
      return () => {
        tmpFanSocket.disconnect()
        tmpAdScoket.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (adInterval && postedAdInterval && adInterval.id === postedAdInterval.id) {
      debounceUpdateAdInterval(null)
    }
  }, [adInterval, postedAdInterval])

  useEffect(() => {
    if (donations && gameSessions && ads) {
      debounceUpdateLiveFeed()
    }
  }, [donations, gameSessions, ads, debounceUpdateLiveFeed])

  useEffect(() => {
    if (user?.role === EnumRole.MOD && !user.self) {
      setSelectedFeed(EnumLiveFeedObjectType.Donation)
    }
  }, [user?.role, user?.self])

  return (
    <LiveNotificationContext.Provider
      value={{
        donations,
        gameSessions,
        ads,
        adInterval,
        setAdInterval,
        adIntervalInitialized,
        setAdIntervalInitialized,
        getAdInterval,
        debounceUpdateAdInterval,
        postedAdInterval,
        liveFeed,
        selectedFeed,
        setSelectedFeed,
        getAllDonations,
        getNextDonations,
        getAllGames,
        getAllAds,
        updateDonationConsent,
        replayDonation,
      }}
    >
      {children}
    </LiveNotificationContext.Provider>
  )
}

export default LiveNotificationContext
