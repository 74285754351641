import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
// STYLES
import styles from '../../ListView.module.scss'
import CampaignContext, { Campaign } from 'context/CampaignContext'
import { useContext, useEffect } from 'react'

type ListViewHeaderProps = {
  variant?: 'featured' | ''
}

const ListViewHeader = ({variant = ''}: ListViewHeaderProps) => {
  const {t} = useTranslation()
  const {campaignList} = useContext(CampaignContext)

  useEffect(() => {
    console.log("campaignList: ", campaignList)
  }, [campaignList])

  return (
    <tbody>
      <tr className={styles.header}>
        <td>{t('CampaignsPage.brand')}</td>
        <td>{t('CampaignsPage.campaignName')}</td>
        <td>{t('CampaignsPage.type')}</td>
        <td>{t('CampaignsPage.remainingTime')}</td>
        {variant !== 'featured' && <td>{t('CampaignsPopup.rpm')}</td>}
        {variant !== 'featured' && campaignList?.some((campaign)=> campaign.CPM_bonus_percentage > 0) && <td>{t('CampaignsPopup.rpmWithBonus')}</td>}
        <td></td>
      </tr>
    </tbody>
  )
}

export default ListViewHeader
