import styles from './ClipInsightsPopup.module.scss'
import ClipHeader from './Components/ClipHeader/ClipHeader'
import ClickAwayListener from 'react-click-away-listener'
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState, useRef} from 'react'
import TabSwitcher from './Components/TabSwitcher/TabSwitcher'
import Chat from './Components/Chat/Chat'
import Labels from './Components/Labels/Labels'
import ClipStatus from './Components/ClipStatus/ClipStatus'
import TranscriptSubtitle from './Components/TranscriptSubtitle/TranscriptSubtitle'
import AdSubtitleDto from 'services/model/dto/ad/adSubtitleDto'
import {GetClipInsightsDetailResponse} from 'services/model/response/clipInsights/getClipInsightsDetailResponse'
import {createClipInsightsService} from 'services/clipInsightsService'
import {toast} from 'react-toastify'
import Loading from 'components/Shared/Loading/Loading'
import {AdChatMessageDto} from 'services/model/dto/ad/adChatMessageDto'
import {GetClipInsightsLabelsResponseItem} from 'services/model/response/clipInsights/getClipInsightsLabelsResponse'
import {GetClipInsightsProblemsResponseItem} from 'services/model/response/clipInsights/getClipInsightsProblemsResponse'

export enum EnumPopupTabs {
  chat = 'chat',
  labels = 'labels',
  transcriptSubtitle = 'transcriptSubtitle',
}

const Tabs = [
  {value: EnumPopupTabs.chat},
  {value: EnumPopupTabs.labels},
  {value: EnumPopupTabs.transcriptSubtitle},
]

const ClipInsightsPopup = () => {
  const navigate = useNavigate()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [clipDetail, setClipDetail] = useState<GetClipInsightsDetailResponse | null>(null)
  const [chatList, setChatList] = useState<AdChatMessageDto[] | null>(null)
  const [chatMessagesLoading, setChatMessagesLoading] = useState(false)
  const [adSubtitles, setAdSubtitles] = useState<AdSubtitleDto[] | null>(null)
  const [adSubtitleLoading, setAdSubtitleLoading] = useState(false)
  const [labels, setLabels] = useState<GetClipInsightsLabelsResponseItem[] | null>(null)
  const [problems, setProblems] = useState<GetClipInsightsProblemsResponseItem[] | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [currentVideoTime, setCurrentVideoTime] = useState(0)
  const [activeTab, setActiveTab] = useState<string>(EnumPopupTabs.chat)
  const {adId} = useParams()

  const clipInsightsService = createClipInsightsService()

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const fetchData = async () => {
    if (!adId) return
    setIsLoading(true)
    try {
      const response = await clipInsightsService.getClipInsightsDetail({
        adId: Number(adId),
      })
      setClipDetail(response)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      toast.error('Failed to fetch clip insight details')
      navigate('/clip-insights')
    }
  }

  const fetchAdSubtitles = async () => {
    if (!adId) return
    setAdSubtitleLoading(true)
    try {
      const response = await clipInsightsService.getClipInsightsAdSubtitles({
        adId: Number(adId),
      })
      setAdSubtitles(response)
      setAdSubtitleLoading(false)
    } catch {
      setAdSubtitleLoading(false)
      toast.error('Failed to fetch ad subtitles')
    }
  }

  const fetchChatMessages = async () => {
    if (!adId) return
    setChatMessagesLoading(true)
    try {
      const response = await clipInsightsService.GetClipInsightsChatMessages({
        adId: Number(adId),
      })
      setChatList(response)
      setChatMessagesLoading(false)
    } catch {
      setChatMessagesLoading(false)
      toast.error('Failed to fetch chat messages')
    }
  }

  const fetchLabels = async () => {
    if (!adId) return
    try {
      const response = await clipInsightsService.GetClipInsightsLabels({
        adId: Number(adId),
      })
      setLabels(response.data)
    } catch {
      toast.error('Failed to fetch labels')
    }
  }

  const fetchProblems = async () => {
    if (!adId) return
    try {
      const response = await clipInsightsService.GetClipInsightsProblems({
        adId: Number(adId),
      })
      setProblems(response.data)
    } catch {
      toast.error('Failed to fetch problems')
    }
  }

  useEffect(() => {
    if (!adId) return
    if (activeTab === EnumPopupTabs.transcriptSubtitle && adSubtitles === null) {
      fetchAdSubtitles()
    }
    if (activeTab === EnumPopupTabs.chat && chatList === null) {
      fetchChatMessages()
    }
    if (activeTab === EnumPopupTabs.labels && labels === null) {
      fetchLabels()
    }
    if (activeTab === EnumPopupTabs.labels && problems === null) {
      fetchProblems()
    }
  }, [activeTab])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current) {
        setCurrentVideoTime(videoRef.current.currentTime)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  if (!adId) return null

  return (
    <div className={styles.myClipsPopup}>
      <ClickAwayListener onClickAway={() => navigate('/clip-insights')}>
        <div className={styles.container}>
          {(isLoading || !clipDetail) && (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          )}
          {!isLoading && clipDetail && (
            <>
              <ClipHeader clipDetail={clipDetail} />
              <div className={styles.section}>
                <div className={styles.videoContainer}>
                  <video
                    ref={videoRef}
                    className={styles.video}
                    controls
                    autoPlay
                    muted
                    src={clipDetail.clipLink ?? undefined}
                    crossOrigin='anonymous'
                  />
                  <ClipStatus clipDetail={clipDetail} />
                </div>
                <div className={styles.tabContainer}>
                  <TabSwitcher activeTab={activeTab} setActiveTab={handleTabChange} tabs={Tabs} />
                  {activeTab === EnumPopupTabs.chat && <Chat chatList={chatList} />}
                  {activeTab === EnumPopupTabs.labels && (
                    <Labels labels={labels} problems={problems} />
                  )}
                  {activeTab === EnumPopupTabs.transcriptSubtitle && (
                    <TranscriptSubtitle
                      adSubtitles={adSubtitles}
                      currentVideoTime={currentVideoTime}
                      setCurrentVideoTime={setCurrentVideoTime}
                      videoRef={videoRef}
                      loading={adSubtitleLoading}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default ClipInsightsPopup
