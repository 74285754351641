import styles from './PayoutRequest.module.scss'
//COMPONENTS
import PayoutInformation from './components/PayoutInformation/PayoutInformation'
import BillingInformation from './components/BillingInformation/BillingInformation'
import Amount from './components/Amount/Amount'
import Result from './components/Result/Result'
import ClickAwayListener from 'react-click-away-listener'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
// Context
import PayoutAccountContext from 'context/PayoutAccountContext'
import {useContext, useEffect, useState} from 'react'
import {loadingHandler, toAbsoluteUrl} from 'helpers'
import Loading from 'components/Shared/Loading/Loading'
import PaymentContext from 'context/PaymentContext'
import PayoutAmountsDto from 'services/model/dto/payoutAmountsDto'
import { createPayoutService } from 'services/payoutService'
import { toast } from 'react-toastify'
import GetPayoutRequestResponse from 'services/model/response/payouts/getPayoutRequestResponse'



const PayoutRequest = () => {
  const payoutService = createPayoutService();
  const {getPayoutAccountData, payoutAccountData, getTaxDocuments, taxRates} = useContext(PayoutAccountContext)
  const {paymentData, paymentTreshold, getPaymentData} = useContext(PaymentContext)
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [grossAmount, setGrossAmount] = useState<number | null>(null);
  const [amounts, setAmounts] = useState<PayoutAmountsDto | null>(null);
  const [payoutRequest, setPayoutRequest] = useState<GetPayoutRequestResponse | null>(null);
  const [invoiceProgress, setInvoiceProgress] = useState<number |null>(null);
  const navigate = useNavigate()
  const {t} = useTranslation()
  const location = useLocation()
  const payoutRequestId = parseInt(location.pathname.split('/')[3] ?? '')
  const [sendingPayoutRequest, setSendingPayoutRequest] = useState<boolean>(false)


  const getPayoutRequest = async () => {
    if (isNaN(payoutRequestId)) {
      return;
    }
    const tmp = await payoutService.getPayoutRequest(payoutRequestId);
    setPayoutRequest(tmp);
  }

  const uploadPayoutInvoice = async (file: File) => {
    if (payoutRequest?.id === undefined) {
      return;
    }
    const tmp = await payoutService.uploadPayoutRequestInvoice(payoutRequest.id, file, setInvoiceProgress);
    setInvoiceProgress(null);
    setPayoutRequest({
      ...payoutRequest,
      invoice: tmp
    })
    getPaymentData();
  }

  useEffect(() => {
    loadingHandler([
      getPayoutAccountData(),
      getTaxDocuments(),
      getPayoutRequest()
    ], setLoading)
    .then(() => {
      setDataLoaded(true)
    })
    .catch(error=> console.log(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    if (dataLoaded && !payoutAccountData) {
      navigate('/settings/payout-account')
    }
  }, [dataLoaded, payoutAccountData])

  useEffect(() => {
    console.log({taxRates, amounts})
  }, [amounts, taxRates])

  useEffect(() => {
    if (paymentData?.balance !== undefined && isNaN(payoutRequestId)) {
      setGrossAmount(paymentData.balance);
    } else if (payoutRequest) {
      setGrossAmount(payoutRequest.grossAmount);
    }
  }, [paymentData?.balance, payoutRequestId, payoutRequest])

  useEffect(() => {
    if (grossAmount !== null && taxRates) {
      setAmounts({
        VAT: grossAmount * taxRates?.VAT_rate,
        VAT_withholding: grossAmount * taxRates?.VAT_withholding_rate,
        withholding: grossAmount * taxRates?.withholding_rate,
        netAmount: grossAmount + grossAmount * taxRates?.VAT_rate + grossAmount * taxRates?.VAT_withholding_rate + grossAmount * taxRates?.withholding_rate
      })
    }
  }, [grossAmount, taxRates])

  return (
    <div className={styles.outterContainer}>
      <ClickAwayListener
        onClickAway={() => {
          navigate('/payments')
        }}
      >
        <div className={styles.payoutRequest}>
          {loading || !payoutAccountData ? (
            <Loading />
          ) : (
            <>
              <div className={styles.payoutRequest_title}>
                {t('PayoutRequestPopup.payoutRequest')}
                <button
                  className={styles.closePopupBtn}
                  onClick={() => {
                    navigate('/payments')
                  }}
                >
                  <img src={toAbsoluteUrl('/assets/icons/closeIcon.png')} alt='close' />
                </button>
              </div>
              <PayoutInformation />
              {payoutAccountData.billing_preference === 'available' && <BillingInformation />}
              {
                grossAmount !== null ? (
                  <Amount grossAmount={grossAmount} setGrossAmount={setGrossAmount} disabled={payoutRequest !== null}  />
                ): null
              }
              {
                amounts && grossAmount ? (
                  <>
                    <Result grossAmount={grossAmount} amounts={amounts}/>

                    {
                      payoutRequest === null && paymentTreshold && grossAmount >= paymentTreshold?.threshold_amount ? (
                        <button onClick={async () => {
                          if (sendingPayoutRequest) {
                            return;
                          }
                          setSendingPayoutRequest(true);
                          try {
                            await toast.promise(payoutService.sendPayoutRequest(grossAmount), {
                              pending: 'Sending payout request...',
                              success: "You've successfully sent a payout request",
                              error: 'Failed to send payout request'
                            })
                            await toast.promise(getPaymentData(), {
                              pending: 'Reloading payment data...',
                              success: 'Payment data reloaded',
                              error: 'Failed to reload payment data'
                            })
                            
                            navigate('/payments')
                          } catch(error) {
                            console.log(error)
                          } finally {
                            setSendingPayoutRequest(false);
                          }

                        }} className={styles.sendRequestBtn}>{t('Buttons.sendRequest')}</button>
                      ): null
                    }

                    {

                    }
                  </>
                ): null
              }
              {
                payoutRequest !== null ? (
                  <div className={styles.line}>
                    <div className={styles.label}>{t('PayoutRequestPopup.uploadInvoice')}</div>
                    {invoiceProgress === null ? (
                      <label className={styles.fileUpload} htmlFor='fileTwo'>
                        <input
                          type='file'
                          id='fileTwo'
                          name='file'
                          onChange={(e) => {
                            console.log(e)
                            if (e.target.files?.length) {
                              uploadPayoutInvoice(e.target.files[0])
                            }
                          }}
                        />
                        <img src={toAbsoluteUrl('/assets/icons/Settings/uploadFile.png')} alt='upload' />
                        {t('SettingsPage.uploadPdfFile')}
                      </label>
                    ) : null}
                    {payoutRequest.invoice || invoiceProgress ? (
                      <div className={styles.uploadingStatus}>
                        <img src={toAbsoluteUrl('/assets/icons/Settings/uploadedFile.png')} alt='icon' />
                        <div className={styles.uploadContent}>
                          <div className={styles.fileContent}>
                            {payoutRequest.invoice ? (
                              <div className={styles.fileName}>{payoutRequest.invoice?.fileName}</div>
                            ) : null}
                            {/* TODO: If file is uploaded, replace text like this : 'Uploaded' */}
                            {invoiceProgress ? (
                              <div className={styles.uploaded}>{invoiceProgress}%</div>
                            ) : null}
                          </div>
                          {/* TODO: If file is uploaded, hide this progress bar */}
                          {invoiceProgress ? (
                            <div className={styles.progressBar}>
                              <div
                                className={styles.progress}
                                style={{
                                  width: `${invoiceProgress}%`,
                                }}
                              ></div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ): null
              }
            </>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default PayoutRequest
