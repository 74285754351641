import React, {useContext} from 'react'
import styles from './ScoreInfoBox.module.scss'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from 'helpers'

// CONTEXT
import LeaderboardContext from 'context/LeaderboardContext'

const ScoreInfoBox = () => {
  const {leaderboardData, selectedIndex} = useContext(LeaderboardContext)
  const {t} = useTranslation()
  return (
    <div
      className={styles.scoreInfo_box}
      style={{
        background: leaderboardData?.[selectedIndex]?.contest?.brandColor1
          ? `linear-gradient(180deg, #252525 0%, ` +
            leaderboardData?.[selectedIndex]?.contest?.brandColor1 +
            ` 100%)`
          : undefined,
      }}
    >
      <h3 className={styles.scoreInfo_title}>
        <img src={toAbsoluteUrl('/assets/icons/Leaderboard/infoIcon.png')} alt='' />
        {t('LeaderboardPage.howIsMyScoreCalculated')}
      </h3>
      <p className={styles.scoreInfo_info}>
        {leaderboardData?.[selectedIndex]?.contest?.scoreDescription ??
          t('LeaderboardPage.scoreDeterminedCampaignParticipation')}
      </p>
    </div>
  )
}

export default ScoreInfoBox
