import classNames from 'classnames'
import styles from './Labels.module.scss'
import {useEffect, useState} from 'react'
import LabelSection from './Components/LabelSection/LabelSection'
import {GetClipInsightsLabelsResponseItem} from 'services/model/response/clipInsights/getClipInsightsLabelsResponse'
import Loading from 'components/Shared/Loading/Loading'
import {GetClipInsightsProblemsResponseItem} from 'services/model/response/clipInsights/getClipInsightsProblemsResponse'
import {createClipInsightsService} from 'services/clipInsightsService'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {t} from 'i18next'

export interface Label extends Partial<GetClipInsightsLabelsResponseItem> {
  id: number
  translationKey: string
}

export interface Problem extends Partial<GetClipInsightsProblemsResponseItem> {
  id: number
  translationKey: string
}

const labelList: Label[] = [
  {
    id: 6,
    translationKey: 'mentionedBrandOrProduct',
  },
  {
    id: 7,
    translationKey: 'explainedCampaignAccordingToBrief',
  },
  {
    id: 8,
    translationKey: 'sharedPersonalExperience',
  },
  {
    id: 9,
    translationKey: 'browsedThroughWebsiteOrApp',
  },
  {
    id: 10,
    translationKey: 'showedProduct',
  },
]

const problemList: Problem[] = [
  {
    id: 1,
    translationKey: 'displayProblem',
  },
  {
    id: 2,
    translationKey: 'chatProblem',
  },
]

interface LabelsProps {
  labels: GetClipInsightsLabelsResponseItem[] | null
  problems: GetClipInsightsProblemsResponseItem[] | null
}

const Labels = ({labels, problems}: LabelsProps) => {
  const [activeSection, setActiveSection] = useState<'labels' | 'problem'>('labels')
  const [combinedLabels, setCombinedLabels] = useState<Label[]>([])
  const [newLabels, setNewLabels] = useState<Label[]>([])
  const [combinedProblems, setCombinedProblems] = useState<Problem[]>([])
  const [newProblems, setNewProblems] = useState<Problem[]>([])
  const [labelsReady, setLabelsReady] = useState(false)
  const [problemsReady, setProblemsReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {adId} = useParams()
  const clipInsightsService = createClipInsightsService()

  useEffect(() => {
    if (labels && labels.length > 0 && !labelsReady) {
      setCombinedLabels(
        labelList.map((label) => ({
          id: label.id,
          translationKey: label.translationKey,
          selected: labels.some((l) => l.id === label.id && l.selected),
        }))
      )
      setLabelsReady(true)
    }
  }, [labels])

  useEffect(() => {
    setNewLabels(combinedLabels)
  }, [combinedLabels])

  useEffect(() => {
    if (problems && problems.length > 0 && !problemsReady) {
      setCombinedProblems(
        problemList.map((problem) => ({
          id: problem.id,
          translationKey: problem.translationKey,
          selected: problems.some((p) => p.id === problem.id && p.selected),
        }))
      )
      setProblemsReady(true)
    }
  }, [problems])

  useEffect(() => {
    setNewProblems(combinedProblems)
  }, [combinedProblems])

  const handleUpdateLabels = async () => {
    if (!adId) return
    setIsLoading(true)
    const selectedLabels = newLabels.filter((label) => label.selected).map((label) => label.id)
    await toast.promise(
      async () => {
        await clipInsightsService.UpdateClipInsightsLabels({
          adId: Number(adId),
          labels: selectedLabels.map((label) => ({
            id: label,
            selected: true,
          })),
        })
      },
      {
        pending: 'Updating labels...',
        success: 'Labels updated successfully',
        error: 'Failed to update labels',
      }
    )
    setIsLoading(false)
  }

  const handleUpdateProblems = async () => {
    if (!adId) return
    setIsLoading(true)
    const selectedProblems = newProblems
      .filter((problem) => problem.selected)
      .map((problem) => problem.id)
    await toast.promise(
      async () => {
        await clipInsightsService.UpdateClipInsightsProblems({
          adId: Number(adId),
          problems: selectedProblems.map((problem) => ({
            id: problem,
            selected: true,
          })),
        })
      },
      {
        pending: 'Updating problems...',
        success: 'Problems updated successfully',
        error: 'Failed to update problems',
      }
    )
    setIsLoading(false)
  }

  if (!labels || !labelsReady || !problemsReady) return <Loading width='15' height='15' />
  return (
    <div className={styles.labels}>
      <div className={styles.sectionInfo}>
        <div
          className={classNames(styles.icon, {
            [styles.problem]: activeSection === 'problem',
          })}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
          >
            <path
              d='M7.89605 11.3333C8.0825 11.3333 8.23889 11.2693 8.36523 11.1413C8.49157 11.0133 8.55452 10.8551 8.55408 10.6667V7.99999C8.55408 7.8111 8.49091 7.65287 8.36457 7.52532C8.23823 7.39776 8.08206 7.33376 7.89605 7.33332C7.71005 7.33287 7.55388 7.39687 7.42754 7.52532C7.3012 7.65376 7.23803 7.81199 7.23803 7.99999V10.6667C7.23803 10.8555 7.3012 11.014 7.42754 11.142C7.55388 11.27 7.71005 11.3338 7.89605 11.3333ZM7.89605 5.99999C8.0825 5.99999 8.23889 5.93599 8.36523 5.80799C8.49157 5.67999 8.55452 5.52176 8.55408 5.33332C8.55364 5.14487 8.49047 4.98665 8.36457 4.85865C8.23867 4.73065 8.0825 4.66665 7.89605 4.66665C7.70961 4.66665 7.55344 4.73065 7.42754 4.85865C7.30164 4.98665 7.23847 5.14487 7.23803 5.33332C7.23759 5.52176 7.30076 5.68021 7.42754 5.80865C7.55432 5.9371 7.71049 6.00087 7.89605 5.99999ZM7.89605 14.6C7.81928 14.6 7.748 14.5944 7.6822 14.5833C7.61639 14.5722 7.55059 14.5555 7.48479 14.5333C6.00423 14.0333 4.82526 13.1082 3.94789 11.758C3.07052 10.4078 2.63184 8.9551 2.63184 7.39999V4.24999C2.63184 3.97221 2.71146 3.72221 2.8707 3.49999C3.02994 3.27776 3.23547 3.11665 3.48727 3.01665L7.43544 1.51665C7.58898 1.4611 7.74252 1.43332 7.89605 1.43332C8.04959 1.43332 8.20313 1.4611 8.35667 1.51665L12.3048 3.01665C12.5571 3.11665 12.7628 3.27776 12.9221 3.49999C13.0813 3.72221 13.1607 3.97221 13.1603 4.24999V7.39999C13.1603 8.95554 12.7216 10.4084 11.8442 11.7587C10.9668 13.1089 9.78788 14.0338 8.30732 14.5333C8.24152 14.5555 8.17572 14.5722 8.10991 14.5833C8.04411 14.5944 7.97282 14.6 7.89605 14.6ZM7.89605 13.2667C9.03664 12.9 9.97981 12.1667 10.7256 11.0667C11.4713 9.96665 11.8442 8.74443 11.8442 7.39999V4.24999L7.89605 2.74999L3.94789 4.24999V7.39999C3.94789 8.74443 4.32077 9.96665 5.06654 11.0667C5.8123 12.1667 6.75547 12.9 7.89605 13.2667Z'
              fill='#5D7FFF'
            />
          </svg>
        </div>
        <p>
          {activeSection === 'labels'
            ? `${t(`ClipInsightsPopup.clipSubmissionExplanation`)}`
            : activeSection === 'problem'
            ? `${t(`ClipInsightsPopup.adDisplayOrChatLinkProblem`)}`
            : null}
        </p>
      </div>
      <div className={styles.container}>
        <LabelSection
          labels={newLabels}
          setLabels={setNewLabels}
          problems={newProblems}
          setProblems={setNewProblems}
          activeSection={activeSection}
        />
      </div>
      <div className={styles.actionSection}>
        <button
          className={styles.switchBtn}
          onClick={() => {
            setActiveSection(activeSection === 'labels' ? 'problem' : 'labels')
          }}
        >
          {activeSection === 'labels' ? (
            <>
              <svg
                stroke='currentColor'
                fill='currentColor'
                strokeWidth={0}
                viewBox='0 0 448 512'
                height='1em'
                width='1em'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32V64 368 480c0 17.7 14.3 32 32 32s32-14.3 32-32V352l64.3-16.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L64 48V32z' />
              </svg>
              {t(`ClipInsightsPopup.reportProblem`)}
            </>
          ) : (
            <>
              <svg
                stroke='currentColor'
                fill='currentColor'
                strokeWidth={0}
                viewBox='0 0 24 24'
                height='1em'
                width='1em'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M16.813 4.419A.997.997 0 0 0 16 4H3a1 1 0 0 0-.813 1.581L6.771 12l-4.585 6.419A1 1 0 0 0 3 20h13a.997.997 0 0 0 .813-.419l5-7a.997.997 0 0 0 0-1.162l-5-7z' />
              </svg>
              {t(`ClipInsightsPopup.backLabels`)}
            </>
          )}
        </button>
        <button
          className={classNames(styles.submitBtn, {
            [styles.problem]: activeSection === 'problem',
          })}
          disabled={isLoading}
          onClick={async () => {
            switch (activeSection) {
              case 'labels':
                handleUpdateLabels()
                break
              case 'problem':
                handleUpdateProblems()
                break
            }
          }}
        >
          {t(`ClipInsightsPopup.submit`)}
        </button>
      </div>
    </div>
  )
}

export default Labels
