import { createBaseStreamerApiService } from "./baseStreamerApiService";
import PayoutAccountCreateDto from "./model/dto/payouts/payoutAccountCreateDto";
import TaxDocumentDto from "./model/dto/taxDocumentDto";
import { EnumRequestType } from "./model/enum/enumRequestType";
import { GetBalanceThresholdResponse } from "./model/response/payouts/getBalanceThresholdResponse";
import GetPayoutAccountResponse from "./model/response/payouts/getPayoutAccountResponse"
import GetPayoutRequestInvoiceResponse from "./model/response/payouts/getPayoutRequestInvoiceResponse";
import GetPayoutRequestResponse from "./model/response/payouts/getPayoutRequestResponse";
import { GetPayoutsResponse } from "./model/response/payouts/getPayoutsResponse";
import GetTaxDocumentsResponse from "./model/response/payouts/getTaxDocumentsResponse";





export const createPayoutService = () => {
    const baseService = createBaseStreamerApiService('/payouts');


    const getPayouts = async (): Promise<GetPayoutsResponse> => {
        const response = await baseService.get<GetPayoutsResponse>('');
        return response.data;
    }

    const getBalanceThreshold = async (): Promise<GetBalanceThresholdResponse> => {
        const response = await baseService.get<GetBalanceThresholdResponse>('/balance_threshold');
        return response.data;
    }

    const getPayabilityData = async (): Promise<any> => {
        const response = await baseService.get<any>('/payability');
        return response.data;
    }


    const getPayoutAccount = async (): Promise<GetPayoutAccountResponse> => {
        const response = await baseService.get<GetPayoutAccountResponse>(`/payoutAccount`);
        return response.data;
    }

    const updatePayoutAccount = async (data: PayoutAccountCreateDto): Promise<void> => {
        // payouts/payout_account
        await baseService.put<void>(`/payout_account`, data);
    }

    const uploadExemptionPdf = async (file: File, progressHandler: (progress: number | null) => void): Promise<TaxDocumentDto> => {
        const formData = new FormData();
        formData.append('file', file);
        const response = await baseService.post<TaxDocumentDto>(`/exemptionUpload`, formData, EnumRequestType.FormData, {
            onUploadProgress: (event) => {
                const { loaded, total } = event;
                console.log({ loaded, total });
                if (event.loaded !== undefined && event.total !== undefined) {
                    const progress = Math.round((event.loaded / event.total) * 100 * 10) / 10;
                    progressHandler(progress)
                }
            },
        });
        return response.data;
    }

    const uploadPetitionPdf = async (file: File, progressHandler: (progress: number | null) => void): Promise<TaxDocumentDto> => {
        const formData = new FormData();
        formData.append('file', file);
        const response = await baseService.post<TaxDocumentDto>(`/petitionUpload`, formData, EnumRequestType.FormData, {
            onUploadProgress: (event) => {
                const { loaded, total } = event;
                console.log({ loaded, total });
                if (event.loaded !== undefined && event.total !== undefined) {
                    const progress = Math.round((event.loaded / event.total) * 100 * 10) / 10;
                    progressHandler(progress)
                }
            },
        });
        return response.data;
    }

    const getTaxDocuments = async (): Promise<GetTaxDocumentsResponse> => {
        const response = await baseService.get<GetTaxDocumentsResponse>(`/taxDocuments`);
        return response.data;
    }

    const uploadPayoutRequestInvoice = async (payoutRequestId: number, file: File, progressHandler: (progress: number | null) => void): Promise<TaxDocumentDto> => {
        const formData = new FormData();
        formData.append('file', file);
        const response = await baseService.post<TaxDocumentDto>(`/invoiceUpload/${payoutRequestId}`, formData, EnumRequestType.FormData, {
            onUploadProgress: (event) => {
                const { loaded, total } = event;
                console.log({ loaded, total });
                if (event.loaded !== undefined && event.total !== undefined) {
                    const progress = Math.round((event.loaded / event.total) * 100 * 10) / 10;
                    progressHandler(progress)
                }
            },
        });
        return response.data;
    }

    const getPayoutRequestInvoice = async (payoutRequestId: number): Promise<GetPayoutRequestInvoiceResponse> => {
        const response = await baseService.get<GetPayoutRequestInvoiceResponse>(`/payoutRequest/${payoutRequestId}/invoice`);
        return response.data;
    }



    const sendPayoutRequest = async (grossAmount: number): Promise<void> => {
        await baseService.post<void>(`/payout_request`, {
            gross_payment_amount: grossAmount
        });
    }


    const getPayoutRequest = async (payoutRequestId: number): Promise<GetPayoutRequestResponse> => {
        const response = await baseService.get<GetPayoutRequestResponse>(`/payoutRequest/${payoutRequestId}`);
        return response.data;
    }
    return {
        getPayouts,
        getBalanceThreshold,
        getPayabilityData,
        getPayoutAccount,
        updatePayoutAccount,
        uploadExemptionPdf,
        uploadPetitionPdf,
        getTaxDocuments,
        sendPayoutRequest,
        getPayoutRequestInvoice,
        uploadPayoutRequestInvoice,
        getPayoutRequest
    }
}