import {useEffect, useState} from 'react'

//STYLES
import styles from './RatioCards.module.scss'

//COMPONENTS
import RatioCardItem from './Components/RatioCardItem/RatioCardItem'
import {GetClipInsightsRatiosResponseItem} from 'services/model/response/clipInsights/getClipInsightsRatiosResponse'

export interface RatioCardListI extends Partial<GetClipInsightsRatiosResponseItem> {
  key: 'displayRatio' | 'brandSafety' | 'brandMention'
}

const RatioCardList: RatioCardListI[] = [
  {
    key: 'displayRatio',
  },
  {
    key: 'brandSafety',
  },
  {
    key: 'brandMention',
  },
]

interface RatioCardsProps {
  ratioList: GetClipInsightsRatiosResponseItem[]
}

const RatioCards = ({ratioList}: RatioCardsProps) => {
  useEffect(() => {
    const newCardList = RatioCardList.map((card) => {
      const ratio = ratioList.find((ratio) => ratio.key === card.key)
      return {
        ...card,
        value: ratio?.value,
        change: ratio?.change,
        status: ratio?.status,
      }
    })
    setCardList(newCardList)
  }, [ratioList])

  const [cardList, setCardList] = useState<RatioCardListI[]>([])
  return (
    <div className={styles.ratioCards}>
      {cardList.map((item, index) => (
        <RatioCardItem key={index} item={item} />
      ))}
    </div>
  )
}

export default RatioCards
