import styles from './AssetPreview.module.scss'

const AssetPreview = (props: {
  setAssetType: (assetType: string) => void
  assetType: string
  previewAsset: any
  setPreviewAsset: (previewAsset: any) => void
  previewAssetSrc: string
}) => {
  return (
    <div className={styles.assetPreview}>
      <div className={styles.content}>
        <button
          className={styles.closeBtn}
          onClick={() => {
            props.setPreviewAsset(false)
          }}
        >
          X
        </button>
        {props.assetType === 'image' ? (
          <img src={props.previewAssetSrc} alt='preview' />
        ) : props.assetType === 'video' ? (
          <video controls autoPlay muted>
            <source src={props.previewAssetSrc} type='video/mp4' />
          </video>
        ) : null}
      </div>
    </div>
  )
}

export default AssetPreview
