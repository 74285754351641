import { AppLevelContext } from 'context/AppLevelContext'
import styles from './StepButtons.module.scss'
import classNames from 'classnames'
import SettingsContext from 'context/SettingsContext'
import { toAbsoluteUrl } from 'helpers'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'
import EnumSetupPreview from 'services/model/enum/enumSetupPreview'
const StepButtons = (props: {
  activeTab: number
  setActiveTab: (activeTab: number) => void
  checkOverlayStatus: EnumSetupPreview | null
}) => {
  const { user } = useContext(AppLevelContext)
  const { signupSteps, completeSignup } = useContext(SettingsContext)
  const navigate = useNavigate()
  const location = useLocation()
  const routePath = location.pathname.split('/')[1]
  const [isLoading, setIsLoading] = useState(false)

  if (!signupSteps) return null

  return (
    <div className={styles.stepButtons}>
      {!user?.signup_completed && props.activeTab === 1 && (
        <button
          className={classNames(styles.stepBtn, styles.backBtn)}
          onClick={() => navigate(`/${routePath}/${EnumSettingsTab.Interests}`)}
        >
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepArrow.png')} alt='back' />
          Back
        </button>
      )}
      {props.activeTab > 1 && (
        <button
          className={classNames(styles.stepBtn, styles.backBtn)}
          onClick={() => props.setActiveTab(props.activeTab - 1)}
        >
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepArrow.png')} alt='back' />
          Back
        </button>
      )}
      {props.activeTab < 6 && (
        <button
          className={classNames(styles.stepBtn, styles.nextBtn)}
          onClick={() => props.setActiveTab(props.activeTab + 1)}
        >
          Next
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepArrow.png')} alt='next' />
        </button>
      )}
      {props.activeTab === 6 && routePath !== 'settings' ? (
        <button
          disabled={isLoading}
          className={classNames(styles.stepBtn, styles.finishBtn)}
          onClick={async () => {
            setIsLoading(true)
            await completeSignup()
            setIsLoading(false)
          }}
        >
          Finish
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepArrow.png')} alt='finish' />
        </button>
      ) : null}
    </div>
  )
}

export default StepButtons
