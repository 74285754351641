import {toAbsoluteUrl} from 'helpers'
import styles from '../KickAuthForm.module.scss'
import classNames from 'classnames'
import {AppLevelContext} from 'context/AppLevelContext'
import {useContext, useState} from 'react'
import {toast} from 'react-toastify'

interface Props {
  view: string
  setView: (view: string) => void
}

const EmailSignup = ({view, setView}: Props) => {
  const {emailSignup} = useContext(AppLevelContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [signupPassword, setSignupPassword] = useState<string>('')
  const [signupConfirmPassword, setSignupConfirmPassword] = useState<string>('')
  const handleSignup = async () => {
    if (isLoading) return
    if (!signupPassword || !signupConfirmPassword) {
      toast.error('Please enter password and confirm password')
      return
    }
    if (signupPassword !== signupConfirmPassword) {
      toast.error('Passwords do not match')
      return
    }
    if (signupPassword.length < 6) {
      toast.error('Password must be at least 6 characters long')
      return
    }
    setIsLoading(true)
    const data = await emailSignup(email, signupPassword)
    setIsLoading(false)
    if (data.success) {
      setEmail('')
      setSignupPassword('')
      setSignupConfirmPassword('')
    } else {
      toast.error(data.error)
    }
  }
  return (
    <div className={styles.signupView}>
      <div className={styles.authFormWrap}>
        <div className={styles.inputLine}>
          <button
            className={styles.backBtn}
            onClick={() => {
              setView('signIn')
            }}
          >
            <img src={toAbsoluteUrl('/assets/icons/Auth/backIcon.png')} alt='Back' /> Back to Sign
            in
          </button>
        </div>
        <div className={styles.platformInfo}>
          <img src={toAbsoluteUrl('/assets/icons/Auth/kick-icon.png')} alt='KICK' />
          You are signing up with KICK
        </div>

        <div className={styles.inputLine}>
          <input
            type='text'
            placeholder='Email'
            id='email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className={styles.inputIcon}>
            <img
              src={toAbsoluteUrl('/assets/icons/Auth/email-icon.png')}
              alt='Email'
              draggable='false'
              onContextMenu={(e) => e.preventDefault()}
            />
          </span>
        </div>

        <div className={styles.inputLine}>
          <input
            type='password'
            placeholder='Password'
            id='password'
            onChange={(e) => setSignupPassword(e.target.value)}
          />
          <span className={styles.inputIcon}>
            <img
              src={toAbsoluteUrl('/assets/icons/Auth/password-icon.png')}
              alt='Password'
              draggable='false'
              onContextMenu={(e) => e.preventDefault()}
            />
          </span>
        </div>

        <div className={styles.inputLine}>
          <input
            type='password'
            placeholder='Confirm Password'
            id='re-password'
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSignup()
            }}
            onChange={(e) => setSignupConfirmPassword(e.target.value)}
          />
          <span className={styles.inputIcon}>
            <img
              src={toAbsoluteUrl('/assets/icons/Auth/password-icon.png')}
              alt='Password'
              draggable='false'
              onContextMenu={(e) => e.preventDefault()}
            />
          </span>
        </div>

        <div className={styles.inputLine}>
          <button
            onClick={(e) => {
              handleSignup()
            }}
            className={classNames(styles.signUpBtn, {
              [styles.disabled]: isLoading, //TODO: isLoading
            })}
          >
            {!isLoading ? (
              <span className={styles.btnText}>Sign up</span>
            ) : (
              <span className={styles.isLoading}>
                <img src={toAbsoluteUrl('/assets/icons/loading.gif')} alt='Loading' />
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmailSignup
