//STYLES
import styles from './ClipInsights.module.scss'

//COMPONENTS
import BonusEarning from './Components/BonusEarning/BonusEarning'
import RatioCards from './Components/RatioCards/RatioCards'
import Filter from './Components/Filter/Filter'
import AdTimeline from './Components/AdTimeline/AdTimeline'
import {useEffect, useState} from 'react'
import {createClipInsightsService} from 'services/clipInsightsService'
import {GetClipInsightsResponseItem} from 'services/model/response/clipInsights/getClipInsightsResponse'
import DateFilter from './Components/DateFilter/DateFilter'
import EnumClipInsightsDateRange from 'services/model/enum/enumClipInsightsDateRange'
import {toast} from 'react-toastify'
import Loading from 'components/Shared/Loading/Loading'
import {GetClipInsightsRatiosResponseItem} from 'services/model/response/clipInsights/getClipInsightsRatiosResponse'
import EnumClipInsightsFilter from 'services/model/enum/enumClipInsightsFilter'
import Pagination from 'components/Shared/Pagination/Pagination'
import PageOptions from 'services/model/dto/pageOptions'
import {t} from 'i18next'

const ClipInsights = () => {
  document.title = t('Pages.clipInsights') + ' • LIVAD'
  const [clipInsights, setClipInsights] = useState<GetClipInsightsResponseItem[] | null>(null)
  const [ratioList, setRatioList] = useState<GetClipInsightsRatiosResponseItem[]>([])
  const [activeFilter, setActiveFilter] = useState<EnumClipInsightsFilter>(
    EnumClipInsightsFilter.All
  )
  const [initialLoading, setInitialLoading] = useState(true)
  const [ratioListLoading, setRatioListLoading] = useState(true)
  const [clipInsightsLoading, setClipInsightsLoading] = useState(true)
  const [activeDateRange, setActiveDateRange] = useState<EnumClipInsightsDateRange>(
    EnumClipInsightsDateRange.Month
  )
  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 0,
    hasNextPage: false,
  })
  const [pageSize, setPageSize] = useState(10)
  const clipInsightsService = createClipInsightsService()

  const fetchClipInsights = async (take: number, skip: number) => {
    try {
      setClipInsightsLoading(true)
      const clips = await clipInsightsService.getClipInsights({
        filter: activeFilter,
        period: activeDateRange,
        take: take,
        skip: skip,
      })
      setClipInsights(clips.data)
      if (clips.rowCount > pageSize) {
        setPageOptions((prev) => {
          return {
            ...prev,
            hasNextPage: true,
          }
        })
      } else {
        setPageOptions((prev) => {
          return {
            ...prev,
            hasNextPage: false,
          }
        })
      }
      setClipInsightsLoading(false)
    } catch {
      setClipInsightsLoading(false)
      toast.error('Failed to fetch clip insights')
    }
  }
  const fetchRatios = async () => {
    try {
      setRatioListLoading(true)
      const ratios = await clipInsightsService.getClipInsightsRatios({
        period: activeDateRange,
      })
      setRatioList(ratios.data)
      setRatioListLoading(false)
    } catch {
      setRatioListLoading(false)
      toast.error('Failed to fetch clip insights ratios')
    }
  }

  useEffect(() => {
    if (initialLoading) {
      ;(async () => {
        Promise.all([fetchClipInsights(pageSize, pageOptions.page * pageSize), fetchRatios()])
        setInitialLoading(false)
      })()
    }
  }, [])

  useEffect(() => {
    if (initialLoading) return
    if (pageOptions.page > 0) {
      setPageOptions((prev) => {
        return {
          ...prev,
          page: 0,
        }
      })
      return
    }
    ;(async () => {
      Promise.all([fetchClipInsights(pageSize, pageOptions.page * pageSize), fetchRatios()])
      setInitialLoading(false)
    })()
  }, [activeDateRange])

  useEffect(() => {
    if (initialLoading) return
    if (pageOptions.page > 0) {
      setPageOptions((prev) => {
        return {
          ...prev,
          page: 0,
        }
      })
      return
    }
    fetchClipInsights(pageSize, pageOptions.page * pageSize)
  }, [activeFilter])

  useEffect(() => {
    if (initialLoading) return
    fetchClipInsights(pageSize, pageOptions.page * pageSize)
  }, [pageOptions.page])

  return (
    <div className={styles.clipInsights}>
      <div className={styles.pageTitle}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={36}
          height={36}
          viewBox='0 0 36 36'
          fill='none'
        >
          <g clipPath='url(#clip0_5_105)'>
            <path
              d='M31.5 12C29.325 12 28.11 14.16 28.605 15.765L23.28 21.105C22.83 20.97 22.17 20.97 21.72 21.105L17.895 17.28C18.405 15.675 17.19 13.5 15 13.5C12.825 13.5 11.595 15.66 12.105 17.28L5.265 24.105C3.66 23.61 1.5 24.825 1.5 27C1.5 28.65 2.85 30 4.5 30C6.675 30 7.89 27.84 7.395 26.235L14.22 19.395C14.67 19.53 15.33 19.53 15.78 19.395L19.605 23.22C19.095 24.825 20.31 27 22.5 27C24.675 27 25.905 24.84 25.395 23.22L30.735 17.895C32.34 18.39 34.5 17.175 34.5 15C34.5 13.35 33.15 12 31.5 12Z'
              fill='#FAFAFA'
            />
            <path
              d='M22.5 13.5L23.91 10.395L27 9L23.91 7.605L22.5 4.5L21.12 7.605L18 9L21.12 10.395L22.5 13.5ZM5.25 16.5L6 13.5L9 12.75L6 12L5.25 9L4.5 12L1.5 12.75L4.5 13.5L5.25 16.5Z'
              fill='#FAFAFA'
            />
          </g>
          <defs>
            <clipPath id='clip0_5_105'>
              <rect width={36} height={36} fill='white' />
            </clipPath>
          </defs>
        </svg>
        {t('ClipInsightsPage.clipInsights')}
      </div>
      <BonusEarning />
      <DateFilter value={activeDateRange} setValue={setActiveDateRange} />
      {!ratioListLoading ? <RatioCards ratioList={ratioList} /> : <Loading />}
      <Filter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
      {!clipInsightsLoading ? (
        <AdTimeline data={clipInsights} setData={setClipInsights} />
      ) : (
        <Loading />
      )}
      {clipInsights && clipInsights.length > 0 && !clipInsightsLoading && (
        <Pagination pageOptions={pageOptions} setPageOptions={setPageOptions} />
      )}
    </div>
  )
}

export default ClipInsights
