import { createBaseAdsApiService } from './baseAdsApiService';
import {createBaseStreamerApiService} from './baseStreamerApiService'
import {GetManagedStreamersResponse} from './model/response/admin/getManagedStreamerResponse'


export interface GetCampaignDisplaySettingsResponse {
    data: {
        campaign_setting: {
            setting_id: number,
            top_padding: string,
            left_padding: string,
            width: string,
        } | null
        streamer_setting: {
            top_padding: string,
            left_padding: string,
            width: string,
        } | {}
    }
}
export const createCampaignSettingsService = () => {
  const baseService = createBaseStreamerApiService('');
  const baseAdsService = createBaseAdsApiService('');

  const resetCampaignDisplaySettings = async (campaignId: number) => {
    await baseService.put<void>(`/campaigns/${campaignId}/settings/default`, {});
    return;
  }

  const updateCampaignDisplaySettings = async (campaignId: number, data: {
    top_padding: string,
    left_padding: string,
    width: string,
  }): Promise<{
    data: {
        setting_id: number
    }
  }> => {
    const response = await baseService.post<{
        data: {
            setting_id: number
        }
    }>(`/campaigns/${campaignId}/settings`, data);
    return response.data;
  }

  const getCampaignDisplaySettings = async (campaignId: number): Promise<GetCampaignDisplaySettingsResponse> => {
    const response = await baseService.get<GetCampaignDisplaySettingsResponse>(`/campaigns/${campaignId}/settings`);
    return response.data; 
  }

  const sendCampaignPreview = async (campaignId: number, adDisplaySetting: {
    topPadding: string,
    leftPadding: string,
    width: string,
    }): Promise<void> => {
    await baseAdsService.post<void>(`/adPreviews/streamers`, {
        campaignId,
        adDisplaySetting
    });
  }
  const sendCampaignPreviewDemo = async (): Promise<void> => {
    await baseAdsService.post<void>(`/adPreviews/streamers/demo`, {
        adDisplaySetting: {
            topPadding: '0vh',
            leftPadding: '0%',
            width: '100%'
        }
    });
  }


  return {
    resetCampaignDisplaySettings,
    updateCampaignDisplaySettings,
    getCampaignDisplaySettings,
    sendCampaignPreview,
    sendCampaignPreviewDemo
  }
}
