import Loading from 'components/Shared/Loading/Loading'
import SingleAdSession from './SingleAdSession'
import styles from '../LiveNotifications.module.scss'
import GameSessionDto from 'services/model/dto/gameSessionDto'
import SingleGameSession from './SingleGameSession'
import {useTranslation} from 'react-i18next'

interface GamesFeedProps {
  gameSessions: GameSessionDto[] | null
  pageLoading: boolean
}

const GamesFeed = ({gameSessions, pageLoading}: GamesFeedProps) => {
  const {t} = useTranslation()
  return (
    <>
      {pageLoading || !gameSessions ? (
        <Loading />
      ) : (
        <>
          {gameSessions && gameSessions.length > 0 ? (
            <>
              {gameSessions.map((gameSession, index) => {
                return <SingleGameSession key={index} gameSession={gameSession} />
              })}
            </>
          ) : (
            <div className={styles.noData}>{t('LiveConsolePage.noData')}</div>
          )}
        </>
      )}
    </>
  )
}

export default GamesFeed
