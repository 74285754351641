import SettingsContext from 'context/SettingsContext'
import styles from './CheckOverlay.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useState, useEffect, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {createCampaignSettingsService} from 'services/campaignSettingsService'
import {toast} from 'react-toastify'

const CheckOverlay = () => {
  const {t} = useTranslation()
  const campaignSettingService = createCampaignSettingsService()
  const {
    setupPreviewConfirmed,
    updateSetupPreviewConfirmed,
    checkSetupPreviewConfirmedDiff,
    checkSetupPreviewConfirmedSave,
  } = useContext(SettingsContext)
  const [isDemo, setIsDemo] = useState(false)

  const playDemo = () => {
    const demoAsset = document.getElementById('demoAsset') as HTMLVideoElement
    demoAsset.play()
  }

  useEffect(() => {
    if (isDemo) {
      playDemo()
      setTimeout(() => {
        setIsDemo(false)
      }, 6000)
    }
  }, [isDemo])

  return (
    <div className={styles.checkOverlay}>
      <div className={styles.demoScene}>
        <div className={styles.blurFill}></div>
        <div className={styles.tryDemo}>
          <button
            className={styles.tryDemoBtn}
            onClick={async () => {
              try {
                await campaignSettingService.sendCampaignPreviewDemo()
                toast.success('Demo sent successfully')
                setIsDemo(true)
              } catch (error) {
                toast.error('Failed to send demo')
                console.log(error)
              }
            }}
          >
            <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/sendAdIcon.png')} alt='send' />
            {t('SettingsPage.sendAdPreview')}
          </button>
        </div>
        {isDemo && (
          <div className={styles.demoView}>
            <video muted id={'demoAsset'}>
              <source
                src='https://livad.blob.core.windows.net/livad-ads/Images/Streamer/overlaySetup_demo.webm'
                type='video/mp4'
              />
              Your browser does not support the video player
            </video>
          </div>
        )}
      </div>
    </div>
  )
}

export default CheckOverlay
