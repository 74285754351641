import { toast } from "react-toastify";
import { createBaseAdsApiService } from "./baseAdsApiService";
import { createBaseStreamerApiService } from "./baseStreamerApiService"
import GameSessionDto from "./model/dto/gameSessionDto"
import { EnumGameType } from "./model/enum/enumGameType"
import { PostWordGamesStreamersResponse } from "./model/response/games/postWordGameStreamersResponse";
import { t } from "i18next";
import { EnumRequestType } from "./model/enum/enumRequestType";

export const createGamesService = () => {
  const baseStreamerService = createBaseStreamerApiService('/games');
  const baseAdsService = createBaseAdsApiService('')

  const getGameSessions = async (): Promise<Array<GameSessionDto>> => {
    const response = await baseStreamerService.get<{
      games: Array<GameSessionDto>
    }>('/getSessions')
    return response.data.games
  }


  const postWordGame = async (type: EnumGameType): Promise<PostWordGamesStreamersResponse> => {

    let path = (() => {
      if (type === EnumGameType.Trivia) return '/wordGames/streamers'
      else if (type === EnumGameType.Flag) return '/wordGames/flagGame/streamers'
      else throw new Error('Invalid Word Game Type')
    })()
    let response = await toast.promise(baseAdsService.post<PostWordGamesStreamersResponse>(path, {}), {
      pending: 'Creating Trivia Session...',
      success: 'Successfully created Trivia Session',
      error: 'Error while creating Trivia Session',
    })
    if (response.data.answer) {
      console.log(response.data.answer)
    }
    return response.data;
  }

  const postTabooGame = async (): Promise<PostWordGamesStreamersResponse> => {


    let response = await toast.promise(baseAdsService.post<PostWordGamesStreamersResponse>('/tabooGames/streamers', {}), {
      pending: 'Creating Taboo Session...',
      success: 'Successfully created Taboo Session',
      error: 'Error while creating Taboo Session',
    })
    if (response.data.answer) {
      console.log(response.data.answer)
    }
    return response.data;
  }

  return {
    getGameSessions,
    postWordGame,
    postTabooGame
  }

}
