import {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
// STYLES
import styles from './ActionBar.module.scss'
// CONTEXT
import CampaignContext from 'context/CampaignContext'
type ActionBarProps = {
  activeView: string
  setActiveView: (view: string) => void
  variant?: 'featured' | ''
}

const ActionBar = ({setActiveView, activeView, variant}: ActionBarProps) => {
  const {t} = useTranslation()
  const {activeFilter, setActiveFilter} = useContext(CampaignContext)
  return (
    <div
      className={classNames(styles.campaigns_header, {
        // {styles.campaigns_header}
        [styles.featured_campaigns_header]: variant === 'featured',
      })}
    >
      <div className={styles.sort_box}>
        <div className={styles.sort_box}>
          {variant === 'featured' ? (
            <span className={styles.title} style={{border: 'unset'}}>
              {t('CampaignsPage.featuredPastCampaigns')}
            </span>
          ) : (
            <span className={styles.title}>{t('CampaignsPage.myCampaigns')}</span>
          )}

          {variant !== 'featured' && (
            <>
              <span className={styles.sort_title}>{t('CampaignsPage.sortBy')}</span>
              <div className={styles.sort_wrap}>
                <div
                  className={
                    activeFilter === 'available'
                      ? [styles.sort_circle, styles.sorted_available].join(' ')
                      : activeFilter === 'active'
                      ? [styles.sort_circle, styles.sorted_active].join(' ')
                      : activeFilter === 'denied'
                      ? [styles.sort_circle, styles.sorted_denied].join(' ')
                      : [styles.sort_circle, styles.sorted_past].join(' ')
                  }
                />

                <select
                  className={styles.sort_select}
                  value={activeFilter}
                  onChange={(e) => setActiveFilter(e.target.value)}
                >
                  <option value='available'>{t('CampaignsPage.available')}</option>
                  <option value='active'>{t('CampaignsPage.active')}</option>
                  <option value='denied'>{t('CampaignsPage.denied')}</option>
                  <option value='past'>{t('CampaignsPage.past')}</option>
                </select>
              </div>
            </>
          )}
        </div>
      </div>

      {variant !== 'featured' && (
        <div className={styles.sort_box}>
          {/* CARD BUTTON */}
          <div
            className={
              activeView === 'card'
                ? [styles.toggle_item, styles.active].join(' ')
                : styles.toggle_item
            }
            onClick={() => setActiveView('card')}
          >
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/card-view-icon.png')} alt='' />
          </div>
          {/* LIST BUTTON */}
          <div
            className={
              activeView === 'list'
                ? [styles.toggle_item, styles.active].join(' ')
                : styles.toggle_item
            }
            onClick={() => setActiveView('list')}
          >
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/list-view-icon.png')} alt='' />
          </div>
        </div>
      )}
    </div>
  )
}

export default ActionBar
