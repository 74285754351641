import {useTranslation} from 'react-i18next'
import styles from './Dashboard.module.scss'
import {loadingHandler} from 'helpers'
import {useEffect, useContext, useState} from 'react'
import {useLocation} from 'react-router-dom'

// Context
import DashboardContext from 'context/DashboardContext'

//COMPONENTS
import UserNavigation from './Components/UserNavigation/UserNavigation'
import UserReport from './Components/UserReport/UserReport'
import StreamingCode from './Components/StreamingCode/StreamingCode'
import Opportunities from './Components/Opportunities/Opportunities'
import RecruitFriend from './Components/RecruitFriend/RecruitFriend'
import MyClips from './Components/MyClips/MyClips'
import Loading from 'components/Shared/Loading/Loading'
import DashboardTable from './Components/DashboardTable/DashboardTable'
import CampaignPopup from 'components/CampaignPopup/CampaignPopup'

const Dashboard = () => {
  const location = useLocation()
  const {getDashboardData, dateFilter, dashboardData, getAllCampaigns} =
    useContext(DashboardContext)
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(true)

  useEffect(() => {
    loadingHandler([getDashboardData(), getAllCampaigns()], setDashboardLoading)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (dashboardData) {
      loadingHandler([getDashboardData()], setDashboardLoading)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter])

  const {t} = useTranslation()
  document.title = t('Pages.dashboard') + ' • LIVAD'
  return (
    <>
      {location.pathname.includes('campaign-popup') && <CampaignPopup />}
      <div className={styles.dashboardContainer}>
        <div className={styles.mainSection}>
          <UserNavigation />
          {dashboardLoading ? (
            <Loading />
          ) : (
            <>
              <UserReport />
              <DashboardTable />
            </>
          )}
        </div>
        <div className={styles.sideSection}>
          <Opportunities />
          <StreamingCode />
          <RecruitFriend />
        </div>
      </div>
    </>
  )
}

export default Dashboard
