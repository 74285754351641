import {createContext, useState, useEffect} from 'react'

// Services
import {createDashboardService} from 'services/dashboardService'
import EnumDashboardDateRange from 'services/model/enum/enumDateRange'

// Models
import {GetDashboardResponse} from 'services/model/response/dashboard/getDashboardResponse'
import {GetDashboardCampaignsResponse} from 'services/model/response/dashboard/getDashboardCampaignsResponse'
import {GetDashboardAdsResponse} from 'services/model/response/dashboard/getDashboardAdsResponse'

import axios from 'axios'
import {loadingHandler} from 'helpers'
import {createCampaignService} from 'services/campaignService'
export interface DashboardContextInterface {
  dashboardData: GetDashboardResponse | null
  getDashboardData: () => Promise<void>
  setDateFilter: (dateFilter: EnumDashboardDateRange) => void
  dateFilter: EnumDashboardDateRange
  tableView: string
  setTableView: (tableView: string) => void
  selectedBrandId: number | null
  setSelectedBrandId: (selectedBrandId: number | null) => void
  selectedCampaignId: number | null
  setSelectedCampaignId: (selectedCampaignId: number | null) => void
  campaigns: GetDashboardCampaignsResponse | null
  ads: GetDashboardAdsResponse | null
  opportunities: any
  getAllCampaigns: () => void
  selectBrand: (brandId: number) => void
  selectCampaign: (campaignId: number) => void
  dashboardLoading: boolean
}

export const DashboardContext = createContext<DashboardContextInterface>({
  dashboardData: null,
  getDashboardData: () => Promise.resolve(),
  setDateFilter: () => {},
  dateFilter: EnumDashboardDateRange.AllTime,
  tableView: 'brands',
  setTableView: () => {},
  selectedBrandId: null,
  setSelectedBrandId: () => {},
  selectedCampaignId: null,
  setSelectedCampaignId: () => {},
  campaigns: null,
  ads: null,
  opportunities: [],
  getAllCampaigns: () => {},
  selectBrand: () => {},
  selectCampaign: () => {},
  dashboardLoading: false,
})

interface DashboardContextType {
  children: React.ReactNode
}

export const DashboardContextProvider = ({children}: DashboardContextType) => {
  const campaignService = createCampaignService()
  const dashboardService = createDashboardService()
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(false)
  const [dashboardData, setDashboardData] = useState<GetDashboardResponse | null>(null)
  const [dateFilter, setDateFilter] = useState<EnumDashboardDateRange>(
    EnumDashboardDateRange.AllTime
  )
  const [tableView, setTableView] = useState<string>('brands')
  const [opportunities, setOpportunities] = useState<any>([])

  // CAMPAIGNS
  const [campaigns, setCampaigns] = useState<GetDashboardCampaignsResponse | null>(null)
  // ADS
  const [ads, setAds] = useState<GetDashboardAdsResponse | null>(null)
  // SELECTEC IDS
  const [selectedBrandId, setSelectedBrandId] = useState<number | null>(null)
  const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(null)

  const getDashboardData = async () => {
    const response = await dashboardService.getDashboardData({
      dateRange: dateFilter,
    })
    setDashboardData(response)
  }

  const getCampaigns = async () => {
    if (!selectedBrandId) return
    const response = await dashboardService.getDashboardCampaigns({
      dateRange: dateFilter,
      clientId: selectedBrandId,
    })

    setCampaigns(response)
  }

  const getAds = async () => {
    if (!selectedCampaignId) return
    const response = await dashboardService.getDashboardAds({
      dateRange: dateFilter,
      campaignId: selectedCampaignId,
    })
    setAds(response)
  }

  const getAllCampaigns = async () => {
    let QUERY = 'campaign_status=active,pending,paused&streamer_status=requested'
    try {
      const response = await campaignService.getCampaigns(QUERY)
      setOpportunities(response)
    } catch (error) {
      console.log(error)
    }
  }

  const selectBrand = (brandId: number) => {
    // setCampaigns(null);
    setSelectedBrandId(brandId)
    setTableView('campaigns')
  }

  const selectCampaign = (campaignId: number) => {
    // setAds(null)
    setSelectedCampaignId(campaignId)
    setTableView('ads')
  }

  useEffect(() => {
    if (selectedBrandId) {
      loadingHandler([getCampaigns()], setDashboardLoading)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrandId])

  useEffect(() => {
    if (selectedCampaignId) {
      loadingHandler([getAds()], setDashboardLoading)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignId])

  useEffect(() => {
    setTableView('brands')
  }, [dateFilter])

  return (
    <DashboardContext.Provider
      value={{
        dashboardData,
        getDashboardData,
        setDateFilter,
        dateFilter,
        tableView,
        setTableView,
        selectedCampaignId,
        setSelectedCampaignId,
        selectedBrandId,
        setSelectedBrandId,
        campaigns,
        ads,
        getAllCampaigns,
        opportunities,
        selectBrand,
        selectCampaign,
        dashboardLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardContext
