import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createCampaignService } from "services/campaignService";
import UpdateAffiliateCampaignRequest from "services/model/request/campaigns/updateAffiliateCampaignRequest";
import AffiliateCampaignResponseItem from "services/model/response/campaigns/affiliateCampaignResponseItem";
import UpdateAffiliateCampaignResponse from "services/model/response/campaigns/updateAffiliateCampaignRespone";




interface AffiliateCampaignsContextProps {
    children: ReactNode;
}

type AffiliateCampaignsContextInterface = {
    // add your context properties here
    campaigns: AffiliateCampaignResponseItem[] | null;
    fetchCampaigns: () => Promise<void>;
    applyToCampaign: (id: number) => Promise<UpdateAffiliateCampaignResponse | null>;
    applying: boolean;
}   


const AffiliateCampaignsContext = createContext<AffiliateCampaignsContextInterface | undefined>(undefined);
export const useAffiliateCampaignsContext = () => {
    const context = useContext(AffiliateCampaignsContext);
    if (context === undefined) {
        throw new Error('useDashboardContext must be used within a AffiliateCampaignsContextProvider');
    }
    return context;
}

export const AffiliateCampaignsContextProvider = ({ children }: AffiliateCampaignsContextProps) => {
    const campaignService = createCampaignService();
    const [campaigns, setCampaigns] = useState<AffiliateCampaignResponseItem[] | null>(null);
    
    const [applying, setApplying] = useState<boolean>(false);
  
    const fetchCampaigns = async () => {
      try {
        const response = await campaignService.getAffiliateCampaigns()
        setCampaigns(response)
      } catch (error) {
        console.log(error)
      }
    }
  
    const applyToCampaign = async (id: number): Promise<UpdateAffiliateCampaignResponse | null> => {
      if (applying) return null
      if (!campaigns) return null
      try {
        setApplying(true)
        const response = await toast.promise(campaignService.updateAffiliateCampaign({
          campaignId: id,
          applied: true
        }), {
          pending: 'Applying...',
          success: 'Applied',
        })
        
        const newCampaigns = campaigns.map(campaign => {
          if (campaign.id === id) {
            return {
              ...campaign,
              status: response.status
            }
          }
          return campaign
        });
        setCampaigns([...newCampaigns]);
        setApplying(false)
        return response
      } catch (error: any) {
        console.log(error)
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message)
        }
        setApplying(false)
        return null
      } 
    }
  


    useEffect(() => {
        fetchCampaigns()
    }, [])

    return (
        <AffiliateCampaignsContext.Provider value={{
            campaigns,
            fetchCampaigns,
            applyToCampaign,
            applying
        }}>
            {children}
        </AffiliateCampaignsContext.Provider>
    )
}