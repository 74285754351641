import classNames from 'classnames'
import axios from 'axios'
import {useState, useContext, useEffect} from 'react'
import {Rnd} from 'react-rnd'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {getPageDiff} from 'helpers'
import DragAndDrop from './components/DragAndDrop'
// STYLES
import styles from './Settings.module.scss'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
import {createCampaignSettingsService} from 'services/campaignSettingsService'
import Loading from 'components/Shared/Loading/Loading'

const Settings = ({campaign}) => {
  const campaingSettingsService = createCampaignSettingsService()
  const {auth0Token} = useContext(AppLevelContext)
  const {t} = useTranslation()
  const [data, setData] = useState(null)
  const [initialData, setInitialData] = useState(null)
  const [diff, setDiff] = useState({})
  const [reset, setReset] = useState(false)
  const [defaultEnabled, setDefaultEnabled] = useState(false)

  // eslint-disable-next-line
  const loadSetting = async () => {
    const response = await campaingSettingsService.getCampaignDisplaySettings(campaign.campaign_id)
    const {data: {campaign_setting, campaign_streamer_setting} = {}} = response

    const {asset} = campaign
    if (campaign_streamer_setting || campaign_setting) {
      if (campaign_streamer_setting) setDefaultEnabled(true)
      else setDefaultEnabled(false)
      setData({
        asset,
        asset_type: campaign.asset_type,
        ...(campaign_streamer_setting || campaign_setting),
      })
      setInitialData({
        asset,
        asset_type: campaign.asset_type,
        ...(campaign_streamer_setting || campaign_setting),
      })
    }
  }

  const sendCampaignPreview = async () => {
    try {
      await campaingSettingsService.sendCampaignPreview(campaign.campaign_id, {
        topPadding: data.top_padding,
        leftPadding: data.left_padding,
        width: data.width,
      })
      toast.success(t('Succesfully Sent an Ad Preview!'))
    } catch (error) {
      toast.error('Error sending the preview')
    }
  }

  const updateSetting = async () => {
    if (!data) {
      return
    }
    try {
      var {data: responseData} = await campaingSettingsService.updateCampaignDisplaySettings(
        campaign.campaign_id,
        data
      )
      if (responseData) {
        await loadSetting()
        setReset(true)
        toast.success(t('Succesfully Updated Setting!'))
      } else {
        toast.error(t('Error while updating setting'))
      }
    } catch (error) {
      toast.error(t('Error while updating setting'))
    }
  }

  const defaultSetting = async () => {
    try {
      await campaingSettingsService.resetCampaignDisplaySettings(campaign.campaign_id)
      await loadSetting()
      setReset(true)
      toast.success(t('Succesfully Updated Setting to Default!'))
    } catch (error) {
      toast.error(t('Error while updating setting'))
    }
  }
  const sendPreview = async () => {
    // const {data,}
    const {data: responseData, error} = await sendCampaignPreview(
      campaign.campaign_id,
      data,
      auth0Token
    )
    if (responseData) {
      toast.success(t('Succesfully Sent an Ad Preview!'))
    } else {
      toast.error(error.response)
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    loadSetting()
  }, [])

  useEffect(() => {
    if (data && initialData) {
      setDiff(getPageDiff(initialData, data))
    }
  }, [data, initialData])

  useEffect(() => {
    // console.log({diff})
  }, [diff])
  return (
    <div className={styles.settingsPopupTab}>
      <div className={styles.campaignCustomer}>
        <div className={styles.brandName}>{campaign.client_name}</div>
        <div className={styles.campaignName}>{campaign.campaign_name}</div>
      </div>
      <div className={styles.campaignSettings_header}>
        <button
          onClick={sendCampaignPreview}
          className={classNames(styles.settingButton, styles.sendAdPreview)}
        >
          <img
            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJ0lEQVRoge2ZPWoCURSFNVrbRLGz18Ja0rsDY5ktpLGMi3ALbsE2lbXYBktBBBNiwDJiruf5uMXADDOjkjsH3genPx/cmfdXKgUCgUDhEZEh8oWskGfrPrlB6Z1EeUc61r0yI/EckQlSs+6XSoKAskVekLJ1z0RSBJQ50rXuGktGAccJmSJ1684Rcggo38gr8mDd/cIVAsoC6Vn3v0XAoWPVYBVQ9uLHqsIqoCyRJ2YBx5/4sWqyCig/4seqyiqgfCB9ZgFlhrSYBRwHZCT3XAT/WUAZswts2AXW7AJvrALUHzHtb5R2IaPdSlBv5mi307QHGuojJe2hnvZaRcfl0bpzhIzlaa8WaS93f4Xoej3ugaNt3Ssz4p+YPsVvugbWfQKBQKA4nAGlJzqS2bOTPwAAAABJRU5ErkJggg=='
            alt='icon'
          />
          {t('Buttons.sendAdPreview')}
        </button>
        {defaultEnabled ? (
          <button
            onClick={defaultSetting}
            className={classNames(styles.settingButton, styles.backToDefault)}
          >
            {/* <img
              src='https://livad-test-bucket.s3.eu-central-1.amazonaws.com/img/default-settings-icon.png'
              alt=''
              draggable='false'
            /> */}
            {t('Buttons.backToDefault')}
          </button>
        ) : null}
        {Object.keys(diff).length ? (
          <>
            <button
              onClick={updateSetting}
              className={classNames(styles.settingButton, styles.savePosition)}
            >
              <img
                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByUlEQVRoge2Zu0rEQBSGF9Fi30AUbcXKwkp9AMXOwgewUfDyBPsArjbeqlQWPof3xiKgT2FlpWbIBYz/wTMwLGtIMjNJivPDIbfZ/5wvcyYJbK8nEolEIt9SSs0nSXKKeEzTNKSI43iLrmG7qM+VjBvtS/vwfEZcwmfBS/FZlm0iwTeS5SOxy0Usj7lWFKEBEOrzyZ92nBbPd35c8c4BGOIH2z1nANQ2BYU4B3AOwT3fKIABcWAN8F8C3wAawnpNtAnAEInV06ltAIa48AWwT2OQYKkKAMa/GP5vJcY/eQGA8RWNyfN8EsfXaYmXGBWP2ObfTWFfVZkx1wAfKGLawvuoass5BeB4pRaq4skzdljy7nsH0LPxXqaFqOcrFN4cgOcQAAHoHAAW4i22q3ii9Gubs8iDvOB51wgAFY+kE7aFj4o84X3fxAys0LUoimaR8BzHgU3A4wxeM5xvzTuAbhsu3kmfEwTPQt87gHEtcLhQA8NXAARAAARAADoCgBfMQ9sA9HlhA3DSAYDj2gBKqTkYfLUFgNyf9M1VG4CUZdmGCdEUABWP3OtWxWvxTAxpTRiJBmm1PzaKYqB9KQe1jfWdF4lEIlEZ/QICsKvtjn6qFAAAAABJRU5ErkJggg=='
                alt='icon'
              />
              {t('Buttons.savePosition')}
            </button>
            <button
              onClick={() => {
                setData(initialData)
                setReset(true)
              }}
              className={classNames(styles.settingButton, styles.resetPosition)}
            >
              <img
                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABM0lEQVRoge2YyQ6CQAyGeRP16MWLD+3yYgZJXC4sc9HWjIYYlnaYzpL0SyYSIv3/HxiYUhSKoiiKomRC0zQbY8y567onDtg+wb51KvVmxUCkBJFXf8C+K4wdtx4eg8cO1CtFQuCZ+hfriVacENZ8NVYPxsF7AHuJxwTJIQjmsdY9eABKCIp5sQA4weaEp0JQzYvdQjixhiYdJQTHPGrUdb3yHgBp23YLAheikRv87rnmXZ5oLJiGKuZ/Zc1/4VyJZM68VIgo5n2FiGp+aYgkzH/hhkjKPMJ8Sf0esbF9f+CaTyqEq/kkQiw1HzWEL/NRQmS9lHBZmLksAEXM2544yHI6eE9MuRWiNzTZt5TZN/VmoifO4rOK7YmDfNgS64kxBIgcQeSBA7eXiPmupyiKoihKaN4q3T2L2EelTQAAAABJRU5ErkJggg=='
                alt='icon'
              />
              {t('Buttons.resetPosition')}
            </button>
          </>
        ) : null}
      </div>
      <div className={styles.campaignSettings_preview}>
        {data ? (
          <DragAndDrop reset={reset} setReset={setReset} campaign={data} setCampaign={setData} />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  )
}

export default Settings
