// CSS Modules
import styles from '../Sidebar.module.scss'
// NAV ROUTES
import {navRoutes} from '../navRoutes'
// COMPONENTS
import SingleNavigation from './components/SingleNavigation'

const Navigation = () => {
  return (
    // add test id
    <div className={styles.menu} data-testid='sidebar-navigation'>
      {navRoutes.map((route, index) => (
        <SingleNavigation key={index} route={route} />
      ))}
    </div>
  )
}

export default Navigation
