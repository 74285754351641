import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
//STYLES
import styles from './GamePopupMenu.module.scss'
import {Campaign} from 'components/CampaignPopup/CampaignPopup'
import {EnumGameType} from 'services/model/enum/enumGameType'

type GamePopupMenuProps = {
  isPopupTab: string
  setIsPopupTab: (value: string) => void
  gameType: EnumGameType
}

const GamePopupMenu = ({isPopupTab, setIsPopupTab, gameType}: GamePopupMenuProps) => {
  return (
    <div className={styles.menu}>
      <div className={styles.gameIcon}>
        <img
          src={
            gameType === EnumGameType.Trivia
              ? toAbsoluteUrl('/assets/icons/Games/game_triviaGame_icon.png')
              : gameType === EnumGameType.Flag
              ? toAbsoluteUrl('/assets/icons/Games/game_findTheFlag_icon.png')
              : gameType === EnumGameType.Taboo
              ? toAbsoluteUrl('/assets/icons/Games/game_reverseTaboo_icon.png')
              : ''
          }
          alt='game'
        />
      </div>
      <div
        className={classNames(styles.menuItem, {[styles.active]: isPopupTab === 'detail'})}
        onClick={() => setIsPopupTab('detail')}
      >
        <img src={toAbsoluteUrl('/assets/icons/LiveConsole/gameDetail_icon.png')} alt='menu' />
        <span className={styles.menuText}>Detail</span>
      </div>
    </div>
  )
}

export default GamePopupMenu
