import classNames from 'classnames'
import axios from 'axios'
import {useState, useContext, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {getPageDiff, toAbsoluteUrl} from 'helpers'
import CampaignDemo from './components/CampaignDemo/CampaignDemo'
// STYLES
import styles from './AdDetail.module.scss'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
import LiveConsoleContext from 'context/LiveConsoleContext'

const getSetting = async (campaign_id, token) => {
  return await axios
    .get(`${process.env.REACT_APP_STREAMER_API_URL}/campaigns/${campaign_id}/settings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({data: {data}}) => {
      return {data}
    })
    .catch(({response: {error} = {}}) => {
      return {error}
    })
}
const updateSettingData = async (campaign_id, data, token) => {
  return await axios
    .post(`${process.env.REACT_APP_STREAMER_API_URL}/campaigns/${campaign_id}/settings`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({data: {data}}) => {
      return {data}
    })
    .catch(({response: {error} = {}}) => {
      return {error}
    })
}
const defaultSettingsData = async (campaign_id, token) => {
  return await axios
    .put(
      `${process.env.REACT_APP_STREAMER_API_URL}/campaigns/${campaign_id}/settings/default`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({data = {}}) => {
      return {data: 'Success'}
    })
    .catch(({response: {error} = {}}) => {
      return {error}
    })
}

const Settings = ({campaign}) => {
  const {auth0Token} = useContext(AppLevelContext)
  const {adAvailabilityData, postManualAd} = useContext(LiveConsoleContext)
  const {t} = useTranslation()
  const [data, setData] = useState(null)
  const [initialData, setInitialData] = useState(null)
  const [diff, setDiff] = useState({})
  const [reset, setReset] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const [defaultEnabled, setDefaultEnabled] = useState(false)
  const campaignId = campaign.id

  const routePath = location.pathname.split('/')[1]

  // eslint-disable-next-line
  const loadSetting = async () => {
    const {data: {campaign_setting, campaign_streamer_setting} = {}} = await getSetting(
      campaignId,
      auth0Token
    )

    const {asset} = campaign
    if (campaign_streamer_setting || campaign_setting) {
      if (campaign_streamer_setting) setDefaultEnabled(true)
      else setDefaultEnabled(false)
      setData({asset, ...(campaign_streamer_setting || campaign_setting)})
      setInitialData(campaign_streamer_setting || campaign_setting)
    }
  }

  // eslint-disable-next-line
  useEffect(() => {
    loadSetting()
  }, [])

  useEffect(() => {
    if (data && initialData) {
      setDiff(getPageDiff(initialData, data))
    }
  }, [data, initialData])

  return (
    <div className={styles.adDetailPopupTab}>
      <div className={styles.campaignCustomer}>
        <div className={styles.brandName}>{campaign.client_name}</div>
        <div className={styles.campaignName}>{campaign.campaign_name}</div>
      </div>
      <CampaignDemo campaign={campaign} />
      <div className={styles.adDetail_footer}>
        <div className={styles.campaignBrief}>
          <img
            className={styles.livadLogoMark}
            src={toAbsoluteUrl('/assets/icons/logoMark.png')}
            alt='logo'
          />
          <div className={styles.campaignBrief_title}>{t('CampaignsPopup.campaignBrief')}</div>
          {campaign?.campaign_brief && (
            <p
              className={styles.campaignBrief_text}
              dangerouslySetInnerHTML={{__html: campaign?.campaign_brief}}
            ></p>
          )}
        </div>
        <div className={styles.campaignAgreement}>
          <button
            onClick={async () => {
              await postManualAd({
                campaignId: campaign.campaign_id,
                adDisplaySetting: {
                  topPadding: data?.top_padding,
                  leftPadding: data?.left_padding,
                  width: data?.width,
                },
              })
              navigate(`/${routePath}`)
            }}
            className={classNames(styles.sendAdBtn, {
              [styles.disabled]:
                !adAvailabilityData?.streamerAvailable ||
                !adAvailabilityData.availableCampaignIds.includes(campaign.campaign_id),
            })}
          >
            <img src={'../../assets/icons/LiveConsole/sendAdIcon.png'} alt='ad' />
            {t('LiveConsolePage.sendAd')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Settings
