import ReactLoading from 'react-loading'
import styles from './Loading.module.scss'

type LoadingProps = {
  width?: string
  height?: string
}

const Loading = ({width = '5', height = '5'}: LoadingProps) => {
  return (
    <div className={styles.loadingContainer}>
      <ReactLoading type='spin' color='#5d7fff' height={height + '%'} width={width + '%'} />
    </div>
  )
}

export default Loading
