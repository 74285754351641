//STYLES
import styles from './Campaigns.module.scss'

import {useContext, useEffect} from 'react'
import LiveConsoleContext from 'context/LiveConsoleContext'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import SingleCampaign from './SingleCampaign'
import {useTranslation} from 'react-i18next'
const Campaigns = () => {
  const {campaignList, setActiveFilter, activeFilter, adAvailabilityData, postRandomAd} =
    useContext(LiveConsoleContext)
  const {t} = useTranslation()
  return (
    <div className={styles.campaigns_container}>
      <div className={styles.campaigns_header}>
        <span className={styles.headerTitle}>
          <img src={toAbsoluteUrl('/assets/icons/LiveConsole/campaignsIcon.png')} alt='campaigns' />
          {t('LiveConsolePage.campaigns')}
        </span>
        <div className={styles.campaignsSort}>
          {t('LiveConsolePage.sortBy')}
          <div className={styles.selectWrap}>
            {/* TODO: You can use classNames: 'available', 'active', 'denied for selected*/}
            <span
              className={classNames(styles.selectedCircle, {
                [styles.available]: activeFilter === 'available',
                [styles.active]: activeFilter === 'active',
                [styles.denied]: activeFilter === 'denied',
              })}
            ></span>
            <select
              onChange={(e) => {
                setActiveFilter(e.target.value)
              }}
              value={activeFilter}
            >
              <option value='available'>{t('LiveConsolePage.available')}</option>
              <option value='active'>{t('LiveConsolePage.active')}</option>
              <option value='denied'>{t('LiveConsolePage.denied')}</option>
            </select>
          </div>
        </div>
        {/* TODO: Add "disabled" class to button if there is not ready for sending ad */}
        <button
          onClick={postRandomAd}
          className={classNames(styles.sendRandomlyBtn, {
            [styles.disabled]: !adAvailabilityData?.streamerAvailable,
          })}
        >
          <img src={toAbsoluteUrl('/assets/icons/LiveConsole/sendAdIcon.png')} alt='send' />
          {t('LiveConsolePage.sendRandomAd')}
        </button>
      </div>
      <div className={styles.campaigns_list}>
        {/* TODO: This is dummy map for testing. You can remove it. */}
        {campaignList.map((campaign, index) => {
          return <SingleCampaign campaign={campaign} key={index} />
        })}
        {
          //if there is no campaigns
          campaignList.length === 0 && (
            <div className={styles.campaigns_list_noCampaigns}>
              <img
                src={toAbsoluteUrl('/assets/icons/LiveConsole/noCampaignsIcon.png')}
                alt='noCampaigns'
              />
              <span>
                {/* There's No{' '}
                {activeFilter === 'available'
                  ? 'Available'
                  : activeFilter === 'active'
                  ? 'Active'
                  : 'Denied'}{' '} */}
                {t('LiveConsolePage.noFilterCampaign', {
                  FILTER: t(`LiveConsolePage.${activeFilter}`),
                })}
              </span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Campaigns
