import {toAbsoluteUrl} from 'helpers'
import styles from './DashboardTable.module.scss'
import {useContext} from 'react'
import DashboardContext from 'context/DashboardContext'
import Brands from '../Brands/Brands'
import Campaigns from '../Campaigns/Campaigns'
import Ads from '../Ads/Ads'
import Loading from 'components/Shared/Loading/Loading'
import EnumDashboardDateRange from 'services/model/enum/enumDateRange'
import {useTranslation} from 'react-i18next'

const DashboardTable = () => {
  const {tableView, setTableView, dashboardLoading, dateFilter, campaigns, dashboardData} =
    useContext(DashboardContext)
  const {t} = useTranslation()
  return (
    <div className={styles.brandsContainer}>
      <div className={styles.brandsContainer_title}>
        {dateFilter === EnumDashboardDateRange.Day
          ? t('DashboardPage.lastXHours', {X: '24'})
          : dateFilter === EnumDashboardDateRange.Week
          ? t('DashboardPage.lastXDays', {X: '7'})
          : dateFilter === EnumDashboardDateRange.Month
          ? t('DashboardPage.lastXDays', {X: '28'})
          : dateFilter === EnumDashboardDateRange.ThreeMonths
          ? t('DashboardPage.lastXMonths', {X: '3'})
          : dateFilter === EnumDashboardDateRange.SixMonths
          ? t('DashboardPage.lastXMonths', {X: '6'})
          : dateFilter === EnumDashboardDateRange.AllTime
          ? t('DashboardPage.allTime')
          : ''}
      </div>
      {tableView === 'campaigns' ? (
        <button className={styles.backBtn} onClick={() => setTableView('brands')}>
          <img src={toAbsoluteUrl('/assets/icons/Dashboard/Brands/backBtnIcon.png')} alt='back' />
          Back to Brands
        </button>
      ) : tableView === 'ads' ? (
        <button className={styles.backBtn} onClick={() => setTableView('campaigns')}>
          <img src={toAbsoluteUrl('/assets/icons/Dashboard/Brands/backBtnIcon.png')} alt='back' />
          Back to Campaigns
        </button>
      ) : null}

      {dashboardLoading ? (
        <Loading />
      ) : !dashboardLoading && dashboardData && dashboardData.clients.length > 0 ? (
        <div className={styles.brandsContainer_content}>
          <table>
            <Brands />

            <Campaigns />

            <Ads />
          </table>
        </div>
      ) : dashboardData && dashboardData.clients.length === 0 ? (
        <div className={styles.noData}>
          <img src={toAbsoluteUrl('/assets/icons/Dashboard/noData_dashboard.png')} alt='noData' />
          {t('DashboardPage.noDataAvailable')} 🎯
        </div>
      ) : null}
    </div>
  )
}

export default DashboardTable
