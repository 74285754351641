import styles from '../../PayoutAccount.module.scss'
import {useContext} from 'react'
import PayoutAccountContext from 'context/PayoutAccountContext'
import CitiesSelect from '../CitiesSelect/CitiesSelect'
import DistrictSelect from '../DistrictSelect/DistrictSelect'
import {useTranslation} from 'react-i18next'

const LtdCompanyInput = () => {
  const {t} = useTranslation()
  const {payoutAccountData, setPayoutAccountData} = useContext(PayoutAccountContext)
  if (!payoutAccountData) return null
  return (
    <>
      {/* Company Name */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.companyName')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.companyName')}
          defaultValue={payoutAccountData?.business_full_name}
          onChange={(e) =>
            setPayoutAccountData({...payoutAccountData, business_full_name: e.target.value})
          }
        />
      </div>

      {/* City */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.city')}</div>
        <CitiesSelect />
      </div>
      {/* District */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.district')}</div>
        <DistrictSelect />
      </div>
      {/* Adress */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.address')}</div>
        <textarea
          placeholder={'' + t('SettingsPage.address')}
          defaultValue={payoutAccountData?.business_full_address}
          onChange={(e) => {
            setPayoutAccountData({...payoutAccountData, business_full_address: e.target.value})
          }}
        ></textarea>
      </div>
      {/* Tax Number */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.taxNumber')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.taxNumber')}
          defaultValue={payoutAccountData?.business_tax_id}
          onChange={(e) => {
            setPayoutAccountData({...payoutAccountData, business_tax_id: e.target.value})
          }}
        />
      </div>
      {/* Tax Office */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.taxOffice')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.taxOffice')}
          defaultValue={payoutAccountData?.business_tax_administration}
          onChange={(e) => {
            setPayoutAccountData({
              ...payoutAccountData,
              business_tax_administration: e.target.value,
            })
          }}
        />
      </div>

      {/* Payment Method */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.payoutMethod')}</div>
        <select disabled>
          <option value='wire' selected={payoutAccountData.payment_method === 'wire'}>
            Wire
          </option>
        </select>
      </div>

      {/* IBAN */}
      <div className={styles.line}>
        <div className={styles.label}>IBAN</div>
        <input
          type='text'
          placeholder='IBAN'
          defaultValue={payoutAccountData.IBAN}
          onChange={(e) => {
            setPayoutAccountData({
              ...payoutAccountData,
              IBAN: e.target.value,
            })
          }}
        />
      </div>
    </>
  )
}

export default LtdCompanyInput
