//tslint:disable
//ts-ignore
//ts-nocheck

import {useContext, useState} from 'react'
import classNames from 'classnames'

import {formatDate, toAbsoluteUrl} from 'helpers'

import {useTranslation, Trans} from 'react-i18next'
//STYLES
import styles from './CampaignDetail.module.scss'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'
// TYPES
import {Campaign} from 'components/CampaignPopup/CampaignPopup'
import AffiliateLink from './Components/AffiliateLink/AffiliateLink'
import SwitchButton from './Components/SwitchButton/SwitchButton'
import {AffiliateCampaignDetail} from 'services/model/response/campaigns/affiliateCampaignDetail'
import {EnumAffiliateCampaignStatus} from 'services/model/enum/enumAffiliateCampaignStatus'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import {createCampaignService} from 'services/campaignService'
import {toast} from 'react-toastify'
import {useAffiliateCampaignsContext} from 'context/AffiliateCampaignsContext'

type CampaignDetailProps = {
  campaign: AffiliateCampaignDetail
  setCampaign: (campaign: AffiliateCampaignDetail) => void
  streamerList: {
    streamers: [
      {
        link_to_logo: string | null
        title: string
      }
  ] | null
    count: number
  }
}

const CampaignDetail = ({campaign, setCampaign, streamerList}: CampaignDetailProps) => {
  const {applyToCampaign} = useAffiliateCampaignsContext()
  const campaignService = createCampaignService()
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()

  const changeChatLinkSharing = async (status: boolean) => {
    const prevStatus = campaign.chatLinkSharing
    setCampaign({
      ...campaign,
      chatLinkSharing: status,
    })
    try {
      const response = await toast.promise(
        campaignService.updateAffiliateCampaign({
          campaignId: campaign.id,
          chatLinkSharing: status,
        }),
        {
          pending: 'Updating...',
          success: 'Updated',
        }
      )
      setCampaign({
        ...campaign,
        chatLinkSharing: response.chatLinkSharing,
      })
    } catch (error: any) {
      console.log(error)
      setCampaign({
        ...campaign,
        chatLinkSharing: prevStatus,
      })
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      }
    }
  }

  const apply = async (id: number) => {
    try {
      const response = await applyToCampaign(id)
      if (!response) return
      setCampaign({
        ...campaign,
        status: response.status,
      })
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      }
    }
  }

  return (
    <div className={styles.campaignPopupTab}>
      <div className={styles.popupHeader}>
        <div className={styles.campaignCustomer}>
          <div className={styles.brandName}>
            {campaign.clientName}
            <button
              className={classNames(styles.button, {
                [styles.active]: campaign.status === EnumAffiliateCampaignStatus.Active,
                [styles.apply]: campaign.status === EnumAffiliateCampaignStatus.Available,
                [styles.denied]: campaign.status === EnumAffiliateCampaignStatus.Denied,
                [styles.pending]: campaign.status === EnumAffiliateCampaignStatus.Pending,
                [styles.past]: campaign.status === EnumAffiliateCampaignStatus.Past,
              })}
            >
              {campaign.status === 'available' ? (
                <div onClick={() => apply(campaign.id)}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M7.45496 9.4844L5.76896 8.92265C4.00421 8.33465 3.12146 8.04065 3.12146 7.4999C3.12146 6.9599 4.00421 6.66514 5.76896 6.07714L12.1537 3.94864C13.3957 3.53464 14.0167 3.32765 14.3445 3.6554C14.6722 3.98315 14.4652 4.60415 14.052 5.8454L11.9227 12.2309C11.3347 13.9956 11.0407 14.8784 10.5 14.8784C9.95996 14.8784 9.66521 13.9956 9.07721 12.2309L8.51471 10.5456L11.7802 7.28015C11.9168 7.13869 11.9924 6.94924 11.9907 6.75259C11.989 6.55595 11.9101 6.36784 11.7711 6.22878C11.632 6.08973 11.4439 6.01085 11.2473 6.00914C11.0506 6.00743 10.8612 6.08303 10.7197 6.21965L7.45496 9.4844Z'
                      fill='#FAFAFA'
                    />
                  </svg>
                  {t('CampaignsPopup.apply')}
                </div>
              ) : (
                t(`CampaignsPage.${campaign.status}`)
              )}
            </button>
          </div>
          <div className={styles.campaignName}>{campaign.name}</div>
        </div>
      </div>
      <div className={styles.campaignDetail_header}>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.startEndDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/start-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.startDate')}
          </div>
          <div className={styles.item_value}>{formatDate('MMM DD, YYYY', campaign.startDate)}</div>
        </div>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.startEndDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/start-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.endDate')}
          </div>
          <div className={styles.item_value}>{formatDate('MMM DD, YYYY', campaign.endDate)}</div>
        </div>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.payableDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/payable-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.payableDate')}
          </div>
          <div className={styles.item_value}>
            <Trans
              i18nKey={'CampaignsPopup.netPaymentDayEOM'}
              values={{
                day: campaign.paymentDaysFromEOM,
              }}
            >
              <b></b>
            </Trans>
          </div>
        </div>
      </div>

      <div className={styles.participations}>
        {streamerList.streamers?.map((streamer, index) => (
          <img src={streamer.link_to_logo ?? toAbsoluteUrl(`/assets/icons/Leaderboard/defaultProfileImg.png`)}
          alt={streamer.title} key={index} />
        ))}
        <span className={styles.participationsDetail}>
          <Trans
            i18nKey={'CampaignsPopup.streamersInCampaign'}
            values={{
              count: '+' + streamerList.count,
            }}
          >
            <span className={styles.participationsCount}></span>
          </Trans>
        </span>
      </div>

      <AffiliateLink status={campaign.status} link={campaign.url} />

      <div className={styles.section}>
        {campaign.conversionDefition ? (
          <div className={styles.sectionItem}>
            <div className={styles.title}>{t('CampaignsPopup.conversionDefinition')}</div>
            <div className={styles.desc}>{campaign.conversionDefition}</div>
          </div>
        ) : null}
      </div>

      <div className={styles.campaignDetail_footer}>
        <div className={styles.campaignRPC}>
          <div className={styles.mainTitle}>{t('CampaignsPopup.revenuePerConversion')}</div>
          {campaign.conversionModels.map((conversionModel, index) => (
            <div className={styles.rpcItem} key={index}>
              <div className={styles.title}>{conversionModel.name}</div>
              <div className={styles.value}>{conversionModel.value}</div>
            </div>
          ))}
        </div>
        <div className={styles.campaignBrief}>
          <img
            className={styles.livadLogoMark}
            src={toAbsoluteUrl('/assets/icons/logoMark.png')}
            alt='logo'
          />
          <div className={styles.campaignBrief_title}>{t('CampaignsPopup.campaignBrief')}</div>
          {campaign.brief && (
            <p
              className={styles.campaignBrief_text}
              dangerouslySetInnerHTML={{__html: campaign.brief}}
            ></p>
          )}
        </div>
      </div>
      <div className={styles.section}>
        {campaign.assetLibraryUrl ? (
          <div className={styles.sectionItem}>
            <div className={styles.title}>{t('CampaignsPopup.assetLibrary')}</div>
            <div className={styles.desc}>
              <Trans i18nKey={'CampaignsPopup.visualAssetDownload'}>
                <a href={campaign.assetLibraryUrl} target='_blank'></a>
              </Trans>
            </div>
          </div>
        ) : null}
        <div className={styles.sectionItem}>
          <div className={styles.title}>
            {t('CampaignsPopup.chatLinkSharing')}
            <SwitchButton
              isActive={campaign.chatLinkSharing}
              setIsActive={(status) => changeChatLinkSharing(status)}
            />
          </div>
          <div className={styles.desc}>{t('CampaignsPopup.chatLinkSharingDesc')}</div>
        </div>
      </div>
    </div>
  )
}

export default CampaignDetail
