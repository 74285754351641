enum EnumSettingsTab {
  ProfileDetails = 'profile-details',
  PayoutAccount = 'payout-account',
  SocialAccounts = 'social-accounts',
  StreamingPreferences = 'streaming-preferences',
  SponsorshipPreferences = 'sponsorship-preferences',
  DonationPreferences = 'donation-preferences',
  Interests = 'interests',
  OverlaySetup = 'overlay-setup',
  Moderators = 'moderators',
}

export default EnumSettingsTab
