import styles from './Payability.module.scss'
import {useContext} from 'react'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'

// Context
import {AppLevelContext} from 'context/AppLevelContext'

const SingleCampaign = ({item}: any) => {
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()
  return (
    <tr>
      <td>{item.campaign_name}</td>
      <td>{item.payable_date_detailed}</td>
      <td>
        <span
          className={classNames(styles.payableStatus, {
            [styles.payable]: item.is_payable === 1,
            [styles.locked]: item.is_payable === 0,
          })}
        >
          {item.is_payable === 1 ? (
            <img src={toAbsoluteUrl('/assets/icons/Payments/payableStatus.png')} alt='status' />
          ) : (
            <img src={toAbsoluteUrl('/assets/icons/Payments/lockedStatus.png')} alt='status' />
          )}

          {item.is_payable === 1 ? t('PaymentsPage.payable') : t('PaymentsPage.locked')}
        </span>
      </td>
      <td>
        {item.revenue.toLocaleString()} {user?.currency_sign}
      </td>
    </tr>
  )
}

export default SingleCampaign
