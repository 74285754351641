import {useContext} from 'react'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'
// CONTEXT
import LeaderboardContext from 'context/LeaderboardContext'
import {AppLevelContext} from 'context/AppLevelContext'
// STYLES
import styles from './Stats.module.scss'

const Stats = () => {
  const {leaderboardData, selectedIndex} = useContext(LeaderboardContext)
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()
  return (
    // TODO: #4f18cd is Brand Color - GET IT FROM API and replace it here
    <div
      className={styles.my_leaderboard_stats}
      style={{
        background: leaderboardData?.[selectedIndex]?.contest?.brandColor1
          ? `linear-gradient(180deg, #252525 0%, ` +
            leaderboardData?.[selectedIndex]?.contest?.brandColor1 +
            ` 100%)`
          : undefined,
      }}
    >
      <img
        src={toAbsoluteUrl('/assets/icons/Leaderboard/logomark.png')}
        alt=''
        className={styles.livad_logo_mark}
        draggable='false'
      />
      <img src={user?.link_to_logo} alt='' className={styles.my_image} draggable='false' />
      <h3 className={styles.my_username}>{user?.title}</h3>
      <span className={styles.my_position}>
        {t('LeaderboardPage.yourPosition')}{' '}
        {leaderboardData?.[selectedIndex]?.self?.position || 'N/A'}
      </span>
      <div className={styles.my_score_box}>
        <span className={styles.my_score_title}>{t('LeaderboardPage.yourScore')}</span>
        <span className={styles.my_score}>
          {leaderboardData?.[selectedIndex]?.self?.points || 'N/A'}
        </span>
      </div>
      {/* SHARE TWITTER */}
      {/* <button className={styles.my_score_share}>
        <img src={toAbsoluteUrl('/assets/icons/Leaderboard/twitter-icon.png')} alt='' />
        Share on Twitter
      </button> */}
    </div>
  )
}

export default Stats
