import styles from './TargetingInfo.module.scss'
import {useTranslation} from 'react-i18next'
import {Campaign} from 'components/CampaignPopup/CampaignPopup'
import EnumCategoryTargetingType from 'services/model/enum/enumCategoryTargetingType'
import {toAbsoluteUrl} from 'helpers'

interface TargetingInfoProps {
  campaign: Campaign
}
const TargetingInfo = ({campaign}: TargetingInfoProps) => {
  const {t} = useTranslation()
  if (
    !campaign.category_targeting_on &&
    !campaign.language_targeting_on &&
    !campaign.country_targeting_on
  )
    return null
  return (
    <div className={styles.targetingInfo}>
      {campaign.country_targeting_on == true && (
        <div className={styles.infoItem}>
          <div className={styles.itemTitle}>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth={0}
              viewBox='0 0 24 24'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zM4 12c0-.899.156-1.762.431-2.569L6 11l2 2v2l2 2 1 1v1.931C7.061 19.436 4 16.072 4 12zm14.33 4.873C17.677 16.347 16.687 16 16 16v-1a2 2 0 0 0-2-2h-4v-3a2 2 0 0 0 2-2V7h1a2 2 0 0 0 2-2v-.411C17.928 5.778 20 8.65 20 12a7.947 7.947 0 0 1-1.67 4.873z' />
            </svg>
            {t('CampaignsPopup.countryTargeting')}
          </div>
          <div className={styles.itemValue}>
            {campaign.countries.map((country) => {
              return (
                <div className={styles.valueItem}>
                  <img src={country.logo} className={styles.flagIcon} />
                  {country.name}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {campaign.language_targeting_on == true && (
        <div className={styles.infoItem}>
          <div className={styles.itemTitle}>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth={0}
              viewBox='0 0 512 512'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='m478.33 433.6-90-218a22 22 0 0 0-40.67 0l-90 218a22 22 0 1 0 40.67 16.79L316.66 406h102.67l18.33 44.39A22 22 0 0 0 458 464a22 22 0 0 0 20.32-30.4zM334.83 362 368 281.65 401.17 362zm-66.99-19.08a22 22 0 0 0-4.89-30.7c-.2-.15-15-11.13-36.49-34.73 39.65-53.68 62.11-114.75 71.27-143.49H330a22 22 0 0 0 0-44H214V70a22 22 0 0 0-44 0v20H54a22 22 0 0 0 0 44h197.25c-9.52 26.95-27.05 69.5-53.79 108.36-31.41-41.68-43.08-68.65-43.17-68.87a22 22 0 0 0-40.58 17c.58 1.38 14.55 34.23 52.86 83.93.92 1.19 1.83 2.35 2.74 3.51-39.24 44.35-77.74 71.86-93.85 80.74a22 22 0 1 0 21.07 38.63c2.16-1.18 48.6-26.89 101.63-85.59 22.52 24.08 38 35.44 38.93 36.1a22 22 0 0 0 30.75-4.9z' />
            </svg>
            {t('CampaignsPopup.languageTargeting')}
          </div>
          <div className={styles.itemValue}>
            {campaign.languages.map((language) => {
              return (
                <div className={styles.valueItem}>
                  <img src={language.logo} className={styles.flagIcon} />
                  {language.name}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {campaign.category_targeting_on == true && (
        <div className={styles.infoItem}>
          <div className={styles.itemTitle}>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth={0}
              viewBox='0 0 24 24'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z' />
            </svg>
            {campaign.category_targeting_type === EnumCategoryTargetingType.Included
              ? t('CampaignsPopup.categoryTargeting')
              : t('CampaignsPopup.exludedCategories')}
          </div>
          <div className={styles.itemValue}>
            {(campaign.category_targeting_type === EnumCategoryTargetingType.Included
              ? campaign.categories
              : campaign.excludedCategories
            ).map((category) => {
              return (
                <div className={styles.valueItem}>
                  <img
                    src={
                      category.logo ?? toAbsoluteUrl('/assets/icons/defaultCategoryThumbnail.png')
                    }
                    className={styles.flagIcon}
                    onError={(e) => {
                      e.currentTarget.src = toAbsoluteUrl(
                        '/assets/icons/defaultCategoryThumbnail.png'
                      )
                    }}
                  />
                  {category.name}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default TargetingInfo
