
import { toAbsoluteUrl } from 'helpers'
import styles from '../KickAuthForm.module.scss'
import classNames from 'classnames'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { AppLevelContext } from 'context/AppLevelContext'

interface Props {
    view: string
    setView: (view: string) => void
}

const ForgotPassword = ({view, setView}: Props) => {
    const {resetPassword} = useContext(AppLevelContext);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    const handlePasswordReset = async (e: any) => {
        e.preventDefault();
        if (isLoading) return;
        if (!email) {
          toast.error("Please enter email");
          return;
        }
        setIsLoading(true);
        const data = await resetPassword(email);
        setIsLoading(false);
        if (data.success) {
          toast.success("Password reset email sent");
        } else {
          toast.error(data.error);
        }
      };
    return (
    <div className={styles.forgetPasswordView}>
        <div className={styles.authFormWrap}>
          <div className={styles.inputLine}>
            <button
              className={styles.backBtn}
              onClick={() => {
                setView('signIn')
              }}
            >
              <img src={toAbsoluteUrl('/assets/icons/Auth/backIcon.png')} alt='Back' /> Back to
              Login
            </button>
          </div>

          <div className={styles.inputLine}>
            <input
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handlePasswordReset(e)
                }
              }}
              type='text'
              placeholder='Email'
              id='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className={styles.inputIcon}>
              <img
                src='https://storage.googleapis.com/livad-ads/Images/Auth/email-icon.png'
                alt=''
                draggable='false'
                onContextMenu={(e) => e.preventDefault()}
              />
            </span>
          </div>

          <div className={styles.inputLine}>
            <button
              onClick={handlePasswordReset}
              className={classNames(styles.resetPasswordBtn, {
                [styles.disabled]: isLoading, //TODO: isLoading
              })}
            >
              {!isLoading ? (
              <span className={styles.btnText}>Request Password Reset</span>
              ) : (
                <span className={styles.isLoading}>
                  <img src={toAbsoluteUrl('/assets/icons/loading.gif')} alt='Loading' />
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    )
}
export default ForgotPassword