export const HelpCenterData = [
  {
    question: {
      tr: "LIVAD'i kullanmak için bir ön şart var mı?",
      en: 'Are there any requirements to use LIVAD?',
      es: '',
      questionKey: 'requirementsQuestion',
    },
    answer: {
      tr: "<p>LIVAD'e her ölçekten yayıncı katılabilir. Takipçi sayısı ya da anlık izlenme sayısının bir önemi yoktur.</p>",
      en: '<p>Streamers of any size can join LIVAD. Follower or concurrent view count does not matter.</p>',
      es: '',
      answerKey: 'requirementsAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '1',
  },
  {
    question: {
      tr: 'Reklamlar hangi sıklıkla gönderilir?',
      en: 'How often do ads display?',
      es: '',
      questionKey: 'adFrequencyQuestion',
    },
    answer: {
      tr: `<p>Reklam gösterim sıklıklarımız dönemsel olarak değişiklik gösterebilir. Bir yayın içerisinde sıklıkla reklam alabileceğin gibi hiç reklam gelmemesi de olasıdır. Reklam gösterim sıklıkları birçok algoritma kullanılarak anlık olarak hesaplanır.</p>
        <p>Ayrıca reklam sıklığınızı Canlı konsol üzerinden manuel olarak da ayarlayabilir, kanalının hangi sıklıkta reklam almaya uygun olduğunu işaretleyebilirsin. 
        </p>      
        <p><strong>Unutmayın: Canlı Konsol üzerinde ayarladığın reklam sıklığı en yoğun kampanya döneminde geçerlidir. Ayarladığın süreler içerisinde reklam alacağını kesinlikle ifade etmez. </strong></p>
        `,
      en: `<p>Our ad display frequencies may change periodically. Receiving ads frequently is as probable as receiving none at all during a stream. Ad display frequencies are calculated in real time by many algorithms.</p>
        <p>Furthermore, you can manually adjust your ad display frequency on your Live Console and determine which recurrence fits your broadcast best.</p>
       <p> <strong>Don't forget: The ad frequence you determine on your Live Console is most valid during busy campaign periods. It certainly is not guaranteed that you will receive advertisements within the time you set.</strong></p>`,
      es: '',
      answerKey: 'adFrequencyAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '2',
  },
  {
    question: {
      tr: 'Kabul ettiğim kampanya sayısı gönderilecek reklam sayısını etkiler mi?',
      en: 'Do the amount of campaigns I have accepted affect the amount displayed ads?',
      es: '',
      questionKey: 'campaignsQuestion',
    },
    answer: {
      tr: '<p>Evet, daha fazla kampanya kabul etmek gönderilecek reklamların sayısını artırabilir.</p>',
      en: '<p>Yes, accepting more campaigns may increase the amount of ads you receive</p>',
      es: '',
      answerKey: 'campaignsAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '3',
  },
  {
    question: {
      tr: 'Hiçbir teknik sorunum yok, neden reklam alamıyorum?',
      en: 'There are no technical issues. Why am I not receiving any ads?',
      es: '',
      questionKey: 'noAdsTechQuestion',
    },
    answer: {
      tr: `<p>Aşağıdaki durumlar buna sebep oluyor olabilir:
        :</p>
        <ol>
          <li><b>Yardım Merkezi</b>’nde yer alan <b>Kurulum</b> sekmesindeki aşamaları eksiksiz gerçekleştirdiğinden emin ol. Buna rağmen sorun devam ediyorsa lütfen <a target="_blank" href="https://discord.gg/AtsxfSfueW">Discord sunucumuz</a> üzerinden destek ile iletişime geç. </li>
          <li>Kanalın üzerinden livadbot’a modluk yetkisi verdiğinden emin ol. (/mod livadbot)</li>
          <li>LIVAD Yayıncı Dashboard’unda yer alan senin için ‘’uygun’’ olarak işaretlenmiş kampanyaları onayladığından emin ol.</li>
          <li>Hesabın veri uyuşmazlığı veya yayın politikalarının ihlali nedeniyle askıya alınmış olabilir, bu durumlarda bizimle iletişime geçmen gerekiyor.</li>
          <li>Panel üzerinde hatalı para birimi seçmiş olabilirsin. Türkiye’deki yayıncılar için kazanç sağlayabilecekleri para birimi TRY’dir. TRY seçimi yapıldığında sorun çözülecektir.</li>
          <li>Kanal ismini değiştirmiş olabilirsiniz. Eğer kanal isminde bir değişiklik yaptıysan bunun sistem tarafından güncellemesi biraz vakit alabilir.</li>
        </ol>
      `,
      en: `<p>The following conditions may cause this:</p>
          <ol>
            <li>You may have changed your Nickname/Channel link. If you have made such a change, it may take some time for it to be updated in our system.</li>
            <li>Make sure you complete the steps in the <b>Setup Instructions</b> tab under <b>Help Center</b>. If the problem still persists, please contact support via our <a target="_blank" href="https://discord.gg/AtsxfSfueW">Discord server.</a> </li>
            <li>Make sure you have modded livadbot through on your Twitch channel. (/mod livadbot)</li>
            <li>Make sure you approve the campaigns marked as "available" for you on the LIVAD Streamer Dashboard.</li>
            <li>Your account may have been suspended due to a data conflict or violation of creator policies, in which case you need to contact us.</li>
            <li>You may have selected the wrong currency on your Streamer Dashboard. 
            For creators in Turkey, the currency they can earn is TRY. The problem will be solved when TRY is selected.</li>
          </ol>
        `,
      es: '',
      answerKey: 'noAdsTechAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '4',
  },
  {
    question: {
      tr: 'Manuel Reklam Gönderme nedir?',
      en: 'What is Manual Ad Posting?',
      es: '',
      questionKey: 'manualAdPostingQuestion',
    },
    answer: {
      tr: '<p>Manuel Reklam Gönderme, yayıncıların reklamları manuel olarak göndermesine olanak tanıyan yeni bir özelliktir. Bu özellik sayesinde reklamları dilediğiniz anlarda göndererek daha etkili tanıtımlar yapabilir, kampanya sonrası markalara gönderilen en iyi tanıtımlara yer verilen videolarda yer alabilirsiniz. </p>',
      en: '<p>Manual Ad Posting is a new feature allowing creators to send the ads manually. This way we aim to give creators more control over their ad experience and help them to create better ad moments and brand shoutouts.</p>',
      es: '',
      answerKey: 'manualAdPostingAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '38',
  },
  {
    question: {
      tr: 'Yayında kendime nasıl manuel reklam gönderirim?',
      en: 'How can I send the ads manually?',
      es: '',
      questionKey: 'manualAdPostingHowQuestion',
    },
    answer: {
      tr: `<ol>
              <li>İlk olarak streamer.livad.stream/live-console adresinden Canlı Konsol'a girmeli ve sağdaki Reklam Yöneticisi'nden Atama Modu'nu Manuel'e almalısın. </li>
              <li>Manuel'e aldıktan sonra, yayındayken — eğer o anda sistemde de uygunluk varsa, kabul ettiğin kampanyaların altında  'Reklam Gönder' butonunun aktif hale gelecektir.</li>
              <li>Aktif haldeki Reklam Gönder butonuna bastıktan kısa bir süre sonra reklam yayınına ulaşmış olacak. </li>
        </ol>
        <p>Böylece artık reklamları uygun olduğun anlarda kendin atarak daha etkili tanıtımlar yapabilir, izleyicilerinden daha yüksek ve gerçek etkileşim alarak daha fazla kampanyaya katılabilirsin! </p>
        <p>Notlar: </p>
        <ul>
          <li>Eğer Atama Modu'nu Manuel'e alırsan reklamlarla gelen linkler chat'e livadbot yerine yayıncının kendi Twitch hesabından atılacaktır.</li>
          <li>Buton hemen aktif olmayabilir, biraz bekledikten sonra bazı kampanyaların mor renge döndüğünü göreceksin.</li>
          <li> Manuel olarak reklamı gönderdikten sonra tekrar göndermeden önce bir süre beklemen gerekli.</li>
        </ul>
        `,
      en: `<ol>
        <li>Go to https://streamer.livad.stream/live-console and select 'Manual' under your 'Ads Manager'.</li>
        <li>If our system thinks that it's an appropriate time to post an ad, the 'Post Ad' button will become clickable. (Only when you are live)</li>
        <li>Click on 'Post Ad' to send yourself an ad.</li>
  </ol>
  <p>This way you will be able to post yourself an ad at the perfect moment for better shoutouts, have your audience be more engaged with the ads, and create higher CTR%.</p>
  <p>Notes: </p>
  <ul>
    <li> Links are now posted through your own channel name, not Livadbot.</li>
    <li>If the button is not active, you may need to wait for it to turn purple.</li>
    <li> The 'Post Ad' button has a cooldown.</li>
  </ul>
  `,
      es: '',
      answerKey: 'manualAdPostingHowAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '39',
  },
  {
    question: {
      tr: 'Her kampanyanın ücreti farklı mı?',
      en: 'Is the pay for each campaign different?',
      es: '',
      questionKey: 'campaignPayQuestion',
    },
    answer: {
      tr: '<p>Evet, markalarla yapılan anlaşmalar doğrultusunda kampanya ücretleri (RPM) değişkenlik gösterebilir</p>',
      en: '<p>Yes, the campaign fees (RPM) may vary in line with the agreements made with the brands.</p>',
      es: '',
      answerKey: 'campaignPayAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '5',
  },
  {
    question: {
      tr: 'Yeni kampanyalardan nasıl haberdar olabilirim?',
      en: 'How can I stay up to date about new campaigns?',
      es: '',
      questionKey: 'campaignUpdatesQuestion',
    },
    answer: {
      tr: `<p>Kanalına uygun olan yeni kampanyalar Yayıncı Panel’ine otomatik olarak eklenir ve her pazartesi günü discord.gg/livad adresindeki Discord sunucumuzda haftanın kampanyalarının detaylarına dair bilgilendirme yapılır. Sunucumuza katılarak olarak ya da sosyal medya adreslerimizi takip ederek de güncel kampanyaları takip edebilirsin.
        </p>
          <a target="_blank" href="http://instagram.com/livadstream">Instagram </a> -
          <a target="_blank" href="http://twitter.com/livadstream">Twitter</a> 
        `,
      en: `<p>New campaigns are automatically added to your Streamer Dashboard. Every Monday, we inform you about the details of the week's campaigns on our Discord server at discord.gg/livad. You can follow current campaigns by joining our Discord server or by following our social media addresses.
        </p>
          <a target="_blank" href="http://instagram.com/livadstream">Instagram </a> -
          <a target="_blank" href="http://twitter.com/livadstream">Twitter</a> 
        `,
      es: '',
      answerKey: 'campaignUpdatesAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '6',
  },
  {
    question: {
      tr: 'Kaç tane kampanya kabul edebilirim?',
      en: 'How many campaigns may I accept?',
      es: '',
      questionKey: 'campaignAcceptanceQuestion',
    },
    answer: {
      tr: '<p>Panelinde yer alan Kampanyalar sekmesinde size tanımlanmış "uygun" kategorisindeki bütün reklamları kabul edebilirsin. Bu konuda herhangi bir sınırlamamız bulunmamaktadır.</p>',
      en: '<p>You may accept all ads appointed to you in the "available" category in Campaigns tab on your Streamer Dashboard and there are no limitations regarding this. </p>',
      es: '',
      answerKey: 'campaignAcceptanceAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '7',
  },

  {
    question: {
      tr: 'Daha fazla reklam nasıl alabilirim?',
      en: 'How can I receive more campaigns?',
      es: '',
      questionKey: 'campaignFrequencyQuestion',
    },
    answer: {
      tr: `
        <p>Reklam alma sıklığını etkileyen faktörler şunlardır:
        </p>
        <ol>
        <li>CTR (Reklam tıklanma oranın):
          <p>Yayın esnasında yayınına düşen LIVAD reklamını kurallara uygun bir şekilde, kampanya sayfasında yer alan brief’e göre tanıtmanı ve izleyicilerinizi ilgili linklere yönlendirmen beklenir.Bu yönlendirmeden elde edilen reklam tıklamaları kanalının CTR oranını oluşturur. Özgün ve etkileyici tanıtımlar CTR oranını ciddi oranda olumlu etkiler. Clickbait tanıtımlar (sadece tıklamaya yönlendirilen tanıtımlar) reklam alma sıklığını ciddi oranda azaltır ve bir süre sonra reklamlar tamamen durdurulur.
          </p>
        </li>
        <li>
        Kampanya kabul etme oranın:
        <p>LIVAD Panel'inde kanalına özel olarak belirlenmiş ve “Uygun” etiketine sahip kampanyaları kabul etmek kampanya katılım oranını arttıracağı için daha fazla reklam almanı sağlar.</p>
        </li>
        </ol>
      `,
      en: `
          <p>The factors that affect the frequency of receiving advertisements are:
          </p>
          <ol>
          <li>CTR (Click Through Rate):
            <p>You are expected to announce the LIVAD advertisemens which appear on your stream, in accordance with the rules according to the brief on the campaign page, and to direct your audience to the relevant links.
            </p>
          </li>
          <li>
          Campaign acceptance rate:
          <p>In the Streamer Dashboard, accepting the campaigns that are determined specifically for your channel under the label “Available” will increase your campaign participation rate, thus allowing you to receive more advertisements.</p>
          </li>
          </ol>
        `,
      es: '',
      answerKey: 'campaignFrequencyAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '8',
  },
  {
    question: {
      tr: 'Reklamların süreleri ne kadardır?',
      en: 'How long do campaigns visual assets last on the screen?',
      es: '',
      questionKey: 'campaignDurationQuestion',
    },
    answer: {
      tr: '<p>LIVAD reklamlarının süresi 5 saniye ile 1 dakika arası değişkenlik gösterir.</p>',
      en: '<p>The duration of LIVAD advertisements varies between 5 seconds and 1 minute.</p>',
      es: '',
      answerKey: 'campaignDurationAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '9',
  },
  {
    question: {
      tr: 'Kliplerimi size neden göndermeliyim?',
      en: 'Why should I send my clips to you?',
      es: '',
      questionKey: 'clipSubmissionQuestion',
    },
    answer: {
      tr: "<p>Gönderdiğin klipler ile sosyal medya paylaşımlarımızda ve markalara iletilen 'en iyi tanıtım yapılan klipler' arasında yer alabilirsin</p>",
      en: "<p>With the clips you send, you can be among the 'best promoted clips' which we forward to our social media posts and to the brands.</p>",
      es: '',
      answerKey: 'clipSubmissionAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '10',
  },
  {
    question: {
      tr: 'Reklam konumlarını ben seçebiliyor muyum?',
      en: 'Can I adjust the position of the ads?',
      es: '',
      questionKey: 'adPositionQuestion',
    },
    answer: {
      tr: '<p>Kampanya ayrıntı ekranında yer alan "ayarlar" kısmında her kampanya için farklı konum ayarlaması yapabilirsin.</p>',
      en: "<p>You can adjust the position of each campaign individually in the 'settings' section on the campaign detail screen (after clicking on 'inspect' a campaign)</p>",
      es: '',
      answerKey: 'adPositionAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '11',
  },
  {
    question: {
      tr: 'Reklam çıktığında ne yapmalıyım, benden nasıl bir tanıtım bekleniyor?',
      en: 'What should I do when an ad appears, what kind of promotion is expected from me?',
      es: '',
      questionKey: 'promotionExpectationQuestion',
    },
    answer: {
      tr: '<p>Reklam gösterimi anlarında marka ve kampanya hakkında, kampanya ayrıntılarında yer alan brief ve chat mesajı dikkate alarak tanıtım yapmanı bekliyoruz. </p>',
      en: '<p>We expect you to promote the brand and the campaign at the time of ad screening by taking the brief and chat message, found in the campaign details, into account</p>',
      es: '',
      answerKey: 'promotionExpectationAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '12',
  },
  {
    question: {
      tr: 'Tıklama başına mı para kazanıyorum?',
      en: 'Do clicks generate revenue?',
      es: '',
      questionKey: 'revenueQuestion',
    },
    answer: {
      tr: `
        <p>Hayır, ücretlendirmeler RPM* üzerinden yapılmaktadır. 
        </p>
        <p>*RPM (Revenue per mille); Bin gösterim başına elde edilen gelir; reklamcılıkta yaygın olarak kullanılan bir metriktir. Yayıncının, bir reklamın bin kişiye gösteriminden elde ettiği geliri işaret eder.
        </p>
        `,
      en: `
        <p>No, revenue is is earned via RPM*.
        </p>
        <p>*RPM (Revenue per mille); Revenue per thousand impressions; It is a widely used metric in advertising. It indicates the creator's revenue from displaying an ad to one thousand people.
        </p>
        `,
      es: '',
      answerKey: 'revenueAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '13',
  },
  {
    question: {
      tr: 'Farklı bir dilde yayın açmaya başladım, ne yapmalıyım?',
      en: 'I started streaming in a different language, what should I do?',
      es: '',
      questionKey: 'languageChangeQuestion',
    },
    answer: {
      tr: '<p> Yayın dilini değiştirmen durumunda Discord üzerinden ticket açarak destek almalısın.</p>',
      en: "<p>In case you change your stream's language, you should ask for support by opening a ticket on our Discord server.</p>",
      es: '',
      answerKey: 'languageChangeAnswer',
    },
    category: 'campaigns',
    is_opened: false,
    id: '14',
  },
  {
    question: {
      tr: 'Yayın açtım, bu yayın neden reklam çıkmadı?',
      en: 'Why did I not receive any ads during my stream?',
      es: '',
      questionKey: 'noAdsQuestion',
    },
    answer: {
      tr: '<p>Reklam gösterim sıklıkları birçok algoritma kullanılarak anlık olarak hesaplanır, yayınlarınızda sıklıkla reklam alabileceğiniz gibi, birden fazla yayında hiç reklam gelmemesi de oldukça olasıdır.</p>',
      en: '<p>Ad frequencies are calculated in real-time using many algorithms. You may receive advertisements frequently in your broadcasts, however it may also be very possible that no advertisements will appear in one or more of your broadcasts.</p>',
      es: '',
      answerKey: 'noAdsAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '15',
  },
  {
    question: {
      tr: 'Reklam ekranımda çıktı ama chat’e link gelmedi, ne yapmalıyım?',
      en: 'The ad appeared on my screen, but the link did not appear in the chat. What should I do?',
      es: '',
      questionKey: 'missingChatLinkQuestion',
    },
    answer: {
      tr: '<p>livadbot’a moderatör yetkisi verdiğinden emin ol. (Twitch için <b>/mod livadbot</b> komutunu Twitch chatine girmen yeterlidir.)</p>',
      en: '<p>Make sure you have give livadbot moderator permissions for your channel. (For Twitch, simply enter the <b>/mod livadbot</b> command into your Twitch chat.)</p>',
      es: '',
      answerKey: 'missingChatLinkAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '16',
  },
  {
    question: {
      tr: 'Link geldi ama reklam çıkmadı, ne yapmalıyım?',
      en: 'The link appeared in the chat, but the ad did not appear on my stream. What should I do?',
      es: '',
      questionKey: 'missingAdDisplayQuestion',
    },
    answer: {
      tr: '<b>Yardım Merkezi</b>’nde yer alan <b>Kurulum</b> sekmesindeki aşamaları eksiksiz gerçekleştirdiğinden emin ol. Buna rağmen sorun devam ediyorsa <a href="https://discord.gg/livad" target="_blank">Discord sunucumuzdaki</a> <b>#streamer-help</b> kanalı üzerinden bizimle iletişime geçebilirsin.',
      en: 'Make sure you complete the steps in the <b>Setup Instructions</b> tab under <b>Help Center</b>. If the problem persists after completing the instructions, please contact our Creator Supports via the <b>#streamer-help</b> channel on our <a href="https://discord.gg/livad" target="_blank">Discord server</a>.',
      es: '',
      answerKey: 'missingAdDisplayAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '17',
  },
  {
    question: {
      tr: 'OBS linkimi ve kurulum aşamalarını nereden bulabilirim?',
      en: 'Where can I find my OBS link and set-up instructions?',
      es: '',
      questionKey: 'obsLinkQuestion',
    },
    answer: {
      tr: '<b>Yardım Merkezi</b>’nde yer alan Kurulum sekmesinde size özel oluşturulan tarayıcı linki ve <b>Kurulum</b> aşamalarına ulaşabilirsin.',
      en: 'You can find setup instructions and your unique link on <b>Setup Instructions</b> tab in <b>Help Center</b>.',
      es: '',
      answerKey: 'obsLinkAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '18',
  },
  {
    question: {
      tr: 'Sisteme yeni kayıt oldum. Neden Panelimde kampanyaları göremiyorum?',
      en: 'I am a new member. Why do I have any active campaigns available on my Streamer Dashboard?',
      es: '',
      questionKey: 'newMemberQuestion',
    },
    answer: {
      tr: `<p>Yeni kullanıcılar, kayıt tarihlerinden önce başlamış kampanyalara dahil olamamaktadır. Kayıt tarihinden sonra eklenen ve kanalına uygun kampanyaları panelinde yer alan Kampanyalar sekmesinden görüntüleyebilirsin. 
        </p>
        <p>Ayrıca yeni kampanyalardan Discord sunucumuza katılarak ve sosyal medya hesaplarımızı takip ederek haberdar olabilirsin. 
        </p>
        <a target="_blank" href="http://discord.gg/livad">Discord </a> -
        <a target="_blank" href="http://instagram.com/livadstream">Instagram </a> -
        <a target="_blank" href="http://twitter.com/livadstream">Twitter</a> 
        `,
      en: `<p>New users cannot participate in campaigns which have already started. Campaigns which are added after your registration date will be visible on the 'campaigns' tab on your Streamer Dashboard. 
        </p>
        <p>You can also stay up to date about new campaigns by joining our Discord server and following our social media accounts.
        </p>
        <a target="_blank" href="http://discord.gg/livad">Discord </a> -
        <a target="_blank" href="http://instagram.com/livadstream">Instagram </a> -
        <a target="_blank" href="http://twitter.com/livadstream">Twitter</a> 
        `,
      es: '',
      answerKey: 'newMemberAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '19',
  },
  {
    question: {
      tr: 'Twitch hesabımı bağlarken LIVAD neden bu kadar çok izin isteniyor?',
      en: 'Why does LIVAD ask for so many permissions when I connect my Twitch account?',
      es: '',
      questionKey: 'twitchPermissionsQuestion',
    },
    answer: {
      tr: `<p>
        Giriş yaparken aldığımız tüm izinleri; reklam gösterimlerinin doğru olduğundan emin olmak için klip almak, ardından bu klipleri silmek, bazı reklam modellerimizi (örn:anket) kullanmak ve reklam optimizasyonlarını doğru yapabilmek için kullanıyoruz. Senin iznin olmadan kanal ayarlarında, klip ve videolarda asla bir değişiklik yapılmaz.</p>`,
      en: `<p>
        We use the permissions we request to; take clips of your stream to ensure that the ad impressions are counted correctly, to delete clips we have taken, to be able to use some of our ad models (eg. Poll) and to optimize advertisements correctly. We will never make any changes to your channels settings, clips and videos without your permission. </p>`,
      es: '',
      answerKey: 'twitchPermissionsAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '20',
  },
  {
    question: {
      tr: 'Hesabımın askıya alındığını düşünüyorum. Ne yapmalıyım?',
      en: 'I think I have been suspended from LIVAD. What should I do?',
      es: '',
      questionKey: 'suspensionQuestion',
    },
    answer: {
      tr: '<a href="https://discord.gg/livad" target="_blank">Discord sunucumuza</a> katılarak Help kategorisinde yer alan <b>#streamer-help</b> kanalından bir ticket oluşturarak destek alabilirsin.',
      en: 'You can get support by creating a ticket in the <b>#streamer-help</b> channel in the Help category on our <a href="https://discord.gg/livad" target="_blank">Discord server</a>.',
      es: '',
      answerKey: 'suspensionAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '21',
  },
  {
    question: {
      tr: 'Neden randevu alamıyorum?',
      en: 'Why can I not make an appointment?',
      es: '',
      questionKey: 'appointmentQuestion',
    },
    answer: {
      tr: "<p>Randevu alabilmek için ilgili ekip arkadaşımızın müsaitlik durumunun kontrol edilmesi gerekmektedir. Randevu alamadığın koşullarda bir süre bekledikten sonra tekrardan Calendly link'i üzerinden saatleri kontrol edip randevu alabilirsin.</p>",
      en: "<p>In order to make an appointment, our teammates availability must be checked. If you can't get an appointment, you can try scheduling an appointment on our teammates Calendly link another time.</p>",
      es: '',
      answerKey: 'appointmentAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '23',
  },
  {
    question: {
      tr: 'Canlı Konsol nedir?',
      en: 'What is the Live Console?',
      es: '',
      questionKey: 'liveConsoleQuestion',
    },
    answer: {
      tr: '<p>Canlı Konsol`u yayınlarına gönderilen reklamlar üzerinde daha fazla kontrol sahibi olmanı sağlayan bir araçtır.</p><p><b>Canlı Konsol`da:</b></p><ul><li>Yayınına gönderilecek reklamları ve kampanya ayrıntılarını görüntüleyip, düzenleyebilirsin.</li><li>Reklamları kabul ve reddedebilir ya da gelecek reklamı erteleyebilirsin.</li></ul>',
      en: '<p>The Live Console is a tool that gives you more control over the ads sent to your streams.</p><p><b>On the Live Console:</b></p><ul><li>You can view and edit the advertisements and campaign which will be sent to your stream.</li><li>You can accept, deny or snooze the next upcoming ad.</li></ul>',
      es: '',
      answerKey: 'liveConsoleAnswer',
    },
    category: 'troubleshooting',
    is_opened: false,
    id: '24',
  },
  {
    question: {
      tr: 'IBAN ve kişisel bilgilerimi nasıl nasıl girmeliyim?',
      en: 'How do I enter my IBAN and personal information correctly?',
      es: '',
      questionKey: 'ibanEntryQuestion',
    },
    answer: {
      tr: '<p>IBAN bilgilerini BOŞLUKSUZ olarak girmen gerekli. Hesap sahibinin adı, soyadı ve diğer kişisel bilgileri faturanın doğru kesilebilmesi için oldukça önemlidir.</p>',
      en: '<p>You must enter your IBAN information WITHOUT SPACES. The name, surname and other requested personal information of the account holder is very important in order to issue the invoice correctly.</p>',
      es: '',
      answerKey: 'ibanEntryAnswer',
    },
    category: 'payouts',
    is_opened: false,
    id: '27',
  },
  {
    question: {
      tr: 'Kilitli Kazanç ve Ödenebilirlik nedir?',
      en: 'What are Locked Revenue and Payability?',
      es: '',
      questionKey: 'lockedRevenueQuestion',
    },
    answer: {
      tr: '<p>Kilitli Kazanç, reklam gösterimlerinden elde ettiğin ancak o sırada Kilitli durumda olan kazançlarındır. Reklamlardan elde ettiğin kazancın ödemesi reklam verenler tarafından yapılana kadar Kilitli durumda kalır, dolayısıyla talep edilebilir bakiyene eklenmez.</p><br><p>Ödenebilirlik Tarihini kontrol ederek kampanyadan elde ettiğin Kazancın ne zaman açılacağını ve talep edilebilir bakiyene ekleyebileceğini kontrol edebilirsin.</p>',
      en: '<p>Locked Revenue is Revenue that you have earned but have not yet unlocked. Campaign earnings are not payable and therefore are not added to your balance until they have been paid by the advertiser.</p><br><p>You can check "Payable Date" to find out when the Revenue will be unlocked and added to your requestable balance.</p>',
      es: '',
      answerKey: 'lockedRevenueAnswer',
    },
    category: 'payouts',
    is_opened: false,
    id: '40',
  },
  {
    question: {
      tr: "Neden ''kilitli kazanç'' ibaresi görüyorum?",
      en: "Why does it say 'locked earnings'?",
      es: '',
      questionKey: 'lockedEarningsQuestion',
    },
    answer: {
      tr: "<p>Bir kampanyadan elde ettiğin gelirleri, o kampanyaya ait “Ödenebilirlik Tarihinden” sonra alabilirsin. Bu tarihe kadar elde ettiğin gelirler “Kilitli” olarak görünür, bu durumdaki kazançları 'Kilitli Kazanç' altında görüntüleyebilirsin.</p>",
      en: "<p>You can only receive the revenue generated from a campaign after its 'Payable Date'. Income you have earned until this date will appear as 'locked'. In this case you can view your earnings under 'locked earnings' on the Payouts tab.  </p>",
      es: '',
      answerKey: 'lockedEarningsAnswer',
    },
    category: 'payouts',
    is_opened: false,
    id: '28',
  },
  {
    question: {
      tr: 'Başka bir şirket adına fatura kesebilir miyim?',
      en: 'Can I invoice on behalf of another company?',
      es: '',
      questionKey: 'canInvoiceQuestion',
    },
    answer: {
      tr: '<p>LIVAD olarak yayıncılarımızın vergi mükellefi olmadan gelir elde etmelerini önermiyoruz. Bu nedenle yalnızca resmi olarak pay sahibi ya da sahibi olduğun şirketler üzerinden fatura kesmelisin</p>',
      en: '<p>As LIVAD, we do not recommend that our creators generate income without being taxpayers. For this reason, you should only issue invoices on companies that you officially or partly own.</p>',
      es: '',
      answerKey: 'canInvoiceAnswer',
    },
    category: 'payouts',
    is_opened: false,
    id: '29',
  },
  {
    question: {
      tr: 'Faturamı neden yükleyemiyorum?',
      en: 'Why can I not upload my invoice?',
      es: '',
      questionKey: 'uploadInvoiceQuestion',
    },
    answer: {
      tr: '<p>Faturalarınızı yalnızca <b>.PDF</b> formatında yükleyebilirsin. Lütfen faturanızın bu formatta olduğundan emin ol.</p>',
      en: '<p>You can only upload your invoices in <b>.PDF</b> format. Please make sure your invoice is in this format.</p>',
      es: '',
      answerKey: 'uploadInvoiceAnswer',
    },
    category: 'payouts',
    is_opened: false,
    id: '30',
  },
  {
    question: {
      tr: 'Paramı ne zaman çekebilirim?',
      en: 'When can I request a payout?',
      es: '',
      questionKey: 'requestPayoutQuestion',
    },
    answer: {
      tr: "<p>LIVAD ödeme eşiği 500 ₺'dir. Ödenebilir kazancınız bu tutara ulaştığında bir ödeme talebi oluşturarak bakiyenin aktarılmasını sağlayabilirsin</p>",
      en: '<p>The LIVAD payment treshold is £/$/€100. When your payable earnings reach this amount, you can create a payment request and have the balance transferred.</p>',
      es: '',
      answerKey: 'requestPayoutAnswer',
    },
    category: 'payouts',
    is_opened: false,
    id: '31',
  },
  {
    question: {
      tr: 'Ödemem ne zaman hesabıma yatacak?',
      en: 'When will I receive my payment?',
      es: '',
      questionKey: 'receivePaymentQuestion',
    },
    answer: {
      tr: '<p><strong>Ayın ilk ve son günü</strong> arasında yapılan ödeme talepleri, takip eden ayın <strong>ilk 5 günü</strong> içerisinde hesabına gönderilecektir.</p>',
      en: '<p>Payment requests created between <strong>the first and last day </strong> of the month will be sent out to your account within the <strong>first 5 days</strong> of the following month.</p>',
      es: '',
      answerKey: 'receivePaymentAnswer',
    },
    category: 'payouts',
    is_opened: false,
    id: '32',
  },
  {
    question: {
      tr: 'Ödeme talebimi iptal etmek istiyorum. Ne yapmalıyım?',
      en: 'I want to cancel my payment request. What should I do?',
      es: '',
      questionKey: 'cancelPaymentQuestion',
    },
    answer: {
      tr: '<p><a href="https://discord.gg/livad" target="_blank">LIVAD Discord sunucusu</a> üzerinden ticket oluşturarak ödeme talebinin iptalini sağlayabilirsin.</p>',
      en: '<p>You can cancel your payment request by creating a ticket on the <a href="https://discord.gg/livad" target="_blank">LIVAD Discord server</a>.</p>',
      es: '',
      answerKey: 'cancelPaymentAnswer',
    },
    category: 'payouts',
    is_opened: false,
    id: '33',
  },
  {
    question: {
      tr: 'Yazılı Rehber',
      en: 'Text Guide',
      es: '',
      questionKey: 'textGuideTitle',
    },
    answer: {
      tr: `
        <h4>Aşağıdaki 6 adımı takip edin</h4>
        <p>Eğer bir sorun yaşıyorsan, <a target="_blank" href="https://discord.gg/livad">Discord sunucumuz</a> üzerinden yardım alabilirsin.</p>
          <div class="textGuide_container">
          <div class="textGuide_box">
            <div class="textGuide_box_title">1- Linki Kopyala & Yayın Programını Aç</div>
            <div class="textGuide_box_item">Size özel oluşturulan linki kopyalayın ve yayın yaptığınız yazılımı açın</div>
            <b class="textGuide_copyCode"><img src="/assets/icons/HelpCenter/setup-icon.png"> Tarayıcı linkini kopyala</b>
            <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/copyLink.gif"></div>
          </div>
          <div class="textGuide_box">
          <div class="textGuide_box_title">2- Yeni Tarayıcı Kaynağı Ekle</div>
          <div class="textGuide_box_item">Kaynaklar bölümünden yeni bir kaynak ekle,“Tarayıcı/Tarayıcı Kaynağı”yı seç ve “LIVAD” olarak adlandır</div>
          <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/browserSource.gif"></div>
        </div>
        <div class="textGuide_box">
        <div class="textGuide_box_title">3- Boyut Ayarlarını Düzenle</div>
        <div class="textGuide_box_item">Kopyaladığın linki URL kısmına yapıştır ve boyut ayarlarını şu şekilde düzenle: Genişlik: 1920, Yükseklik: 1080</div>
        <div class="textGuide_box_item">Eğer 1920x1080’den farklı bir çözünürlük ayarı kullanıyorsan, LIVAD kaynağına sağ tıklayarak Dönüştür - Ekrana Sığdır’ı seç</div>
        <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/setOverlay.gif"></div>
      </div>
      <div class="textGuide_box">
      <div class="textGuide_box_title">4- Kaynağı En Üste Koy</div>
      <div class="textGuide_box_item">LIVAD kaynağını diğer tüm kaynakların üzerinde konumlandır ve tüm sahnelere bu kaynağı ekle</div>
      <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/placement.gif"></div>
    </div>
    <div class="textGuide_box">
    <div class="textGuide_box_title">5- Her Sahnede Kilitle</div>
    <div class="textGuide_box_item">Kaynağı kilitle ve kaynağı hareket ettirme, boyutlandırma</div>
    <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/lockOverlay.gif"></div>
  </div>
        </div>
        `,
      en: `
        <h4>Follow 6 steps below</h4>
        <p>Having an issue? Join our <a target="_blank" href="https://discord.gg/livad">Discord server</a> to ask for help.</p>
          <div class="textGuide_container">
          <div class="textGuide_box">
            <div class="textGuide_box_title">1- Copy Link & Open Software</div>
            <div class="textGuide_box_item">Copy the link uniquely generated for you and open your streaming software</div>
            <b class="textGuide_copyCode"><img src="/assets/icons/HelpCenter/setup-icon.png"> Copy code</b>
            <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/copyLink.gif"></div>
          </div>
          <div class="textGuide_box">
          <div class="textGuide_box_title">2- Add Browser Source</div>
          <div class="textGuide_box_item">Add a new source, choose “Browser/Browser Source” and name the source as “LIVAD”</div>
          <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/browserSource.gif"></div>
        </div>
        <div class="textGuide_box">
        <div class="textGuide_box_title">3- Set Dimensions</div>
        <div class="textGuide_box_item">Paste the link to URL section and set dimensions as follows: Width: 1920, Height: 1080</div>
        <div class="textGuide_box_item">If you are using a different base resolution than 1920x1080, right-click on source 'LIVAD', choose Transform -> Fit to Screen</div>
        <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/setOverlay.gif"></div>
      </div>
      <div class="textGuide_box">
      <div class="textGuide_box_title">4- Place Source On Top</div>
      <div class="textGuide_box_item">Place the source “LIVAD” on top of the other sources and add it to every scene</div>
      <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/placement.gif"></div>
    </div>
    <div class="textGuide_box">
    <div class="textGuide_box_title">5- Lock The Source In Every Scene</div>
    <div class="textGuide_box_item">Lock the source in every scene, do not move or scale the source</div>
    <div class="textGuide_box_item_image"><img src="/assets/icons/OverlaySetup/OBS/lockOverlay.gif"></div>
  </div>
        </div>
        `,
      es: '',
      answerKey: 'textGuideContent',
    },
    category: 'setup',
    is_opened: false,
    id: '34',
  },
  {
    question: {
      tr: 'Genel Kurallar',
      en: 'General Rules',
      es: '',
      questionKey: 'generalRulesTitle',
    },
    answer: {
      tr: `<ul><li>İzleyici botu kullanmak kesinlikle yasaktır. Bot kullandığı tespit edilen yayıncılar doğrudan LIVAD yayıncı ağından çıkarılır.</li>
        <li>Reklam gösterimi süresince ve yayının herhangi bir anında, anlaşmalı olduğunuz markalar hakkında uygunsuz söylemlerde bulunmak yasaktır, bu davranışı sergileyen yayıncı sistemden uzaklaştırılır.</li>
        <li>Her türlü ayrımcı davranış ve nefret söyleminde bulunan yayıncılar LIVAD yayıncı ağından çıkarılır.</li></ul>
        `,
      en: `<ul><li>Viewbotting is strictly forbidden. Creators found to be using viewer bots will be directly removed from the LIVAD creator network. 
        </li>
        <li>It is forbidden to make inappropriate statements about the brands you have contracted with during the advertisement display and at any other time of your broadcast. Creators exhibiting this behavior will be removed from the system. 
        </li>
        <li>Creators who engage in any kind of discriminatory behavior or hate speech will be removed from the LIVAD creator network. 
        </li>
        </ul>
        `,
      es: '',
      answerKey: 'generalRulesContent',
    },
    category: 'rules',
    is_opened: false,
    id: '35',
  },
  {
    question: {
      tr: 'Kampanya Kuralları',
      en: 'Campaign Rules',
      es: '',
      questionKey: 'campaignRulesTitle',
    },
    answer: {
      tr: `<ul><li>Paneline eklenen kampanyaları inceledikten sonra istemediğin kampanyaları reddedebilirsin. Reddettiğin kampanyayı daha sonra tekrar kabul edebilirsin</li>
        <li>Kampanyaları kabul ettiğinde kampanya brief’ini okumuş ve kabul etmiş sayılırsın.</li>
        <li>Sistem kabul etmediğin kampanya görsellerini ekranında çıkarmaz.</li>
        <li>Markalardan gelen brief doğrultusunda, her kampanya her yayıncının paneline tanımlanmayabilir. LIVAD aktif veya gelecek kampanyalara yayıncı ekleme ve çıkarma hakkını saklı tutar.</li>
        <li>Kampanyaların başlangıç ve bitiş tarihleri markalardan gelen talebe göre değişkenlik gösterebilir.</li>
        <li>Gelen kampanyalar bireysel sponsorlukların ile çakışıyorsa LIVAD olarak ilgili sözleşmeni incelemeni ve kabul ederken sahip olduğun anlaşmana hassasiyet göstermenizi öneriyoruz.</li>
        <li>Reklam almana engel olabilecek bir anasayfa promosyonu sözleşmeniz varsa, Yayıncı Panel’i üzerinden reklamlarınızı kapatabilirsin.</li></ul>
        `,
      en: `<ul><li>You may deny campaigns which you do not want after inspecting them on your Streamer Dashboard.</li>
        <li>When you accept the campaigns, you are deemed to have read and accepted the campaign brief.</li>
        <li>LIVAD's system does not display the images of campaigns you do not accept.</li>
        <li>In line with the brief coming from brands, not every campaign may appear in every creators Panel. LIVAD reserves the right to add or remove creators from active or upcoming campaigns</li>
        <li>The start and end dates of the campaigns may vary according to the demand from the brands.</li>
        <li>If available campaigns conflict with your individual sponsorships, LIVAD recommends you to examine your relevant contract and be considerate of the agreement you have while accepting the campaign.</li>
        <li>If you have a home page promotion agreement that may prevent you from receiving ads, you can turn off your ads via the Streamer Dashboard.</li></ul>
        `,
      es: '',
      answerKey: 'campaignRulesContent',
    },
    category: 'rules',
    is_opened: false,
    id: '36',
  },
  {
    question: {
      tr: 'Ödeme Kuralları',
      en: 'Payment Rules',
      es: '',
      questionKey: 'paymentRulesTitle',
    },
    answer: {
      tr: `<ul><li>Ödeme almak için sizden istenen tüm bilgilerin eksiksiz girilmesi gerekmektedir.</li>
        <li>Kurallarımıza aykırı bir durum sonucu sistemden uzaklaştırılan/çıkarılan yayıncılara kazandıkları parayı ödeme hakkını LIVAD saklı tutar.
        </li></ul>
        `,
      en: `<ul><li>In order to receive a payment, all information requested from you must be entered correctly.</li>
        <li>LIVAD reserves the right to pay the money earned by creators who have been suspended/removed from the system as a result of a violation of our rules.</li></ul>
        `,
      es: '',
      answerKey: 'paymentRulesContent',
    },
    category: 'rules',
    is_opened: false,
    id: '37',
  },
]
