// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getAuth, GoogleAuthProvider, OAuthProvider} from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDW5ac10QXN_T5Ji3dmcrtOcXpXyUQ0T_4",
  authDomain: "livad-streamer.firebaseapp.com",
  projectId: "livad-streamer",
  storageBucket: "livad-streamer.appspot.com",
  messagingSenderId: "144693611568",
  appId: "1:144693611568:web:e305fe9d6123b696b1b1f5",
  measurementId: "G-M2H57XYF7B"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()
export const twitchProvider = new OAuthProvider('oidc.twitch')
