import classNames from 'classnames'
import {useState} from 'react'
import styles from './SoftwareTab.module.scss'
import {toAbsoluteUrl} from 'helpers'

const SoftwareTab = (props: {softwareTab: string; setSoftwareTab: (arg0: string) => void}) => {
  return (
    <div className={styles.softwareTab}>
      <div
        className={classNames(styles.softwareTabItem, {
          [styles.active]: props.softwareTab === 'obs',
        })}
        onClick={() => props.setSoftwareTab('obs')}
      >
        <img src={toAbsoluteUrl('/assets/icons/Settings/obs-icon.png')} alt='OBS' />
        OBS
      </div>
      <div
        className={classNames(styles.softwareTabItem, {
          [styles.active]: props.softwareTab === 'streamlabs',
        })}
        onClick={() => props.setSoftwareTab('streamlabs')}
      >
        <img src={toAbsoluteUrl('/assets/icons/Settings/streamlabs-icon.png')} alt='Streamlabs' />
        Streamlabs
      </div>
    </div>
  )
}

export default SoftwareTab
