import {useContext} from 'react'
import DashboardContext from 'context/DashboardContext'
import classNames from 'classnames'
import styles from '../DashboardTable/DashboardTable.module.scss'

const Ads = () => {
  const {tableView, ads} = useContext(DashboardContext)

  const formatDate = (date: Date) => {
    const dateObj = new Date(date)
    let day: any = dateObj.getDate()
    if (day < 10) day = `0${day}`
    let month: any = dateObj.getMonth() + 1
    if (month < 10) month = `0${month}`
    const year = dateObj.getFullYear()

    return `${day}.${month}.${year}`
  }

  const formatHour = (date: Date) => {
    const dateObj = new Date(date)
    let hour: any = dateObj.getHours()
    if (hour < 10) hour = `0${hour}`
    let minutes: any = dateObj.getMinutes()
    if (minutes < 10) minutes = `0${minutes}`
    const pmOrAm = hour > 12 ? 'PM' : 'AM'
    return `${hour}:${minutes}${pmOrAm}`
  }
  return (
    <>
      {tableView === 'ads' && (
        <>
          {/* Table Sub Head */}
          <tr className={classNames(styles.adView, styles.header)}>
            <th>Ad Date</th>
            <th>Time</th>
            <th>Views</th>
            <th>Clicks</th>
            <th>CTR</th>
            <th>RPM</th>
          </tr>

          {/* Actual Ads Mapping */}

          {ads?.ads.map((ad, index) => (
            <tr className={styles.adView} key={index}>
              <td>{formatDate(ad.timestamp)}</td>
              <td>{formatHour(ad.timestamp)}</td>
              <td>{ad.views.toLocaleString()}</td>
              <td>{ad.clicks.toLocaleString()}</td>
              <td>{ad.CTR}%</td>
              <td>
                {ad.revenue.toLocaleString()} {ad.currency_sign}
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  )
}

export default Ads
