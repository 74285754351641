import {useContext} from 'react'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'
// STYLES
import styles from './CardView.module.scss'
// COMPONENTS
import SingleCardItem from './components/SingleCardItem/SingleCardItem'
// CONTEXT
import CampaignContext, {Campaign} from 'context/CampaignContext'

type CardViewProps = {
  variant?: 'featured' | ''
}

const CardView = ({variant}: CardViewProps) => {
  const {campaignList, featuredCampaignList, activeFilter} = useContext(CampaignContext)
  const {t} = useTranslation()
  const selectedFilter = activeFilter.slice(0, 1).toLowerCase() + activeFilter.slice(1)
  return (
    <div className={styles.card_view_wrap}>
      {variant !== 'featured' &&
        campaignList.length > 0 &&
        campaignList?.map((campaign: Campaign, index: number) => (
          <SingleCardItem key={index} campaign={campaign} variant={variant} />
        ))}

      {campaignList.length === 0 && variant !== 'featured' && (
        <div className={styles.no_campaigns}>
          <img
            draggable='false'
            src={toAbsoluteUrl('/assets/icons/Campaigns/no-campaigns.png')}
            alt='no data'
          />
          <h1>
            {t('CampaignsPage.noFilterCampaign', {
              FILTER: t(`CampaignsPage.${selectedFilter}`),
            })}
          </h1>
        </div>
      )}

      {/* FEATURED */}
      {variant === 'featured' &&
        featuredCampaignList.length > 0 &&
        featuredCampaignList?.map((campaign: Campaign, index: number) => (
          <SingleCardItem key={index} campaign={campaign} variant={variant} />
        ))}

      {featuredCampaignList.length === 0 && variant === 'featured' && (
        <div className={styles.no_campaigns}>
          <img
            draggable='false'
            src={toAbsoluteUrl('/assets/icons/Campaigns/no-campaigns.png')}
            alt='no data'
          />
          <h1>
            {t('CampaignsPage.noFilterCampaign', {
              FILTER: activeFilter.slice(0, 1).toUpperCase() + activeFilter.slice(1),
            })}
          </h1>
        </div>
      )}
    </div>
  )
}

export default CardView
