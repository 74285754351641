import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from 'helpers'
// COMPONENTS
import LanguagePicker from '../LanguagePicker/LanguagePicker'
// STYLES
import styles from './QuickMenu.module.scss'
//QUICK ROUTES
import {quickList} from './quickList'
import {useContext} from 'react'
import {AppLevelContext} from 'context/AppLevelContext'
import RestrictedRoute from 'components/Shared/auth/RestrictedRoute'

const QuickMenu = () => {
  const navigate = useNavigate()
  const {user} = useContext(AppLevelContext)
  const {unreadNotifications, permissions} = useContext(AppLevelContext)

  return (
    <div className={styles.quickMenu}>
      {/*QUICK MENU ITEMS*/}
      {!!user?.signup_completed && (
        <>
          {quickList.map((item, index) =>
            item.isEnabled && (!item.permission || permissions?.includes(item.permission)) ? (
              <div className={styles.quickMenuItem} key={index} onClick={() => navigate(item.path)}>
                <img src={toAbsoluteUrl(`/assets/icons/Header/${item.icon}.png`)} alt='icon' />
                {item.icon === 'notification' && unreadNotifications ? (
                  <div className={styles.notificationCount}>{unreadNotifications}</div>
                ) : null}
              </div>
            ) : null
          )}
        </>
      )}

      {/*LANGUAGE PICKER*/}
      <LanguagePicker />
    </div>
  )
}

export default QuickMenu
