enum EnumTipPosition {
    TopRight = 'topRight',
    TopLeft = 'topLeft',
    BottomRight = 'bottomRight',
    BottomLeft = 'bottomLeft',
    CenterLeft = 'centerLeft',
    CenterRight = 'centerRight',
    CenterTop = 'centerTop',
    CenterBottom = 'centerBottom',
    CenterMiddle = 'centerMiddle',
}
export default EnumTipPosition;