import styles from './ReferralsList.module.scss'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'

//Context
import ReferralsContext from 'context/ReferralsContext'
import SingleReferal from './SingleReferal'

const ReferralsList = () => {
  const {referralsList} = useContext(ReferralsContext)
  const {t} = useTranslation()
  return (
    <div className={styles.referralsList}>
      <div className={styles.referralsList_title}>
        {t('RecruitedFriendPage.recruitedStreamers')}
      </div>
      <div className={styles.referralsList_table}>
        <table>
          <thead>
            <tr>
              <th>{t('RecruitedFriendPage.streamer')}</th>
              <th>{t('RecruitedFriendPage.completed')}</th>
              <th>{t('RecruitedFriendPage.milestone')}</th>
              <th>{t('RecruitedFriendPage.currentMilestoneProgress')}</th>
            </tr>
          </thead>
          <tbody>
            {referralsList?.referrees.map((referral, index) => (
              <SingleReferal referral={referral} key={index} />
            ))}
            {
              //if there is no referrals
              referralsList?.referrees.length === 0 && (
                <tr>
                  <td colSpan={4} className={styles.referralsList_table_noReferrals}>
                    {t('RecruitedFriendPage.noReferralsYet')} 🤑
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ReferralsList
