import {useContext, useEffect, useState} from 'react'

// CONTEXT
import LeaderboardContext from 'context/LeaderboardContext'
// STYLE
import styles from './Results.module.scss'
import SingleResult from './components/SingleResult'
import {toAbsoluteUrl} from 'helpers'
import { LeaderboardItemData } from 'services/leaderboardService'

const Results = () => {
  const {leaderboardData, selectedIndex} = useContext(LeaderboardContext)
  const [percentage, setPercentage] = useState<number | null>(null)
  const [ctr, setCTR] = useState<number | null>(null)

  useEffect(() => {
    if (
      leaderboardData?.[selectedIndex]?.contest?.id == 9 &&
      leaderboardData?.[selectedIndex]?.self?.points != undefined
    ) {
      let tmp =
        Math.round(((leaderboardData?.[selectedIndex]?.self?.points ?? 0) / 10) * 100 * 100) / 100
      tmp = tmp <= 100 ? tmp : 100
      setPercentage(tmp)
    } else if (
      leaderboardData?.[selectedIndex]?.contest?.id == 11 &&
      leaderboardData?.[selectedIndex]?.self?.points != undefined
    ) {
      let tmp =
        Math.round(((leaderboardData?.[selectedIndex]?.self?.points ?? 0) / 50) * 100 * 100) / 100
      tmp = tmp <= 100 ? tmp : 100
      setPercentage(tmp)
    } else if (
      leaderboardData?.[selectedIndex]?.contest?.id == 12 &&
      leaderboardData?.[selectedIndex]?.totalPoints != undefined
    ) {
      let tmp = Math.round((leaderboardData?.[selectedIndex]?.totalPoints / 250) * 100 * 100) / 100
      tmp = tmp <= 100 ? tmp : 100
      setPercentage(tmp)
    } else {
      setPercentage(null)
    }
    if (
      [14, 15].includes(leaderboardData?.[selectedIndex]?.contest?.id ?? -1) &&
      leaderboardData?.[selectedIndex]?.self?.ctr != undefined
    ) {
      setCTR(leaderboardData?.[selectedIndex]?.self?.ctr ?? null)
    }
  }, [selectedIndex])

  return (
    <>
      <div className={styles.leaderboard_title}>
        <img
          src={
            leaderboardData?.[selectedIndex]?.contest?.logo ??
            toAbsoluteUrl('/assets/icons/logo-vector.png')
          }
          alt='brand'
        />
        {leaderboardData?.[selectedIndex]?.contest?.name}
      </div>
      {percentage !== null && (
        <>
          <p className={styles.leaderboardProgress_desc}>
            {leaderboardData?.[selectedIndex]?.contest?.id == 9 && (
              <>Your Campaign Goal Completion Rate:</>
            )}
            {leaderboardData?.[selectedIndex]?.contest?.id == 11 && (
              <>
                If you bring more than 50 wishlists, your CPM will be increased by 35%.
                <br></br>
                You can track your progress below:
              </>
            )}

            {leaderboardData?.[selectedIndex]?.contest?.id == 12 && (
              <>
                You will earn $1 per deposit made through your link, up until and in case the goal
                of 250 overall deposits is met. The person who gets the most deposits until the end.
                <br></br>
                Total Deposit Goal Progress:
              </>
            )}
          </p>
          <div className={styles.leaderboardCampaign_progress}>
            <div
              className={styles.leaderboardCampaign_progress_bar}
              style={{
                width: `${percentage}%`,
                background: leaderboardData?.[selectedIndex]?.contest?.brandColor1 ?? '#5d7fff',
              }}
            >
              {percentage}%
            </div>
          </div>
        </>
      )}
      {ctr !== null ? (
        <>
          {[14, 15].includes(leaderboardData?.[selectedIndex]?.contest?.id ?? -1) && (
            <>
              If you have more than 2% CTR at the end of the campaign, your CPM will be increased by
              25%.
              <p>
                Your current CTR in the campaign: <b>{ctr}%</b>
              </p>
            </>
          )}
        </>
      ) : null}
      {leaderboardData?.[selectedIndex]?.contest?.id == 16 &&
      leaderboardData?.[selectedIndex]?.contest?.redirectUrl ? (
        <>
          <span>
            Size özel oluşturulan{' '}
            <a href={leaderboardData?.[selectedIndex]?.contest.redirectUrl} target='_blank'>
              {leaderboardData?.[selectedIndex]?.contest.redirectUrl}
            </a>{' '}
            bağlantı linkini yayınınıza sabitleyerek izleyicilerinizin link üzerinden Hepsiburada
            Premium üyesi olmalarını sağlayabilir, kazanma şansınızı arttırabilirsiniz. GLHF{' '}
          </span>
        </>
      ) : null}
      {leaderboardData?.[selectedIndex]?.contest?.id == 17 ? (
        <>
          <span>
            Se você atingir mais de 20 cadastros no site, independente do formato de anúncio, você
            ganhará o Bônus da campanha de todos os formatos, ou seja, você ganhará até 4x o CPM da
            campanha. Além disso, os 3 primeiros com mais registros ganham prêmios em dinheiro. O
            seu Score é a quantidade de registros que você trouxe através do seu link (link que
            aparece na sua live). Atualização conforme o cliente enviar, estimativa é a cada 3 dias.
          </span>
        </>
      ) : null}

      {/* If the leaderboard has a progress bar, show it here - END*/}
      {leaderboardData &&
        leaderboardData[selectedIndex]?.data.map((item: LeaderboardItemData, index: number) => (
          <SingleResult key={index} item={item} />
        ))}
    </>
  )
}

export default Results
