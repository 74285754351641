import {createBaseStreamerApiService} from './baseStreamerApiService'
import {GetClipInsightsDetailRequest} from './model/request/clipInsights/getClipInsightsDetailRequest'
import {GetClipInsightsDetailResponse} from './model/response/clipInsights/getClipInsightsDetailResponse'
import {GetClipInsightsRequest} from './model/request/clipInsights/getClipInsightsRequest'
import GetClipInsightsResponse from './model/response/clipInsights/getClipInsightsResponse'
import {GetClipInsightsRatiosRequest} from './model/request/clipInsights/getClipInsightsRatiosRequest'
import GetClipInsightsRatiosResponse from './model/response/clipInsights/getClipInsightsRatiosResponse'
import {GetClipInsightsAdSubtitlesRequest} from './model/request/clipInsights/getClipInsightsAdSubtitlesRequest'
import AdSubtitleDto from './model/dto/ad/adSubtitleDto'
import {AdChatMessageDto} from './model/dto/ad/adChatMessageDto'
import {GetClipInsightsChatMessagesRequest} from './model/request/clipInsights/getClipInsightsChatMessagesRequest'
import {GetClipInsightsLabelsRequest} from './model/request/clipInsights/getClipInsightsLabelsRequest'
import GetClipInsightsLabelsResponse from './model/response/clipInsights/getClipInsightsLabelsResponse'
import {GetClipInsightsProblemsRequest} from './model/request/clipInsights/getClipInsightsProblemsReques'
import GetClipInsightsProblemsResponse from './model/response/clipInsights/getClipInsightsProblemsResponse'
import {UpdateResponse} from './model/response/updateResponse'
import {UpdateClipInsightsLabelsRequest} from './model/request/clipInsights/updateClipInsightsLabelsRequest'
import {UpdateClipInsightsProblemsRequest} from './model/request/clipInsights/updateClipInsightsProblemsRequest'

export const createClipInsightsService = () => {
  const baseService = createBaseStreamerApiService('/clip_insights')

  const getClipInsights = async (req: GetClipInsightsRequest): Promise<GetClipInsightsResponse> => {
    const response = await baseService.post<GetClipInsightsResponse>(`/get`, req)
    return response.data
  }

  const getClipInsightsRatios = async (
    req: GetClipInsightsRatiosRequest
  ): Promise<GetClipInsightsRatiosResponse> => {
    const response = await baseService.post<GetClipInsightsRatiosResponse>(`/getRatios`, req)
    return response.data
  }

  const getClipInsightsDetail = async (
    req: GetClipInsightsDetailRequest
  ): Promise<GetClipInsightsDetailResponse> => {
    const response = await baseService.post<GetClipInsightsDetailResponse>(`/getDetail`, req)
    return response.data
  }

  const getClipInsightsAdSubtitles = async (
    req: GetClipInsightsAdSubtitlesRequest
  ): Promise<AdSubtitleDto[]> => {
    const response = await baseService.post<AdSubtitleDto[]>(`/getAdSubtitles`, req)
    return response.data
  }

  const GetClipInsightsChatMessages = async (
    req: GetClipInsightsChatMessagesRequest
  ): Promise<AdChatMessageDto[]> => {
    const response = await baseService.post<AdChatMessageDto[]>(`/getChatMessages`, req)
    return response.data
  }

  const GetClipInsightsLabels = async (
    req: GetClipInsightsLabelsRequest
  ): Promise<GetClipInsightsLabelsResponse> => {
    const response = await baseService.post<GetClipInsightsLabelsResponse>(`/getLabels`, req)
    return response.data
  }

  const GetClipInsightsProblems = async (
    req: GetClipInsightsProblemsRequest
  ): Promise<GetClipInsightsProblemsResponse> => {
    const response = await baseService.post<GetClipInsightsProblemsResponse>(`/getProblems`, req)
    return response.data
  }

  const UpdateClipInsightsLabels = async (
    req: UpdateClipInsightsLabelsRequest
  ): Promise<UpdateResponse> => {
    const response = await baseService.post<UpdateResponse>(`/updateLabels`, req)
    return response.data
  }

  const UpdateClipInsightsProblems = async (
    req: UpdateClipInsightsProblemsRequest
  ): Promise<UpdateResponse> => {
    const response = await baseService.post<UpdateResponse>(`/updateProblems`, req)
    return response.data
  }

  return {
    getClipInsights,
    getClipInsightsRatios,
    getClipInsightsDetail,
    getClipInsightsAdSubtitles,
    GetClipInsightsChatMessages,
    GetClipInsightsLabels,
    GetClipInsightsProblems,
    UpdateClipInsightsLabels,
    UpdateClipInsightsProblems,
  }
}
