import React, {useContext} from 'react'
import styles from './AwardsBox.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'

// CONTEXT
import LeaderboardContext from 'context/LeaderboardContext'

const AwardsBox = () => {
  const {leaderboardData, selectedIndex, setSelectedIndex} = useContext(LeaderboardContext)
  const {t} = useTranslation()
  return (
    // TODO: #4f18cd is Brand Color - GET IT FROM API and replace it here
    <div
      className={styles.awards_box}
      style={{
        background: leaderboardData?.[selectedIndex]?.contest?.brandColor1
          ? `linear-gradient(180deg, #252525 0%, ` +
            leaderboardData?.[selectedIndex]?.contest?.brandColor1 +
            ` 100%)`
          : undefined,
      }}
    >
      <h3 className={styles.awards_title}>
        <img src={toAbsoluteUrl('/assets/icons/Leaderboard/awardsIcon.png')} alt='' />
        {t('LeaderboardPage.awards')}
      </h3>
      {/* REWARDS */}
      {leaderboardData?.[selectedIndex]?.contest?.prizes?.map((prize, index) => {
        return (
          <div className={styles.award_item}>
            <span className={styles.award_item_number}>{index + 1}.</span>
            {prize?.image && <img src={prize?.image} alt='award' />}
            {/* <a href="#"> */}
            {prize?.name}
            {/* </a> */}
          </div>
        )
      })}
    </div>
  )
}

export default AwardsBox
