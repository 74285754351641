import styles from './SwitchButton.module.scss'
import classNames from 'classnames'
import {useState} from 'react'

type Props = {
  isActive: boolean
  setIsActive: (value: boolean) => void
}
const SwitchButton = ({isActive, setIsActive}: Props) => {
  return (
    <div
      className={classNames(styles.switchButton, {
        [styles.active]: isActive,
      })}
      onClick={() => {
        setIsActive(!isActive)
      }}
    >
      <div className={styles.switchInner}></div>
    </div>
  )
}

export default SwitchButton
