import {useState} from 'react'
import classNames from 'classnames'

//STYLES
import styles from './CustomToggle.module.scss'

interface CustomToggleProps {
  isToggleEnable: boolean
  setIsToggleEnable: (value: boolean) => void
}

const CustomToggle = ({isToggleEnable, setIsToggleEnable}: CustomToggleProps) => {
  return (
    <div
      className={classNames(styles.customToggle, {
        [styles.enabled]: isToggleEnable,
      })}
      onClick={() => {
        setIsToggleEnable(!isToggleEnable)
      }}
    >
      <span className={styles.circle}></span>
    </div>
  )
}

export default CustomToggle
