export const categories = [
  {
    name: 'Campaigns & Ads',
    translateName: 'campaignsAndAds',
    iconUrl: '/assets/icons/HelpCenter/campaign-icon.png',
    value: 'campaigns',
  },
  {
    name: 'Troubleshooting',
    translateName: 'troubleshooting',
    iconUrl: '/assets/icons/HelpCenter/troubleshoot-icon.png',
    value: 'troubleshooting',
  },
  {
    name: 'Payouts',
    translateName: 'payouts',
    iconUrl: '/assets/icons/HelpCenter/billings-icon.png',
    value: 'payouts',
  },
  {
    name: 'Setup Instructions',
    translateName: 'setupInstructions',
    iconUrl: '/assets/icons/HelpCenter/setup-icon.png',
    value: 'setup',
  },
  {
    name: 'Rules',
    translateName: 'rules',
    iconUrl: '/assets/icons/HelpCenter/rules-icon.png',
    value: 'rules',
  },
]
