import {createBaseStreamerApiService} from './baseStreamerApiService'

export const createIndexService = () => {
  const baseService = createBaseStreamerApiService('')
  const getCountries = async (search?: string): Promise<any> => {
    const response = await baseService.post<any>('/countries', {search})
    return response.data
  }

  const getCities = async (countryId: string, search?: string): Promise<any> => {
    const response = await baseService.post<any>(`/countries/${countryId}/cities`, {search})
    return response.data
  }

  const getDistricts = async (cityId: number): Promise<any> => {
    const response = await baseService.get<any>(`/cities/${cityId}/districts`)
    return response.data
  }

  const getReferrals = async (): Promise<any> => {
    const response = await baseService.get<any>('/referrees')
    return response.data
  }

  const getLanguages = async (): Promise<any> => {
    const response = await baseService.get<any>('/languages')
    return response.data
  }

    const sendEmailVerification = async (): Promise<void> => {
        const response = await baseService.post<void>('/sendEmailVerification')
        return response.data
    }


    return {
        getCountries,
        getCities,
        getDistricts,
        getReferrals,
        getLanguages,
        sendEmailVerification
    }
}
