import {EnumPopupTabs} from 'components/ClipInsightsPopup/ClipInsightsPopup'
import styles from './TabItem.module.scss'
import classNames from 'classnames'
import {t} from 'i18next'

interface TabItemProps {
  value: EnumPopupTabs
  activeTab: string
  setActiveTab: (tab: EnumPopupTabs) => void
}

const TabItem = (props: TabItemProps) => {
  return (
    <div
      className={classNames(styles.tabItem, {
        [styles.active]: props.activeTab === props.value,
      })}
      onClick={() => props.setActiveTab(props.value)}
    >
      {t(`ClipInsightsPopup.${props.value}`)}
    </div>
  )
}

export default TabItem
