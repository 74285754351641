// STYLES
import {loadingHandler, toAbsoluteUrl} from 'helpers'
import styles from '../DropdownMenu.module.scss'
import {useContext, useEffect, useRef, useState} from 'react'
import {AppLevelContext} from 'context/AppLevelContext'
import Loading from 'components/Shared/Loading/Loading'
import EnumRole from 'services/model/enum/enumRole'
const AdminMenu = () => {
  const {user, userSearchList, searchStreamers, updateStreamer, setUserSearchList, mainStreamer} =
    useContext(AppLevelContext)
  const searchInput = useRef<HTMLInputElement>(null)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  useEffect(() => {
    if (
      !userSearchList &&
      user?.role &&
      [EnumRole.MANAGER, EnumRole.MOD, EnumRole.ADMIN].includes(user?.role)
    ) {
      loadingHandler([searchStreamers('')], setSearchLoading)
    }
  }, [])

  if (searchLoading || !mainStreamer) {
    return <Loading />
  }
  return (
    <div className={styles.adminMenu}>
      {mainStreamer && !user?.self ? (
        <div className={styles.adminUserSearchResults}>
          <p className={styles.switchToDesc}>
            You are currently viewing as <b>{user?.title}</b>
          </p>
          <button
            className={styles.switchToMain}
            onClick={() => {
              if (!user?.self) {
                updateStreamer(mainStreamer.streamerId)
              }
            }}
          >
            Switch to Main Account
          </button>
          {/* <div
            className={styles.adminUserResultItem}
            onClick={() => {
              if (!user?.self) {
                updateStreamer(mainStreamer.streamerId)
              }
            }}
          >
            <img
              className={styles.platform}
              // src={toAbsoluteUrl('/assets/icons/Header/twitch-icon.png')}
              src={
                mainStreamer.platform === 'twitch'
                  ? toAbsoluteUrl('/assets/icons/Platforms/twitch-icon.png')
                  : mainStreamer.platform === 'youtube'
                  ? toAbsoluteUrl('/assets/icons/Platforms/youtube-icon.png')
                  : toAbsoluteUrl('/assets/icons/Platforms/trovo-icon.png')
              }
              alt='platform'
            />
            <img
              className={styles.picture}
              src={mainStreamer.linkToLogo}
              onError={(e) => {
                e.currentTarget.src = toAbsoluteUrl(
                  '/assets/icons/Leaderboard/defaultProfileImg.png'
                )
              }}
              alt='profile'
            />
            <span className={styles.username}>{mainStreamer.title}</span>
          </div> */}
        </div>
      ) : null}
      <p className={styles.switchToDesc}>
        You are the <b style={{textTransform: 'capitalize'}}>{user?.role}</b>. Select a user to view
        as. You will be able to view the site as if you were that user.
      </p>
      <div className={styles.adminUserSearch}>
        <input
          type='text'
          placeholder='Search username...'
          ref={searchInput}
          onKeyDown={(e) => {
            if (user?.role !== EnumRole.ADMIN) {
              return
            }
            if (e.key === 'Enter') {
              if (searchInput.current) {
                loadingHandler([searchStreamers(searchInput.current.value)], setSearchLoading)
              }
            } else if (e.key === 'Escape') {
              setUserSearchList(null)
            }
          }}
        />
        {user?.role === EnumRole.ADMIN ? (
          <button
            className='userSearch'
            onClick={() => {
              if (searchInput.current) {
                searchStreamers(searchInput.current.value)
              }
            }}
          >
            <img src={toAbsoluteUrl('/assets/icons/Header/search.png')} alt='search' />
          </button>
        ) : null}
      </div>

      {userSearchList || searchLoading ? (
        <div className={styles.adminUserSearchResults}>
          {!searchLoading ? (
            <>
              {userSearchList?.streamers.length === 0
                ? 'No Results'
                : userSearchList?.streamers.map((streamer, index) => (
                    <div
                      className={styles.adminUserResultItem}
                      key={index}
                      onClick={() => {
                        updateStreamer(streamer.streamerId)
                      }}
                    >
                      <img
                        className={styles.platform}
                        // src={toAbsoluteUrl('/assets/icons/Header/twitch-icon.png')}
                        src={
                          streamer.platform === 'twitch'
                            ? toAbsoluteUrl('/assets/icons/Platforms/twitch-icon.png')
                            : streamer.platform === 'youtube'
                            ? toAbsoluteUrl('/assets/icons/Platforms/youtube-icon.png')
                            : streamer.platform === 'trovo'
                            ? toAbsoluteUrl('/assets/icons/Platforms/trovo-icon.png')
                            : streamer.platform === 'kick'
                            ? toAbsoluteUrl('/assets/icons/Platforms/kick-icon.png')
                            : ''
                        }
                        alt='platform'
                      />
                      <img
                        className={styles.picture}
                        src={streamer.linkToLogo}
                        alt='profile'
                        onError={(e) => {
                          e.currentTarget.src = toAbsoluteUrl(
                            '/assets/icons/Leaderboard/defaultProfileImg.png'
                          )
                        }}
                      />
                      <span className={styles.username}>{streamer.title}</span>
                    </div>
                  ))}
            </>
          ) : (
            <Loading />
          )}
        </div>
      ) : null}
    </div>
  )
}
export default AdminMenu
