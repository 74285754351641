import styles from './Onboarding.module.scss'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import SettingsContext from 'context/SettingsContext'
import {useContext, useEffect, useState} from 'react'
import SignupStepComponent from './components/CompletedStep'
import {useTranslation} from 'react-i18next'
const Onboarding = () => {
  const {t} = useTranslation()
  const {signupSteps} = useContext(SettingsContext)

  const [percentage, setPercentage] = useState<number | null>(null)

  useEffect(() => {
    console.log('signupSteps', signupSteps)
    if (signupSteps) {
      const length = Object.keys(signupSteps).length
      const completed = Object.values(signupSteps).filter((x) => x).length
      const percent = Math.round((completed / length) * 100)
      console.log('percent', percent)
      setPercentage(percent)
    }
  }, [signupSteps])
  if (signupSteps === null || percentage === null) return null
  return (
    <>
      <div className={styles.header}>
        <span className={styles.title}>{t('OnboardingPage.finishSetup')}</span>
        <span className={styles.desc}>{t('OnboardingPage.getMoreFromLivad')}</span>
      </div>
      <div className={styles.complete_bar}>
        <span className={styles.complete_message}>
          {t('OnboardingPage.completeProfilePer', {
            PERCENTAGE: percentage,
          })}
        </span>
        <div className={styles.complete_bar_wrap}>
          <div
            className={styles.complete_bar_percent}
            style={{
              width: `${percentage}%`,
            }}
          ></div>
        </div>
      </div>
      <div className={styles.onboarding_steps}>
        <SignupStepComponent
          name={t('SettingsPage.profileDetails')}
          image={toAbsoluteUrl('/assets/icons/Settings/profile.png')}
          completed={signupSteps.profileDetails}
          redirectMessage={t('OnboardingPage.completeProfile')}
          description={t('OnboardingPage.setupProfileDetails')}
        />
        <SignupStepComponent
          name={t('SettingsPage.socialAccounts')}
          image={toAbsoluteUrl('/assets/icons/Settings/social.png')}
          completed={signupSteps.socialMedia}
          redirectMessage={t('OnboardingPage.completeSocialAccounts')}
          description={t('OnboardingPage.setupSocialAccounts')}
        />
        <SignupStepComponent
          name={t('SettingsPage.streamingPreferences')}
          image={toAbsoluteUrl('/assets/icons/Settings/streaming.png')}
          completed={signupSteps.streamingPreferences}
          redirectMessage={t('OnboardingPage.completeStreamingSettings')}
          description={t('OnboardingPage.setupStreamingPreferences')}
        />
        <SignupStepComponent
          name={t('SettingsPage.sponsorshipPreferences')}
          image={toAbsoluteUrl('/assets/icons/Settings/campaigns.png')}
          completed={signupSteps.sponsorshipPreferences}
          redirectMessage={t('OnboardingPage.completeSponsorshipSettings')}
          description={t('OnboardingPage.setupSponsorshipPreferences')}
        />
        <SignupStepComponent
          name={t('SettingsPage.interests')}
          image={toAbsoluteUrl('/assets/icons/Settings/interests.png')}
          completed={signupSteps.interests}
          redirectMessage={t('OnboardingPage.completeInterests')}
          description={t('OnboardingPage.setInterestPreferences')}
        />
        <SignupStepComponent
          name={t('SettingsPage.overlaySetup')}
          image={toAbsoluteUrl('/assets/icons/Settings/streaming.png')}
          completed={signupSteps.overlaySetup}
          redirectMessage={t('OnboardingPage.completeSetup')}
          description={t('OnboardingPage.setLivadOverlay')}
        />
      </div>
    </>
  )
}

export default Onboarding
