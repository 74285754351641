import styles from '../../Select/Select.module.scss'

import {useState, useContext, useEffect} from 'react'
import {toAbsoluteUrl} from 'helpers'
import ClickAwayListener from 'react-click-away-listener'

// Context
import SettingsContext from 'context/SettingsContext'
import {Country} from 'context/PayoutAccountContext'
import Loading from 'components/Shared/Loading/Loading'

const CountrySelect = ({newProfileDetails, setNewProfileDetails}: any) => {
  const {
    countries,
    filteredCountries,
    setFilteredCountries,
    countryIsLoading,
    setCountryIsLoading,
    profileDetails,
    handleSearchCountry,
  } = useContext(SettingsContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)

  useEffect(() => {
    if (profileDetails?.country_id) {
      const selectedCountry = countries.find(
        (country) => country.value === profileDetails?.country_id
      )

      if (selectedCountry) {
        setSelectedCountry(selectedCountry)
      }
    }
  }, [countries])

  const handleSelectCountry = (country: Country) => {
    setSelectedCountry(country)
    setNewProfileDetails({...newProfileDetails, country_id: country.value})
    setSearchTerm('')
    setIsOpen(false)
    setFilteredCountries(countries)
  }

  useEffect(() => {
    setCountryIsLoading(true)
    const debounceTimer = setTimeout(() => {
      handleSearchCountry(searchTerm)
    }, 1000)
    return () => clearTimeout(debounceTimer)
  }, [searchTerm])

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false)
        setSearchTerm('')
      }}
    >
      <div className={styles.selectBox}>
        <div className={styles.selectBox_selected} onClick={() => setIsOpen(!isOpen)}>
          {selectedCountry ? selectedCountry.label : 'Select Country'}
          <img
            className={styles.selectBox_arrow}
            src={toAbsoluteUrl('/assets/icons/Settings/arrowIcon.png')}
            alt='arrow'
          />
        </div>
        {isOpen && (
          <div className={styles.selectItem_list}>
            <div className={styles.listItem_search}>
              <input
                type='text'
                placeholder='Search'
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className={styles.listResults}>
              {!countryIsLoading &&
                filteredCountries.map((country, idx) => (
                  <span
                    className={styles.listItem}
                    key={idx}
                    onClick={() => handleSelectCountry(country)}
                  >
                    <img
                      className={styles.flag}
                      src={
                        country.flag ? country.flag : toAbsoluteUrl('/assets/icons/defaultFlag.png')
                      }
                      onError={(e: any) => {
                        e.target.src = toAbsoluteUrl('/assets/icons/defaultFlag.png')
                      }}
                    />
                    {country.label}
                  </span>
                ))}
              {countryIsLoading && <Loading />}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default CountrySelect
