import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import styles from '../../HelpCenter.module.scss'
// CATEGORIES COMING FROM ANOTHER FILE
//TO ADD OR REMOVE A CATEGORY, JUST ADD OR REMOVE AN OBJECT FROM THE ARRAY
import {categories} from './categories'

const CategorySelection = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  return (
    <div className={styles.helpCenter_category}>
      {categories.map((category, index) => (
        <div
          key={index}
          className={styles.categoryItem}
          onClick={() => {
            navigate(`/help-center/${category.value}`)
          }}
        >
          <img src={category.iconUrl} alt='category' />
          {t(`HelpCenterPage.${category.translateName}`)}
        </div>
      ))}
    </div>
  )
}

export default CategorySelection
