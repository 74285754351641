import { toast } from "react-toastify";
import { createBaseStreamerApiService } from "./baseStreamerApiService"
import { PermsForModDto } from "./model/dto/mods/permsForModDto";
import { StreamerModDto } from "./model/dto/mods/streamerModDto";
import { StreamerAccountDto } from "./model/dto/streamerAccountDto";
import { InterestsDto } from "./model/dto/streamerAccountModels";
import { AddModToStreamerRequest } from "./model/request/mods/addModToStreamerRequest";
import { RemoveModFromStreamerRequest } from "./model/request/mods/removeModFromStreamerRequest";
import { UpdatePermsForModRequest } from "./model/request/mods/updatePermsForModRequest";
import UpdateDonationPreferencesRequest from "./model/request/streamers/updateDonationPreferencesRequest";
import { GetModsForStreamerResponse } from "./model/response/mods/getModsForStreamerResponse";
import GetDonationPreferencesResponse from "./model/response/streamers/getDonationPreferencesResponse";
import NotificationDto from "./model/dto/notifications/notificationDto";
import { GetAutoAcceptSettingsResponse } from "./model/response/streamers/getAutoAcceptSettingsResponse";
import { UpdateAutoAcceptSettingsRequest } from "./model/request/streamers/updateAutoAcceptSettingsRequest";
import SearchResultDto from "./model/dto/searchResultDto";
import SearchRequest from "./model/request/searchRequest";
import { LoginResponse } from "./model/response/loginResponse";
import { KickLoginResponse } from "./model/response/kickLoginResponse";



export const createStreamersService = () => {
    const baseService = createBaseStreamerApiService('/streamers');
    const baseServiceInterests = createBaseStreamerApiService('/interests');
    const baseServiceMain = createBaseStreamerApiService('');


    const login = async (): Promise<LoginResponse> => {
        const response = await baseService.post<LoginResponse>('/');
        return response.data;
    }

    const kickLogin = async (): Promise<KickLoginResponse> => {
        const response = await baseService.post<KickLoginResponse>('/kickLogin');
        return response.data
    }

    const verifyKickAccount = async (): Promise<KickLoginResponse> => {
        const response = await baseService.post<KickLoginResponse>('/verifyKickAccount');
        return response.data;
    }


    const getDonationPreferences = async (): Promise<GetDonationPreferencesResponse> => {
        const response = await baseService.get<GetDonationPreferencesResponse>(`/donationPreferences`);
        return response.data;
    }

    const updateDonationPreferences = async (data: UpdateDonationPreferencesRequest): Promise<GetDonationPreferencesResponse> => {
        if (data.minAmount === null) {
            toast.error('Minimum amount is required');
        }
        const response = await baseService.put<GetDonationPreferencesResponse>(`/donationPreferences`, data);
        return response.data;
    }

    const getStreamersAccount = async (): Promise<StreamerAccountDto> => {
        const response = await baseService.get<StreamerAccountDto>(`/settings`);
        return response.data;
    }

    const updateStreamersAccount = async (data: StreamerAccountDto): Promise<void> => {
        await baseService.put<void>(`/`, data);
        // const response = await getStreamersAccount();
        // return response;
    }

    const updateInterests = async (interests: number[]): Promise<void> => {
        await baseService.put<void>(`/interests`, {
            interests
        });
        // const data = await getStreamersAccount();
        // return data;
    }

    const getAllInterests = async (): Promise<InterestsDto> => {
        const response = await baseServiceInterests.get<{
            data: InterestsDto
        }>('/all');
        return response.data.data
    }

    // const getAllCountries = async () => {
    //     const response = await baseService2.get<any>(`/countries`);
    //     return response.data;
    // }

    const completeSignup = async () => {
        await baseService.post<void>(`/completeSignup`);
    }

    const addModToStreamer = async (data: AddModToStreamerRequest): Promise<StreamerModDto> => {
        const response = await baseService.post<StreamerModDto>(`/mods`, data);
        return response.data;
    }

    const getModsForStreamer = async (): Promise<GetModsForStreamerResponse> => {
        const response = await baseService.post<GetModsForStreamerResponse>(`/mods/get`);
        return response.data;
    }

    const getPermsForMod = async (twitchUsername: string): Promise<PermsForModDto> => {
        const response = await baseService.post<PermsForModDto>(`/mods/perms/get`, {
            twitchUsername
        });
        return response.data;
    }

    const updatePermsForMod = async (data: UpdatePermsForModRequest): Promise<PermsForModDto> => {
        const response = await baseService.post<PermsForModDto>(`/mods/perms/update`, data);
        return response.data;
    }

    const removeModFromStreamer = async (data: RemoveModFromStreamerRequest) => {
        await baseService.post<void>(`/mods/remove`, data);
    }

    const getNotifications = async (): Promise<NotificationDto[]> => {
        const response = await baseService.get<NotificationDto[]>(`/notifications`);
        return response.data;
    }

    const readNotifications = async (ids: number[]) => {
        await baseService.post(`/notifications/read`, {
            notificationIds: ids
        });
    }

    const getAutoAcceptSettings = async (): Promise<GetAutoAcceptSettingsResponse> => {
        const response = await baseService.get<GetAutoAcceptSettingsResponse>(`/autoAcceptSettings`);
        return response.data;
    }

    const updateAutoAcceptSettings = async (req: UpdateAutoAcceptSettingsRequest): Promise<GetAutoAcceptSettingsResponse> => {
        const response = await baseService.post<GetAutoAcceptSettingsResponse>(`/updateAutoAcceptSettings`, req);
        return response.data;
    }

    const searchAutoAcceptClients = async (req: SearchRequest): Promise<SearchResultDto<number>[]> => {
        const response = await baseService.post<SearchResultDto<number>[]>(`/searchAutoAcceptClients`, req);
        return response.data;
    }



    return {
        login,
        kickLogin,
        verifyKickAccount,
        getDonationPreferences,
        updateDonationPreferences,
        getStreamersAccount,
        updateStreamersAccount,
        updateInterests,
        getAllInterests,
        completeSignup,
        addModToStreamer,
        getModsForStreamer,
        getPermsForMod,
        updatePermsForMod,
        removeModFromStreamer,
        getNotifications,
        readNotifications,
        getAutoAcceptSettings,
        updateAutoAcceptSettings,
        searchAutoAcceptClients
    }
}