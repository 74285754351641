import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'
// STYLE
import styles from '../Results.module.scss'
import { LeaderboardItemData } from 'services/leaderboardService'
// TYPE

const SingleResult = ({item}: {item: LeaderboardItemData}) => {
  const {t} = useTranslation()
  return (
    <div className={styles.leaderboard_item}>
      <span className={styles.leaderboard_item_number}>
        {item.order === 1 ? (
          <img src={toAbsoluteUrl('/assets/icons/Leaderboard/livad-medal-1.gif')} alt='' />
        ) : item.order === 2 ? (
          <img src={toAbsoluteUrl('/assets/icons/Leaderboard/livad-medal-2.gif')} alt='' />
        ) : item.order === 3 ? (
          <img src={toAbsoluteUrl('/assets/icons/Leaderboard/livad-medal-3.gif')} alt='' />
        ) : (
          item.order
        )}
      </span>
      <div className={styles.leaderboard_info_box}>
        <span className={styles.leaderboard_user_photo}>
          <img
            src={
              item.streamer_logo ?? toAbsoluteUrl('/assets/icons/Leaderboard/defaultProfileImg.png')
            }
            alt='logo'
            onError={(e) => {
              e.currentTarget.src = toAbsoluteUrl('/assets/icons/Leaderboard/defaultProfileImg.png')
            }}
          />
        </span>
        <span className={styles.leaderboard_user_platform}>
          <img src={toAbsoluteUrl(`./assets/icons/Leaderboard/${item.platform}-icon.png`)} alt='' />
        </span>
        <span className={styles.leaderboard_user_flag}>
          {item.flag !== null ? <img src={item.flag} alt='flag' /> : null}
        </span>
        <span className={styles.leaderboard_item_username}>{item.streamer_title}</span>
        <span className={styles.leaderboard_item_score}>
          {t('LeaderboardPage.score')} <b>{item.points}</b>
        </span>
      </div>
    </div>
  )
}

export default SingleResult
