import styles from './OverlaySetup.module.scss'
import {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'

//COMPONENTS
import Steps from './components/Steps/Steps'
import SoftwareTab from './components/SoftwareTab/SoftwareTab'
import StepInfo from './components/StepInfo/StepInfo'
import CopyLink from './components/CopyLink/CopyLink'
import BrowserSource from './components/BrowserSource/BrowserSource'
import SetOverlay from './components/SetOverlay/SetOverlay'
import Placement from './components/Placement/Placement'
import LockOverlay from './components/LockOverlay/LockOverlay'
import CheckOverlay from './components/CheckOverlay/CheckOverlay'
import StepButtons from './components/StepButtons/StepButtons'
import SettingsContext from 'context/SettingsContext'
import EnumSetupPreview from 'services/model/enum/enumSetupPreview'

const OverlaySetup = () => {
  const {t} = useTranslation()
  const {
    setupPreviewConfirmed,
    updateSetupPreviewConfirmed,
    checkSetupPreviewConfirmedDiff,
    checkSetupPreviewConfirmedSave,
  } = useContext(SettingsContext)
  const [activeTab, setActiveTab] = useState(1)
  const [softwareTab, setSoftwareTab] = useState('obs')
  const [checkOverlayStatus, setCheckOverlayStatus] = useState<EnumSetupPreview | null>(null)

  return (
    <div className={styles.overlaySetup}>
      <div className={styles.sectionTitle}>{t('SettingsPage.overlaySetup')}</div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.overlaySetup')}</div>
        <div className={styles.desc}>{t('SettingsPage.setLivadsOverlay')}</div>
      </div>
      <Steps activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab !== 6 && <SoftwareTab softwareTab={softwareTab} setSoftwareTab={setSoftwareTab} />}
      <StepInfo
        activeTab={activeTab}
        checkOverlayStatus={checkOverlayStatus}
        setCheckOverlayStatus={setCheckOverlayStatus}
      />
      {(activeTab === 1 && (
        <CopyLink softwareTab={softwareTab} setSoftwareTab={setSoftwareTab} />
      )) ||
        (activeTab === 2 && (
          <BrowserSource softwareTab={softwareTab} setSoftwareTab={setSoftwareTab} />
        )) ||
        (activeTab === 3 && (
          <SetOverlay softwareTab={softwareTab} setSoftwareTab={setSoftwareTab} />
        )) ||
        (activeTab === 4 && (
          <Placement softwareTab={softwareTab} setSoftwareTab={setSoftwareTab} />
        )) ||
        (activeTab === 5 && (
          <LockOverlay softwareTab={softwareTab} setSoftwareTab={setSoftwareTab} />
        )) ||
        (activeTab === 6 && <CheckOverlay />)}
      <StepButtons
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        checkOverlayStatus={checkOverlayStatus}
      />
    </div>
  )
}

export default OverlaySetup
