import styles from './Switch.module.scss'
import classNames from 'classnames'
import {useContext, useEffect} from 'react'

// Context
import LiveConsoleContext from 'context/LiveConsoleContext'

const Switch = () => {
  const {adSettings, setAdSettings, saveAdSettings} = useContext(LiveConsoleContext)
  useEffect(() => {
    console.log({adSettings})
  },[adSettings])
  return (
    <div
      className={classNames(styles.switchToggle, {
        [styles.active]: adSettings?.ads_on === 1,
      })}
      onClick={() => {
        setAdSettings({...adSettings, ads_on: adSettings?.ads_on === 1 ? 0 : 1})
        saveAdSettings({...adSettings, ads_on: adSettings?.ads_on === 1 ? 0 : 1})
      }}
    >
      <span className={styles.toggleCircle}></span>
    </div>
  )
}

export default Switch
