import {useContext, useEffect} from 'react'
import styles from './LeaderBoard.module.scss'
import {useTranslation} from 'react-i18next'
// COMPONENTS
import Stats from './components/Stats/Stats'
import Results from './components/Results/Results'
import AwardsBox from './components/AwardsBox/AwardsBox'
import ScoreInfoBox from './components/ScoreInfoBox/ScoreInfoBox'
import ReactLoading from 'react-loading'
import LeaderboardNav from './components/LeaderboardNav/LeaderboardNav'
// CONTEXT
import LeaderboardContext from 'context/LeaderboardContext'

const LeaderBoard = () => {
  const {t} = useTranslation()
  document.title = t('Pages.leaderboard') + ' • LIVAD'
  const {leaderboardData, getLeaderboardData, pageLoading, selectedIndex} =
    useContext(LeaderboardContext)

  useEffect(() => {
    if (!leaderboardData) {
      getLeaderboardData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={styles.leaderboard_container}>
        {pageLoading || leaderboardData?.[selectedIndex] == undefined ? (
          <div className='mainLoadingContainer'>
            <ReactLoading type='spin' color='#5d7fff' height={'100px'} width={'100px'} />
          </div>
        ) : (
          <>
            <LeaderboardNav />
            <div className={styles.section_left}>
              <Stats />
              {leaderboardData?.[selectedIndex]?.contest?.isPrized &&
              leaderboardData?.[selectedIndex]?.contest?.prizes?.length ? (
                <AwardsBox />
              ) : null}
              <ScoreInfoBox />
            </div>
            <div
              className={styles.section_right}
              style={{
                background: leaderboardData?.[selectedIndex]?.contest?.brandColor1
                  ? `linear-gradient(180deg, #252525 0%, ` +
                    leaderboardData?.[selectedIndex]?.contest?.brandColor1 +
                    ` 100%)`
                  : undefined,
              }}
            >
              <Results />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default LeaderBoard
