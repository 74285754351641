import {toAbsoluteUrl} from 'helpers'
import {useContext, useEffect, useState} from 'react'
import classNames from 'classnames'
import {useNavigate} from 'react-router-dom'
//STYLES
import styles from './DonationPreferences.module.scss'
import SettingsContext from 'context/SettingsContext'
import Loading from 'components/Shared/Loading/Loading'
import {set} from 'lodash'
import EnumTipPosition from 'services/model/enum/enumTipPosition'
import EnumTipType from 'services/model/enum/enumTipType'
import EnumDonationType from 'services/model/enum/enumDonationType'

const DonationPreferences = () => {
  const navigate = useNavigate()
  const [donationType, setDonationType] = useState<EnumTipType | null>(null)
  const [selectedTakeoverId, setSelectedTakeoverId] = useState<number | null>(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const {
    donationPreferences,
    donationCanBeSaved,
    getDonationPreferencesDiff,
    setDonationPreferences,
    updateDonationPreferences,
    fetchDonationPreferences,
  } = useContext(SettingsContext)

  useEffect(() => {
    if (donationPreferences == null) {
      fetchDonationPreferences()
    }
  }, [donationPreferences])

  if (donationPreferences == null) {
    return <Loading />
  }
  return (
    <div className={styles.donationPreferences}>
      <div className={styles.line}>
        <span
          className={styles.guideBtn}
          onClick={() => {
            navigate('/fans-guide')
          }}
        >
          Learn How to Configure Tip Preferences (Turkish Guide)
        </span>
      </div>
      <div className={styles.line}>
        <div className={styles.label}>Tip Overlay</div>
        <div className={styles.desc}>Enable or disable your tip overlay.</div>
        <div className={styles.inLine}>
          <div
            className={classNames(styles.switch, {
              [styles.enabled]: donationPreferences.tipEnabled,
            })}
          >
            <div
              className={styles.switchButton}
              onClick={() => {
                setDonationPreferences({...donationPreferences, tipEnabled: false})
              }}
            >
              <div className={styles.switchButtonInner}>Disabled</div>
            </div>
            <div
              className={styles.switchButton}
              onClick={() => {
                setDonationPreferences({...donationPreferences, tipEnabled: true})
              }}
            >
              <div className={styles.switchButtonInner}>Enabled</div>
            </div>
            <div className={styles.switchButton_overlay}></div>
          </div>
        </div>
      </div>

      {donationPreferences.tipEnabled ? (
        <>
          <div className={styles.line}>
            <div className={styles.label}>Anonymous</div>
            <div className={styles.desc}>
              Enable or disable anonymous tips. If disabled, it will be required to enter their name
              while tipping.
            </div>
            <div className={styles.inLine}>
              <div
                className={classNames(styles.switch, {
                  [styles.enabled]: donationPreferences.anonymousEnabled,
                })}
              >
                <div
                  className={styles.switchButton}
                  onClick={() => {
                    setDonationPreferences({...donationPreferences, anonymousEnabled: false})
                  }}
                >
                  <div className={styles.switchButtonInner}>Disabled</div>
                </div>
                <div
                  className={styles.switchButton}
                  onClick={() => {
                    setDonationPreferences({...donationPreferences, anonymousEnabled: true})
                  }}
                >
                  <div className={styles.switchButtonInner}>Enabled</div>
                </div>
                <div className={styles.switchButton_overlay}></div>
              </div>
            </div>
          </div>

          <div className={styles.line}>
            <div className={styles.label}>LIVAD for Fans Assets</div>
            <div className={styles.desc}>Download LIVAD for Fans assets for your channel.</div>
            <div className={styles.inLine}>
              <button
                className={styles.downloadBtn}
                onClick={() => {
                  window.open(
                    'https://livad.blob.core.windows.net/livad-fans/streamerPack/fans-tr-assets-comp.zip'
                  )
                }}
              >
                Download
              </button>
            </div>
          </div>

          <div className={styles.line}>
            <div className={styles.label}>Tip Widget Position</div>
            <div className={styles.desc}>In which part of the screen should the tip appear?</div>
            <div className={styles.widgetPosition}>
              {[
                EnumTipPosition.TopLeft,
                EnumTipPosition.CenterTop,
                EnumTipPosition.TopRight,
                EnumTipPosition.CenterLeft,
                EnumTipPosition.CenterMiddle,
                EnumTipPosition.CenterRight,
                EnumTipPosition.BottomLeft,
                EnumTipPosition.CenterBottom,
                EnumTipPosition.BottomRight,
              ].map((position: EnumTipPosition, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setDonationPreferences({...donationPreferences, position})
                    }}
                    className={classNames(styles.position, {
                      [styles.selected]: donationPreferences.position === position,
                    })}
                  >
                    <img
                      src={
                        donationPreferences.position === position
                          ? toAbsoluteUrl('/assets/icons/Settings/checkIcon.png')
                          : undefined
                      }
                      alt='selected'
                    />
                  </div>
                )
              })}
            </div>
            {/* <select
              onChange={(e) => {
                setWidgetPosition(e.target.value)
              }}
            >
              <option value='topLeft'>Top Left</option>
              <option value='topRight'>Top Right</option>
              <option value='bottomLeft'>Bottom Left</option>
              <option value='bottomRight'>Bottom Right</option>
              <option value='centerLeft'>Center Left</option>
              <option value='centerRight'>Center Right</option>
              <option value='centerTop'>Center Top</option>
              <option value='centerBottom'>Center Bottom</option>
              <option value='centerMiddle'>Center Middle</option>
            </select> */}
          </div>

          <div className={styles.line}>
            <div className={styles.label}>Tip Widget Colors</div>
            <div className={styles.desc}>Set your widget colors. You can use the color picker.</div>
            <div className={styles.pickerSection}>
              <div className={styles.picker}>
                <span className={styles.pickerType}>Primary</span>
                <input
                  type='color'
                  value={donationPreferences.primaryColor}
                  onChange={(e) => {
                    let value = e.target.value
                    setDonationPreferences({...donationPreferences, primaryColor: value})
                  }}
                />
              </div>
              <div className={styles.picker}>
                <span className={styles.pickerType}>Text</span>
                <input
                  type='color'
                  value={donationPreferences.textColor}
                  onChange={(e) => {
                    let value = e.target.value
                    setDonationPreferences({...donationPreferences, textColor: value})
                  }}
                />
              </div>
              <div className={styles.picker}>
                <span className={styles.pickerType}>Background</span>
                <input
                  type='color'
                  value={donationPreferences.backgroundColor}
                  onChange={(e) => {
                    let value = e.target.value
                    setDonationPreferences({...donationPreferences, backgroundColor: value})
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.line}>
            <div className={styles.label}>Widget Preview</div>
            <div className={styles.previewWidget}>
              <div className={styles.widgetTheme_default}>
                <div
                  className={styles.borderColor}
                  style={{background: donationPreferences.primaryColor}}
                >
                  <div
                    className={styles.widget}
                    style={{background: donationPreferences.backgroundColor}}
                  >
                    <span
                      className={styles.newTip}
                      style={{
                        background: donationPreferences.primaryColor,
                        color: donationPreferences.textColor,
                      }}
                    >
                      New Tip
                    </span>
                    <div className={styles.title} style={{color: donationPreferences.textColor}}>
                      <span className={styles.donator}>Name</span> tipped{' '}
                      <span
                        className={styles.amount}
                        style={{color: donationPreferences.primaryColor}}
                      >
                        50$
                      </span>
                    </div>
                    <div
                      className={styles.donationText}
                      style={{color: donationPreferences.textColor}}
                    >
                      Tip message will look like this
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.line}>
            <div className={styles.label}>Min Tip Amount</div>
            <div className={styles.desc}>Set your minimum amount for display in your overlay.</div>
            <div className={styles.inLine}>
              <span>{donationPreferences.currencySign}</span>
              <input
                onChange={(e) => {
                  let value = e.target.value
                  if (value == '') {
                    setDonationPreferences({...donationPreferences, minAmount: null})
                  } else if (/^[0-9\b]+$/.test(value)) {
                    setDonationPreferences({...donationPreferences, minAmount: parseInt(value)})
                  } else {
                    return
                  }
                }}
                value={donationPreferences.minAmount?.toString() ?? ''}
                type='text'
                placeholder='Amount'
              />
            </div>
            {donationPreferences.minAmount === null ||
            donationPreferences.minAmount < donationPreferences.currencyMinAmount ? (
              <div className={styles.desc} style={{color: '#f33'}}>
                The minimum amount should be higher then {donationPreferences.currencyMinAmount}{' '}
                {donationPreferences.currencySign}
              </div>
            ) : null}
          </div>

          <div className={styles.line}>
            <div className={styles.label}>Custom Tip Minimum Amount</div>
            <div className={styles.desc}>
              Select tip type to set settings. Each set is made specific to that type.
            </div>
            <div className={styles.donationTypes}>
              <div
                className={classNames(styles.typeItem, {
                  [styles.checked]: donationType === EnumTipType.TAKEOVER,
                })}
                onClick={() => {
                  setDonationType(EnumTipType.TAKEOVER)
                }}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/Settings/Donations/takeover_icon.png')}
                  alt='icon'
                />
                Takeover
              </div>
              <div
                className={classNames(styles.typeItem, {
                  [styles.checked]: donationType === EnumTipType.AIImage,
                })}
                onClick={() => {
                  setDonationType(EnumTipType.AIImage)
                }}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/Settings/Donations/donationAI_icon.png')}
                  alt='icon'
                />
                AI-Image
              </div>
              <div
                className={classNames(styles.typeItem, {
                  [styles.checked]: donationType === EnumTipType.IMAGE,
                })}
                onClick={() => {
                  setDonationType(EnumTipType.IMAGE)
                }}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/Settings/Donations/donationGIF_icon.png')}
                  alt='icon'
                />
                Image / GIF
              </div>
              <div
                className={classNames(styles.typeItem, {
                  [styles.checked]: donationType === EnumTipType.VIDEO,
                })}
                onClick={() => {
                  setDonationType(EnumTipType.VIDEO)
                }}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/Settings/Donations/donationVideo_icon.png')}
                  alt='icon'
                />
                Video
              </div>
              {/* <div
                className={classNames(styles.typeItem, {
                  [styles.checked]: donationType === 'text',
                })}
                onClick={() => {
                  setDonationType('text')
                }}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/Settings/Donations/donationMessage_icon.png')}
                  alt='icon'
                />
                Text
              </div> */}
            </div>
          </div>

          {donationType &&
          [EnumTipType.AIImage, EnumTipType.IMAGE, EnumTipType.VIDEO].includes(donationType) ? (
            <div className={styles.line}>
              <div className={styles.desc}>
                Set a minimum amount for{' '}
                {donationType === EnumTipType.AIImage
                  ? 'AI-Image'
                  : donationType === EnumTipType.IMAGE
                  ? 'Image'
                  : donationType === EnumTipType.VIDEO
                  ? 'Video'
                  : ''}{' '}
                Tips.
              </div>
              <div className={styles.inLine}>
                <span>{donationPreferences.currencySign}</span>
                <input
                  onChange={(e) => {
                    let value = e.target.value
                    let minAmount: number | undefined = undefined
                    if (value == '') {
                      minAmount = undefined
                    } else if (/^[0-9\b]+$/.test(value)) {
                      minAmount = parseInt(value)
                    } else {
                      return
                    }
                    // Set donationPreferences.typePrices
                    const typePrices = donationPreferences.typePrices.map((x) => {
                      if (x.type === donationType) {
                        x.minAmount = minAmount
                      }
                      return x
                    })
                    setDonationPreferences({...donationPreferences, typePrices})
                  }}
                  value={
                    donationPreferences.typePrices
                      .find((x) => x.type === donationType)
                      ?.minAmount?.toString() ?? ''
                  }
                  type='text'
                  placeholder='Amount'
                />
              </div>
            </div>
          ) : null}
          {donationType === EnumTipType.TAKEOVER && (
            <>
              <div className={styles.line}>
                <div className={styles.desc}>Set a custom minimum amount for Takeover effects.</div>
                <div className={styles.inLine}>
                  <span>{donationPreferences.currencySign}</span>
                  <input
                    onChange={(e) => {
                      let value = e.target.value
                      let minAmount: number | undefined = undefined
                      if (value == '') {
                        minAmount = undefined
                      } else if (/^[0-9\b]+$/.test(value)) {
                        minAmount = parseInt(value)
                      } else {
                        return
                      }
                      // Set donationPreferences.typePrices
                      const typePrices = donationPreferences.typePrices.map((x) => {
                        if (x.type === donationType) {
                          x.minAmount = minAmount
                        }
                        return x
                      })
                      setDonationPreferences({...donationPreferences, typePrices})
                    }}
                    value={
                      donationPreferences.typePrices
                        .find((x) => x.type === EnumTipType.TAKEOVER)
                        ?.minAmount?.toString() ?? ''
                    }
                    type='text'
                    placeholder='Amount'
                  />
                </div>
                {donationPreferences.takeoverPrices.length > 0 ? (
                  <>
                    <div className={styles.desc}>Set a custom amount for the selected effect.</div>
                    <div className={styles.donationTypes}>
                      {donationPreferences.takeoverPrices.map((item, index) => (
                        <div
                          key={index}
                          className={classNames(styles.typeItem, {
                            [styles.checked]: selectedTakeoverId === item.takeover.id,
                          })}
                          onClick={() => {
                            setSelectedTakeoverId(item.takeover.id)
                          }}
                          style={{
                            backgroundImage: `url("${item.takeover.logo}")`,
                          }}
                        >
                          {item.takeover.name}
                        </div>
                      ))}
                    </div>
                    {selectedTakeoverId ? (
                      <div className={styles.inLine}>
                        <span>{donationPreferences.currencySign}</span>
                        <input
                          onChange={(e) => {
                            let value = e.target.value
                            let minAmount: number | undefined = undefined
                            if (value == '') {
                              minAmount = undefined
                            } else if (/^[0-9\b]+$/.test(value)) {
                              minAmount = parseInt(value)
                            } else {
                              return
                            }
                            // Set donationPreferences.typePrices
                            const takeoverPrices = donationPreferences.takeoverPrices.map((x) => {
                              if (x.takeover.id === selectedTakeoverId) {
                                x.minAmount = minAmount
                              }
                              return x
                            })
                            setDonationPreferences({
                              ...donationPreferences,
                              takeoverPrices: [...takeoverPrices],
                            })
                          }}
                          value={
                            donationPreferences.takeoverPrices
                              .find((x) => x.takeover.id === selectedTakeoverId)
                              ?.minAmount?.toString() ?? ''
                          }
                          type='text'
                          placeholder='Amount'
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
              {selectedTakeoverId &&
              donationPreferences.takeoverPrices.find((x) => x.takeover.id === selectedTakeoverId)
                ?.takeover?.mainVideo ? (
                <div key={selectedTakeoverId} className={styles.line}>
                  <div className={styles.label}>Preview</div>
                  <div className={styles.desc}>Preview of your selected donation type overlay.</div>
                  <div className={styles.previewScreen}>
                    <div className={styles.overlayEffect}>
                      <video key={selectedTakeoverId} loop muted autoPlay>
                        <source
                          src={
                            donationPreferences.takeoverPrices.find(
                              (x) => x.takeover.id === selectedTakeoverId
                            )?.takeover?.mainVideo
                          }
                          type='video/mp4'
                        />
                      </video>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : null}
      {donationCanBeSaved() && getDonationPreferencesDiff() ? (
        <div
          className={classNames(styles.line, styles.lineSave, {
            [styles.disabled]: isSaving,
          })}
        >
          <button
            onClick={async () => {
              if (isSaving) return
              setIsSaving(true)
              try {
                await updateDonationPreferences()
              } catch (error) {
                console.log(error)
              }
              setIsSaving(false)
            }}
          >
            Save
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default DonationPreferences
