import {useState, useEffect, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {loadingHandler} from 'helpers'
// CONTEXT
import CampaignContext from 'context/CampaignContext'
// COMPONENTS
import ActionBar from './components/ActionBar/ActionBar'
import CardView from './components/CardView/CardView'
import ListView from './components/ListView/ListView'
import Loading from 'components/Shared/Loading/Loading'
import Affiliate from './components/Affiliate/Affiliate'

const Campaigns = () => {
  const {getAllCampaigns, activeFilter, setActiveFilter, getFeaturedCampaigns} =
    useContext(CampaignContext)
  const [activeView, setActiveView] = useState('card')
  const [pageLoading, setPageLoading] = useState(true)
  const {t} = useTranslation()
  document.title = t('Pages.campaigns') + ' • LIVAD'

  useEffect(() => {
    loadingHandler([getAllCampaigns(), getFeaturedCampaigns()], setPageLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter])

  return (
    <>
      <ActionBar setActiveView={setActiveView} activeView={activeView} />
      {/* VIEWS */}
      {!pageLoading && activeView === 'card' ? (
        <CardView />
      ) : !pageLoading && activeView === 'list' ? (
        <ListView />
      ) : (
        <Loading />
      )}

      {/* AFFILIATE */}
      <Affiliate />

      {/* FEATURED LIST */}
      <ActionBar variant={'featured'} setActiveView={setActiveView} activeView={activeView} />

      {!pageLoading && activeView === 'card' ? (
        <CardView variant={'featured'} />
      ) : !pageLoading && activeView === 'list' ? (
        <ListView variant={'featured'} />
      ) : (
        <Loading />
      )}
    </>
  )
}

export default Campaigns
