import {useContext} from 'react'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import styles from '../../PayoutAccount.module.scss'
import PayoutAccountContext from 'context/PayoutAccountContext'
import {useTranslation} from 'react-i18next'

const InvoiceSelection = () => {
  const {t} = useTranslation()
  const {payoutAccountData, setPayoutAccountData} = useContext(PayoutAccountContext)
  if (!payoutAccountData) return null
  return (
    <div className={styles.line}>
      <div className={styles.label}>{t('SettingsPage.invoiceStatus')}</div>
      <div className={styles.desc}>{t('SettingsPage.ifYouAreBusiness')}</div>
      <div className={styles.inLine}>
        <div className={styles.invoiceStatus}>
          <div
            // className={styles.invoiceType}
            className={classNames(styles.invoiceType, {
              [styles.selected]: payoutAccountData.billing_preference === 'available',
            })}
            onClick={() => {
              setPayoutAccountData({...payoutAccountData, billing_preference: 'available'})
            }}
          >
            <img src={toAbsoluteUrl('/assets/icons/Settings/yesInvoice.png')} alt='invoice' />
            {t('SettingsPage.canCreateInvoice')}
          </div>
          <div
            className={classNames(styles.invoiceType, {
              [styles.selected]: payoutAccountData.billing_preference === 'not_available',
            })}
            onClick={() => {
              setPayoutAccountData({
                ...payoutAccountData,
                billing_preference: 'not_available',
              })
            }}
          >
            <img src={toAbsoluteUrl('/assets/icons/Settings/noInvoice.png')} alt='invoice' />
            {t('SettingsPage.cannotCreateInvoice')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceSelection
