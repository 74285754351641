import {GetClipInsightsDetailResponse} from 'services/model/response/clipInsights/getClipInsightsDetailResponse'
import styles from './ClipHeader.module.scss'
import {useNavigate} from 'react-router-dom'
import {formatNumber, toAbsoluteUrl} from 'helpers'
import {t} from 'i18next'

interface ClipHeaderProps {
  clipDetail: GetClipInsightsDetailResponse
}

const ClipHeader = ({clipDetail}: ClipHeaderProps) => {
  const navigate = useNavigate()
  return (
    <div className={styles.clipHeader}>
      <div className={styles.section}>
        <div className={styles.clipInfo}>
          <div className={styles.brand}>
            <img
              src={
                clipDetail.client.logo ?? toAbsoluteUrl('/assets/icons/defaultBusinessAvatar.png')
              }
              alt='Logo'
              onError={(e) => {
                e.currentTarget.src = toAbsoluteUrl('/assets/icons/defaultBusinessAvatar.png')
              }}
            />
            {clipDetail.client.name}
          </div>
          <div className={styles.time}>
            {new Date(clipDetail.timestamp).toLocaleDateString(
              `${localStorage.getItem('i18nextLng') || 'en'}`,
              {
                month: 'short',
                day: 'numeric',
                year:
                  new Date(clipDetail.timestamp).getFullYear() !== new Date().getFullYear()
                    ? 'numeric'
                    : undefined,
                hour: 'numeric',
                minute: 'numeric',
              }
            )}
          </div>
        </div>
        <div className={styles.clipDetail}>
          <div className={styles.detailItem}>
            <span className={styles.title}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  d='M7.99998 4.88889C7.70558 4.8935 7.41335 4.94027 7.13221 5.02778C7.26225 5.25648 7.33149 5.5147 7.33332 5.77778C7.33332 5.98206 7.29308 6.18434 7.21491 6.37307C7.13673 6.5618 7.02215 6.73328 6.87771 6.87773C6.73326 7.02217 6.56178 7.13675 6.37305 7.21493C6.18432 7.2931 5.98204 7.33334 5.77776 7.33334C5.51468 7.33151 5.25646 7.26227 5.02776 7.13223C4.84733 7.758 4.86836 8.42469 5.08787 9.03785C5.30739 9.65101 5.71426 10.1796 6.25086 10.5486C6.78746 10.9177 7.42659 11.1086 8.0777 11.0942C8.72881 11.0799 9.3589 10.861 9.87873 10.4687C10.3986 10.0764 10.7818 9.5304 10.9741 8.90817C11.1664 8.28593 11.158 7.61897 10.9502 7.00175C10.7424 6.38452 10.3456 5.84834 9.81612 5.46913C9.28663 5.08993 8.65125 4.88693 7.99998 4.88889ZM15.9033 7.59445C14.3969 4.65528 11.4147 2.66667 7.99998 2.66667C4.58526 2.66667 1.60221 4.65667 0.0966515 7.59473C0.0331076 7.72042 0 7.8593 0 8.00014C0 8.14099 0.0331076 8.27986 0.0966515 8.40556C1.60304 11.3447 4.58526 13.3333 7.99998 13.3333C11.4147 13.3333 14.3978 11.3433 15.9033 8.40528C15.9669 8.27959 16 8.14071 16 7.99987C16 7.85902 15.9669 7.72015 15.9033 7.59445ZM7.99998 12C5.25971 12 2.74748 10.4722 1.39082 8C2.74748 5.52778 5.25943 4.00001 7.99998 4.00001C10.7405 4.00001 13.2525 5.52778 14.6092 8C13.2528 10.4722 10.7405 12 7.99998 12Z'
                  fill='#5D7FFF'
                />
              </svg>
              {t(`ClipInsightsPopup.views`)}
            </span>
            <div className={styles.value}>{formatNumber(clipDetail.views)}</div>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.title}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  d='M7.80671 11.9933C6.78046 11.9448 5.81235 11.5028 5.10346 10.7592C4.39458 10.0155 3.99941 9.02737 4.00004 7.99998C4.00004 5.79331 5.79337 3.99998 8.00004 3.99998C10.1467 3.99998 11.8934 5.68665 11.9934 7.80665L10.5934 7.38665C10.4844 6.9245 10.254 6.49984 9.92595 6.15656C9.5979 5.81329 9.18413 5.56385 8.7274 5.43404C8.27067 5.30422 7.78756 5.29874 7.32801 5.41816C6.86845 5.53758 6.44913 5.77757 6.11338 6.11332C5.77763 6.44907 5.53764 6.86839 5.41822 7.32795C5.2988 7.7875 5.30428 8.27061 5.4341 8.72734C5.56391 9.18407 5.81335 9.59784 6.15662 9.92589C6.4999 10.2539 6.92456 10.4843 7.38671 10.5933L7.80671 11.9933ZM14.6667 7.99998C14.6667 8.19998 14.66 8.39998 14.64 8.59998L13.3267 8.20665C13.3334 8.13998 13.3334 8.06665 13.3334 7.99998C13.3334 5.05331 10.9467 2.66665 8.00004 2.66665C5.05337 2.66665 2.66671 5.05331 2.66671 7.99998C2.66671 10.9466 5.05337 13.3333 8.00004 13.3333C8.06671 13.3333 8.14004 13.3333 8.20671 13.3266L8.60004 14.64C8.40004 14.66 8.20004 14.6666 8.00004 14.6666C4.32004 14.6666 1.33337 11.68 1.33337 7.99998C1.33337 4.31998 4.32004 1.33331 8.00004 1.33331C11.68 1.33331 14.6667 4.31998 14.6667 7.99998ZM12.1534 10.84L14.6667 9.99998L8.00004 7.99998L10 14.6666L10.84 12.1533L13.6867 15L15.0067 13.68L12.1534 10.84Z'
                  fill='#5D7FFF'
                />
              </svg>
              {t(`ClipInsightsPopup.clicks`)}
            </span>
            <div className={styles.value}>{formatNumber(clipDetail.clicks)}</div>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.title}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  d='M14.0625 4C13.839 4.00007 13.6187 4.05224 13.4189 4.15235C13.2191 4.25247 13.0454 4.39778 12.9116 4.57675C12.7778 4.75572 12.6876 4.96341 12.6481 5.18335C12.6086 5.40329 12.6209 5.6294 12.684 5.84375L10.4537 8.07375C10.1596 7.97577 9.84161 7.97577 9.54747 8.07375L7.89403 6.42031C7.94844 6.208 7.95358 5.98607 7.90906 5.77147C7.86455 5.55687 7.77155 5.35529 7.63718 5.18214C7.50281 5.00899 7.33063 4.86886 7.1338 4.77246C6.93697 4.67607 6.7207 4.62595 6.50153 4.62595C6.28236 4.62595 6.0661 4.67607 5.86926 4.77246C5.67243 4.86886 5.50025 5.00899 5.36588 5.18214C5.23151 5.35529 5.13851 5.55687 5.094 5.77147C5.04948 5.98607 5.05462 6.208 5.10903 6.42031L2.34372 9.18375C2.03671 9.09287 1.70817 9.1069 1.41002 9.22363C1.11188 9.34036 0.861143 9.55312 0.697442 9.82828C0.533741 10.1034 0.466414 10.4253 0.506104 10.743C0.545794 11.0607 0.690236 11.3562 0.9166 11.5826C1.14296 11.809 1.43834 11.9536 1.75603 11.9934C2.07373 12.0332 2.39563 11.9659 2.67084 11.8023C2.94606 11.6387 3.1589 11.388 3.27572 11.0899C3.39255 10.7918 3.40668 10.4633 3.31591 10.1562L6.04622 7.42625C6.34035 7.5243 6.65834 7.5243 6.95247 7.42625L8.60622 9.08C8.55181 9.29231 8.54667 9.51425 8.59118 9.72885C8.6357 9.94345 8.7287 10.145 8.86307 10.3182C8.99744 10.4913 9.16962 10.6314 9.36645 10.7278C9.56328 10.8242 9.77955 10.8744 9.99872 10.8744C10.2179 10.8744 10.4342 10.8242 10.631 10.7278C10.8278 10.6314 11 10.4913 11.1344 10.3182C11.2687 10.145 11.3617 9.94345 11.4063 9.72885C11.4508 9.51425 11.4456 9.29231 11.3912 9.08L13.6562 6.81625C13.854 6.87459 14.0619 6.88971 14.266 6.86059C14.4701 6.83147 14.6656 6.75879 14.8391 6.64748C15.0126 6.53617 15.1602 6.38884 15.2718 6.21548C15.3833 6.04212 15.4563 5.84679 15.4858 5.64274C15.5152 5.43869 15.5004 5.23069 15.4424 5.03287C15.3843 4.83504 15.2844 4.65201 15.1494 4.4962C15.0144 4.34038 14.8475 4.21543 14.6599 4.12981C14.4724 4.0442 14.2686 3.99993 14.0625 4Z'
                  fill='#5D7FFF'
                />
              </svg>
              {t(`ClipInsightsPopup.ctr`)}
            </span>
            <div className={styles.value}>{clipDetail.CTR}%</div>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.title}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
              >
                <g clip-path='url(#clip0_16_852)'>
                  <path
                    d='M3.11111 4.00001C3.11111 4.98056 2.31389 5.77778 1.33333 5.77778V10.2222C2.31389 10.2222 3.11111 11.0194 3.11111 12H12.8889C12.8889 11.0194 13.6861 10.2222 14.6667 10.2222V5.77778C13.6861 5.77778 12.8889 4.98056 12.8889 4.00001H3.11111ZM0 4.44445C0 3.46389 0.797222 2.66667 1.77778 2.66667H14.2222C15.2028 2.66667 16 3.46389 16 4.44445V11.5556C16 12.5361 15.2028 13.3333 14.2222 13.3333H1.77778C0.797222 13.3333 0 12.5361 0 11.5556V4.44445ZM4.88889 8.00001C4.88889 7.59145 4.96936 7.18689 5.12571 6.80943C5.28206 6.43198 5.51122 6.08901 5.80011 5.80012C6.08901 5.51122 6.43197 5.28206 6.80943 5.12571C7.18689 4.96937 7.59144 4.88889 8 4.88889C8.40856 4.88889 8.81311 4.96937 9.19057 5.12571C9.56803 5.28206 9.91099 5.51122 10.1999 5.80012C10.4888 6.08901 10.7179 6.43198 10.8743 6.80943C11.0306 7.18689 11.1111 7.59145 11.1111 8.00001C11.1111 8.40856 11.0306 8.81312 10.8743 9.19058C10.7179 9.56803 10.4888 9.911 10.1999 10.1999C9.91099 10.4888 9.56803 10.7179 9.19057 10.8743C8.81311 11.0306 8.40856 11.1111 8 11.1111C7.59144 11.1111 7.18689 11.0306 6.80943 10.8743C6.43197 10.7179 6.08901 10.4888 5.80011 10.1999C5.51122 9.911 5.28206 9.56803 5.12571 9.19058C4.96936 8.81312 4.88889 8.40856 4.88889 8.00001ZM7.11111 6.66667C7.11111 6.91112 7.31111 7.11112 7.55556 7.11112V8.88889H7.33333C7.08889 8.88889 6.88889 9.08889 6.88889 9.33334C6.88889 9.57778 7.08889 9.77778 7.33333 9.77778H8H8.66667C8.91111 9.77778 9.11111 9.57778 9.11111 9.33334C9.11111 9.08889 8.91111 8.88889 8.66667 8.88889H8.44444V6.66667C8.44444 6.42223 8.24444 6.22223 8 6.22223H7.55556C7.31111 6.22223 7.11111 6.42223 7.11111 6.66667Z'
                    fill='#5D7FFF'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_16_852'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              {t(`ClipInsightsPopup.earnings`)}
            </span>
            <div className={styles.value}>
              {clipDetail.currency.currencySign}
              {clipDetail.earnings}
            </div>
          </div>
        </div>
      </div>
      <button className={styles.closePopupBtn} onClick={() => navigate('/clip-insights')}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M16.3964 7.757C16.4894 7.84988 16.5632 7.96016 16.6135 8.08156C16.6638 8.20296 16.6897 8.33309 16.6897 8.4645C16.6897 8.59592 16.6638 8.72605 16.6135 8.84744C16.5632 8.96884 16.4894 9.07913 16.3964 9.172L13.4144 12.153L16.0904 14.828C16.2781 15.0156 16.3835 15.2701 16.3835 15.5355C16.3835 15.8009 16.2781 16.0554 16.0904 16.243C15.9028 16.4306 15.6483 16.5361 15.3829 16.5361C15.1176 16.5361 14.8631 16.4306 14.6754 16.243L12.0004 13.567L9.32543 16.243C9.13779 16.4306 8.8833 16.5361 8.61793 16.5361C8.35257 16.5361 8.09807 16.4306 7.91043 16.243C7.72279 16.0554 7.61738 15.8009 7.61738 15.5355C7.61738 15.2701 7.72279 15.0156 7.91043 14.828L10.5864 12.153L7.60443 9.172C7.51152 9.07903 7.43784 8.96866 7.38758 8.84721C7.33732 8.72575 7.31148 8.59559 7.31152 8.46415C7.31157 8.33271 7.33751 8.20256 7.38785 8.08114C7.43819 7.95973 7.51196 7.84941 7.60493 7.7565C7.69791 7.66359 7.80827 7.5899 7.92973 7.53965C8.05118 7.48939 8.18134 7.46355 8.31279 7.46359C8.44423 7.46364 8.57437 7.48957 8.69579 7.53992C8.81721 7.59026 8.92752 7.66403 9.02043 7.757L12.0004 10.74L14.9814 7.758C15.0743 7.66503 15.1846 7.59127 15.306 7.54094C15.4274 7.49062 15.5575 7.46472 15.6889 7.46472C15.8203 7.46472 15.9505 7.49062 16.0719 7.54094C16.1933 7.59127 16.3036 7.66403 16.3964 7.757Z'
            fill='#7B7B7B'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4 1C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4V20C1 20.7956 1.31607 21.5587 1.87868 22.1213C2.44129 22.6839 3.20435 23 4 23H20C20.7956 23 21.5587 22.6839 22.1213 22.1213C22.6839 21.5587 23 20.7956 23 20V4C23 3.20435 22.6839 2.44129 22.1213 1.87868C21.5587 1.31607 20.7956 1 20 1H4ZM20 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H20C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3Z'
            fill='#7B7B7B'
          />
        </svg>
      </button>
    </div>
  )
}

export default ClipHeader
